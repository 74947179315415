define('wow-app/models/abstract-photo', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({

		mosaicStatus: DS['default'].attr('string'),

		//link:           DS.attr('string'),
		//mosaicId:       DS.attr('string'),
		//profileId:      DS.attr('string'),
		//x:       DS.attr('integer'),
		//y:       DS.attr('integer'),
		//posX:    DS.attr('integer', { defaultValue: -50 } ), // x position in tilezoom
		//posY:    DS.attr('integer', { defaultValue: -50 } ), // y position in tilezoom
		//created: DS.attr('date', { defaultValue: function() { return new Date(); } } ),
		//pic_owner_token: DS.attr('string'),

		metadata: DS['default'].attr('object'),

		croppedSrc: (function () {
			// e.g. http://wowmosaic-develop.dev.wallofwonders.com/cropped/ID/0.12-0.193/500x500
			var wowmosaicFrontendUrlBase = this.get('globals').get('srvParams.wowmosaicFrontendUrlBase');
			var path = wowmosaicFrontendUrlBase + '/cropped';
			if (!this.get('metadata.cropX') || !this.get('metadata.cropY')) {
				return path + '/' + this.get('id');
			}
			return path + '/' + this.get('id') + '/' + this.get('metadata.cropX') + '-' + this.get('metadata.cropY');
		}).property('metadata.cropX', 'metadata.cropY'),

		originalSrc: (function () {
			var wowmosaicFrontendUrlBase = this.get('globals').get('srvParams.wowmosaicFrontendUrlBase');
			var path = wowmosaicFrontendUrlBase + '/original';
			return path + '/' + this.get('id');
		}).property(),

		created: (function () {
			var date = this.get('metadata').created;
			return $.format.date(date, "MM.dd.yyyy");
		}).property('metadata'),

		wonder: (function () {
			return this.get('metadata').wonderId;
		}).property('metadata'),

		owner: (function () {
			//if(typeof console === 'object') { console.log('AbstractPhotoModel hasOwner profileId',this.get('metadata').profileId); }
			var profileId = this.get('metadata').profileId;
			if (profileId && profileId.length > 0) {
				return profileId;
			}
			return null;
		}).property('metadata.profileId'),

		//user: DS.belongsTo('user',{async:true}),
		//
		//userData: function() {
		//	var profileId = this.get('metadata').profileId;
		//	return this.get('user').then(function(user){
		//		if(typeof console === 'object') { console.log('AbstractPhotoModel.userData?',user); }
		//		return user.mapBy('metadata.profileId');
		//	});
		//
		//}.property('metadata'),

		statusText: (function () {

			//var mosaicStatus = this.get('mosaicStatus');
			var mosaicStatus = this.get('metadata').mosaicStatus;

			switch (mosaicStatus) {

				case 'MOSAIC_STATUS_APPROVED':
					{

						return 'APPROVED';
					}
				case 'MOSAIC_STATUS_PENDING':
					{

						return 'UPLOADED';
					}
				case 'MOSAIC_STATUS_REJECTED':
					{

						return 'REJECTED';
					}
				default:
					{

						return 'UNKNOWN';
					}
			}
		}).property('mosaicStatus'),

		statusCls: (function () {

			var mosaicStatus = this.get('metadata').mosaicStatus;

			switch (mosaicStatus) {

				case 'MOSAIC_STATUS_APPROVED':
					{

						return 'fa-check-circle';
					}
				case 'MOSAIC_STATUS_PENDING':
					{

						return 'fa-clock-o';
					}
				case 'MOSAIC_STATUS_REJECTED':
					{

						return 'fa-times-circle';
					}
				default:
					{

						return 'UNKNOWN';
					}
			}
		}).property('mosaicStatus'),

		posStyle: (function () {

			return 'left:' + this.get('metadata').pos[0] + 'px;top:' + this.get('metadata').pos[1] + 'px';
		}).property('metadata.posX', 'metadata.posY')
	});

});