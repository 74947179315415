define('wow-app/routes/change-wonder', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		isSecure: true,
		docTitle: 'Change Wonder',

		baseRouteViewSettings: {
			visibleContent: 'change-wonder',
			activeNavigationItem: 'my-wonderlock',
			templates: {
				bottom: {
					name: 'choose-wonder', // use choose-wonder view
					outlet: 'bottom',
					height: 250,
					parent: 'mosaic',
					resizeTilezoom: 'soft',
					scrollable: '-buttonarea'
				},
				top: {
					name: 'photo-lock',
					outlet: 'top',
					parent: 'mosaic'
				}
			},
			showContent: true
		},

		/**
	  * load photo by id
	  *
	  * @param params
	  * @param transition
	  * @returns {*}
	  */
		model: function model(params, transition) {

			var me = this;

			return me.store.find('picture', params.photo_id).then(function (photo) {
				return photo;
			}, function (reason) {

				var mosaic_id = me.getMosaicId(params, transition);

				if (mosaic_id) {
					me.transitionTo('choose-wonder', mosaic_id);
				} else {
					me.transitionTo('start');
				}

				me.controllerFor('error-modal').raise({
					statusText: 'Sorry',
					statusCode: 404,
					reason: reason,
					message: 'The photo <strong>"' + params.photo_id + '"</strong> could not be found'
				});

				return null;
			});
		},

		/**
	  * Refers to BaseView._lookupsettings
	  * Usally _lookupsettings returns just the baseRouteViewSettings but
	  * if getBaseRouteViewSettings is defined you may use this function
	  * to set baseRouteViewSettings dynamically
	  *
	  * @returns {baseRouteViewSettings|{visibleContent, templates, showContent}|{visibleContent, activeNavigationItem, templates, showContent}}
	  */
		getBaseRouteViewSettings: function getBaseRouteViewSettings() {
			var me = this,
			    orientation = UTIL.getOrientation(),
			    settings = $.extend(true, {}, me.baseRouteViewSettings);

			switch (orientation) {
				case 'landscape':
					settings.templates.bottom.height = 160;
					settings.templates.bottom.width = 900;
					settings.templates.bottom.isOverlay = true;
					delete settings.templates.top;
					break;
			}

			return settings;
		},

		afterModel: function afterModel() {
			this.updateBaseView();
		},

		renderTemplate: function renderTemplate(controller, model) {

			this.render('choose-wonder', {
				into: 'mosaic',
				outlet: 'bottom',
				controller: controller,
				model: model,
				view: 'choose-wonder'
			});

			this.render('photo-lock', {
				into: 'mosaic',
				outlet: 'top',
				model: model
			});
		}

	});

});