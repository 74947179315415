define('wow-app/templates/accept-terms/ko', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("wonderswall.com 모바일 웹사이트, 앱, 웹사이트(또한, 때때로 여기에서 통칭 wonderswall.com 또는 웹사이트라고 함)를 입력하거나 그 기능을 이용함으로써, 웹사이트, 귀하가 서비스 및 기타 제반 관련 사항에 대해 다음의 이용 약관을 준수할 것에 동의하는 것입니다.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("저작권");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("아랍 에미레이트 두바이 Global Platform Limited(GPL) 2014-2021 저작권");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("NewOpenWorld Corporation의 허가를 받음.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("연락처: wonderswall dot com으로 연락하십시오.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("판권 소유. ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("주의 사항 및 기타 통신에 대한 지적 재산권 대리인");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("WONDERSWALL.COM에 참여와 관련된 주요 용어");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("사진 업로드");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("웹사이트의 일부는 귀하가 우리에게 사진을 전송할 수 있게 합니다. 우리는 자료가 모든 사람이 즐길 수 있는 사이트에 남아 있도록 하기 위해 자료의 콘텐츠 질과 적합성에 대해 간단히 확인하지만, 콘텐츠 문제 발생 시 책임을 지지 않습니다.\n        귀하가 제공하는 모든 자료는 타인이나 타 집단의 저작권 또는 기타 권리를 침해하지 않는다는 것을 보증합니다.\n        귀하가 필요한 권한이 없는 다른 사람이 소유한 자료를 보내지 마십시오.\n        GPL은 이러한 제삼자가 소유한 자료에 대해 명시적으로 책임을 지지 않습니다. GPL은 귀하가 고의로 또는 무의식적으로 GPL에 제삼자의 콘텐츠를 제공하여 발생하는\n        데이터 손실, 수익의 손실, 사업의 손실, 기회의 손실, 모든 간접적, 결과적, 특수적 또는 예시적 손해 배상을 포함하지만 이에 한정되지 않는 (손실이 예측되었든, 예측되든, 알려졌든 또는 다른 방식이든) 모든 손실에 대해 귀하 또는 제삼자에게 책임을 질 수 없습니다.  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("업로드된 콘텐츠의 소유권");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("귀하가 웹사이트에 사진을 업로드하는 경우, 귀하의 기증을 제출함으로써 GLS에 귀하의 기증에 대해 전 세계적으로 사용, 복제, 수정, 조정, 출판, 번역, 이차적 저작물을 창출하고, 배포하고, 공연하고, 재생하고, 대중에 공개하고, 모든 저작권 및 공표 권리를 행사하고 또는 귀하의 기여에 존재할 수 있는 권리의 전체 기간 동안 귀하의 기여에 대해 현재 알려졌거나 나중에 개발되는 미디어의 다른 작품과 통합할 수 있는 영구적인, 사용료 없는, 비독점적, 서브 라이센스 권리 및 사용권을 허용하는 데 동의하는 것입니다.\n        GPL에 상기에 정한 권리를 부여하지 않으려면, 사진을 업로드하지 마십시오.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Wallofwonders.com의 사진");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL은 사진을 수락하거나 거부할 권리를 보유합니다. 사진의 위치는 불가사의의 창조된 최적의 전체 이미지를 위해 또는 GPL.이 정한 다른 이유로 언제라도 변경될 수 있으며, GPL은 가끔 업로드된 사진을 재량으로 GPL이 얻은 다른 사진으로 보완할 수 있습니다.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("윤리 및 도덕적 행동 강령");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("귀하는 다음 목적으로 웹사이트를 사용할 수 없습니다.(1) 불법적, 학대적, 중상적, 모욕적, 위협적, 유해적, 저속적, 음란적 또는 다른 방식으로 이의가 생길 여지가 있거나 다른 방식으로 법을 위반하는 자료의 전파, (2) 귀하가 속하거나 다른 법원 관할 구역에서 형사상 범죄가 되거나, 민사 책임을 초래하거나 다른 방식으로 적용 가능한 법률, 규정 또는 실천 요강을 위반하는 행동을 고무시키는 자료를 전송, (3) 타인의 웹사이트 향유를 방해 또는 소유자의 허가 없이 저작권에 의해 보호받는 자료의 전자 사본을 만들거나, 전송하거나 저장 귀하는 이러한 요구 사항의 위반으로 인한 손실과 비용에 대해 책임이 있습니다. 우리는 우리가 재량으로 적합하다고 보는 자료를 제거할 수 있는 권리를 보유합니다.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("기타 일반 이용 약관");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("저작권 및 기타 지적 재산권 및 규약의 존중");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL은 타인의 지적 재산을 존중하며, 우리 사이트 사용자에게도 그렇게 할 것을 요청합니다. GPL은 필요한 경우 재량에 따라 GPL 및 타인의 지적 재산권을 침해할 수 있는 사용자의 계정을 사용 금지 및/또는 해지시킬 수 있습니다.귀하의 작품이 저작권 침해가 되는 방식으로 복사되었거나 귀하의 지적 재산권이 다른 방식으로 침해되었다고 생각될 경우, 연락하시기 바랍니다.   ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("New7Wonders 상표");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("New7Wonders 및 기타 New7Wonders 로고, 제품 및 서비스 이름은 GPL로 표현되는 New Open World Corporation의 등록 상표입니다. 귀하는 GPL의 사전 허가 없이 어떤 방식으로든 New7Wonders 상표를 표시하거나 사용하지 않을 것을 동의합니다.귀하가 웹사이트를 이용하고 참여하면 귀하는 어떤 방식으로든 모든 미디어에서 그리고 모든 웹사이트에서 귀하의 개인적, 비상업적 용도를 제외한 어떤 방식으로든 New7Wonders 콘텐츠를 복사, 복제, 재발행, 다운로드, 게시, 방송, 전송, 공개 또는 다른 방식으로 사용하지 않을 것에 동의하는 것을 확인하는 것입니다. 귀하는 귀하 자신의 개인적, 엄격히 비상업적인 용도를 제외하고 웹사이트 또는 기타 New7Wonders 콘텐츠에서 파생 작품을 개작하거나, 변경하거나 만들지 않을 것에 동의합니다. 웹사이트 또는 New7Wonders를 다른 용도로 사용하려면 GPL의 사전 서면 동의가 필요합니다. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("개인 정보");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL은 귀하의 개인 정보를 소중하게 다룹니다.우리는 반드시 우리의 사용자에 대한 특정 정보를 수집해야 하므로 귀하가 해당 정보의 수집 및 이용을 둘러싼 이용 약관에 대해 이해하는 데 도움이 되고자 합니다.귀하가 GPL이 정보의 업데이트 및 기타 통신의 목적을 위해 귀하에게 연락하는 것을 승인한 경우, 귀하에 대한 세부 사항은 GPL 데이터베이스에 저장될 수 있습니다.GPL은 전자 이미지 등 특정 표준 장치를 이용하여 일반적인 사용자 트래픽 패턴을 추적할 수 있습니다.GPL은 또한 우리의 서버와 귀하의 브라우저에서 자동으로 귀하의 IP 주소, 시간 및 귀하가 요청한 페이지에 대한 정보를 수신 및 기록할 수 있습니다.웹에서 일반적으로 그러하듯이, GPL은 또한 다른 소스에서 귀하에 대한 정보를 수신하여 귀하가 우리에게 제공한 정보에 추가할 수 있습니다. GPL은 아래 네 개의 일반적 목적을 위해 정보를 이용합니다. 1. 귀하가 보는 광고 및 콘텐츠를 사용자에 맞춤; 2.우리의 사이트의 네트워크에 대한 사용자 경험을 향상. 우리는 각 페이지가 총 사용자 통계 및 이러한 페이지에 대한 트래픽 패턴에 기반을 두어 전반적으로 얼마나 잘 수행하는지 확인하기 위해 트래킹 정보를 이용합니다. 이것은 우리가 귀하를 위해 더 나은 서비스를 구축하는 데 도움을 줍니다. 3. 장래에 전자 뉴스레터의 발송 및 사용자의 여론 조사, 콘텐츠, 게시판 및 경매 참여 등 특정 제품 및 서비스에 대한 귀하의 요청 이행. 4.우리가 귀하에게 도움이 된다고 생각하는 주제에 대해 귀하가 수신하기로 동의한 정보 발송. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("국가법");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("인터넷의 글로벌 특성을 인식하고, 귀하는 온라인 행동 및 허용 콘텐츠에 대한 모든 해당 국가 및 지역의 법률, 규정 및 규칙을 준수하는 데 동의합니다. 특히, 귀하는 귀하가 거주하는 국가로부터 반출하는 기술 자료의 전송에 관한 모든 해당 법을 준수하는 것에 동의합니다.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("면책");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("귀하는 귀하가 서비스, 서비스의 사용, 서비스에 연결, 이용 약관의 위반 또는 다른 사람의 권리 침해 통해 제출, 게시, 전송 또는 공표하는 콘텐츠로 인해 제삼자가 청구한 합리적인 변호사 비용을 포함한 모든 배상 청구 또는 요구에 대해 책임을 지고 보상하고 GPL과 그 자회사, 계열사, 임원, 대리인, 코브랜더(co-brander) 또는 기타 파트너 그리고 직원을 면책합니다. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("서비스의 재판매 금지");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("귀하는 서비스 일부를 상업적 목적으로 복제, 복제, 복사, 판매, 재판매 또는 사용하거나, 서비스를 사용하거나 서비스에 액세스하지 않을 것에 동의합니다.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("웹사이트의 수정");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL은 통지 후 또는 통지 없이 언제든지 또는 수시로 웹사이트를 일시적 또는 영구적으로 수정 또는 중단할 권리를 보유합니다. 귀하는 GPL이 웹사이트의 수정, 일시 중지 또는 중단에 대해 귀하 또는 제삼자에 책임을 지지 않는 것에 동의합니다.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("링크");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("웹사이트 또는 제삼자는 다른 월드 와이드 웹 사이트 또는 자원에 대한 링크를 제공할 수 있습니다. GPL은 이러한 사이트 및 자원에 대한 관리 권한이 없으므로 귀하는 GPL이 이러한 외부 사이트 또는 자원의 가용성에 대해 책임이 없으며, 이러한 사이트 또는 자원에서 사용 가능한 콘텐츠, 광고, 제품 또는 기타 자료를 보증하지 않으며 이에 대해 책임을 지지 않는다는 것에 인정하고 동의합니다. 귀하는 또한 GPL이 이러한 사이트 또는 자원에서 사용 가능한 콘텐츠, 상품 또는 서비스의 사용과 관련하여 발생하거나 발생한 것으로 주장되는 손해 또는 손실에 대해 책임을 지지 않는다는 것을 인정하고 동의합니다. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("데이터 소유권");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL이 표시 또는 발표할 수 있는 불가사의의 벽 참여 등 모든 데이터는 저작권을 소유하고 있습니다. 이 데이터의 사용은 구속력을 갖는 특정 규칙이 적용됩니다. 뉴스 매체:인정되는 뉴스 언론 매체는 이 자료를 텍스트가 항상 눈에 보이는 방식으로 뉴스 맥락에만 복제할 수 있습니다. 데이터 © www.wonderswall.com.기타 용도:단체, 회사, 웹사이트에 의한 다른 용도는 GPL의 허가 및 승인을 받아야 합니다. 자세한 정보를 원하시면 연락하십시오.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("기술 서비스의 부인");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("New7Wonders 및 불가사의의 벽 또는 그 서비스와 관련된 정보, 이름, 이미지, 사진 및 로고를 포함하여 웹사이트 콘텐츠는 “있는 그대로” 및 “사용 가능한 근거”에 입각하여 비침해, 호환성, 보안 및 정확성을 포함하여 법이 허용하는 범위까지(명시적 또는 묵시적으로 법에 의해) 이루어진 어떠한 표명이나 보증 없이 제공됩니다. GPL은 웹사이트 콘텐츠에 포함된 기능이 지연 또는 중단 없이 또는 사진 또는 기타 전송이 정확하거나 완전할 것이며, 결함은 수정될 것이라는 점을 또는 사용 가능한 웹사이트나 서버가 바이러스 또는 버그 또는 다른 손상을 주는 인터넷 기반 자료의 염려가 없다는 점을 보증하지 않습니다. 어떠한 상황에서도 GPL은 데이터 손실, 수익의 손실, 사업의 손실, 기회의 손실, 모든 간접적, 결과적, 특수적 또는 예시적 손해 배상을 포함하지만 이에 한정되지 않는 (손실이 예측되었든, 예측되든, 알려졌든 또는 다른 방식이든) 모든 손실에 대해 귀하 또는 제삼자에게 책임을 지지 않습니다.  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("보증의 부인");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("귀하는 귀하의 웹사이트 사용이 전적으로 귀하의 책임이라는 것을 명시적으로 이해합니다. 웹사이트는 “있는 그대로” 및 “사용 가능한 근거”에 입각하여 제공됩니다. GPL은 명시적이든 묵시적이든 특정 목적 및 비침해에 대해 상업성, 적합성의 묵시적인 보증을 포함하지만 이에 한정되지 않는 어떠한 종류의 보증도 명시적으로 부인합니다. GPL은 (1) 웹사이트가 귀하의 요구 사항을 충족하며, (2) 웹사이트가 중단되지 않고, 시기에 알맞고, 안전하고 오류가 없으며, (3) 웹사이트를 사용하여 얻는 결과는 정확하고 신뢰할 수 있으며, (4) 웹사이트를 통해 귀하가 구매하거나 획득한 제품, 서비스, 정보 또는 다른 자료의 품질이 귀하의 기대에 부응하며, (5) 소프트웨어의 오류는 수정될 것이라고 보증하지 않습니다. 허용되는 경우 웹사이트의 사용을 통해 다운로드하거나 다른 방식으로 획득한 자료는 귀하 자신의 판단과 책임 하에 이루어지며, 이러한 자료의 다운로드로 인한 귀하 컴퓨터 시스템의 손상 또는 데이터 손실은 전적으로 귀하의 책임입니다. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("책임의 제한");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("귀하는 GPL이 다음으로 인한 이익의 손실, 영업권, 사용, 데이터 또는 기타 무형의 손실 등의 손해 배상금을 포함하지만 이에 한정되지 않는 직접적, 간접적, 부수적, 특별한, 결과적 또는 예시적인 손해 배상금에 대해 (GPL이 이러한 손해에 대한 가능성에 대해 통지를 받은 경우에도) 책임을 지지 않는다는 것을 명시적으로 이해하고 동의합니다. (1) 웹사이트의 사용 또는 사용 불능, (2) 웹사이트를 통해 또는 웹사이트로부터 구매하거나, 획득하거나, 수신한 메시지 또는 맺은 계약으로 인한 상품, 데이터, 정보 또는 서비스 대체 상품 및 서비스 조달 비용, (3) 귀하의 전송 또는 데이터에 무단 액세스 또는 변경, (4) 웹사이트에 제삼자의 성명 또는 행동 또는 (5) 웹사이트에 관한 기타 사항. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("사용자 연령");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("우리는 wallof wonders.com이 모든 사람과 모든 연령대에 열려 있다고 믿습니다.그러나 일부 국가와 문화권에서 사용자의 연령에 관한 다른 법률 또는 다른 틀이 있을 수 있음을 이해합니다.우리는 법으로 금지되어 있는 경우 (자신의 나라에서) 허용하는 연령 미만의 사람으로부터 개인 정보를 의도적으로 수집 또는 요청하거나 그러한 사람이 등록하도록 의도적으로 허용하지 않습니다.귀하의 나라가 등록을 금지하는 경우, 귀하는 웹사이트에 등록하거나, 귀하의 이름, 주소, 전화번호 또는 이메일 주소를 포함하지만 이에 한정되지 않는 귀하에 대한 정보를 우리에게 전송하려 하면 안 됩니다. 귀하의 나라가 허가받을 것을 요구하는 경우, 웹사이트에 개인 정보를 제공하기 전에 귀하의 부모/보호자의 허가를 받으십시오. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("조건의 변경");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL은 이러한 이용 약관을 언제든지 온라인으로 변경 내용을 게시하여 변경할 수 있는 권리를 보유합니다. 이러한 조건을 정기적으로 검토하여 GPL에 의해 이루어진 변경 내용에 대해 알고 계십시오. 변경 사항이 게시된 후 귀하가 계속 웹사이트를 사용하면 귀하가 이러한 업데이트 및/또는 개정된 조건에 법적으로 구속되는 것에 동의한다는 확인을 의미합니다. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("준거법");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("이 이용 약관은 스위스 취리히 주의 법률에 따라 지배되고 해석됩니다.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});