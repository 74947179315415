define('wow-app/views/upload-photo', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].View.extend({

		classNames: ['transparent', 'view-upload-photo']

	});

});