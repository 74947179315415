define('wow-app/views/navbar', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].View.extend({

		didInsertElement: function didInsertElement() {
			this.onResponse();
			this.clickAddition();
		},

		/**
	  * Additional to all navi clicks, execute this click function
	  */
		clickAddition: function clickAddition() {

			var me = this,
			    controller = me.get('controller'),
			    $element = me.$(),
			    tilezoomController = controller.get('controllers.tilezoom');

			var clickAddition = function clickAddition(e) {
				tilezoomController.tilezoom.cont.tilezoom('stopScreensaver', e);
				//tilezoomController.tilezoom.cont.tilezoom('removeTilephoto', e);
			};

			$element.find('li a').off('click', clickAddition).on('click', clickAddition);
			$element.find('.upload-teaser-wrapper').off('click', clickAddition).on('click', clickAddition);
			$element.find('li.up').off('click', clickAddition).on('click', clickAddition);
		},

		onResponse: function onResponse() {
			var me = this,
			    $element = me.$();

			// check if it the landscape or portrait navigation
			var portrait = $element.closest('.app-navigation.portrait');

			// if navigation is too small to fit in, use swiper (only on mobile?)
			if (portrait && portrait.get(0)) {
				if (this.swiper) {
					this.destroySwiper();
				}

				var loadSwiper = function loadSwiper() {
					portrait.addClass('hasSwiper');
					portrait.find('.swiper-container-off').addClass('swiper-container');
					portrait.find('.swiper-wrapper-off').addClass('swiper-wrapper');
					var thisNavBarSwiper = $element.find('.navbar-swiper');
					if (thisNavBarSwiper && thisNavBarSwiper.get(0)) {
						me.swiper = new Swiper(thisNavBarSwiper.get(0), {
							slidesPerView: 'auto',
							//slidesPerView: 5,
							mousewheelControl: true,
							//paginationClickable: true,
							spaceBetween: 0,
							freeMode: true
						});
					}
				};

				setTimeout(function () {
					loadSwiper();
				}, 100);
			}

			var uploadTeaser = $element.find('.upload-teaser');
			if (uploadTeaser && uploadTeaser.get(0)) {
				this.uploadTeaserAttraction(uploadTeaser);
			}
		},

		uploadTeaserAttraction: function uploadTeaserAttraction(uploadTeaser) {

			var min = 10000;
			var max = 30000;
			var r = Math.random() * (max - min) + min;

			var me = this;

			uploadTeaser.addClass('animated bounce');
			uploadTeaser.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
				uploadTeaser.removeClass('bounce').removeClass('animated');
			});

			setTimeout(function () {
				me.uploadTeaserAttraction(uploadTeaser);
			}, r);
		},

		destroySwiper: function destroySwiper() {

			var me = this,
			    $element = me.$();

			if (!$element) {
				return false;
			}

			var portrait = $element.closest('.app-navigation.portrait');
			portrait.removeClass('hasSwiper');
			portrait.find('.swiper-container-off').removeClass('swiper-container');
			portrait.find('.swiper-wrapper-off').removeClass('swiper-wrapper');

			try {
				if (this.swiper && typeof this.swiper.destroy === 'function') {
					this.swiper.destroy();
					this.swiper = null;
				}
			} catch (e) {
				if (typeof console === 'object') {
					console.log('Swiper Error', e);
				}
			}
		},

		/**
	  * Called when the element of the view is going to be destroyed.
	  * Override this function to do any teardown that requires an element, like removing event listeners.
	  */
		willDestroyElement: function willDestroyElement() {
			this.destroySwiper();
		},

		actions: {
			toggleContent: function toggleContent(m) {
				if (typeof console === 'object') {
					console.log('NavbarView.toggleContent', m);
				}
			}
		}

	});

});