define('wow-app/routes/user-stay', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		docTitle: 'Stay',

		baseRouteViewSettings: {
			visibleContent: 'user-stay',
			activeNavigationItem: 'choose-wonder',
			templates: {
				bottom: {
					//isRendered: true,
					name: 'user-stay',
					outlet: 'bottom',
					height: 280,
					width: 400,
					parent: 'mosaic',
					scrollable: true
				},
				top: {
					//isRendered: true,
					name: 'photo-lock',
					outlet: 'top',
					parent: 'mosaic'
				}
			},
			showContent: true
		},

		/**
	  * load current picture
	  *
	  * @param params
	  * @param transition
	  * @returns {*}
	  */
		model: function model(params, transition) {

			//if(typeof console === 'object') { console.log('StayOnWallRoute model',params, transition); }

			var me = this;

			return me.store.find('picture', params.photo_id).then(function (photo) {
				return photo;
			}, function () {
				var mosaic_id = me.getMosaicId(params, transition);

				if (mosaic_id) {
					me.transitionTo('choose-wonder', mosaic_id);
				} else {
					me.transitionTo('start');
				}
				return null;
			});
		},

		afterModel: function afterModel() {
			this.updateBaseView();
		},

		renderTemplate: function renderTemplate(controller, model) {

			this.render({
				into: 'mosaic',
				outlet: 'bottom'
			});

			//if(typeof console === 'object') { console.log('renderTemplate.model',model); }

			this.render('photo-lock', {
				into: 'mosaic',
				outlet: 'top',
				model: model
			});
		}

	});

});