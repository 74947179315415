define('wow-app/locales/de', ['exports', 'wow-app/locales/en'], function (exports, EN) {

    'use strict';

    var DE = {
        "about": {
            "sub_title": "Hinterlasse Dein Zeichen!<br />Mach mit bei der WondersWall!<br>Sei dabei!",
            "text": "<p>Hunderte Millionen Stimmen haben die Neuen 7 Weltwunders gewählt und jetzt kannst DU Teil der WondersWall werden - dort oben mit deinem eigenen Foto in einem der Neuen 7 Weltwunder.</p><p>Wähle dein Wunder. Lade dein Foto hoch. Teile es mit der ganzen Welt <br>Ja, du bist jetzt Teil der <a href=\"https://twitter.com/hashtag/wonderswall\" target=\"_blank\">#wonderswall</a>!!!</p>"
        },
        "buttons": {
            "cancel": "Abbrechen",
            "change_photo": "Foto ändern",
            "change_wall": "Wall wechseln",
            "change_wonder": "Wunder wechseln",
            "choose_wonder": "Wunder auswählen",
            "close": "Schließen",
            "crop_and_save": "Foto zuschneiden und speichern",
            "crop_photo": "Foto zuschneiden",
            "join": "Anmelden",
            "log_out": "Abmelden",
            "ok": "OK",
            "register": "Registrieren",
            "save": "Speichern",
            "select": "Auswählen",
            "share": "Teilen",
            "sign_in": "Anmelden",
            "sign_up": "Registrieren",
            "upload": "Foto hochladen",
            "copy": "Kopieren",
            "yes": "Ja!",
            "edit": "Edit",
            "forget_password": "Passwort vergessen?"
        },
        "congratulation": {
            "sub_title": "Du bist jetzt Teil der<br>WondersWall",
            "title": "Herzlichen Glückwunsch!"
        },
        "global": {
            "error_title": "Entschuldigung",
            "next": "Nächstes",
            "previous": "Vorheriges",
            "title": "Willkommen auf der WondersWall"
        },
        "joyscreen": {
            "join": "Mach mit bei der<span class=\"break-portrait break-landscape\"></span>WondersWall",
            "title": "Möchtest Du Teil der<span class=\"break-portrait break-landscape\"></span>WondersWall werden?",
            "welcome": "Willkommen auf der<span class=\"break-portrait break-landscape\"></span>WondersWall"
        },
        "mosaic": {
            "manmade": {
                "title": "New 7 Wonders of the World"
            },
            "nature": {
                "title": "New 7 Wonders of Nature"
            }
        },
        "my_photo": {
            "my_wonder": "Mein Wunder",
            "no_login": "Du bist nicht angemeldet! Du musst dich registrieren, um dich auf der WondersWall eintragen zu können.",
            "no_photo": "Du hast noch kein Foto hochgeladen...",
            "status": "Status",
            "sub_title": "Hier änderst und bearbeitest Du dein Foto, wählst ein anderes Wunder dafür aus oder teilst es mit Freunden.",
            "title": "Mein Foto",
            "uploaded": "Hochgeladen",
            "motto": "Say something",
            "motto_placeholder": "Tell us about your photo",
            "location": "Location",
            "city": "City",
            "country": "Country",
            "ping": "WOW!",
            "my_wows": "My WOWs",
            "wowed_by": "WOWed by",
            "no_wows": "No WOWs yet."
        },
        "register": {
            "email": "eMail",
            "logged_in_as": "Angemeldet als",
            "password": "Passwort",
            "sign_in_email": "Per eMail anmelden",
            "sign_up_email": "Per eMail registrieren"
        },
        "seo": {
            "meta_description": "Hunderte Millionen Stimmen haben die New7Wonders gewählt und jetzt kannst DU Teil der WondersWall werden."
        },
        "settings": {
            "change_language": "Sprache",
            "email": "eMail",
            "facebook": "Facebook",
            "login": "Anmelden",
            "new_to_wall": "Der WondersWall beitreten?",
            "on_the_wall": "Schon auf der WondersWall?",
            "signin": "Registrieren",
            "title": "Einstellungen",
            "twitter": "Twitter",
            "upload_photo": "Lade ein Foto hoch"
        },
        "share": {
            "fb_caption": "Ich bin jetzt Teil der WondersWall",
            "fb_description": "Mach mit! Lade dein Foto auf www.wonderswall.com hoch und werde selbst zum Wunder! #wonderswall",
            "fb_name": "Willkommen auf der WondersWall",
            "fb_published": "Dein Beitrag wurde veröffentlicht.",
            "sub_title": "Teile Dein Foto mit Freunden <br> und macht gemeinsam bei der WondersWall mit.",
            "title": "Teilen",
            "whatsapp": "Whatsapp",
            "link": "Link",
            "email_from": "Von",
            "email_to": "An",
            "email_subject": "Betreff",
            "email_text": "Dein Text",
            "email_to_placeholder": "Trenne mehrere eMail-Adressen mit einem Komma",
            "email_successfully_send": "eMail erfolgreich gesendet"
        },
        "stay": {
            "accept": "Nutzungsbedinungen akzeptieren",
            "title": "Registriere dich und werde Teil der <span class=\"break-portrait\"></span>WondersWall",
            "privacy_policy": "Datenschutzbestimmungen"
        },
        "terms": {
            "accept": "Bitte akzeptiere unsere Nutzungsbedinungen.",
            "title": "Nutzungsbedinungen"
        },
        "top": {
            "title": "Die meisten Uploads"
        },
        "upload": {
            "image_error": "Foto kann nicht geladen werden. Bitte lade das Bild erneut hoch.",
            "import_fb": "Facebook",
            "import_insta": "Instagram",
            "import_my_photos": "Deine Fotos",
            "import_tw": "Twitter",
            "import_webcam": "Webcam",
            "sub_title": "oder eine Option zum Importieren wählen",
            "take_photo": "Foto auswählen",
            "title": "Foto auswählen",
            "upload_error": "Beim Hochladen deines Bildes ist ein Fehler aufgetreten",
            "upload_save_error": "Foto kann nicht gespeichert werden. Bitte lade das Foto erneut hoch.",
            "webcam_allow_error": "Klicke \"zulassen\", um ein Foto aufzunehmen",
            "webcam_error": "Deine Webcam funktioniert nicht mit deinem Browser. Bitte führe ein Software-Update durch."
        },
        "upload_choose": {
            "change_title": "Wechsel dein Wunder",
            "sub_title": "und lade dein Foto hoch",
            "title": "Wähle dein Wunder"
        },
        "upload_steps": {
            "1": "Schritt 1",
            "2": "Schritt 2",
            "3": "Schritt 3"
        },
        "wall": {
            "upload_photo": "Lade deine Fotos auf die WondersWall hoch"
        },
        "wonders": {
            "amazon": {
                "location": "Südamerika",
                "title": "Amazonas"
            },
            "christ_redeemer": {
                "location": "Brasilien",
                "title": "Erlöserstatue"
            },
            "colosseum": {
                "location": "Italien",
                "title": "Kolosseum"
            },
            "great_wall_of_china": {
                "location": "China",
                "title": "Chinesische Mauer"
            },
            "ha_long_bay": {
                "location": "Vietnam",
                "title": "Ha Long-Bucht"
            },
            "iguazu_falls": {
                "location": "Argentinien/Brasilien",
                "title": "Iguazú-Wasserfälle"
            },
            "jeju_island": {
                "location": "Südkorea",
                "title": "Jeju"
            },
            "komodo": {
                "location": "Indonesien",
                "title": "Komodo"
            },
            "machu_pichu": {
                "location": "Peru",
                "title": "Machu Picchu"
            },
            "petra": {
                "location": "Jordanien",
                "title": "Petra"
            },
            "pp_underground_river": {
                "location": "Philippinen",
                "title": "PP unterirdischer Fluss"
            },
            "pyramid_at_chichen_itza": {
                "location": "Mexiko",
                "title": "Chichén Itzá"
            },
            "table_mountain": {
                "location": "Südafrika",
                "title": "Tafelberg"
            },
            "taj_mahal": {
                "location": "Indien",
                "title": "Taj Mahal"
            }
        },
        "oauth_errors": {
            "login_failed": "Log in via social network failed or was cancelled."
        }
    };

    DE = $.extend(true, {}, EN['default'], DE);

    exports['default'] = DE;

});