define('wow-app/transforms/object', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

	'use strict';

	exports['default'] = DS['default'].Transform.extend({
		deserialize: function deserialize(value) {
			if (!Ember['default'].$.isPlainObject(value)) {
				return {};
			} else {
				return value;
			}
		},
		serialize: function serialize(value) {
			if (!Ember['default'].$.isPlainObject(value)) {
				return {};
			} else {
				return value;
			}
		},
		//deserialize: function(serialized) {
		//	//return serialized;
		//	return Ember.none(serialized) ? {} : serialized;
		//},
		//
		//serialize: function(deserialized) {
		//	//return deserialized;
		//	return Ember.none(deserialized) ? {} : deserialized;
		//},
		from: function from(serialized) {
			return Ember['default'].none(serialized) ? {} : serialized;
		},
		to: function to(deserialized) {
			return Ember['default'].none(deserialized) ? {} : deserialized;
		}
	});

});