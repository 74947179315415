define('wow-app/templates/privacy-policy/ko', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("최종 업데이트: 2015년 11월");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("NewOpenWorld Corporation(\"우리를\", \"우리\", 또는 \"우리의\")는 ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","//www.wonderswall.com");
        var el4 = dom.createTextNode("www.wonderswall.com");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (이하 “사이트”라고 함)을 운영하고 있습니다.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("이 페이지에서는 사이트의 사용자로부터 우리가 받은 개인 정보의 수집, 이용 및 공개에 관한 정책을 알려줍니다.우리는 사이트를 제공하고 개선하는 목적만을 위해 개인 정보를 이용합니다.이 사이트를 사용함으로써 귀하는 이 정책에 따른 정보의 수집 및 이용에 동의하게 됩니다.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("정보 수집 및 이용");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("우리의 사이트를 사용하는 동안 우리는 귀하에게 연락 또는 귀하를 식별하는 데 이용될 수 있는 특정 개인 식별 정보를 제공하도록 요청할 수 있습니다. 개인 식별 정보는 귀하의 이름에 한정되지 않을 수 있습니다(”개인 정보”). ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("로그 데이터");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("많은 사이트 운영자와 마찬가지로, 우리는 귀하가 우리 사이트를 방문할 때마다 귀하의 브라우저가 전송하는 정보를 수집합니다(”로그 데이터”). 이 로그 데이터는 귀하 컴퓨터의 인터넷 프로토콜(”IP”) 주소, 브라우저 유형, 브라우저 버전, 귀하가 방문한 우리 사이트의 페이지, 방문 시간 및 날짜, 이러한 페이지에 머문 시간 및 기타 통계 등의 정보를 포함할 수 있습니다. 또한, 우리는 제삼자의 서비스를 이용하여 이 로그 데이터를 수집, 모니터링 및 분석할 수 있습니다. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("통신");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("우리는 뉴스레터, 마케팅 또는 홍보 자료 및 기타 관심 있는 정보를 제공하기 위해 귀하의 개인 정보를 이용할 수 있습니다. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("쿠키");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("쿠기는 소량의 데이터를 가진 파일이며, 익명의 고유 식별자를 포함할 수 있습니다. 쿠키는 웹사이트에서 귀하의 브라우저로 전송하며, 귀하의 컴퓨터 또는 휴대 전화의 하드 드라이버에 저장됩니다. 많은 사이트와 마찬가지로, 우리는 “쿠키”를 이용하여 정보를 수집합니다. 귀하가 쿠키를 허용하지 않을 경우, 우리 사이트의 일부를 사용하지 못할 수 있습니다.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("보안");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("귀하의 개인 정보에 대한 보안은 우리에게 중요합니다. 그러나 인터넷을 통한 전송 방법이나 전자 저장 방법은 100% 안전하지 않다는 것을 명심하십시오.우리가 귀하의 개인 정보를 보호하기 위해 상업적으로 입수 가능한 방법을 사용하기 위해 노력하고 있지만, 우리는 그것의 절대적인 보안을 보장할 수 없습니다. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("이 개인 정보 보호 정책의 변경");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("이 개인 정보 보호 정책은 상기에 명시된 최종 업데이트 날짜 부로 유효하며, 이 페이지에 게시된 후에 즉시 유효한 미래의 규정 변경에 대하여 제외하고 효력을 유지합니다. 우리는 언제라도 우리의 개인 정보 보호 정책을 갱신하거나 변경할 수 있는 권리를 보유하며, 귀하는 이 개인 정보 보호 정책을 주기적으로 확인해야 합니다.우리가 개인 정보 보호 정책의 수정 사항을 이 페이지에 게시한 후 귀하가 이 서비스를 계속 사용하는 것은 귀하가 그 수정 사항을 인정하고 수정된 개인 정보 보호 정책을 준수하는 데 동의하는 것입니다. 우리가 이 개인 정보 보호 정책에 중대한 변경을 할 경우, 귀하의 개인 정보를 이용하거나 이 사이트에 눈에 잘 띄게 게시하여 귀하에게 통지할 것입니다.  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("연락처");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("개인 정보 보호 정책에 질문이 있는 경우, 우리에게 ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","mailto:support@wonderswall.com");
        var el4 = dom.createTextNode("연락하십시오.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(".");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});