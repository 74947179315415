define('wow-app/controllers/error-modal', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].ObjectController.extend({

		errorObj: {
			statusText: 'Sorry',
			statusCode: 404,
			reason: null,
			message: 'An Error occured.'
		},

		/**
	  * Raise error
	  *
	  *
	  	    me.controllerFor('error-modal').raise({
	 			statusText: 'Sorry',
	 			statusCode: 404,
	 			message:	'An error occured'
	 		});
	  *
	  * @param error
	  */
		raise: function raise(error) {

			error = error.responseJSON ? error.responseJSON : error;

			var me = this,
			    errorObj = this.errorObj,
			    m = null;

			if (error) {
				error = error || {};
				$.extend(errorObj, error);
				m = Ember['default'].Object.create(errorObj);
			}

			// Action is in application route...
			me.controllerFor('Application').send('showModal', 'error-modal', m);

			if (typeof console === 'object') {
				console.log('An error occured', errorObj);
			}
		},

		actions: {

			closeAction: function closeAction() {

				var errorObj = this.get('errorObj');
				if (typeof errorObj === 'object' && errorObj.route) {
					if (errorObj.routeId1) {
						this.transitionToRoute(errorObj.route, errorObj.routeId1);
					} else {
						this.transitionToRoute(errorObj.route);
					}
				}
			}

		}

	});

});