define('wow-app/controllers/joy-screen', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'tilezoom', 'error-modal'],

		runningPicture: null,

		/**
	  * the requested picture might not be a picture of the running user
	  */
		isUserPicture: true

	});

});