define('wow-app/templates/privacy-policy/pt', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Última atualização em: Novembro de 2015");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("A NewOpenWorld Corporation (\"nossa\", \"nosso\" ou \"nós\") opera ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","//www.wonderswall.com");
        var el4 = dom.createTextNode("www.wonderswall.com");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (o \"Site\").");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Esta página informa sobre as nossas políticas relativas à coleta, uso e divulgação de Informações Pessoais que recebemos dos usuários do Site. Usamos as Informações Pessoais somente para fornecer e melhorar o Site. Ao usar o Site, você concorda com a coleta e o uso de informações de acordo com esta política.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Uso e coleta de informações");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Enquanto estiver usando nosso site, poderemos solicitar algumas informações pessoais que podem ser usadas para contatar ou identificar você. As informações pessoais identificáveis podem incluir, mas não se limitam ao seu nome (\"Informações Pessoais\").");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Dados de registro");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Como muitos operadores do site, coletamos informações que seu navegador envia sempre que você visitar o nosso site (\"Registro de Dados\"). Esses Registros de Dados podem incluir informações como o endereço do Internet Protocol (\"IP\") do seu computador, o tipo de navegador, a versão do navegador, as páginas do nosso site que você visita, a data e a hora da sua visita, o tempo gasto nessas páginas e outras estatísticas. Além disso, podemos usar serviços de terceiros para coletar, monitorar e analisar esses Registros de Dados.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Comunicação");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Podemos usar suas informações pessoais para entrar em contato com você através de boletins informativos, marketing ou materiais promocionais e outras informações que sejam de interesse.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Cookies");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Cookies são arquivos com uma pequena quantidade de dados, que podem incluir um identificador único anônimo. Os cookies são enviados para o seu navegador a partir de um site e armazenado no seu computador ou disco rígido do telefone. Como muitos sites, usamos \"cookies\" para coletar informações. Se você não aceita cookies, você poderá não ter acesso a algumas partes do nosso Site.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Segurança");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("A segurança de suas Informações Pessoais é importante para nós, mas lembre-se de que nenhum método de transmissão pela Internet, ou método de armazenamento eletrônico, é 100% seguro. Enquanto nos esforçamos para usar meios comercialmente aceitáveis para proteger suas Informações Pessoais, não podemos garantir segurança absoluta.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Alterações na Política de Privacidade");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Esta Política de Privacidade está em vigor desde a última atualização acima, e permanecerá em vigor exceto com relação a eventuais alterações das suas disposições no futuro, que estarão em vigor imediatamente após serem publicadas nesta página. Reservamo-nos o direito de atualizar ou alterar nossa Política de Privacidade a qualquer momento e você deve verificar esta Política de Privacidade periodicamente. Seu uso continuado do Serviço após publicarmos quaisquer modificações à Política de Privacidade nesta página constituirá o seu reconhecimento das modificações e seu consentimento em cumprir e ser regido pela Política de Privacidade modificada. Se fizermos quaisquer alterações materiais a esta Política de Privacidade, você será notificado através do contato mencionado em sua Informações Pessoais ou através de um aviso em destaque no site.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Entre em contato conosco");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Se tiver qualquer questão sobre esta Política de Privacidade, ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","mailto:support@wonderswall.com");
        var el4 = dom.createTextNode("entre em contato conosco");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(".");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});