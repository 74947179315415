define('wow-app/locales/vi', ['exports', 'wow-app/locales/en'], function (exports, EN) {

    'use strict';

    var VI = {
        "about": {
            "sub_title": "Hãy ghi lại dấu ấn của bạn!<br />Hãy tham gia WondersWall!<br />Hãy tới đó!",
            "text": "<p>Hàng trăm triệu người đã bỏ phiếu bầu ra New7Wonders, và giờ BẠN có thể trở thành một phần của WondersWall với bức ảnh chụp một trong New7Wonders đó của riêng bạn.</p><p>Hãy lựa chọn kỳ quan. Đăng ảnh của bạn. Sẻ chia nó với mọi người. <br />Đúng thế, giờ đây bạn đã là một thành phần của <a href=\"https://twitter.com/hashtag/wonderswall\" target=\"_blank\">#wonderswall</a>!!!</p>"
        },
        "buttons": {
            "cancel": "Hủy",
            "change_photo": "Thay ảnh",
            "change_wall": "Thay tường",
            "change_wonder": "Thay kỳ quan",
            "choose_wonder": "Chọn kỳ quan",
            "close": "Đóng",
            "crop_and_save": "Cắt và lưu ảnh",
            "crop_photo": "Cắt ảnh",
            "join": "Tham gia",
            "log_out": "Thoát",
            "ok": "OK",
            "register": "Đăng ký",
            "save": "Lưu",
            "select": "Lựa chọn",
            "share": "Chia sẻ",
            "sign_in": "Đăng nhập",
            "sign_up": "Đăng ký",
            "upload": "Đăng ảnh",
            "copy": "Sao chép",
            "yes": "Vâng!",
            "edit": "Edit",
            "forget_password": "Quên mật khẩu?"
        },
        "congratulation": {
            "sub_title": "Giờ bạn đã trở thành một phần của <br>WondersWall",
            "title": "Xin chúc mừng"
        },
        "global": {
            "error_title": "Xin lỗi",
            "next": "Tiếp theo",
            "previous": "Trước",
            "title": "WondersWall"
        },
        "joyscreen": {
            "join": "Tham gia vào <span class=\"break-portrait break-landscape\"></span>WondersWall",
            "title": "Bạn muốn ở lại <span class=\"break-portrait break-landscape\"></span>WondersWall?",
            "welcome": "Chào mừng bạn tới với <span class=\"break-portrait break-landscape\"></span>WondersWall"
        },
        "mosaic": {
            "manmade": {
                "title": "7 kỳ quan thế giới mới"
            },
            "nature": {
                "title": "7 kỳ quan thiên nhiên mới"
            }
        },
        "my_photo": {
            "my_wonder": "Kỳ quan của tôi",
            "no_login": "Bạn chưa đăng nhập! Bạn phải đăng ký để ở lại WondersWall.",
            "no_photo": "Bạn vẫn chưa tải ảnh nào lên...",
            "status": "Hiện trạng",
            "sub_title": "Thay ảnh hoặc thay kỳ quan",
            "title": "Ảnh của tôi",
            "uploaded": "Đăng lên",
            "motto": "Say something",
            "motto_placeholder": "Tell us about your photo",
            "location": "Location",
            "city": "City",
            "country": "Country",
            "ping": "WOW!",
            "my_wows": "My WOWs",
            "wowed_by": "WOWed by",
            "no_wows": "No WOWs yet."
        },
        "register": {
            "email": "Email",
            "logged_in_as": "Đăng nhập với tư cách",
            "password": "Mật khẩu",
            "sign_in_email": "Đăng nhập bằng email",
            "sign_up_email": "Đăng ký bằng email"
        },
        "seo": {
            "meta_description": "Hàng trăm triệu người đã bỏ phiếu bầu ra New7Wonders, và giờ BẠN có thể trở thành một phần của WondersWall."
        },
        "settings": {
            "change_language": "Ngôn ngữ",
            "email": "E-Mail",
            "facebook": "Facebook",
            "login": "Đăng nhập",
            "new_to_wall": "Tham gia vào WondersWall?",
            "on_the_wall": "Bạn đã có mặt trên WondersWall?",
            "signin": "Đăng ký",
            "title": "Cài đặt",
            "twitter": "Twitter",
            "upload_photo": "Đăng ảnh"
        },
        "share": {
            "fb_caption": "Giờ tôi đã là một phần của WondersWall.",
            "fb_description": "Tham gia cùng tôi! Đăng ảnh của bạn tại www.wonderswall.com và cả bạn cũng có thể trở thành kỳ quan! #wonderswall",
            "fb_name": "WondersWall",
            "fb_published": "Bài của bạn đã được đăng.",
            "sub_title": "Chia sẻ với bạn bè,<br>cùng gia nhập WondersWall",
            "title": "Chia sẻ",
            "whatsapp": "Whatsapp",
            "link": "Đường dẫn",
            "email_from": "Từ",
            "email_to": "tới",
            "email_subject": "Chủ đề",
            "email_text": "Nội dung văn bản",
            "email_to_placeholder": "Tách các địa chỉ khác nhau bằng dấu phẩy",
            "email_successfully_send": "Đã gửi thành công email"
        },
        "stay": {
            "accept": "Chấp nhận các qui định & điều kiện",
            "title": "Hãy ở lại với <span class=\"break-portrait\"></span>WondersWall",
            "privacy_policy": "Chế độ bảo mật"
        },
        "terms": {
            "accept": "Xin hãy chấp nhận các điều kiện và qui định của chúng tôi",
            "title": "Các qui định & điều kiện"
        },
        "top": {
            "title": "Được đăng lên nhiều nhất"
        },
        "upload": {
            "image_error": "Không đăng được ảnh, xin bạn hãy quay về đăng lại",
            "import_fb": "Facebook",
            "import_insta": "Instagram",
            "import_my_photos": "Ảnh của bạn",
            "import_tw": "Twitter",
            "import_webcam": "Webcam",
            "sub_title": "hoặc chuyển từ dưới lên",
            "take_photo": "Chụp ảnh",
            "title": "Chụp ảnh",
            "upload_error": "Báo lỗi trong quá trình đăng ảnh của bạn",
            "upload_save_error": "Không lưu được ảnh, xin bạn hãy quay về và đăng lại",
            "webcam_allow_error": "Nhấn \"cho phép\" để chụp ảnh",
            "webcam_error": "Webcam không hoạt động với bộ trình duyệt của bạn, xin bạn cập nhật phần mềm máy tính"
        },
        "upload_choose": {
            "change_title": "Thay đổi kỳ quan của bạn",
            "sub_title": "và đăng ảnh của bạn lên trên đó",
            "title": "Chọn kỳ quan của bạn"
        },
        "upload_steps": {
            "1": "Bước 1",
            "2": "Bước 2",
            "3": "Bước 3"
        },
        "wall": {
            "upload_photo": "Đăng ảnh của bạn lên WondersWall"
        },
        "wonders": {
            "amazon": {
                "location": "Nam Mĩ",
                "title": "Amazon"
            },
            "christ_redeemer": {
                "location": "Braxin",
                "title": "Tượng Chúa cứu thế"
            },
            "colosseum": {
                "location": "Ý",
                "title": "Đấu trường La Mã"
            },
            "great_wall_of_china": {
                "location": "Trung Quốc",
                "title": "Vạn lý trường thành"
            },
            "ha_long_bay": {
                "location": "Việt Nam",
                "title": "Vịnh Hạ Long"
            },
            "iguazu_falls": {
                "location": "Argentina/Braxin",
                "title": "Thác Iguazu"
            },
            "jeju_island": {
                "location": "Hàn Quốc",
                "title": "Đảo Jeju"
            },
            "komodo": {
                "location": "Indonesia",
                "title": "Komodo"
            },
            "machu_pichu": {
                "location": "Peru",
                "title": "Machu Picchu"
            },
            "petra": {
                "location": "Jordan",
                "title": "Petra"
            },
            "pp_underground_river": {
                "location": "Philippin",
                "title": "Sông ngầm PP"
            },
            "pyramid_at_chichen_itza": {
                "location": "Mexico",
                "title": "Chichén Itzá"
            },
            "table_mountain": {
                "location": "Nam Phi",
                "title": "Núi Cái Bàn"
            },
            "taj_mahal": {
                "location": "Ấn Độ",
                "title": "Taj Mahal"
            }
        },
        "oauth_errors": {
            "login_failed": "Log in via social network failed or was cancelled."
        }
    };

    VI = $.extend(true, {}, EN['default'], VI);

    exports['default'] = VI;

});