define('wow-app/tests/unit/helpers/photo-url-test', ['wow-app/helpers/photo-url', 'qunit'], function (photo_url, qunit) {

  'use strict';

  qunit.module('Unit | Helper | photo url');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = photo_url.photoUrl(42);
    assert.ok(result);
  });

});