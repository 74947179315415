define('wow-app/router', ['exports', 'ember', 'wow-app/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router = Ember['default'].Router.extend({
    location: config['default'].locationType

    //,didTransition: function() {
    //  this._super(arguments);
    //
    //  if(typeof console === 'object') { console.log('DIDTRANSITION',this,arguments,this.get('url'),this.get('name')); }
    //  return true;
    //  //return ga('send', 'pageview', {
    //  //  'page': this.get('url'),
    //  //  'title': this.get('url')
    //  //});
    //}
  });

  Router.map(function () {
    //this.resource('splash', { path:'splash' });
    this.resource('start', { path: '/' });

    this.route('accept-terms');
    this.route('privacy-policy');

    // redirect route for login services (facebook, twitter)
    this.resource('oauth-login');

    // redirect route for image services (instagram) without login
    this.resource('oauth-service');

    this.resource('mosaic', { path: ':mosaic_Id' }, function () {
      //:mosaic_Id
      //this.resource('screensaver',{ path: 'screensaver'});

      this.resource('about', { path: 'about' });

      this.resource('choose-wonder', { path: 'choose-wonder' });
      this.resource('upload', { path: 'upload' });

      this.resource('photo-crop', { path: 'photo-crop/:photo_id' });
      this.resource('user-stay', { path: 'stay/:photo_id' });
      this.resource('joy-screen', { path: 'joy/:photo_id' });
      this.resource('congratulation', { path: 'congratulation/:photo_id' });
      this.resource('change-wonder', { path: 'change-wonder/:photo_id' });
      this.resource('change-position', { path: 'change-position/:photo_id' });
      this.resource('change-wall', { path: 'change-wall/:photo_id' });
      this.resource('share', { path: 'share/:photo_id' });

      this.resource('my-wonderlock', { path: 'my-photo' });
      this.resource('my-wows', { path: 'my-wows' });
      this.resource('wowed-by', { path: 'wowed-by' });
      this.resource('tilezoommap', { path: 'map' });
      this.resource('base-content-route', { path: 'base' });
      this.resource('top', { path: 'top' });
    });

    this.route('catchall', { path: '/*wildcard' });
  });

  exports['default'] = Router;

});