define('wow-app/templates/privacy-policy/it', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Ultimo aggiornamento: novembre 2015");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("NewOpenWorld Corporation (\"noi\" o \"nostro\") opera ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","//www.wonderswall.com");
        var el4 = dom.createTextNode("www.wonderswall.com");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (il \"Sito\").");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Questa pagina vi informa delle nostre politiche che riguardano la raccolta, l'utilizzo e la divulgazione delle informazioni personali che riceviamo dagli utenti del sito. Utilizziamo le vostre informazioni personali soltanto per accudire e migliorare il sito. Utilizzando il sito, siete d'accordo con la raccolta e l'utilizzo delle informazioni in questione, secondo la suddetta politica.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Raccolta ed utilizzo delle informazioni");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Mentre utilizzate il nostro sito, potremmo chiedervi di fornirci certe informazioni di identificazione della vostra persona, che possono essere utilizzate per entrare in contatto con voi o per identificarvi. Tali informazioni di identificazione della persona possono includere, ma non si limitano, al vostro nome (\"Informazioni personali\").");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Dati registrati");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Come molti altri operatori di siti web, raccogliamo informazioni che il vostro browser invia ogni volta che visitate il nostro sito (\"Dati registrati\"). Questi Dati registrati possono includere informazioni quali l'indirizzo del protocollo internet del vostro computer (\"IP\"), il tipo di browser, la versione del browser, le pagine del nostro sito, oggetto della vostra visita, l'ora e la data della vostra visita, il tempo trascorso su tali pagine ed altre statistiche. Possiamo inoltre utilizzare servizi di parti terze per raccogliere, monitorare ed analizzare i Dati registrati in questione.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Comunicazioni");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Potremo utilizzare le vostre Informazioni personali per entrare in contatto con voi tramite bollettini informativi e materiale commerciale o promozionale ed altre informazioni interessanti.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Cookies");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("I cookies sono file contenenti piccole quantità di dati e che possono contenere un identificatore unico ed anonimo. I cookies sono inviati dal vostro browser da un sito web, per essere memorizzati sul disco fisso del vostro computer o nella memoria del vostro telefonino. Come molti altri siti, utilizziamo \"cookies\" per la raccolta delle informazioni. Nel caso non accettiate i nostri cookies, potreste non ottenere l'accesso ad alcune parti del nostro sito.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Sicurezza");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("La sicurezza delle vostre Informazioni personali è per noi importante, ma ricordate che nessun metodo di trasmissione sulla rete Internet, né alcun metodo di memorizzazione elettronica, sono sicuri al 100%. Anche se ci sforziamo di utilizzare mezzi commercialmente accettabli per la protezione delle vostre Informazioni personali, non possiamo garantirne l'assoluta sicurezza.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Modifiche a questa politica di riservatezza");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Questa Politica di riservatezza è effettiva in base all'ultimo aggiornamento qui sopra, e resterà in vigore ad eccezione del rispetto di qualsiasi modifica alle sue clausole nel futuro, che diventeranno effettive una volta pubblicate in questa pagina. Ci riserviamo il diritto di aggiornare o modificare la nostra Politica di riservatezza in qualsiasi momento e gli utenti dovranno controllare periodicamente questa Politica di riservatezza. Il fatto di continuare ad utilizzare questo servizio, una volta che sarà stata pubblicata una qualsiasi modifica alla Politica di riservatezza in questa pagina, costituirà la vostra accettazione delle modifiche ed il vostro consenso a continuare ad osservare la Politica di riservatezza così modificata. Nel caso apportassimo qualsiasi modifica materiale a questa Politica di riservatezza, ve lo notificheremo sia tramite le vostre Informazioni personali, oppure mediante un avvertimento posto in evidenza sul Sito.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Mettetevi in contatto con noi");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Se avete qualche altra domanda riguardante questa Politica di riservatezza, potete ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","mailto:support@wonderswall.com");
        var el4 = dom.createTextNode("mettervi in contatto con noi");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(".");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});