define('wow-app/templates/navbar', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.0",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","swiper-slide");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","nav-my-wonderlock");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","icon-camera");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element2 = dom.childAt(fragment, [1, 0]);
          var attrMorph0 = dom.createAttrMorph(element2, 'href');
          attribute(env, attrMorph0, element2, "href", concat(env, [get(env, context, "globals.app_route"), "/", get(env, context, "model.id"), "/my-wonderlock"]));
          element(env, element2, context, "action", ["linkTo", "my-wonderlock"], {"target": get(env, context, "view.parentView")});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.0",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1,"class","swiper-slide upload");
          var el2 = dom.createTextNode("\n	            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2,"class","nav-choose-wonder");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3,"class","icon-camera");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, concat = hooks.concat, attribute = hooks.attribute, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element1 = dom.childAt(fragment, [1, 1]);
          var attrMorph0 = dom.createAttrMorph(element1, 'href');
          attribute(env, attrMorph0, element1, "href", concat(env, [get(env, context, "globals.app_route"), "/", get(env, context, "model.id"), "/choose-wonder"]));
          element(env, element1, context, "action", ["linkTo", "choose-wonder"], {"target": get(env, context, "view.parentView")});
          return fragment;
        }
      };
    }());
    var child2 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.0",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","upload-teaser-wrapper");
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","upload-teaser");
          var el3 = dom.createElement("div");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, element = hooks.element, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var morph0 = dom.createMorphAt(dom.childAt(element0, [0, 0]),0,0);
          element(env, element0, context, "action", ["linkTo", "choose-wonder"], {"target": get(env, context, "view.parentView")});
          inline(env, morph0, context, "t", ["wall.upload_photo"], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"class","navbar-swiper swiper-container-off");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2,"class","swiper-wrapper-off");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"class","right swiper-slide");
        var el4 = dom.createElement("span");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"class","swiper-slide");
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","nav-tilezoommap");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5,"class","icon-wow_logo");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"class","swiper-slide");
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","nav-top");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5,"class","icon-heart");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"class","swiper-slide");
        var el4 = dom.createElement("a");
        dom.setAttribute(el4,"class","nav-about");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5,"class","icon-about");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"class","swiper-slide");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3,"class","up swiper-slide");
        var el4 = dom.createElement("span");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, inline = hooks.inline, element = hooks.element, concat = hooks.concat, attribute = hooks.attribute, block = hooks.block, content = hooks.content;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element3 = dom.childAt(fragment, [2, 1]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3, 0]);
        var element6 = dom.childAt(element3, [7, 0]);
        var element7 = dom.childAt(element3, [9, 0]);
        var element8 = dom.childAt(element3, [13]);
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        var attrMorph0 = dom.createAttrMorph(element5, 'href');
        var morph1 = dom.createMorphAt(element3,5,5);
        var attrMorph1 = dom.createAttrMorph(element6, 'href');
        var attrMorph2 = dom.createAttrMorph(element7, 'href');
        var morph2 = dom.createMorphAt(dom.childAt(element3, [11]),0,0);
        var morph3 = dom.createMorphAt(fragment,4,4,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        inline(env, morph0, context, "log", [get(env, context, "model")], {});
        element(env, element4, context, "action", ["toggleContent", get(env, context, "model")], {"target": get(env, context, "view.parentView")});
        attribute(env, attrMorph0, element5, "href", concat(env, [get(env, context, "globals.app_route"), "/", get(env, context, "model.id"), "/map"]));
        element(env, element5, context, "action", ["linkTo", "tilezoommap"], {"target": get(env, context, "view.parentView")});
        block(env, morph1, context, "if", [get(env, context, "controllers.application.userHasPicture")], {}, child0, child1);
        attribute(env, attrMorph1, element6, "href", concat(env, [get(env, context, "globals.app_route"), "/", get(env, context, "model.id"), "/top"]));
        element(env, element6, context, "action", ["linkTo", "top"], {"target": get(env, context, "view.parentView")});
        attribute(env, attrMorph2, element7, "href", concat(env, [get(env, context, "globals.app_route"), "/", get(env, context, "model.id"), "/about"]));
        element(env, element7, context, "action", ["linkTo", "about"], {"target": get(env, context, "view.parentView")});
        content(env, morph2, context, "settings-button");
        element(env, element8, context, "action", ["toggleContent", get(env, context, "model")], {"target": get(env, context, "view.parentView")});
        block(env, morph3, context, "if", [get(env, context, "showUploadTeaser")], {}, child2, null);
        return fragment;
      }
    };
  }()));

});