define('wow-app/locales/zh', ['exports', 'wow-app/locales/en'], function (exports, EN) {

    'use strict';

    var ZH = {
        "about": {
            "sub_title": "留下您的印记！加入奇迹之墙！不见不散！",
            "text": "数以亿计的选票都投给了New7Wonders，现在您也可以通过上传自己在某一个New7Wonders的照片而成为奇迹之墙的一部分。选择您的奇迹。上传您的照片。与每一个人分享。没错，您现在也可以成为#奇迹之墙的一部分！！！"
        },
        "buttons": {
            "cancel": "取消",
            "change_photo": "更改照片",
            "change_wall": "更改奇迹之墙",
            "change_wonder": "更改奇迹",
            "choose_wonder": "选择奇迹",
            "close": "关闭",
            "crop_and_save": "裁剪照片并保存",
            "crop_photo": "裁剪照片",
            "join": "加入",
            "log_out": "退出",
            "ok": "确认",
            "register": "注册",
            "save": "保存",
            "select": "选择",
            "share": "分享",
            "sign_in": "登录",
            "sign_up": "注册",
            "upload": "上传超片",
            "copy": "复制",
            "yes": "是！",
            "edit": "Edit",
            "forget_password": "Forgot Password?"
        },
        "congratulation": {
            "sub_title": "您现在成为了奇迹之墙的一部分",
            "title": "祝贺"
        },
        "global": {
            "error_title": "抱歉",
            "next": "下一页",
            "previous": "上一页",
            "title": "奇迹之墙"
        },
        "joyscreen": {
            "join": "加入奇迹之墙",
            "title": "是否想要留在奇迹之墙上？",
            "welcome": "欢迎来到奇迹之墙"
        },
        "mosaic": {
            "manmade": {
                "title": "新世界7大奇迹"
            },
            "nature": {
                "title": "新7大自然奇观"
            }
        },
        "my_photo": {
            "my_wonder": "我的奇迹",
            "no_login": "您还没有登录！如果您要保留在奇迹之墙上，则需要注册。",
            "no_photo": "您尚未上传任何照片...",
            "status": "状态",
            "sub_title": "更改照片或更改奇迹",
            "title": "我的照片",
            "uploaded": "已上传",
            "motto": "Say something",
            "motto_placeholder": "Tell us about your photo",
            "location": "Location",
            "city": "City",
            "country": "Country",
            "ping": "WOW!",
            "my_wows": "My WOWs",
            "wowed_by": "WOWed by",
            "no_wows": "No WOWs yet."
        },
        "register": {
            "email": "电子邮件",
            "logged_in_as": "以此身份登录",
            "password": "密码",
            "sign_in_email": "通过电子邮件登录",
            "sign_up_email": "通过电子邮件注册"
        },
        "seo": {
            "meta_description": "数以亿计的选票都投给了New7Wonders，现在您也可以成为奇迹之墙的一部分。"
        },
        "settings": {
            "change_language": "语言",
            "email": "电子邮件",
            "facebook": "脸书",
            "login": "登录",
            "new_to_wall": "是否加入奇迹之墙？",
            "on_the_wall": "是否已在奇迹之墙上？",
            "signin": "注册",
            "title": "设置",
            "twitter": "推特",
            "upload_photo": "上传超片"
        },
        "share": {
            "fb_caption": "我现在是奇迹之墙的一部分",
            "fb_description": "加入我吧！将照片上传到www.wonderswall.com，你也可以成为奇迹！#奇迹之墙",
            "fb_name": "奇迹之墙",
            "fb_published": "您的帖子已经发表。",
            "sub_title": "与您的朋友们分享，一起加入奇迹之墙",
            "title": "分享",
            "whatsapp": "Whatsapp",
            "link": "Link",
            "email_from": "发件人",
            "email_to": "至",
            "email_subject": "主题",
            "email_text": "您的文本",
            "email_to_placeholder": "多个地址用逗号分隔",
            "email_successfully_send": "成功发送电子邮件"
        },
        "stay": {
            "accept": "接受条款和条件",
            "title": "留在奇迹之墙上",
            "privacy_policy": "隐私政策"
        },
        "terms": {
            "accept": "请接受我们的条款和条件",
            "title": "条款和条件"
        },
        "top": {
            "title": "大多数上传"
        },
        "upload": {
            "image_error": "无法加载照片，请返回并重新上传",
            "import_fb": "脸书",
            "import_insta": "\nInstagram",
            "import_my_photos": "您的照片",
            "import_tw": "推特",
            "import_webcam": "摄像头",
            "sub_title": "或从下面导入",
            "take_photo": "拍摄照片",
            "title": "拍摄照片",
            "upload_error": "上传您的图片时出错",
            "upload_save_error": "无法保存照片，请返回并重新上传",
            "webcam_allow_error": "点击“允许”拍照",
            "webcam_error": "您的摄像头与您的浏览器不兼容，请更新软件"
        },
        "upload_choose": {
            "change_title": "更改您的奇迹",
            "sub_title": "并将您的照片上传到其上",
            "title": "选择您的奇迹"
        },
        "upload_steps": {
            "1": "第1步",
            "2": "第2步",
            "3": "第3步"
        },
        "wall": {
            "upload_photo": "将您的照片上传到奇迹之墙"
        },
        "wonders": {
            "amazon": {
                "location": "南美洲",
                "title": "亚马逊"
            },
            "christ_redeemer": {
                "location": "巴西",
                "title": "里约热内卢基督像"
            },
            "colosseum": {
                "location": "意大利",
                "title": "斗兽场"
            },
            "great_wall_of_china": {
                "location": "中国",
                "title": "长城"
            },
            "ha_long_bay": {
                "location": "越南",
                "title": "下龙湾"
            },
            "iguazu_falls": {
                "location": "阿根廷/巴西",
                "title": "伊瓜苏瀑布"
            },
            "jeju_island": {
                "location": "韩国",
                "title": "济州岛"
            },
            "komodo": {
                "location": "印度尼西亚",
                "title": "科莫多"
            },
            "machu_pichu": {
                "location": "秘鲁",
                "title": "马丘比丘"
            },
            "petra": {
                "location": "约旦",
                "title": "佩特拉"
            },
            "pp_underground_river": {
                "location": "菲律宾",
                "title": "PP地下河"
            },
            "pyramid_at_chichen_itza": {
                "location": "墨西哥",
                "title": "奇琴伊察"
            },
            "table_mountain": {
                "location": "南非",
                "title": "桌山"
            },
            "taj_mahal": {
                "location": "印度",
                "title": "泰姬陵"
            }
        },
        "oauth_errors": {
            "login_failed": "Log in via social network failed or was cancelled."
        }
    };

    ZH = $.extend(true, {}, EN['default'], ZH);

    exports['default'] = ZH;

});