define('wow-app/routes/oauth-service', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({

		model: function model(params, transition) {

			var me = this,
			    applicationController = me.controllerFor('application'),
			    mosaicId = applicationController.getMosaicId();

			var oauthLoginType = UTIL.storage.getSession('oauth-login');
			//var lastRoute = UTIL.storage.getSession('oauth-login-route');
			var external_connect_error = UTIL.storage.getSession('external_connect_error');

			UTIL.storage.removeSession('oauth-login');
			UTIL.storage.removeSession('oauth-login-route');
			UTIL.storage.removeSession('external_connect_error');

			var transit = function transit() {

				if (external_connect_error) {
					transition.send('raiseError', 'Sorry', 404, null, external_connect_error);
					me.transitionTo('upload', mosaicId);
				} else {
					UTIL.storage.storeSession('loadService', oauthLoginType);
					me.transitionTo('upload', mosaicId);
				}
			};

			return me.store.find('user', 'checkuser').then(function (user) {

				if (user) {
					applicationController.setUser(user);
				}

				transit();
			}, function (reason) {
				transit();
				return null;
			});
		}

	});

});