define('wow-app/controllers/tilezoommap', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({

		needs: ['application', 'tilezoom']

		//,userHasPicture: function() {
		//	// TODO - IF THIS IS VISIBLE WE NEED TO UPDATE THE VIEW
		//	// BUT - we don´t need to show this if the user was ever logged in...
		//	var userHasPicture = this.get('controllers.application').get('userHasPicture');
		//	if(typeof console === 'object') { console.log('TODO - IF THIS IS VISIBLE WE NEED TO UPDATE THE VIEW',userHasPicture); }
		//	if(!userHasPicture) {
		//		return false;
		//	} else {
		//		return true;
		//	}
		//}.property('controllers.application.userHasPicture')

	});

});