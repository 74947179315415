define('wow-app/views/signup-email-modal', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].View.extend({

		willDestroyElement: function willDestroyElement() {
			var controller = this.get('controller');
			controller.set('loginFailed', false);
		},

		actions: {

			signUp: function signUp() {

				var $element = this.$(this.element),
				    controller = this.get('controller'),
				    p = {
					el: $element
				};

				// save user in controller...
				controller.send('signUp', p);
			}
		}

	});

});