define('wow-app/components/scroll-content', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({

		didInsertElement: function didInsertElement() {

			//var $element        = $(this.element),
			//	$content        = $element.closest('.app-content-contain'),
			//	$scrollWrapper  = $('.scrollWrapper',$element),
			//	height;
			//
			//// we need to wait for the content
			//if ($scrollWrapper && $scrollWrapper.get(0) && $scrollWrapper.height() > 0) {
			//	// my childViews are inserted
			//	if(typeof console === 'object') { console.log('FOUND SCROLLWRAPPER HEIGHT!',$scrollWrapper,$scrollWrapper.height()); }
			//
			//} else if($content && $content.get(0) && $content.height() > 0) {
			//
			//	if(typeof console === 'object') { console.log('FOUND CONTENT CONTAIN!',$content,$content.height()); }
			//	$scrollWrapper.css({
			//		height: $content.height()
			//	});
			//} else {
			//
			//	if(typeof console === 'object') { console.log('ScrollContentComponent.waitFor',$content); }
			//
			//	Ember.run.next(this, function() {
			//		this.didInsertElement();
			//	});
			//}

			//if(typeof console === 'object') { console.log('$ScrollContentComponent.element',$element,$element.closest('app-content-contain')); }

			//
			//fastdom.read(function() {
			//	height = $element.height();
			//});
			//
			//fastdom.write(function() {
			//	$scrollWrapper.css({
			//		height: height
			//	});
			//});
			//
			//if(typeof console === 'object') { console.log('$element',$element); }

		}

	});

});