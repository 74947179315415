define('wow-app/helpers/ifdisplaycondition-helper', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(function (v1, v2) {
		if (v1 === v2) {
			return '';
		}
		return 'display: none;';
	});

});