define('wow-app/controllers/oauth-login', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({

		needs: ['application', 'error-modal']

	});

});