define('wow-app/controllers/my-wonderlock', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'mosaic', 'tilezoom', 'error-modal'],

		isProzessing: false,
		saveFailed: false,
		saveError: 'Save failed',
		locationProzessing: false,

		t_city: (function () {
			return this.t('my_photo.city');
		}).property(),

		t_country: (function () {
			return this.t('my_photo.country');
		}).property(),

		t_motto: (function () {
			return this.t('my_photo.motto_placeholder');
		}).property(),

		country: (function () {
			var application = this.get('controllers.application');
			return application.get('picture.metadata.country');
		}).property('controllers.application.picture.metadata.country'),

		WOWedBy: (function () {
			var application = this.get('controllers.application');
			return application.get('user.numRecvInt');
		}).property('controllers.application.user.numRecvInt'),

		MyWOWs: (function () {
			var application = this.get('controllers.application');
			return application.get('user.numSentInt');
		}).property('controllers.application.user.numSentInt'),

		/**
	  * Get Wonderlock title of picture wonderId
	  */
		wonder: (function () {
			var applicationController = this.get('controllers.application');
			var mosaicController = this.get('controllers.mosaic');
			var wonders = mosaicController.get('model.wonders');
			var picture = applicationController.get('picture');
			var wonder = '';
			if (picture) {
				var wonderId = picture.get('wonder');
				wonder = this.t('wonders.' + wonderId + '.title');
				if (!wonder) {
					var wonderObj = wonders.findBy('id', wonderId);
					if (wonderObj) {
						wonder = wonderObj.title;
					}
				}
			}

			return wonder;
		}).property('controllers.application.picture.metadata'),

		pictureIsOnActiveMosaic: (function () {
			return this.get('controllers.mosaic').get('pictureIsOnActiveMosaic');
		}).property('controllers.mosaic.pictureIsOnActiveMosaic'),

		/**
	  * Ask (application) if, user has a picture,
	  * if not, go to tilezoommap
	  */
		userHasPicture: (function () {
			var userHasPicture = this.get('controllers.application').get('userHasPicture');
			if (!userHasPicture) {
				this.transitionToRoute('tilezoommap');
			} else {
				return true;
			}
		}).property('controllers.application.userHasPicture'),

		canChangePosition: (function () {
			var application = this.get('controllers.application');
			return application.get('user.vip');
		}).property('controllers.application.user.vip'),

		isIMP: (function () {
			var application = this.get('controllers.application');
			return application.get('user.imp');
		}).property('controllers.application.user.imp'),

		actions: {

			save: function save(a, b) {

				var me = this,
				    applicationController = this.get('controllers.application'),
				    photo = this.get('controllers.application').get('picture');

				var mosaicView = UTIL._lookupView('mosaic');
				mosaicView.set('hasLazyLoader', true);

				var onSuccess = function onSuccess(p) {
					if (mosaicView) {
						UTIL.tracker.track('event', { category: 'Photo', action: 'saved', label: 'Photo Saved', value: 1 });
						applicationController.setPicture(photo);
						mosaicView.set('hasLazyLoader', false);
					}
				};

				var onFail = function onFail(p) {

					var serverMessage = '';
					if (p && typeof p === 'object' && typeof p.responseJSON === 'object' && p.responseJSON.message) {
						serverMessage = ' Reason: ' + p.responseJSON.message;
					}

					mosaicView.set('hasLazyLoader', false);
					me.get('controllers.error-modal').raise({
						statusText: 'Sorry',
						statusCode: 404,
						reason: p,
						route: 'choose-wonder',
						message: 'Save failed' // me.t('upload.upload_save_error') + serverMessage
					});
				};

				photo.save().then(onSuccess, onFail);
			},

			autosave: function autosave(a, b) {
				if (typeof console === 'object') {
					console.log('autosave', a, b);
				}
			},

			setLocation: function setLocation(addr, latlng) {

				var application = this.get('controllers.application');

				if (typeof addr === 'object') {
					if (addr.city && addr.city.length > 0) {
						application.set('picture.metadata.city', addr.city);
					}

					if (addr.country_iso_code && addr.country_iso_code.length > 0) {
						application.set('picture.metadata.country', addr.country_iso_code);
					}
				}

				if (typeof latlng === 'object') {
					if (latlng.lat) {
						application.set('picture.metadata.lat', latlng.lat);
					}
					if (addr.city && addr.country.length > 0) {
						application.set('picture.metadata.long', latlng.lng);
					}
				}
			}
		}

	});

});