define('wow-app/templates/accept-terms/fr', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("En accédant au site Web mobile, à l'application et au site Internet wonderswall.com (ci-après dénommé collectivement\n        « wonderswall.com » ou « le site»)\n        et en utilisant ses fonctionnalités, vous acceptez de vous soumettre aux présentes conditions concernant le site, les services et toutes les questions qui y sont liées.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Copyright");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Copyright 2014-2021, Global Platform Limited, (GPL), Dubaï, Émirats arabes unis.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Sous licence NewOpenWorld Corporation.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Contact : contact@wonderswall.com");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Tous droits réservés.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Office de la propriété intellectuelle pour les avis et autres communications.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("CONDITIONS GÉNÉRALES DE PARTICIPATION À WONDERSWALL.COM");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Téléchargement de photos");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Certaines rubriques du site vous permettent de nous envoyer vos photos. Nous nous efforçons de vérifier brièvement la qualité des contenus et l'adéquation du fichier envoyé\n        afin de nous assurer que tout le monde puisse continuer à profiter de ce site. En cas de problème lié au contenu, GPL ne saurait être tenu responsable.\n        Vous garantissez à GPL que votre contenu ne contrefait pas les droits d'auteurs ou tout audre droit de tierces parties ou de toute autre organisation.\n        Merci de ne pas envoyer des fichiers appartenant à d'autres personnes sans leur autorisation.\n        GPL décline expressément toute responsabilité quant au contenu des fichiers de tiers ; GPL décline toute responsabilité envers un quelconque utilisateur ou envers tout\n        tiers pour toute perte (que ces pertes aient été prévues, attendues, connues ou autre), y compris, mais sans s'y restreindre :\n        la perte de données ; la perte de revenus ; la perte d'activité ; la perte d'opportunités ; ou tous dommages indirects, consécutifs, particuliers ou exemplaires résultant de\n        l'utilisation du site découlant de l'envoi, volontaire ou inconscient, de contenus tiers à GPL.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Titulaire du contenu téléchargé");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("En téléchargeant vos photos sur le site, vous acceptez, par votre contribution, d'accorder à GPL une licence mondiale, irrévocable, gratuite, non exclusive\n        et sous-licenciable pour utiliser, reproduire, modifier, adapter, publier, traduire, créer des produits dérivés, distribuer, représenter, jouer,\n        rendre public vos\n        contributions, exercer pleinement tous les droits d'auteur et de publicité s'y rapportant et/ou exploiter vos contributions dans tout média existant ou à venir, pour toute la durée des droits qui peuvent exister quant à celles-ci.\n        Si vous ne souhaitez pas accorder à GPL les droits susmentionnés, nous vous prions de ne pas télécharger de photos.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Photos de wonderswall.com");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL se réserve le droit d'accepter ou de refuser toute photo. La position de vos photos peut être modifiée à tout moment afin d'assurer la qualité optimale de la représentation\n        des merveilles ou pour tout autre motif établi par GPL. Afin de créer une représentation d'ensemble qui soit de la meilleure qualité possible,\n        GPL pourra, à sa seule discrétion, compléter les photos téléchargées avec d'autres photos qui lui auront été envoyées.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Conduite éthique et morale");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Vous êtes tenu de ne pas utiliser le site pour les raisons énoncées ci-après : (i) diffuser tout message dont le contenu est illégal, constitutif de harcèlement, diffamatoire, injurieux, menaçant,\n        nuisible, vulgaire, obscène ou autrement inadmissible et répréhensible ; (ii) transmettre tout matériel qui encourage une conduite\n        considérée comme un délit, qui engendre une responsabilité civile ou qui transgresse les lois, réglementations et codes de pratique applicables à votre régime juridictionnel\n        ou dans toute autre juridiction ; (iii) gêner une personne dans l'utilisation ou la jouissance du site ; ou de faire, transmettre ou stocker des copies électroniques\n        de contenus protégés par les droits d'auteur, sans l'autorisation de leur propriétaire. Vous serez tenu d'assumer les pertes et coûts découlant d'un manquement à\n        la présente obligation. Nous nous réservons le droit de supprimer tout matériel, à notre entière discrétion, lorsque nous l'estimerons nécessaire.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("AUTRES CONDITIONS GÉNÉRALES");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Respect du droit d'auteur et d'autres droits de propriété intellectuelle et conventions");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL respecte la propriété intellectuelle des autres et nous demandons à nos utilisateurs de faire de même. GPL peut, lorsque les circonstances s'y prêtent et si elle le juge opportun, désactiver et/ou résilier le compte d'un utilisateur soupçonné de porter atteinte aux droits de propriété intellectuelle de GPL ou de tiers. Si vous pensez que vos travaux ont été copiés de sorte à porter atteinte aux droits d'auteur, ou que vos droits de propriété intellectuelle ont été violés, merci de nous le signaler.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Marques Nouvelles 7 Merveilles");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Nouvelles 7 Merveilles et tout autre nom de logo, produit ou service de Nouvelles 7 Merveilles sont des marques déposées de New Open World Corporation, représenté aux fins des présentes par GPL. Vous consentez à ne pas exposer ni utiliser les marques Nouvelles 7 Merveilles, de quelque manière que ce soit, sans en avoir obtenu au préalable l'autorisation de GPL. En utilisant et en participant au site, vous acceptez de ne pas copier, reproduire, rééditer, télécharger, publier, diffuser, transmettre, rendre public ou utiliser autrement tout autre contenu de Nouvelles 7 Merveilles dans tout média, site ou de quelque autre façon, sauf pour votre usage personnel et non-commercial. Vous consentez également à ne pas adapter, altérer ou créer un travail dérivé du contenu de ce site ou de tout autre contenu de Nouvelles 7 Merveilles, sauf pour un usage personnel et exclusivement non-commercial. Toute autre utilisation du site ou du contenu de Nouvelles 7 Merveilles nécessite l'autorisation écrite préalable de GPL.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Confidentialité");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL prend très au sérieux le respect de votre vie privée. Étant donné que nous recueillons certains renseignements sur nos usagers, nous voulons vous aider à comprendre les modalités relatives à la collecte et à l'utilisation de ces informations. Si vous avez autorisé GPL à vous contacter à des fins de mises à jour de l'information et autres communications, vos coordonnées sont susceptibles d'être intégrées aux bases de données de GPL. GPL peut utiliser certains dispositifs Internet classiques, tels que des images électroniques, pour garder une trace des tendances de navigation des utilisateurs. Par ailleurs, nous recueillons et enregistrons automatiquement les informations transmises par notre serveur et notre navigateur, y compris votre adresse IP, la date et les renseignements relatifs à la page demandée. Conformément aux pratiques Internet, GPL peut également recevoir d'autres informations vous concernant issues de sources différentes et les ajouter à votre profil. Les renseignements que nous recueillons sont utilisés aux fins suivantes : 1. Cibler notre publicité et personnaliser les contenus qui vous sont présentés ; 2. Améliorer la qualité de l'expérience des usagers sur les sites de notre réseau. Nous utilisons des informations de suivi pour déterminer le fonctionnement général de chaque page selon des données d'ensemble sur les utilisateurs et des tendances de visites de ces pages. Ces démarches nous aident à vous fournir de meilleurs services ; 3. Répondre à vos futures demandes ou commandes de certains produits et services, par exemple, l'envoi de bulletins électroniques ou permettre aux utilisateurs de participer aux sondages, concours, forums de discussion et enchères ; 4. Vous envoyer des informations que vous avez acceptées de recevoir sur des sujets susceptibles de vous intéresser.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Droit national");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Compte tenu du caractère mondial du réseau Internet, vous acceptez de vous conformer à toutes les lois, règles et conventions locales et nationales régissant les comportements et contenus en ligne jugés acceptables. Vous acceptez, en particulier, de vous conformer à toutes les lois applicables concernant la transmission des données techniques exportées du pays dans lequel vous résidez.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Indemnisations");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Vous acceptez d'indemniser et de dégager de toute responsabilité GPL ainsi que ses filiales, compagnies affiliées, dirigeants, représentants, partenaires de marque et autres partenaires commerciaux et employés, à l'égard de toute plainte ou demande, y compris les honoraires d'avocats dans une limite raisonnable, déposée par toute tierce partie en raison de, ou découlant du contenu que vous auriez envoyé, publié, transmis ou rendu disponible par l'intermédiaire du site, de votre utilisation du site, de votre connexion au site, de votre violation des conditions d'utilisation spécifiques à ce site ou de votre violation des droits d'autrui.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Interdiction de toute revente des services");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Vous convenez de ne pas reproduire, copier, vendre, revendre ou exploiter dans un but commercial, quel qu'il soit, toute partie du service, toute utilisation du service ou tout droit d'accès au service.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Modification du site");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL se réserve le droit de modifier ou d'interrompre, temporairement ou définitivement, le site (ou une partie du site) en tout temps et à sa convenance, avec ou sans préavis. GPL ne peut être tenu responsable envers vous ni envers toute tierce partie de toute modification, suspension ou interruption du site.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Liens");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Le site ou des tiers peuvent inclure des liens vers d'autres sites Internet ou ressources. GPL ne contrôlant pas ces derniers, vous reconnaissez et acceptez que GPL n'est pas responsable de la disponibilité de ces sites ou de ces ressources, et que le contenu, la publicité, les produits ou les documents qui y figurent ou sont disponibles par leur intermédiaire n'engagent pas la responsabilité de GPL et ne font pas l'objet, de la part de cette dernière, d'une approbation. En outre, vous reconnaissez et acceptez que GPL ne peut être tenue responsable, directement ou indirectement, des pertes ou dommages causés ou pouvant avoir été causés en liaison ou en rapport avec l'utilisation ou toute action sur la foi de tels contenus, marchandises ou services disponibles sur de tels sites ou ressources, ou par leur intermédiaire.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Titulaire des données");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Toutes les données, y compris celles liées à la participation sur le Mur des Merveilles, pouvant être affichées ou publiées par GPL sont protégées par les droits d'auteur. L'utilisation de ces données est encadrée par des dispositions particulières qui sont impératives. Médias : les organes de presse reconnus peuvent reproduire ces données uniquement à des fins d'information, à condition d'afficher le texte suivant de manière visible : Données © www.wonderswall.com. Autres : les organisations, entreprises et sites souhaitant faire quelque autre utilisation de ces données sont assujettis aux conditions de licence et à l'approbation de GPL. Pour plus de renseignements, merci de nous contacter.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Clause de non-responsabilité technique");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Le contenu du site, y compris les informations, noms, images, photos et logos associés à Nouvelles 7 Merveilles et au Mur des Merveilles ou à ses services, sont fournis « en l'état » et « selon la disponibilité », dans la mesure permise par la loi, sans assertion ni garantie d'aucune sorte (qu'elles soient expresses ou implicites en vertu de la loi), et notamment, sans garantie d'absence de contrefaçon, de compatibilité, de sécurité et d'exactitude. GPL n’accorde aucune garantie quant à l’absence d’interruption et de retard dans les fonctionnalités fournies via le site, ni ne garantit que les photos ou autres transferts soient justes et complets, que les défauts seront corrigés ou que le site ou le serveur qui le rend accessible sont dépourvus de virus, erreur informatique ou autre élément nuisible. GPL ne sera en aucun cas responsable ni redevable, envers vous ou envers tout tiers, de toute perte (que ces pertes aient été prévues, attendues, connues ou autre), y compris, mais sans s'y restreindre : la perte de données ; la perte de revenus ; la perte d'activité ; la perte d'opportunités ; ou tous dommages indirects, consécutifs, particuliers ou exemplaires résultant de l'utilisation du site.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Exclusion de garanties");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Vous comprenez et consentez formellement au fait que vous utilisez ce site à vos risques exclusifs. Le site est fourni « en l'état » et « selon la disponibilité ». GPL décline expressément toute garantie, expresse ou implicite, de quelque sorte que ce soit, notamment, mais sans s'y limiter, les garanties implicites de qualité marchande, d'adéquation à un usage particulier et de non-contrefaçon. GPL ne garantit aucunement que : (i) ce site répond à vos besoins ; (ii) ce site est disponible sans interruption, en temps opportun, en toute sécurité et sans erreur ; (iii) les résultats qui pourraient être obtenus par l'utilisation de ce site seront précis et fiables ; (iv) la qualité des produits, services, informations et autres articles achetés ou obtenus par le biais du site répondront à vos attentes ; et que (v) les défauts du logiciel utilisé, s'il en existe, seront corrigés. Tous les matériels téléchargés ou autrement obtenus via l'utilisation du site, lorsque ce dernier le permet, le sont à vos gré et risque. Vous êtes seul responsable de tout dommage à votre système informatique ainsi que de toute perte de données résultant du téléchargement desdits matériels.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Limitation de responsabilité");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Vous comprenez et consentez formellement au fait que GPL ne pourra être tenue responsable des dommages directs, indirects, accessoires, particuliers, consécutifs ou exemplaires, y compris, sans toutefois s'y limiter, les dommages associés aux pertes de bénéfices, de survaleur, de jouissance, de données ou autres pertes intangibles (même si GPL a été informée de l'éventualité de tels dommages), résultant : (i) de l'utilisation du site ou l'incapacité d'utiliser celui-ci ; (ii) du coût d'acquisition des biens et services de remplacement découlant de l'achat ou de l'obtention de tous biens, données, informations ou services, ou de la réception de messages, ou encore de la réalisation de transactions par le biais ou à partir du site ; (iii) de l'accès non autorisé à vos transmissions ou données, ou à l'altération de celles-ci ; (iv) des déclarations ou de la conduite d'un tiers sur le site ; ou (v) de toute autre question en relation avec le site.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Âge des utilisateurs");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Nous estimons que wonderswall.com doit être ouvert à toutes les personnes, indépendamment de leur âge. Nous sommes toutefois conscients qu'il existe dans d'autres pays et cultures des cadres juridiques différents, ou autres, concernant l'âge  des utilisateurs. GPL ne sollicite ni ne recueille de manière délibérée les données personnelles fournies par et concernant les enfants qui n'ont pas l'âge minimum requis, dans les pays où la loi l'interdit, ni ne permet intentionnellement à ces personnes de s'inscrire. Si les lois en vigueur dans votre pays vous interdisent de vous inscrire sur le site, vous devez vous abstenir de le faire ou de nous envoyer des informations personnelles, notamment (mais non exclusivement), votre nom, adresse, numéro de téléphone ou adresse électronique. Si, en vertu des lois en vigueur dans votre pays, vous êtes tenu d'obtenir une autorisation de vos parents ou tuteurs légaux, veuillez obtenir leur consentement avant de fournir des renseignements personnels via notre site.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Modification des conditions");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL se réserve le droit de modifier les présentes conditions, en affichant les modifications en ligne. Veillez à consulter régulièrement ces conditions afin de vous assurer que vous êtes au courant des modifications effectuées par GPL. En poursuivant l'utilisation du site après que les modifications aient été effectuées, vous acceptez d’être légalement responsable des conditions modifiées et/ou mises à jour.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Droit applicable");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Ces conditions doivent être appliquées et interprétées conformément à la législation du canton de Zurich/Suisse.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});