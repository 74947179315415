define('wow-app/components/wonder-lock', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({
		classNames: ['wonderlock'],
		classNameBindings: ['hasActions:showactions', 'uploadPhoto:uploadphoto', 'disableActions:disabled', 'isPreview:preview'],

		needs: ['application', 'upload'],

		// Todo: get uploaded image here user.hasImage
		// See: http://guides.emberjs.com/v1.10.0/cookbook/user_interface_and_interaction/adding_css_classes_to_your_components_based_on_properties/
		//isOpen: function() {
		//	var settings = this.getMySettings();
		//	return settings.isOpen;
		//}.property(),

		uploadPhoto: (function () {
			var settings = this.getMySettings();
			return settings.uploadPhoto;
		}).property(),

		hasActions: (function () {
			var settings = this.getMySettings();
			return settings.hasActions;
		}).property(),

		disableActions: (function () {
			var settings = this.getMySettings();
			return settings.disableActions;
		}).property(),

		isPreview: (function () {
			var settings = this.getMySettings();
			return settings.isPreview;
		}).property(),

		getMySettings: function getMySettings() {

			var parentWonderlockSettings = this.get('parentView.wonderlock');
			var settings = {
				isOpen: false,
				uploadPhoto: true,
				hasActions: false,
				disableActions: false,
				isPreview: false
			};

			if (parentWonderlockSettings && typeof parentWonderlockSettings === 'object') {
				return $.extend(settings, parentWonderlockSettings);
			}

			return settings;
		},

		didInsertElement: function didInsertElement() {

			var me = this,
			    $element = $(this.element),
			    webcamSupport = false;

			if (this.get('hasDropzone')) {

				var lockimg = $element.find('.lockimg').addClass('dropzone');

				if (!UTIL.user.isDevice) {
					var webCamSupport = UTIL.testWebcamSupport();

					if (webCamSupport) {
						UTIL.waitForElement('.wc-trigger', function (el) {
							el.on('click', function () {
								me.set('hasWebcam', true);
								setTimeout(function () {
									me.initWebcam($element);
								}, 500);
							});
						}, 0);

						$('.lockbody').on('click', function () {
							me.set('hasWebcam', true);
							setTimeout(function () {
								me.initWebcam($element);
							}, 500);
						});

						//$('.uploadForm').on('click',function() {
						//	me.dz.hiddenFileInput.click();
						//});
						webcamSupport = true;
					}
				}

				setTimeout(function () {
					me.initDropzone($element, webcamSupport);
				}, 500);
			}

			if (this.get('size')) {
				$element.addClass(this.get('size'));
			}

			if (this.get('photo')) {
				// we used to have photoType=cropped here, but only the cropper
				// itself should get the original source, so always use the cropped!
				//var photoType = 'cropped';
				//if(this.get('photoType')) {
				//	photoType = this.get('photoType');
				//}
				//var url = UTIL.getUserImagePath(this.get('photo').get('id'),photoType);
				//url = new Ember.Handlebars.SafeString(url);
				//var randomnumber=Math.floor(Math.random()*10001);
				//url = url+'?v'+randomnumber;
				var url = this.get('photo').get('croppedSrc');
				$element.find('.lockimg').css('background-image', 'url(' + url + ')');
				$element.removeClass('uploadphoto');
			}
		},

		getPhotoLimit: function getPhotoLimit() {
			var me = this,
			    photoLimit = 1,
			    parentView = me.get('parentView');

			if (parentView) {
				var parentController = parentView.get('controller');
				if (parentController) {
					photoLimit = parentController.get('photoLimit');
				}
			}

			return photoLimit;
		},

		getController: function getController(c) {

			var me = this,
			    parentView = me.get('parentView'),
			    controller;

			if (parentView) {
				var parentController = parentView.get('controller');
				if (parentController) {
					controller = parentController.get(c);
				}
			}

			return controller;
		},

		/**
	  * get the mosaic id
	  * this is ok, but we should use getMosaicId in apllication controller
	  * @returns {*}
	  */
		getMosaicId: function getMosaicId() {

			if (localStorage && localStorage.mosaic) {
				return localStorage.mosaic;
			}

			var mosaicController = this.getController('controllers.mosaic'),
			    mosaicId;

			if (mosaicController) {
				var model = mosaicController.get('model');
				if (model) {
					mosaicId = model.get('id');
				}
			}

			//if(typeof console === 'object') { console.log('WonderlockComponent.beforeUpload.mosaicId',mosaicController,mosaicId); }

			return mosaicId;
		},

		initDropzone: function initDropzone($element, webcamSupport) {

			Dropzone.autoDiscover = false;

			var me = this,
			    controller = me.get('controller'),
			    dzSelector = '.uploadForm';

			var isTouchSupported = typeof window.ontouchstart != 'undefined';

			var $uploadForm = $(dzSelector, $element).dropzone({
				init: function init(a) {

					// 2020-11-30, disabled, because iOS was not working anymore
					// if(isTouchSupported) {
					// var uploadtouchtrigger = '.dz-trigger',
					// 	trigger = $(uploadtouchtrigger),
					// 	me = this;
					//
					// trigger.on('click',function(e) {
					//   if(typeof console === 'object') { console.log('click',e); }
					//   // me.hiddenFileInput.click();
					//   // e.preventDefault();
					// });

					// var fixtouchlinks = new Hammer(trigger.get(0), {});
					// fixtouchlinks.on('tap', function(e) {
					//   if(typeof console === 'object') { console.log('dropzone.fixtouchlinks',e); }
					//
					//   me.hiddenFileInput.click();
					// 	e.preventDefault();
					// });
					// }
				},
				dictDefaultMessage: this.t('upload.take_photo'),
				method: 'post',
				url: '/api/v1/picture/upload',
				paramName: 'file',
				autoProcessQueue: true,
				maxFiles: 1,
				maxFilesize: 10000,
				acceptedFiles: 'image/*',
				clickable: webcamSupport ? ['.dz-trigger'] : [dzSelector, '.dz-trigger'],
				thumbnailWidth: 225
			});

			me.dz = Dropzone.forElement(dzSelector);

			// Events: thumbnail, complete

			this.dz.on('sending', function (file, xhr, formData) {
				// if(typeof console === 'object') { console.log('dropzone.sending',file); }
				controller.send('beforeUpload', file, xhr, formData);
			}).on('uploadprogress', function (file, progress, bytesSent) {
				// if(typeof console === 'object') { console.log('dropzone.uploadprogress',file); }
				controller.send('uploadProgress', file, progress, bytesSent);
			})
			// .on('canceled', function (file) {
			//   if(typeof console === 'object') { console.log('dropzone.canceled',file); }
			// })
			// .on('maxfilesexceeded', function (file) {
			//   if(typeof console === 'object') { console.log('dropzone.maxfilesexceeded',file); }
			// })
			// .on('accept', function (file, done) {
			// 	if(typeof console === 'object') { console.log('dropzone.accept',file); }
			//   if (file.type != "image/jpeg") {
			//     done("Error! Files of this type are not accepted");
			//   }
			//   else { done(); }
			// })
			.on('success', function (file, responseText, e) {
				// if(typeof console === 'object') { console.log('dropzone.success',file); }
				controller.send('uploadSuccess', file, responseText, e);
			}).on('error', function (file, xhr) {
				if (typeof console === 'object') {
					console.log('dropzone.error', file);
				}
				controller.send('uploadError', file, xhr);
			}).on("addedfile", function (file) {
				// if(typeof console === 'object') { console.log('dropzone.addedfile',file); }
				$('.view-upload-webcam').hide();
			});
		},

		initWebcam: function initWebcam($element) {

			var me = this,
			    controller = me.get('controller'),
			    $view = $('.view-upload-webcam'),
			    $preview = $('.webcam-preview'),
			    $screen = $('.webcam-screen');

			if (this.get('cam')) {
				$preview.hide();
				$screen.show();
				return true;
			}

			var cam = new SayCheese('.webcam-source', { snapshots: true });
			var $webcamThumbwrapper = $(".webcam-thumbwrapper", $element);

			cam.on('snapshot', function (snapshot) {

				$('<img>', {

					src: snapshot.toDataURL('image/png')
				}).on('load', function () {

					var $img = $(this).addClass('img-thumbnail').hide(),
					    $div = $('<div class="img-contain"></div>');

					$div.append($img);
					$div.show();

					$div.on('click', function () {
						$screen.show();
						$preview.hide();
					});
					$screen.hide();
					$preview.show();

					$webcamThumbwrapper.append($div);

					var dropzoneAddFile = function dropzoneAddFile() {

						var blob = window.dataURLtoBlob && window.dataURLtoBlob($img.attr('src'));

						var parts = [blob, new ArrayBuffer()];
						var file = new File(parts, "imageUploadTestFile", {
							lastModified: new Date(0), // optional - default = now
							type: "image/png"
						});

						me.dz.addFile(file);
					};

					$div.on('click', function (e) {
						e.preventDefault();
						dropzoneAddFile();
					});

					if (!Modernizr.touch) {

						$img.fadeIn();
					} else {

						$img.show();
					}

					dropzoneAddFile();
				});
			});

			cam.on('error', function (error) {
				me.set('hadError', true);

				//error.name NotFoundError: The object can not be found here.
				if (error === 'NOT_SUPPORTED' || typeof error === 'object' && error.name && error.name.indexOf('NotFoundError') >= 0) {
					me.getController('controllers.error-modal').raise({
						statusText: 'Sorry',
						statusCode: 404,
						reason: null,
						message: me.t('upload.webcam_error')
					});
				} else {
					me.getController('controllers.error-modal').raise({
						statusText: 'Sorry',
						statusCode: 404,
						reason: null,
						message: me.t('upload.webcam_allow_error')
					});
				}

				// remove webcam view
				me.set('hasWebcam', false);
				$('.lockbody').off('click');
				$view.hide();
				$('.uploadForm').on('click', function () {
					me.dz.hiddenFileInput.click();
				});
			});

			cam.on('start', function () {

				if (!me.$('.webcam-contain')) {
					cam.stop();
				} else {
					$view.show();
					$screen.show();
					$preview.hide();
					$('.webcam-contain', $element).css('display', 'table');
					$('.img-contain').hide();
				}
			});

			cam.start();

			me.set('cam', cam);

			return true;
		},

		actions: {

			snapIt: function snapIt() {
				var cam = this.get('cam');
				cam.takeSnapshot();
			},

			// Dropzone Actions
			beforeUpload: function beforeUpload(file, xhr, formData) {
				var mosaicId = this.getMosaicId();
				formData.append('mosaicId', mosaicId);

				var applicationController = this.getController('controllers.application');
				var wonder = applicationController.getWonder(mosaicId);
				formData.append('wonderId', wonder);
			},

			uploadProgress: function uploadProgress(file, progress, bytesSent) {
				//if(typeof console === 'object') { console.log('Wonderlock.uploadProgress file, progress, bytesSent',file, progress, bytesSent); }
			},

			uploadSuccess: function uploadSuccess(file, response, e) {
				if (typeof response === 'object') {
					this.getController('controllers.upload').send('photoUploaded', response);
				}
			},

			uploadError: function uploadError(file, xhr) {
				this.getController('controllers.error-modal').raise(xhr);
			}
		}

	});

});