define('wow-app/adapters/my-photo', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

	'use strict';

	var inflector = Ember['default'].Inflector.inflector;

	inflector.irregular('myPhoto', 'picture/list');
	inflector.uncountable('myPhoto');

	exports['default'] = DS['default'].RESTAdapter.extend({
		namespace: '/api/v1',

		updateRecord: function updateRecord(store, type, record) {

			return {};

			//var data = this.serialize(record, { includeId: true });
			//var id = record.get('id');
			//var url = [type, id].join('/');
			//
			//return new Ember.RSVP.Promise(function(resolve, reject) {
			//	jQuery.ajax({
			//		type: 'PUT',
			//		url: url,
			//		dataType: 'json',
			//		data: data
			//	}).then(function(data) {
			//		Ember.run(null, resolve, data);
			//	}, function(jqXHR) {
			//		jqXHR.then = null; // tame jQuery's ill mannered promises
			//		Ember.run(null, reject, jqXHR);
			//	});
			//});
		}
	});

});