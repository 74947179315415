define('wow-app/controllers/signup-email-modal', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application'],

		// template attributes
		loginFailed: false,
		isProcessing: false,
		closeModal: false,
		defaultError: 'Invalid email or password.',
		error: 'Invalid email or password.',

		// form values
		email: null,
		password: null,

		init: function init() {
			this.set("loginFailed", false);
			this.set('error', this.defaultError);
		},

		show: function show(p) {

			var me = this;

			var loadModal = function loadModal(user) {
				me.get('controllers.application').send('showModal', 'signup-email-modal', user);
			};

			var user = this.get('controllers.application').get('user');

			if (user) {
				me.setProperties({
					loginFailed: false,
					isProcessing: false,
					closeModal: false
				});

				loadModal(user);
			} else {
				me.setProperties({
					loginFailed: false,
					isProcessing: false,
					closeModal: false
				});
				loadModal();
			}
		},

		setError: function setError(json) {

			var e = 'Invalid email or password.',
			    i,
			    singleError = false;

			if (typeof json === 'object') {

				var errors = json.errors;
				if (errors) {
					for (i = 0; i < errors.length; i++) {
						if (singleError) {
							continue;
						}

						var error = errors[i];
						switch (error.code) {
							case 'fos_user.email.already_used':
								e = error.title;
								singleError = true;
								if (typeof console === 'object') {
									console.log('EROROR FOUND', e);
								}
								break;
						}
					}
				}
			}

			this.set('error', e);
		},

		actions: {

			/**
	   * user sign up and save all pictures to this user
	   * Todo: move this getting and setting of pictures to a user to the application, cause we need this also @signIn
	   */
			signUp: function signUp() {

				var me = this;

				var applicationController = this.get('controllers.application');

				var user = me.store.createRecord('user', {
					email: this.get('email'),
					password: this.get('password')
				});

				this.setProperties({
					loginFailed: false,
					isProcessing: true
				});

				user.set('username', user.get('email'));

				var onSuccess = function onSuccess(p) {

					var goOn = function goOn(route, id) {
						me.set("isProcessing", false);
						me.set("closeModal", true);
						me.transitionTo(route, id);
					};

					var mosaic = applicationController.getMosaicId();
					var picture = applicationController.get('picture');

					UTIL.tracker.track('event', { category: 'User', action: 'signup', label: 'E-Mail Sign-Up', value: 1 });

					me.store.findQuery('picture', { mosaicId: mosaic }).then(function (pictures) {
						var firstPicId = null,
						    firstPic;

						pictures.forEach(function (item) {
							if (!firstPicId) {
								firstPic = item;
								firstPicId = item.get('id');
							}
							item.set('metadata.profileId', user.get('token'));
							item.save().then(function () {
								// Cookie will be removed on the first picture...
								Cookies.remove('pic_anon_owner_token', { path: '/' });
							});
						});

						// save pictures in application controller
						applicationController.set('pictures', pictures);
						applicationController.setPicture(firstPic);
						user = applicationController.setUser(user);
						goOn('congratulation', firstPicId);
					}, function (p) {
						goOn('choose-wonder', mosaic);
					});
				};

				var onFail = function onFail(p) {
					me.setError(p.responseJSON);
					me.set("isProcessing", false);
					me.set("loginFailed", true);
				};

				user.save().then(onSuccess, onFail);
			}

		}

	});

});