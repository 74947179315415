define('wow-app/controllers/mosaic-list', ['exports', 'wow-app/controllers/base-object-controller'], function (exports, BaseController) {

  'use strict';

  exports['default'] = BaseController['default'].extend({

    needs: ['application', 'mosaic']

  });

});