define('wow-app/controllers/start', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Controller.extend({

		needs: ['application']

	});

});