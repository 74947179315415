define('wow-app/routes/stage', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    init: function init() {}

  });

});