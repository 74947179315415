define('wow-app/routes/application', ['exports', 'ember', 'wow-app/config/environment'], function (exports, Ember, ENV) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({

		/**
	  * Generate the global applicationstate model
	  * load via controller.set('applicationstate', this.store.find('applicationstate','globalstate'));
	  * @returns {*}
	  */
		model: function model() {
			var store = this.store;
			return store.find('applicationstate', 'globalstate');
		},

		/**
	  * Languages
	  * To add a new translation:
	  * - add a new translation file in wow-app/app/locales, e.g. dk.js
	  * - add translation the new language here
	  * - add "Accept Terms" and "Privacy Policy" template file for your language in wow-app/app/templates/acceppt-terms, e.g. dk.hbs
	  * - add language key in /sites/n7w/n7w_wallofwonders/src/N7W/WallOfWondersBundle/Services/LocaleHelper.php --> getLanguage
	  * - add meta tags translation in /sites/n7w/n7w_wallofwonders/src/N7W/WallOfWondersBundle/Resources/views/Frontend/Global/_meta_mulitlang_description.html.twig
	  * - add start-up translation in wow-app/public/_js/start-splash.js
	  */
		languages: [{ lang: "العربية", id: 'ar' }, { lang: "deutsch", id: 'de' }, { lang: "english", id: 'en' }, { lang: "español", id: 'es' }, { lang: "français", id: 'fr' }, { lang: "indonesia", id: 'id' }, { lang: "italiano", id: 'it' }, { lang: "português", id: 'pt' }, { lang: "tiếng việt", id: 'vi' }, { lang: "中國", id: 'zh' }, { lang: "한국의", id: 'ko' }],

		supportedLangs: function supportedLangs() {
			var supportedLangs = [],
			    idx = 0;
			var languages = this.get('languages');

			for (var i in languages) {
				if (!languages.hasOwnProperty(i)) {
					continue;
				}
				supportedLangs[idx] = languages[i].id;
				idx++;
			}

			return supportedLangs;
		},

		/**
	  * Get (and set) language through jsconfig.js on activation of the application
	  */
		detectLanguage: (function () {

			UTIL.storage.checkStorage(ENV['default'].APP.localStorageCacheVersion);

			var lang = 'en';
			var browserLang = this.get('globals').get('srvParams.lang');

			var supportedLangs = this.supportedLangs();
			var idx = $.inArray(browserLang, supportedLangs);

			if (idx >= 0) {
				lang = supportedLangs[idx];
			}

			var set = Ember['default'].set;
			var application = this.container.lookup('application:main');
			set(application, 'locale', lang);

			this.initServices();
		}).on('activate'),

		/**
	  * init foreign services
	  */
		initServices: function initServices() {
			UTIL.socialConnect.initService('facebook');
			//UTIL.socialConnect.initService('twitter');
		},

		/**
	  * Load user model
	  *
	  * @param transition
	  * @returns {boolean}
	  */
		beforeModel: function beforeModel(transition) {

			//var t = this.store.unloadAll('user');

			var me = this,
			    controller = me.controllerFor('application');

			//var controller = this.controllerFor('application');
			controller.set('languages', this.get('languages'));

			if (controller.get('loginChecked') === false) {

				// create globalstatestore

				var wonder = localStorage.wonder;
				if (wonder) {
					try {
						wonder = JSON.parse(wonder);
					} catch (e) {
						wonder = '';
					}
				}

				me.store.createRecord('applicationstate', {
					id: 'globalstate',
					expanded: localStorage.isContentExpanded || false,
					visibleContent: false,
					seenSplash: localStorage.seenSplash || false,
					lang: this.get('globals').get('srvParams.lang'),
					mosaic: localStorage.mosaic || this.get('globals').get('srvParams.nearestWonderWallId'),
					wonder: wonder
				});

				transition.abort();

				var transit = function transit() {
					controller.set('loginChecked', true);

					//				reload the transition
					var url = transition.intent.url;
					if (!url) {

						url = transition.targetName;
					}

					me.transitionTo(url);
				};

				// Change user to model

				$.get("/api/v1/auth/checkuser").then(function (a) {
					if (a && a.user) {
						controller.setUser(a.user);
					}
					transit();
				}, (function () {
					transit();
					return null;
				}).bind(this));

				return false;
			}
		},

		actions: {

			/**
	   * Application actions to show/remove modal overlay
	   * @param name
	   * @param model
	   */
			showModal: function showModal(name, model) {
				this.render(name, {
					into: 'application',
					outlet: 'modal',
					model: model
				});
			},
			removeModal: function removeModal() {
				this.disconnectOutlet({
					outlet: 'modal',
					parentView: 'application'
				});
			},

			linkToModal: function linkToModal(route) {
				var modal = this.controllerFor(route);
				modal.show({});
			},

			oauthLogin: function oauthLogin(type, route) {
				UTIL.storage.storeSession('oauth-login', type);
				UTIL.storage.storeSession('oauth-login-route', route);
				this.render('loading', {
					into: 'application',
					outlet: 'loading'
				});
				location.href = '/account/' + type;
			},

			raiseError: function raiseError(statusText, statusCode, reason, message) {
				this.controllerFor('error-modal').raise({
					statusText: statusText || 'Sorry',
					statusCode: statusCode || 404,
					reason: reason,
					message: message || 'An error occured'
				});
			},

			error: function error(_error, transition) {
				if (typeof console === 'object') {
					console.log('ApplicationRoute an Error occured', _error);
				}
			},

			linkToMosaic: function linkToMosaic(mosaicId) {

				var controller = this.get('controller');
				var visibleContent = controller.get('model.visibleContent'),
				    subRoute;
				switch (visibleContent) {
					case 'choose-wonder':
					case 'tilezoommap':
					case 'map':
					case 'top':
					case 'my-photo':
					case 'my-wonderlock':
						subRoute = visibleContent;
						break;
				}

				if (subRoute) {
					this.transitionTo(subRoute, mosaicId);
				} else {
					this.transitionTo('mosaic', mosaicId);
				}
			},

			/**
	   * Fake action
	   *
	   * @link http://discuss.emberjs.com/t/get-html-element-of-the-clicked-link-in-a-view/6447/3|Get HTML element of the clicked link in a View
	   */
			comingSoon: function comingSoon(a, b) {
				if (typeof console === 'object') {
					console.log('TODO ', this, a, b);
				}
			},

			/**
	   * Debug Function to delete all
	   */
			clearAllCookiesAndStorages: function clearAllCookiesAndStorages() {
				Cookies.remove('mosaicTile', { path: '/' });
				Cookies.remove('pic_anon_owner_token', { path: '/' });
				//Cookies.remove('PHPSESSID',{ path: '/' });
				var model = this.controllerFor('application').get('model');
				model.eachAttribute(function (name, meta) {
					//if(typeof console === 'object') { console.log('item',name); }
					localStorage.removeItem(name);
				});
				model.deleteRecord();
				//this.transitionTo( 'splash' );
				location.href = '/';
			}
		}
	});

});