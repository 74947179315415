define('wow-app/models/picture', ['exports', 'wow-app/models/abstract-photo', 'ember-data'], function (exports, AbstractPhoto, DS) {

  'use strict';

  exports['default'] = AbstractPhoto['default'].extend({

    isMyPhoto: DS['default'].attr('boolean', { defaultValue: true })

  });

});