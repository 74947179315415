define('wow-app/views/mosaic', ['exports', 'ember', 'wow-app/views/base-view'], function (exports, Ember, BaseView) {

	'use strict';

	exports['default'] = BaseView['default'].extend({

		classNames: ['app-mainstage'],
		classNameBindings: ['hasLazyLoader:lazyLoader', 'visContent', 'bottomContentPosition'],

		/**
	  * Binded Class Atribute for content position (bottom-left/top-right)
	  */
		bottomContentPosition: 'bottom-left',

		/**
	  * set class name to visible content
	  */
		visContent: (function () {
			var me = this,
			    controller = me.get('controller');
			return controller.get('model.applicationstate.visibleContent');
		}).property('controller.isContentVisible'),

		/**
	  * Hide the content with the given context (complete|complete+application-button)
	  * @param c
	  */
		hideAll: function hideAll(c) {
			var controller = this.get('controller');
			this.hideContent(c);
			controller.set('model.applicationstate.expanded', false);
		},

		actions: {

			/**
	   * push the content out of te view port (leave navigation) in order to
	   * see the mosaic fullscreen
	   */
			toggleContent: function toggleContent() {

				var controller = this.get('controller');
				var willBeExpanded = controller.get('model.applicationstate.expanded') ? false : true;

				if (willBeExpanded) {
					controller.set('model.applicationstate.expanded', true);
					this.reArrangeLayout();
				} else {
					controller.set('model.applicationstate.expanded', false);
					this.hideContent('toggle');

					// stop video
					UTIL.stopAllVideoPlayer();
				}
			},

			/**
	   * Main Navigation Function
	   *
	   * @param route
	   * @param modelId
	   * @returns {boolean}
	   */
			linkTo: function linkTo(route, modelId) {

				var controller = this.get('controller'),
				    isExpanded = controller.get('model.applicationstate.expanded'),
				    visibleContent = controller.get('model.applicationstate.visibleContent');

				if (isExpanded && route == visibleContent) {
					return true;
				} else if (!isExpanded && route == visibleContent) {
					this.showContent();
					controller.set('model.applicationstate.expanded', true);
				} else {

					this.activeNavigation(route);
					this.set('hasLazyLoader', true);

					// transition should be done after animation
					if (modelId) {
						controller.transitionToRoute(route, modelId);
					} else {
						controller.transitionToRoute(route);
					}
				}

				//if(typeof console === 'object') { console.log('Link To',route); }
			},

			openMosaicList: function openMosaicList() {

				var $element = this.$(),
				    mosaicList = $element.find('.tilezoom-mosaic-choose'),
				    chooserButton = $element.find('.zoom-to-mosaic');

				var btPos = chooserButton.offset();

				// recheck if window width is to small for the overlay
				var checkSize = function checkSize() {

					var wwidth = $(window).width();
					if (mosaicList.width() >= wwidth) {
						mosaicList.css({
							width: wwidth + 'px'
						});
					} else {
						mosaicList.css({
							width: '255px'
						});
					}
				};

				checkSize();

				var moveOutside = function moveOutside() {
					mosaicList.transit({
						x: $(window).width() - btPos.left + (mosaicList.outerWidth() + chooserButton.outerWidth()) + 100 + 'px',
						y: '0px',
						complete: function complete() {
							$(window).off('click.mosaicList');
							mosaicList.off('mouseout.mosaicList');
						}

					});
				};

				fastdom.write(function () {

					mosaicList.css({
						left: btPos.left,
						top: btPos.top - (mosaicList.outerHeight() - chooserButton.outerHeight()),
						x: $(window).width() - btPos.left + (mosaicList.outerWidth() + chooserButton.outerWidth()) + 100 + 'px'
					});

					fastdom.defer(5, function () {
						mosaicList.transit({
							x: (mosaicList.outerWidth() - chooserButton.outerWidth()) * -1 + 'px',
							y: '0px',
							complete: function complete() {

								mosaicList.on('mouseout.mosaicList', function () {
									moveOutside();
								});

								$(window).on('click.mosaicList', function () {
									moveOutside();
								});
							},
							duration: 300,
							easing: 'snap'
						});
					});
				});
			}

		},

		/**
	  * Called before re-arrange (positioning) of next content/route
	  *
	  * @param route
	  */
		beforeLinkTo: function beforeLinkTo(route) {

			this.activeNavigation(route);
			this.set('hasLazyLoader', true);
			var settings = this._lookUpSettings(route);
			//if(typeof console === 'object') { console.log('MosaicView.linkTo.new-settings,activesettings',route,settings,this.settings); }

			// check if we need to hide a content and if
			// transition may only start AFTER hiding of content
			var hasBottomContent = settings && settings.templates && settings.templates.bottom;
			var hasTopContent = settings && settings.templates && settings.templates.top;
			var hasBottomContentOverlay = settings && settings.templates && settings.templates.bottomOverlay;

			// remove bottom on joy screen...
			if (settings && settings.hideAll) {
				this.hideAll(settings.hideAll);
			}

			if (!hasTopContent) {
				this._hideTopContent();
			}

			if (!hasBottomContentOverlay) {
				this._hideBottomContentOverlayContent();
			}
		},

		/**
	  * binded attribute to collapse content
	  * Update Layout on expand / collapse
	  */
		isContentExpanded: (function () {
			var me = this,
			    controller = me.get('controller');
			return controller.get('model.applicationstate.expanded');
		}).observes('controller.isContentExpanded'),

		/**
	  * Keep an eye on this! --> fixed with willDestroyElement->removeObserver
	  * We are using doLayout in "isContentVisible" and didInsertElement to avoid that reArrangeLayout runs twice
	  * BUT: this is not really good working, after the second initialization of mosaic controller and view (after splash screen)
	  * it still runs twice  - without an $element? SO KEEP SURE THAT this.$() IS DEFINED BEFORE EXECUTING THIS
	  *
	  */
		doLayout: function doLayout() {

			var me = this,
			    controller = me.get('controller'),
			    newRoute = controller.get('model.applicationstate.visibleContent');

			this.beforeLinkTo(newRoute);
			this.reArrangeLayout();
		},

		/**
	  * binded attribute to visibleContent
	  * Update on content change (height?)
	  */
		isContentVisible: (function () {

			var me = this,
			    controller = me.get('controller'),
			    newRoute = controller.get('model.applicationstate.visibleContent');
			Ember['default'].run.scheduleOnce('afterRender', this, this.doLayout);

			return newRoute;
		}).observes('controller.isContentVisible'),

		didInsertElement: function didInsertElement() {
			var me = this,
			    controller = me.get('controller');

			me.hideStartSplash();
			Ember['default'].run.scheduleOnce('afterRender', this, this.doLayout);
		},

		willDestroyElement: function willDestroyElement() {
			this.removeObserver('controller.isContentVisible');
			this.removeObserver('controller.isContentExpanded');
		}

	});

});