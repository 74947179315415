define('wow-app/templates/accept-terms/es', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Al entrar al sitio web móvil, a la aplicación o al sitio web de wonderswall.com (en ocasiones denominados colectivamente «wonderswall.com» o «sitio web») o al usar sus funciones, usted acepta cumplir las siguientes condiciones en relación con el sitio web, los servicios y todas las cuestiones afines.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Derechos de autor");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Copyright 2014-2021, Global Platform Limited, (GPL), Dubai, Emiratos Árabes Unidos.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Con licencia de NewOpenWorld Corporation.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Contacto: contact ( arroba ) wonderswall punto com");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Todos los derechos reservados.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Agente de propiedad intelectual para avisos y otras comunicaciones.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("CONDICIONES PRINCIPALES PARA PARTICIPAR EN WONDERSWALL.COM");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Subir fotografías");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Algunas partes del sitio web le permiten enviarnos sus fotos. Pretendemos comprobar brevemente la calidad del contenido y la idoneidad del material para asegurarnos de que este siga siendo un sitio en el que todo el mundo pueda disfrutar, pero no asumimos responsabilidad alguna si surge algún problema con el contenido.\n        Usted garantiza que el material que facilita no viola los derechos de autor ni otros derechos de terceras partes u organizaciones. Le rogamos que no nos envíe material que sea propiedad de otra persona si no tiene el permiso necesario.\n        GPL no acepta expresamente ninguna responsabilidad por material que sea propiedad de una tercera persona; GPL no será responsable, frente a usted o a terceras partes, de ninguna pérdida (independientemente de que dicha pérdida se hayan previsto o conocido previamente), incluyendo, entre otras cosas: la pérdida de datos, la pérdida de ingresos, la pérdida de negocio, la pérdida de oportunidades o cualquier daño indirecto, resultante, especial o ejemplar derivado del uso del sitio web como resultado de haber facilitado a GPL contenido de terceras partes intencionadamente o sin saberlo.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Propiedad del contenido subido");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Al subir fotos al sitio web, usted acepta, mediante su envío, conceder a GPL un derecho perpetuo, exento de regalías, no exclusivo y sublicenciable y da su autorización para usarlas, reproducirlas, modificarlas, adaptarlas, publicarlas, traducirlas, crear trabajos derivados de ellas, distribuirlas, representarlas, interpretarlas, ponerlas a disposición del público y ejercer todos los derechos de autor y de publicidad de su contribución en todo el mundo o incorporar su contribución en otras obras de cualquier medio conocido actualmente o desarrollado más tarde durante todo el período de cualquier derecho que pueda existir en su contribución.\n        Si no quiere conceder a GPL los derechos mencionados anteriormente, le rogamos que no suba sus fotos.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Fotos en wonderswall.com");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL se reserva el derecho a aceptar o rehusar cualquier fotografía.\n        La posición de las fotos se podrá modificar en cualquier momento para garantizar una imagen global óptima de las maravillas o por cualquier otro motivo que GPL decida.\n        Para generar una imagen global óptima de las maravillas, de vez en cuando GPL podrá, según su criterio, completar las fotos subidas con otras fotos que haya obtenido.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Conducta ética y moral");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("No podrá utilizar el sitio web para ninguno de los siguientes propósitos:\n        (i) difundir material ilegal, hostil, difamatorio, abusivo, amenazante, perjudicial, vulgar, obsceno o material que resulte censurable de cualquier otro modo o que infrinja la ley; (ii) transmitir material que fomente una conducta que constituya un delito, que dé lugar a una responsabilidad civil o que de cualquier otra forma infrinja una ley, normativa o código de prácticas vigente en su jurisdicción o en otra; (iii) interferir con el uso o disfrute que haga otra persona del sitio web; hacer, transmitir o guardar copias electrónicas de material protegido por derechos de autor sin el permiso del propietario. Usted será responsable de las pérdidas y gastos derivados del incumplimiento de este requisito. Nos reservamos el derecho a eliminar cualquier material que creamos conveniente según nuestro absoluto criterio.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("OTROS TÉRMINOS Y CONDICIONES GENERALES");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Respeto de los derechos de autor y de otros derechos y acuerdos sobre propiedad intelectual");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL respeta la propiedad intelectual de otras personas y pedimos a nuestros usuarios que hagan lo mismo. En determinadas circunstancias y según su criterio, GPL podrá desactivar o dar de baja aquellas cuentas de usuario que puedan violar los derechos de propiedad intelectual e GPL o de otros. Si cree que su obra ha sido copiada de forma que suponga una violación de los derechos de autor o han violado sus derechos de propiedad intelectual de otra forma, póngase en contacto con nosotros.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Marcas de New7Wonders");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("New7Wonders y otros logotipos y nombres de productos y servicios de New7Wonders son marcas registradas de New Open World Corporation, representada por GPL.\n        Usted acepta no exhibir ni utilizar en modo alguno las marcas de New7Wonders sin el permiso previo de GPL. Con su uso y participación en el sitio web, usted confirma que acepta no copiar, reproducir, volver a publicar, descargar, publicar, divulgar, transmitir, poner a disposición del público o utilizar de cualquier otra forma en ningún medio o sitio web ningún contenido de New7Wonders para otro fin que no sea el de su propio uso personal y no comercial. Usted acepta no adaptar, modificar o crear una obra derivada del sitio web o de otro contenido de New7Wonders si no es para su uso personal y exclusivamente no comercial. Cualquier otro uso del sitio web o del contenido de New7Wonders requiere el consentimiento previo por escrito de GPL.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Privacidad");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL se toma su privacidad muy en serio.\n        Puesto que necesitamos recopilar cierto tipo de información sobre nuestros usuarios, queremos ayudarle a comprender los términos y condiciones relacionados con la colección y el uso de dicha información. Si usted ha autorizado a GPL a ponerse en contacto con usted para actualizar la información o recibir otras comunicaciones, sus datos se guardarán en las bases de datos de GPL. GPL podrá utilizar ciertos dispositivos web estándares, como imágenes electrónicas, para hacer un seguimiento de las pautas de tráfico generales de los usuarios. GPL también podrá recibir y grabar automáticamente información de nuestros servidores y de su navegador, como su dirección IP, la hora e información sobre la página solicitada. Como es normal en la web, GPL también podrá recibir información sobre usted de otras fuentes y añadirla a la información que usted nos ha facilitado. GPL utiliza la información que recopila para cuatro propósitos generales:\n        1. Personalizar la publicidad y el contenido que usted ve; 2. Mejorar la experiencia de usuario en nuestra red de sitios. Utilizamos información de seguimiento para determinar cómo funciona cada página en general basándonos en características demográficas globales de los usuarios y en el tráfico en esas páginas. Esto nos ayuda a mejorar el servicio para usted; 3. Satisfacer sus futuras solicitudes respecto a ciertos productos y servicios, como el envío de boletines electrónicos y la participación de los usuarios en encuestas, concursos, tablones de anuncios y subastas. 4. Enviarle la información que ha aceptado recibir sobre temas que creamos que pueden interesarle.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Legislación nacional");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Dada la naturaleza global de Internet, usted acepta cumplir con toda las leyes, normas y acuerdos nacionales y locales correspondientes en materia de conducta en línea y contenido aceptable. En concreto, usted acepta cumplir con todas las leyes vigentes en relación con la transmisión de datos técnicos exportados desde el país en que reside.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Exención de responsabilidad");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Usted acepta eximir de toda responsabilidad a GPL y sus subsidiarias, filiales, delegados, representantes, asociados y demás socios y empleados respecto a cualquier reclamación o demanda, incluidos honorarios razonables de abogados, planteada por terceras partes que se deba o surja del contenido que usted haya enviado, publicado, transmitido o facilitado a través del servicio, de su uso del servicio, de su conexión al servicio, de su violación de los términos y condiciones o de la violación de los derechos de otros.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Prohibición de reventa del servicio");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Usted acepta no reproducir, duplicar, copiar, vender, revender o explotar con fines comerciales ninguna parte del servicio, ningún uso del servicio ni ningún acceso al servicio.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Modificación del sitio web");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL se reserva el derecho a, en cualquier momento y cada cierto tiempo, modificar o suspender el sitio web (o parte de él) temporal o permanentemente y con o sin previo aviso.\n        Usted acepta que GPL no será responsable frente a usted ni frente a terceras partes de cualquier modificación o suspensión del sitio web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Enlaces");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("El sitio web o terceras partes pueden facilitar enlaces a otros sitios o recursos de Internet.\n        Puesto que GPL no tiene control sobre esos sitios y recursos, usted entiende y acepta que GPL no es responsable de la disponibilidad de dichos sitios o recursos externos y que no respalda ni es responsable de ningún contenido, publicidad, producto u otro material que aparezca o esté disponible en esos sitios o recursos. Usted también entiende y acepta que GPL no será responsable, directa o indirectamente, de ningún daño o pérdida causado o presuntamente causado por o en relación con el uso de o la confianza en ese contenido, producto o servicio disponible en o a través de dicho sitio o recurso.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Propiedad de los datos");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Cualquier dato, como la participación en el Muro de las Maravillas, que GPL pueda publicar estará protegido por derechos de autor. Hay normas vinculantes específicas que contemplan el uso de estos datos. Medios informativos: los medios de comunicación reconocidos podrán reproducir estos datos en un contexto exclusivamente informativo y siempre y cuando se muestre el siguiente texto de forma visible: datos © www.wonderswall.com.\n        Otros usos: cualquier otro uso por parte de organizaciones, empresas o sitios web requerirá la licencia y la aprobación de GPL. Póngase en contacto con nosotros si desea más información.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Exclusión de garantías del servicio técnico");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("El contenido del sitio web, como información, nombres, imágenes y logotipos, relacionado con New7Wonders y el Muro de las Maravillas o sus servicios se proporciona «tal cual» y en función de su disponibilidad sin declaraciones ni garantías (explícitas o implícitas) en la medida en que la ley lo permita, incluyendo la no violación, la compatibilidad, la seguridad y la exactitud. GPL no garantiza que las funciones incluidas en el contenido del sitio web no sufran retrasos o interrupciones y que la fotografía u otras transferencias sean exactas o completas, que los defectos se corrijan o que el sitio web o el servidor que hace que esté disponible esté libre de virus o fallos o de cualquier otro material basado en Internet que resulte perjudicial. En ningún caso GPL será responsable frente a usted o a terceras partes de ninguna pérdida (independientemente de que dicha pérdida se haya previsto o conocido previamente), incluyendo, entre otras cosas: la pérdida de datos, la pérdida de ingresos, la pérdida de negocio, la pérdida de oportunidades o cualquier daño indirecto, resultante, especial o ejemplar derivado del uso del sitio web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Exclusión de garantías");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Usted entiende y acepta expresamente que usted usa el sitio web por su propia cuenta y riesgo. El sitio web se proporciona «tal cual» y en función de su disponibilidad.\n        GPL no se hace responsable de ningún tipo de garantía, ya sea explícita o implícita, incluyendo, entre otras, las garantías implícitas de comerciabilidad, idoneidad para un fin concreto y no violación. GPL no garantiza que (i) el sitio web satisfaga sus necesidades, (ii) el sitio web no sufra interrupciones o retrasos, sea seguro y no tenga errores, (iii) los resultados que se puedan obtener del uso del sitio web sean precisos o fiables, (iv) la calidad de los productos, servicios, información u otro material adquirido u obtenido por usted a través del sitio web satisfaga sus expectativas y (v) se corrijan los errores de software. Toda descarga u obtención de material a través del sitio web, siempre que esté permitida, correrá por su propia cuenta y riesgo. Asimismo, usted será el único responsable de cualquier daño en su sistema informático o de cualquier pérdida de datos que surja de la descarga de dicho material.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Limitación de responsabilidad");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Usted entiende y acepta expresamente que GPL no será responsable de ningún daño directo, indirecto, imprevisto, especial, resultante o ejemplar, incluyendo, entre otros, daños por pérdidas de beneficios, de fondos de comercio, de uso u otras pérdidas intangibles (incluso si GPL hubiera advertido sobre la posibilidad de dichos daños) que se deban a:\n        (i) el uso o la incapacidad de usar el sitio web; (ii) los gastos de adquisición de bienes y servicios sustitutivos derivados de cualquier bien, dato, información o servicio comprado u obtenido o de mensajes recibidos o transacciones realizadas desde el sitio web; (iii) el acceso no autorizado o la modificación de sus transmisiones o datos; (iv) declaraciones o conductas de terceras partes en el sitio web o (v) cualquier otra cuestión relacionada con el sitio web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Edad de los usuarios");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Creemos que wonderswall.com está abierto a cualquier persona de cualquier edad.\n        Sin embargo, entendemos que en algunos países y culturas puede haber un marco legal diferente respecto a la edad de los usuarios. No recopilamos ni solicitamos deliberadamente información personal de nadie que no tenga la edad permitida (en su propio país) si la ley lo prohíbe ni dejamos que esa persona se registre. Si la legislación de su país le prohíbe registrarse, no debe intentar registrarse en el sitio web ni enviarnos información personal, como su nombre, dirección, número de teléfono o su dirección de correo electrónico. Si la legislación de su país le exige obtener un permiso, entonces solicite ese permiso a sus padres o tutores antes de facilitar información personal al sitio web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Modificación de las condiciones");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL se reserva el derecho a modificar estos términos y condiciones en cualquier momento mediante la publicación de los cambios en línea. Consulte periódicamente estas condiciones para asegurarse de que está al tanto de los cambios realizados por GPL. Si continúa utilizando el sitio web después de que se publiquen los cambios, con ello confirma que acepta estar sujeto legalmente a estos términos tal y como se hayan actualizado o corregido.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Legislación aplicable");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Estos términos y condiciones se regirán e interpretarán según la legislación del cantón de Zúrich, Suiza.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});