define('wow-app/templates/accept-terms/zh', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("通过输入wonderswall.com移动网站、应用、网站（有时这里也统称位wonderswall.com或网站）\n        和/或使用其功能，您同意遵守以下有关网站、服务以及所有相关事宜的条款和条件。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("版权");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("版权所有2014 - 2021年，Global Platform Limited, (GPL)，迪拜，阿拉伯联合酋长国。");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("由NewOpenWorld公司授权。");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("联系我们: contact ( at ) wonderswall dot com");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("保留一切权利。");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("声明和其他通信的知识产权代理。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("关于加入WONDERSWALL.COM的主要条款");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("上传照片");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("本网站的某些部分允许您向我们发送你的照片。我们的目标是力求通过简洁的方式保证内容质量和材料的适当性，\n        从而确保本网站对每一个访问者来说均是一种享受的同时，不会由于内容问题而被追究责任。\n        您保证提供的任何材料不会侵犯版权或任何第三方个人或组织的其他权利。\n        请不要向我们发送任何您并不拥有必要许可的第三方所有的材料。\n        GPL明确表示对任何此类第三方拥有的材料不承担任何责任; GPL对您或\n        第三方的任何损失不承担任何责任（无论这种损失是否已预见，可预见，已知或其它），损失包括但不限于：\n        数据丢失; 收入损失; 业务损失; 机会损失;\n        由于使用网站故意或无意向GPL提供第三方内容的结果而产生的任何间接的，继发的，特殊的或惩罚性赔偿。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("上载内容所有权");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("当您将照片上传至网站时，您同意在提交你的资料时授予GPL永久的，免版税的，非排他性的，\n        分许可权利和许可，以供使用，复制，修改，改编，出版，翻译，创作，发布，表演，播放衍生作品，\n        提供给公众并在全球行使所有您所提供的作品的版权及宣传权以及/或者\n        将您所提供的作品纳入到其他任何媒体中的其他作品。\n        如果您不希望授予GPL上述权利，请不要上传您的照片。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("wonderswall.com上的照片");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL保留接受或拒绝任何照片的权利。所有照片的位置均可在任何时间改变，\n        以确保奇迹的最佳整体形象，或者由于GPL提出的任何其他原因。\n        为了打造奇迹的最佳整体形象，GPL偶尔会酌情补充上传其所获得的其他照片。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("伦理和道德操守");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("不得将本网站用于以下任何目的：\n        （一）传播非法，骚扰，诽谤，辱骂，威胁，有害，粗俗，淫秽或\n        其他不良的材料或以其他方式违反任何法律;\n        （ii）发送鼓励构成犯罪行为，导致民事责任或违反任何适用法律，法规或任何其他司法管辖区的工作守则的的材料;\n        （iii）干扰任何其他人使用或享用本网站;或者未经所有者许可制作，传送或储存受版权保护的材料电子副本。\n        您将对我们的损失负责以及违反这项规定所产生的费用。我们保留酌情删除我们认为合适的任何材料的权利。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("其他一般条款和条件");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("遵守版权及其他知识产权和公约");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL尊重他人的知识产权，我们要求我们的用户也这样做。 GPL可能在适当情况下自行决定禁用和/或终止可能侵犯GPL和/或他人知识产权的帐户的用户。如果您认为您的作品被复制并构成版权侵权或您的知识产权被侵犯，请与我们联系。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("New7Wonders商标");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("New7Wonders和其他New7Wonders徽标、产品和服务名称是New Open World公司的注册商标，此处以GPL表示。未经GPL事先许可，您同意不显示或以任何方式使用New7Wonders商标。您在使用和参与本网站时证明您同意不复制，转载，再版，下载，发布，广播，传送，提供给公众或以任何方式在任何网站或以其他方式在任何媒体上以任何方式在除个人，非商业用途范围之外使用New7Wonders内容。您同意不修改，更改或通过网站或其他New7Wonders内容创作衍生作品（个人，非商业用途除外）。网站或者New7Wonders内容的任何其他用途必须经GPL的事先书面许可。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("隐私");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL重视您的隐私。因为我们必定要收集某些类型的用户信息，我们希望能够帮助您了解有关收集和使用这些信息的条款和的条件。如果您授权GPL在以信息更新和其他通讯为目的的情况下与您联系，那么您的详细情况将被保留在GPL数据库中。GPL可能会使用某些网络标准设备，例如：电子影像，来跟踪普通用户流量模式。GPL也可能从我们的服务器和您的浏览器自动接收并记录信息，包括您的IP地址，时间以及您请求的页面信息。由于在网络上属于正常现象，GPL也可能从其他来源收到关于您的信息并把它添加到您所提供给我们的信息中。GPL将收到的信息用于以下四个普通用途：1. 定制您看到的广告和内容; 2.加强我们站点的用户体验。我们使用跟踪信息，以便基于用户人口统计资料和流量模式在整体上确定这些网页的表现，这有助于我们继续为您打造更优质的服务; 3. 要在未来满足您对某些产品和服务的要求，如：发送电子通讯，使用户能够参与投票，竞赛，使用留言板和进行拍卖; 4. 为您发送您同意接收的，我们认为您会感兴趣的主题。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("国家法律");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("认识到互联网的全球性，您同意遵守有关在线行为和可接受内容的所有相关国家和地方法律，法规和惯例。具体而言，您同意遵守关于从您的居住国出口的技术数据传输的所有适用的法律。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("损害赔偿");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("您同意确保GPL及其子公司，关联公司，职员，代理人，联名单位或其他合作伙伴及员工免于承担任何索赔或要求，包括由于您提交，发布，传输或通过服务使其有效的内容，您使用本服务，您连接到服务，您违反本条款和条件或侵犯其他人的任何权利所导致的任何第三方提出的合理的律师费。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("禁止转售服务");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("您同意不复制，复印，出售，转售该服务的任何部分，服务的使用或对该服务访问或将其用于任何商业目的。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("修改网站");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL保留在经过或未经通知的情况下在任何时候和不时修改或暂时或永久中断网站（或其任何部分）的权利。您同意GPL在进行任何修改，暂停或终止网站时对您或任何第三方网站概不负责。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("链接");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("该网站或第三方可能提供链接到其他万维网网站或资源。由于GPL对此类网站和资源无法控制，您承认并同意，GPL对于此类外部网站或资源的可用性不认可，不负责或对其内容，广告，产品或其他来自此类网站的材料或资源承担任何责任。您进一步承认和同意，GPL对于由于使用或依赖任何在此类网站或资源上提供的内容，商品或服务所引起的损坏或损失不承担任何责任。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("数据所有权");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("任何由GPL显示或发布的数据（如：加入奇迹之墙）均具有版权。具体的约束力规则涵盖了这些数据的使用。新闻媒体：经认可的新闻媒体仅可在新闻背景下复制此数据并且必须始终注明：数据©www.wonderswall.com。其他用途：由组织机构，公司，网站进行的任何其他用途均必须经过GPL批准和授权。请联系我们获取更多信息。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("技术服务免责声明");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("网站的内容，包括信息，姓名，照片，图片和有关New7Wonders和奇迹之墙壁或其服务的标识，按“原样”和“可依据基础”在未作出任何陈述或任何形式担保的情况下（无论提供法律明示或暗示），在法律（包括非侵权，兼容性，安全性和准确性）允许的范围内提供。GPL不保证包含在网站内容中的功能不会延误或中断，或照片或其他传输数据准确或完整，缺陷得到纠正，或该网站或服务器不存在病毒或错误或任何其他损害互联网为基础的材料。在任何情况下，GPL对您或任何第三方的任何损失（这种损失已预见，可预见，已知或其它）将不承担任何责任，包括但不限于：数据丢失; 收入损失; 业务损失; 机会损失; 任何由于使用本网站所导致的间接的，继发的，特殊的或惩罚性的赔偿。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("担保声明");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("你明确了解并同意：使用本网站时，您须自行承担风险。该网站按“原样”和“可依据基础”提供。 GPL明确表示不承担任何形式的担保，无论明示或暗示，包括但不限于适用于特定用途和非侵权的适销性，适用性的隐含担保。 GPL不保证（i）本网站将满足您的要求，（ii）本网站不间断，及时，安全或无差错;（iii）使用本网站所获得的结果准确或可靠，（iv）任何通过该网站购买或获得产品，服务，信息或其他材料满足您的质量期望，（v）软件中的任何错误都将被纠正。通过使用网站，在允许的情况下下载或取得任何资料由您自行决定并且风险自负，您将对由于下载任何损害材料而造成的您的计算机系统或数据丢失单独承担责任。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("责任限制");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("您明确理解并同意，GPL不会对任何直接，间接，附带，特别，衍生性或惩罚性损害承担责任，包括但不限于：利润、信誉损失，应用、数据或其他无形损失（即使GPL已经被告知此类损害的可能性），这些损失由于以下各项的解锁所导致：（i）使用或无法使用本网站; （ii）由于使用本网站所获取或购买的任何商品、数据、信息或服务所产生的替代商品和服务的采购成本; （iii）未经授权访问或更改您的传输或数据; （iv）任何第三方在网站上的声明或行为; 或（v）任何与网站相关的其他事项。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("用户年龄");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("我们相信，wonderswall.com向所有人以及所有年龄段的人群开放。然而，据我们了解，在一些国家和文化中有可能是关于用户年龄的法律或其他框架。我们不会在不允许的年龄段人群中在其所在国家在法律禁止的情况下故意收集或征求任何个人信息。如果您的国家法律禁止注册，您将无法注册网站或发送有关自己的任何信息，包括但不限于：姓名，地址，电话号码或电子邮件地址。如果您的国家法律要求你获得许可，那么请向网站提供个人信息之前，取得您的父母/监护人的许可。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("更改条款");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL保留在任何时候通过网上发布变更修改这些条款和条件的权利。请定期查看这些条款，以确保您知道通过GPL所做的任何更改。您继续使用本网站的任何改变被发布后，表示您同意在法律上受这些条款更新和/或修订的约束。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("适用法律");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("这些条款和条件受瑞士苏黎世州的法律约束。");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});