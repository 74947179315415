define('wow-app/controllers/base-controller', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    //
    //openContent: function() {
    //this.set('contentIsOpen',true);
    //if(typeof console === 'object') { console.log('openContent',this.get('contentIsOpen')); }
    //},
    //
    //closeContent: function() {
    //this.set('contentIsOpen',false);
    //
    //},
    //
    //actions: {
    //
    //
    //
    //   showContent: function() {
    //
    //   },
    //
    //	close: function () {
    //
    //        this.transitionToRoute('home');
    //    }
    //}
  });

});