define('wow-app/views/tilezoom', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].View.extend({

		classNames: ['app-tilezoom-contain'],
		classNameBindings: ['zoomNavigation:zoom-navi-on:zoom-navi-off', 'screensaver:screensaver-on:screensaver-off'],

		zoomNavigation: (function () {
			var controller = this.get('controller');
			return controller.get('zoomNavigation');
		}).property('controller.zoomNavigation'),

		screensaver: (function () {
			var controller = this.get('controller');
			return controller.get('screensaver');
		}).property('controller.screensaver'),

		isRendered: false,
		viewLoaded: false,

		didInsertElement: function didInsertElement() {

			this.loadingWrapper = $('.loading-wrapper', this.$());
			this.refreshTilezoom();

			this.viewLoaded = true;
		},

		/**
	  * on unload of the view remove widget and widgets events
	  */
		willClearRender: function willClearRender() {
			this.destroyTilezoom();
		},

		destroyTilezoom: function destroyTilezoom() {
			var me = this,
			    $element = me.$(),
			    $tilezoom = $('#tilezoom', $element);

			// tilezoom
			$tilezoom.data('tilezoom.settings', null);
			$('.zoom-overlay').remove();
		},

		refreshTilezoom: function refreshTilezoom() {

			var me = this,
			    $element = me.$(),
			    screenWidth = $(document).width(),
			    controller = me.get('controller'),
			    parentView = this.get('parentView'),
			   
			//controllerPhototiles = me.get('controllers.phototiles'),
			$container = $('#tilezoom');

			controller.set('isLoading', true);

			var minLevel = 10;
			if (screenWidth >= 1200) {

				minLevel = 10;
			} else if (screenWidth >= 1000) {

				minLevel = 9;
			} else {

				minLevel = 8;
			}

			var mosaic = controller.getMosaic();
			var overviewImageSize = controller.getOverviewImageSize();

			var $tilezoom = me.tilezoom = $('.zoom-container', $element).tilezoom({

				controller: controller,
				view: me,
				parentView: parentView,

				wowmosaicFrontendUrlBase: me.get('globals').get('srvParams.wowmosaicFrontendUrlBase'),
				path: me.get('globals').get('srvParams.wowmosaicFrontendUrlBase') + '' + '/tile/' + mosaic.get('currentTarget') + '/' + mosaic.get('renderSnapshot'),
				thumb: overviewImageSize.n,
				thumbSize: overviewImageSize,

				nearestWonder: me.get('globals').get('srvParams.nearestWonder'),
				nearestWonderWallId: me.get('globals').get('srvParams.nearestWonderWallId'),

				mosaic: mosaic,
				width: mosaic.get('finalWidth'),
				height: mosaic.get('finalHeight'),
				tileWidth: mosaic.get('tileWidth'),
				tileHeight: mosaic.get('tileHeight'),
				tileSize: mosaic.get('tilezoomWidth'),
				tilezoomBase: mosaic.get('tilezoomBase'),
				tilezoomBlowFactor: mosaic.get('tilezoomBlowFactor'),
				maxLevel: mosaic.get('zoomLvlMax'),
				currentTarget: mosaic.get('currentTarget'),

				mousewheel: true,
				autoResize: false,

				offset: '0',
				startLevel: 12,
				level: 13,
				minLevel: minLevel,

				getURL: function getURL(level, x, y, settings) {
					return settings.path + '/' + level + '/' + x + '/' + y;
				},

				//setLoading: function() {
				//	controller.set('isLoading', true);
				//},
				//
				//stopLoading: function() {
				//	controller.set('isLoading', false);
				//},

				beforeZoom: function beforeZoom($cont, oldLevel, newLevel) {
					controller.set('isLoading', true);
					controller.send('beforeZoom', oldLevel, newLevel);
				},

				afterZoom: function afterZoom($cont, coords, level) {
					controller.set('isLoading', false);
					controller.send('afterZoom', coords, level);
				},

				gotoWonder: function gotoWonder(wonderId, fit, fallback) {
					controller.gotoWonder(wonderId, fit, fallback);
				},

				getUserPicture: function getUserPicture() {
					return controller.getUserPicture();
				},

				controllerSetSize: function controllerSetSize() {
					return controller.send('setSize');
				},

				setError: function setError(m, r) {
					controller.get('controllers.error-modal').raise({
						statusText: 'Sorry',
						statusCode: 404,
						reason: r,
						message: m
					});
				}
			});

			//		allows to select pictures on the mosaic
			if (controller.get('mosaicphotoSelectable')) {

				$tilezoom.tilezoomtilephoto({

					tileWidth: mosaic.get('tileWidth'),
					tileHeight: mosaic.get('tileHeight'),

					tileClick: function tileClick(event, tilePosX, tilePosY) {

						controller.send('mosaicTileClick', event, tilePosX, tilePosY);
					}
				});
			}

			var tilezoomSettings = $tilezoom.data('tilezoom.settings');

			controller.send('initTilezoom', tilezoomSettings);

			// short delay to make content animation smoother
			me.set('isRendered', true);
		}
	});

});