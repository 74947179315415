define('wow-app/locales/fr', ['exports', 'wow-app/locales/en'], function (exports, EN) {

    'use strict';

    var FR = {
        "about": {
            "sub_title": "Laissez votre empreinte !<br />Inscrivez-vous sur le Mur des Merveilles !<br />Participez ! ",
            "text": "<p>Des centaines de millions de votes ont choisi les Nouvelles 7 Merveilles, et désormais vous-même pouvez faire partie du Mur des Merveilles en affichant votre propre photo parmi l'une des Nouvelles 7 Merveilles. </p><p>Choisissez votre merveille. Téléchargez votre photo. Partagez avec le monde entier. <br>Oui, vous faites désormais partie du <a href=\"https://twitter.com/hashtag/wonderswall\" target=\"_blank\">#murdesmerveilles</a> !!</p>"
        },
        "buttons": {
            "cancel": "Annuler",
            "change_photo": "Changer de photo",
            "change_wall": "Changer de mur",
            "change_wonder": "Changer de merveille",
            "choose_wonder": "Sélectionner une merveille",
            "close": "Fermer",
            "crop_and_save": "Sauvegardez la photo recadrée",
            "crop_photo": "Recadrer la photo",
            "join": "Participer",
            "log_out": "Se déconnecter",
            "ok": "OK",
            "register": "Inscription",
            "save": "Sauvegarder",
            "select": "Sélectionnez",
            "share": "Partager",
            "sign_in": "Se connecter",
            "sign_up": "S'inscrire",
            "upload": "Télécharger photo",
            "copy": "Copier",
            "yes": "Oui !",
            "edit": "Edit",
            "forget_password": "Mot de passe oublié?"
        },
        "congratulation": {
            "sub_title": "Vous faites désormais partie du<br>\"Mur des Merveilles\"",
            "title": "Félicitations"
        },
        "global": {
            "error_title": "Désolé",
            "next": "Suivant",
            "previous": "Précédent",
            "title": "Mur des Merveilles"
        },
        "joyscreen": {
            "join": "Participer au <span class=\"break-portrait break-landscape\"></span>Mur des Merveilles",
            "title": "J'accepte de figurer sur le <span class=\"break-portrait break-landscape\"></span>Mur des Merveilles?",
            "welcome": "Bienvenue sur le <span class=\"break-portrait break-landscape\"></span>Mur des Merveilles"
        },
        "mosaic": {
            "manmade": {
                "title": "Nouvelles 7 Merveilles du Monde"
            },
            "nature": {
                "title": "Nouvelles 7 Merveilles de la Nature"
            }
        },
        "my_photo": {
            "my_wonder": "Ma merveille",
            "no_login": "Vous n'êtes pas connecté ! Vous devez vous inscrire pour rester sur le Mur des Merveilles.",
            "no_photo": "Vous n'avez pas encore téléchargé de photo...",
            "status": "Statut",
            "sub_title": "Changer la photo ou bien changer le mur",
            "title": "Ma photo",
            "uploaded": "Téléchargement terminé",
            "motto": "Say something",
            "motto_placeholder": "Tell us about your photo",
            "location": "Location",
            "city": "City",
            "country": "Country",
            "ping": "WOW!",
            "my_wows": "My WOWs",
            "wowed_by": "WOWed by",
            "no_wows": "No WOWs yet."
        },
        "register": {
            "email": "E-mail",
            "logged_in_as": "Connecté en tant que",
            "password": "Mot de passe",
            "sign_in_email": "Se connecter par e-mail",
            "sign_up_email": "Inscription par e-mail"
        },
        "seo": {
            "meta_description": "Des centaines de millions de votes ont choisi les Nouvelles 7 Merveilles. Désormais vous-même pouvez faire partie du Mur des Merveilles."
        },
        "settings": {
            "change_language": "Langue",
            "email": "Adresse e-mail",
            "facebook": "Facebook",
            "login": "Se connecter",
            "new_to_wall": "Souhaitez-vous faire partie du Mur des Merveilles ?",
            "on_the_wall": "Déjà sur le Mur des Merveilles ?",
            "signin": "S'inscrire",
            "title": "Paramètres",
            "twitter": "Twitter",
            "upload_photo": "Télécharger une photo"
        },
        "share": {
            "fb_caption": "Je fais désormais partie du \"Mur des Merveilles\"",
            "fb_description": "Participez! Téléchargez votre photo vers le site www.wonderswall.com et devenez vous aussi une merveille! #murdesmerveilles",
            "fb_name": "Mur des Merveilles",
            "fb_published": "Votre article a bien été publié.",
            "sub_title": "Montrez à vos amis<br>votre photo accrochée sur le \"Mur des Merveilles\" ",
            "title": "Partager",
            "whatsapp": "WhatsApp",
            "link": "Lien",
            "email_from": "De",
            "email_to": "À",
            "email_subject": "Objet",
            "email_text": "Votre texte",
            "email_to_placeholder": "Veuillez séparer les adresses par une virgule",
            "email_successfully_send": "Votre e-mail a bien été envoyé"
        },
        "stay": {
            "accept": "J'accepte les conditions d'utilisation",
            "title": "Je souhaite que ma photo apparaisse sur le <span class=\"break-portrait\"></span>Mur des Merveilles",
            "privacy_policy": "Politique de confidentialité"
        },
        "terms": {
            "accept": "Veuillez accepter nos conditions d'utilisation",
            "title": "Conditions d'utilisation"
        },
        "top": {
            "title": "Téléchargement presque terminé"
        },
        "upload": {
            "image_error": "La photo ne peut être téléchargée, veuillez revenir en arrière et réessayer",
            "import_fb": "Facebook",
            "import_insta": "Instagram",
            "import_my_photos": "Vos photos",
            "import_tw": "Twitter",
            "import_webcam": "Webcam",
            "sub_title": "ou bien importez-en une ci-dessous",
            "take_photo": "Prendre une photo",
            "title": "Prendre une photo",
            "upload_error": "Une erreur s'est produite lors du téléchargement de votre image",
            "upload_save_error": "La photo ne peut être enregistrée, veuillez revenir en arrière et réessayer",
            "webcam_allow_error": "Cliquez sur « autoriser » pour prendre la photo",
            "webcam_error": "Votre webcam n'est pas compatible avec votre navigateur, merci de mettre à jour votre logiciel"
        },
        "upload_choose": {
            "change_title": "Changer votre merveille",
            "sub_title": "sur laquelle vous souhaitez accrocher votre photo",
            "title": "Choisissez la merveille"
        },
        "upload_steps": {
            "1": "1ère étape",
            "2": "2ème étape",
            "3": "3ème étape"
        },
        "wall": {
            "upload_photo": "Téléchargez vos photos sur le Mur des Merveilles"
        },
        "wonders": {
            "amazon": {
                "location": "Amérique du Sud",
                "title": "Amazone"
            },
            "christ_redeemer": {
                "location": "Brésil",
                "title": "Christ Rédempteur"
            },
            "colosseum": {
                "location": "Italie",
                "title": "Colisée"
            },
            "great_wall_of_china": {
                "location": "Chine",
                "title": "La Grande Muraille"
            },
            "ha_long_bay": {
                "location": "Vietnam",
                "title": "Baie de Hạ Long"
            },
            "iguazu_falls": {
                "location": "Argentine/Brésil",
                "title": "Chutes d'Iguazú"
            },
            "jeju_island": {
                "location": "Corée du Sud",
                "title": "Île de Jéju"
            },
            "komodo": {
                "location": "Indonésie",
                "title": "Komodo"
            },
            "machu_pichu": {
                "location": "Pérou",
                "title": "Machu Picchu"
            },
            "petra": {
                "location": "Jordanie",
                "title": "Petra"
            },
            "pp_underground_river": {
                "location": "Philippines",
                "title": "Rivière souterraine de Puerto Princesa"
            },
            "pyramid_at_chichen_itza": {
                "location": "Mexique",
                "title": "Chichén Itzá"
            },
            "table_mountain": {
                "location": "Afrique du Sud",
                "title": "Montagne de la Table"
            },
            "taj_mahal": {
                "location": "Inde",
                "title": "Taj Mahal"
            }
        },
        "oauth_errors": {
            "login_failed": "Log in via social network failed or was cancelled."
        }
    };

    FR = $.extend(true, {}, EN['default'], FR);

    exports['default'] = FR;

});