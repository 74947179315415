define('wow-app/controllers/change-wonder', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'mosaic', 'tilezoom', 'error-modal'],

		changeWonder: (function () {
			return this.get('controllers.mosaic').get('pictureIsOnActiveMosaic');
		}).property('controllers.mosaic.pictureIsOnActiveMosaic'),

		changeWall: (function () {
			return !this.get('controllers.mosaic').get('pictureIsOnActiveMosaic');
		}).property('controllers.mosaic.pictureIsOnActiveMosaic'),

		/**
	  * get visible wonder inside mosaic
	  * @returns {*}
	  */
		getWonder: function getWonder() {
			return this.get('controllers.tilezoom').getActiveWonder();
		},

		/**
	  * move to specific wonder id
	  * @param wonderId
	  * @returns {*}
	  */
		gotoWonder: function gotoWonder(wonderId) {
			return this.get('controllers.tilezoom').gotoWonder(wonderId, false);
		},

		saveWonderId: function saveWonderId(wonderId) {
			var applicationController = this.get('controllers.application');
			applicationController.setWonder(false, wonderId);
		},

		do_changeWonder: function do_changeWonder(wonderId, mosaicId) {

			var me = this,
			    photo = this.get('model'),
			    applicationController = this.get('controllers.application');

			var user = this.get('controllers.application').get('user');
			if (!user) {
				return false;
			}

			var m = {
				wonderId: wonderId
			};

			if (mosaicId) {
				m['mosaicId'] = mosaicId;
			}

			applicationController.updatePictureMetadata(photo, m);

			var onSuccess = function onSuccess(p) {
				if (!mosaicId) {
					mosaicId = applicationController.getMosaicId();
				}
				applicationController.setWonder(mosaicId, wonderId);
				applicationController.setPicture(photo);
				me.transitionToRoute('my-wonderlock');
			};

			var onFail = function onFail(p) {
				me.get('controllers.error-modal').raise({
					statusText: 'Sorry',
					statusCode: 404,
					reason: p,
					message: me.t('upload.upload_save_error')
				});
			};

			photo.save().then(onSuccess, onFail);
		},

		actions: {

			changeWonder: function changeWonder(wonderId) {
				this.do_changeWonder(wonderId);
			},

			changeWall: function changeWall(wonderId) {
				var applicationController = this.get('controllers.application');
				var mosaicId = applicationController.getMosaicId();
				this.do_changeWonder(wonderId, mosaicId);
			}

		}

	});

});