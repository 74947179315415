define('wow-app/models/interaction', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    recipient: DS['default'].attr('object'),
    sender: DS['default'].attr('object'),
    actionDate: DS['default'].attr('object'),
    sourcePic: DS['default'].attr('object'),
    targetPic: DS['default'].attr('object')
  });

});