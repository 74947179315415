define('wow-app/views/photo-crop', ['exports', 'ember', 'wow-app/views/base-routing-view'], function (exports, Ember, BaseRoutingView) {

	'use strict';

	exports['default'] = BaseRoutingView['default'].extend({

		classNames: 'view-photo-crop',

		cropperInstance: null,

		image: null,

		createImage: function createImage(c) {
			var me = this,
			    $element = me.$(),
			    controller = me.get('controller');

			var $thumb = $element.find('.crop-photo');

			if (!$thumb || !$thumb.get(0)) {
				var photoImage = $element.find('.photo-image');
				$thumb = me.image = $('<img>', {
					'class': 'crop-photo'
				}).appendTo(photoImage);
			}

			var model = controller.get('model');
			// Todo: change this to models originalSrc, when Tonado
			// has   Header set Access-Control-Allow-Origin "*"
			//var src = this.get('globals').get('srvParams.AssetsPath') + '/picture/original/' +model.get('id');
			var src = model.get('originalSrc');

			// strange... load is not always fired, so check this
			var hasSrc = $thumb.attr('src');
			if (hasSrc) {
				me[c]();
			} else {
				$thumb.on('load', function () {
					me[c]();
				});

				$thumb.on('error', function () {
					me.setError();
				});
			}

			$thumb.attr('src', src);

			return $thumb;
		},

		didInsertElement: function didInsertElement() {
			var me = this,
			    $element = me.$();

			this.createImage('setIsRendered');
		},

		setIsRendered: function setIsRendered() {
			this.set('isRendered', true);
		},

		setError: function setError() {
			var controller = this.get('controller');
			this.setIsRendered();

			controller.get('controllers.error-modal').raise({
				statusText: 'Sorry',
				statusCode: 404,
				route: 'choose-wonder',
				reason: null,
				message: controller.t('upload.image_error')
			});
		},

		onResponse: function onResponse() {

			var me = this,
			    $element = me.$();

			var container = $element.closest('.app-content-contain');
			var buttonGroup = $element.find('.btn-group');
			var photoCropWrapper = $element.find('.photo-image');

			// set container height
			var containerHeight = container.height() - buttonGroup.height();
			photoCropWrapper.css({
				height: containerHeight + 'px'
			});
		},

		/**
	  * Callback Function from base-view after show transition
	  */
		afterShowCSSTransition: function afterShowCSSTransition() {
			this.createImage('respondAndInit');
		},

		respondAndInit: function respondAndInit() {
			this.onResponse();
			this._initCropper();
		},

		/**
	  * Callback Function from base-view after show transition
	  */
		afterToggleShowCSSTransition: function afterToggleShowCSSTransition() {
			//if(typeof console === 'object') { console.log('PhotoCropView.afterToggleCSSTransition',true); }
		},

		_getCropData: (function () {

			var me = this,
			    controller = me.get('controller'),
			    canvasData,
			    cropBoxData;

			var $image = this._getCropperImage();
			cropBoxData = $image.cropper("getData");
			canvasData = $image.cropper("getCanvasData");
			this.get('controller').send('saveCrop', cropBoxData, canvasData);
		}).observes('controller.getCropData'),

		_getCropperImage: function _getCropperImage() {

			var me = this,
			    $element = me.$();

			if (!$element) {
				return false;
			}

			return $element.find('.photo-image > img');
		},

		_initCropper: function _initCropper() {

			var me = this,
			    controller = me.get('controller'),
			    canvasData = null,
			    cropBoxData = null;

			var $image = this._getCropperImage();

			//if(typeof console === 'object') { console.log('typeof $image.cropper',$image,$('.lockimg'),typeof $image.cropper, typeof this.cropperInstance,this.cropperInstance); }

			var cropper = function cropper() {
				me.cropperInstance = $image.cropper({
					autoCropArea: 0.8,
					aspectRatio: 1,
					strict: true,
					responsive: true,
					guides: true,
					highlight: true,
					dragCrop: false,
					movable: true,
					resizable: true,
					zoomable: true,
					mouseWheelZoom: false,
					preview: '.lockimg',
					touchDragZoom: true,
					built: function built() {
						$image.cropper('setCanvasData', canvasData);
						$image.cropper('setCropBoxData', cropBoxData);
					}
				});
			};

			if (this.cropperInstance) {
				this.destroyCropper();
				var lockbody = $('.lockbody');
				$('.lockimg').css({
					width: lockbody.width() || '100%',
					height: lockbody.height() || '100%'
				});
				setTimeout(function () {
					cropper();
				}, 500);
			} else {
				cropper();
			}
		},

		destroyCropper: function destroyCropper() {

			var me = this,
			    $element = me.$();

			if (!$element) {
				return false;
			}

			var $image = this._getCropperImage(),
			    controller = me.get('controller');

			//canvasData = $image.cropper('getCanvasData');
			//cropBoxData = $image.cropper('getCropBoxData');
			$image.cropper('destroy');
			delete this.cropperInstance;
		},

		/**
	  * Called when the element of the view is going to be destroyed.
	  * Override this function to do any teardown that requires an element, like removing event listeners.
	  */
		willDestroyElement: function willDestroyElement() {
			this.destroyCropper();
		},

		actions: {

			saveCropAction: function saveCropAction() {
				var _call_view = this.get('getCropData') ? false : true;
				this.set('getCropData', _call_view);
			},

			saveCrop: function saveCrop() {

				var $image = this._getCropperImage(),
				    controller = this.get('controller'),
				    canvasData,
				    cropBoxData,
				    imageData;

				canvasData = $image.cropper('getCanvasData');
				cropBoxData = $image.cropper('getCropBoxData');
				imageData = $image.cropper('getImageData');
				this.get('parentView').set('hasLazyLoader', true);
				controller.send('saveCroppedPhoto', canvasData, cropBoxData, imageData);
			}
		}

	});

});