define('wow-app/routes/mosaic', ['exports'], function (exports) {

	'use strict';

	//import Ember from 'ember';

	/**
	 * MosaicRoute
	 *
	 * The base route after our application.
	 * Load mosiac and check for user pictures and store them in the application
	 * This route contains the complete structure of the app and loads the navigation
	 * and the mosiac (tilezoom)
	 *
	 */

	exports['default'] = Ember.Route.extend({

		/**
	  * Get all user pictures (user is maybe already logged in)
	  * @param transition
	  */
		beforeModel: function beforeModel(transition) {
			var applicationController = this.controllerFor('application');
			var picture = applicationController.get('picture');
			if (!picture) {
				return this.store.findQuery('picture', { mosaicId: applicationController.getMosaicId(transition) }).then(function (pictures) {

					var p = pictures.get('firstObject');

					//var testPicturePosition = function() {
					//	//finalHeight: 3956,finalWidth: 27993,tileHeight: 43
					//	//applicationController.updatePictureMetadata(p,{pos:[0,0]});  // left - top
					//	//applicationController.updatePictureMetadata(p,{pos:[(27993-43),(3956-43)]}); // right - top
					//	applicationController.updatePictureMetadata(p,{pos:[0,(3956-43)]}); // right - top
					//};
					//
					//testPicturePosition();

					applicationController.set('picture', p);
					applicationController.set('pictures', pictures);
					return pictures;
				});
			}
		},

		/**
	  * load mosaic by id
	  *
	  * @param params
	  * @param transition
	  * @returns {*}
	  */
		model: function model(params, transition) {

			var me = this,
			    controller = me.controllerFor('application'),
			    mosaicId = controller.getMosaicId(transition),
			    mosaic;

			var setMosaic = function setMosaic(mosaics) {
				var mosaic = mosaics.findBy('id', mosaicId);
				if (!mosaic) {
					return null;
				}
				var tilezoomController = me.controllerFor('tilezoom');
				tilezoomController.set('mosaic', mosaic);
				controller.set('mosaicList', mosaics);
				//tilezoomController.set('myphotos', me.store.findQuery('my-photo', { mosaicId: mosaic.get('mosaicId') }));
				tilezoomController.set('tilephotos', []);
				controller.setMosaicId(mosaic.get('id'));
				return mosaic;
			};

			return this.store.findAll('mosaic').then(function (mosaics) {
				controller.set('skipSplashRedirectDone', true);
				var mosaic = setMosaic(mosaics);
				if (!mosaic) {
					transition.abort();
					me.transitionTo('start');
				}
				return mosaic;
			}, function (reason) {

				transition.abort();

				me.transitionTo('start');

				me.controllerFor('error-modal').raise({
					statusText: 'Sorry',
					statusCode: 404,
					reason: reason,
					message: 'The mosaic could not be found'
				});

				return null;
			});

			// one mosiac
			//return me.store.find( 'mosaic', mosaicId).then(function(mosaic) {
			//
			//	// reset that we skipped the splash in order to go back...
			//	controller.set('skipSplashRedirectDone',true);
			//
			//	var tilezoomController = me.controllerFor('tilezoom');
			//	tilezoomController.set('mosaic',mosaic);
			//	//tilezoomController.set('myphotos',   me.store.findQuery('my-photo', { mosaicId: mosaic.get('mosaicId') }));
			//	tilezoomController.set('tilephotos', []);
			//
			//	controller.set('model.mosaic',mosaic.get('id'));
			//
			//	return mosaic;
			//}, function ( reason ) {
			//
			//	transition.abort();
			//
			//	me.transitionTo('start');
			//
			//	me.controllerFor('error-modal').raise({
			//		statusText: 'Sorry',
			//		statusCode: 404,
			//		reason: reason,
			//		message:	'The mosaic <strong>"' +params.mosaic_Id+ '"</strong> could not be found'
			//	});
			//
			//	return null;
			//});
		},

		/**
	  * After model is loaded, transitionTo upload or my-photo (if
	  * user is loggedIn and hasUserImage?) and no other route is questioned
	  *
	  * If we are requesting a photoId
	  * recheck if the user is logged in and if it is its own picture
	  *
	  * @param mosaics
	  * @param transition
	  */
		afterModel: function afterModel(mosaics, transition) {

			var controller = this.controllerFor('application');

			if (transition.targetName && transition.targetName.indexOf('mosaic') >= 0) {

				if (controller.get('user') && controller.get('user').get('loggedIn')) {
					this.transitionTo('my-wonderlock');
				} else {
					this.transitionTo('tilezoommap'); // 2016-02-16 - changed to tilezoommap from choose-wonder
				}
			} else {

					var photoId = controller.getPhotoId(transition);
					if (photoId) {
						var isLoggedIn = controller.get('user') && controller.get('user').get('loggedIn');
						if (!isLoggedIn) {
							this.transitionTo('joy-screen', photoId);
						} else {
							var isUserPicture = controller.isUserPictureById(photoId);
							if (!isUserPicture) {
								this.transitionTo('joy-screen', photoId);
							}
						}
					}
				}

			// open a modal window if requested in a session storage
			var showModal = UTIL.storage.getSession('showModal');
			UTIL.storage.removeSession('showModal');
			if (showModal && showModal.length > 0) {
				controller.send('showModal', showModal);
			}
		},

		/**
	  * load application model into controller
	  *
	  * @param controller
	  * @param model
	  */
		setupController: function setupController(controller, model) {
			controller.set('model', model);
			controller.set('applicationstate', this.store.find('applicationstate', 'globalstate'));
		},

		/**
	  * Render all sub-templates
	  *
	  * @param controller
	  * @param model
	  */
		renderTemplate: function renderTemplate(controller, model) {

			this.render('mosaic', {});

			this.render('navbar', {
				into: 'mosaic',
				outlet: 'navbar',
				model: model
			});

			this.render('tilezoom', {
				into: 'mosaic',
				outlet: 'tilezoom'
			});

			this.render('mosaic-list', {
				into: 'mosaic',
				outlet: 'mosaic-list'
			});
		}

	});

});