define('wow-app/templates/accept-terms/de', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Durch die Benutzung der wonderswall.com mobilen Website, Applikation, Website (hier manchmal auch zusammenfassend als wonderswall.com oder als die Seite bezeichnet) und oder deren Funktionen, erkennst du die folgenden allgemeinen Geschäftsbedingungen in Bezug auf die Seite, die Leistungen und alle damit verbundenen Belange an.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Copyright");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Copyright 2014-2021, Global Platform Limited, (GPL), Dubai, United Arab Emirates.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Unter Lizenz der NewOpenWorld Corporation.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Kontakt: contact ( at ) wonderswall dot com");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Alle Rechte vorbehalten. ​");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Vertreter des geistigen Eigentums für Mitteilungen und sonstigen Benachrichtigungen.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("WESENTLICHE BEDINGUNGEN IN BEZUG AUF DIE TEILNAHME BEI WONDERSWALL.COM");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Hochladen von Fotos");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Bereiche der Website ermöglichen es dir uns deine Fotos zu senden. Wir haben das Ziel das Material kurz auf Qualität und Angemessenheit zu überprüfen, um sicherzustellen, dass dies eine Seite bleibt, die von allen besucht werden kann, aber keine Haftung übernimmt, sollte ein Problem mit Inhalten auftauchen.\n        Du garantierst für alle Materialien, die du bereitstellst, dass sie kein Urheberrecht oder andere Rechte dritter Personen oder Organisationen verletzen.\n        Bitte sende uns kein Material zu, dessen Eigentumsrechte bei einer anderen Person liegen und für welches du keine erforderliche Genehmigung besitzt.\n        GPL lehnt jede Haftung für die Verwendung solcher Inhalte, die Eigentum von Dritten sind, ausdrücklich ab; GPL ist dir oder einem Dritten gegenüber nicht verantwortlich oder haftbar für jeden Verlust (unabhängig davon ob diese Verluste vorausgesehen wurden, oder vorhersehbar, bekannt oder anders bedingt waren), einschließlich, aber nicht beschränkt auf:\n        Datenverlust; Einnahmeverlust; Geschäftsverlust; entgangene Chancen; jegliche indirekte, mittelbare, spezielle oder exemplarische Schäden, die durch die Nutzung der Website oder als Folge aus der vorsätzlichen oder unbewussten Bereitstellung von Inhalten dritter für GPL entstehen.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Eigentum hochgeladener Inhalte");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Wenn du ein Foto auf die Website hochlädst, erklärst du dich einverstanden, durch das Einreichen deines Beitrags, GPL auf weltweiter, unbefristeter, nicht-exklusiver, unentgeltlicher und sublizenzierbarer Basis sämtliche Nutzungs-, Reproduktions-, Modifikations-, Adaptions-, Veröffentlichungs-, Übersetzungs-, Weiterverarbeitungs-, Distributions- und Aufführungsrechte und -lizenzen an diesem Beitrag sowie alle gegenwärtigen und zukünftigen Rechte um diese Inhalte in andere Werke in allen bekannten und zukünftig entwickelten Medien unbefristet zu integrieren, zu garantieren. Falls du GPL die oben genannten Rechte nicht garantieren willst, lade deine Fotos bitte nicht hoch.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Fotos auf wonderswall.com");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL behält sich das Recht vor alle Fotos zu akzeptieren oder abzulehnen. Die Position jedes Fotos kann zu jeder Zeit geändert werden, um ein optimales generiertes Gesamtbild der Wunder zu gewährleisten, oder aus einem beliebigen von GPL festgelegten Grund. Um ein optimales Gesamtbild der Wunder zu generieren, kann es vorkommen, dass GPL nach eigenem Ermessen hochgeladene Fotos mit anderen Fotos ergänzt, die GPL erhalten hat.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Ethisches und moralisches Verhalten");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Du darfst die Website nicht für einen der folgenden Zwecke verwenden: (i) Verbreitung jeglicher ungesetzliche, belästigende, verleumderische, unehrliche, beleidigende, anstößige, drohende oder obszöne Nachrichten oder Materialien gleich welcher Art oder anderweitige Rechtsverstöße.; (ii) Übermittlung von Material, das Verhalten ermutigt, das eine Straftat darstellt, in einer zivilrechtlicher Haftung resultiert oder auf andere Weise irgendein anwendbares Gesetz, Vorschriften oder Verhaltenskodizes deiner oder irgendeiner anderen Rechtssprechung verletzt; (iii) Die  Beeinträchtigung anderer Personen in der Nutzung der Website oder der Freude daran; oder die Herstellung, Übermittlung oder Speicherung elektronischer Kopien urheberrechtlich geschützten Materials ohne die Erlaubnis des Eigentümers. Du bist verantwortlich für unsere Verluste und Kosten, die sich aus deinem Verstoß gegen diese Bedingungen ergeben. Wir behalten uns das Recht vor, alle Materialien nach unseren Wünschen und nach eigenem Ermessen zu löschen.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("AWEICHENDE ALLGEMEINE GESCHÄFTSBEDINGUNGEN");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Schutz von Urheberrechten und anderen Rechten und Übereinkünften geistigen Eigentums");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL respektiert das geistige Eigentum anderer und wir bitten unsere Nutzer das selbe zu tun. GPL kann, unter entsprechenden Umständen und nach eigenem Ermessen, Benutzerkonten deaktivieren und/oder löschen, die die geistigen Eigentumsrechte von GPL und oder anderen verletzten. Falls du glaubst, dass dein Werk in einer Weise kopiert worden ist, die eine Urheberrechts-/Copyright-Verletzung darstellt oder deine Urheberrechte auf andere Weise verletzt, kontaktiere uns bitte.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("New7Wonders Warenzeichen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("New7Wonders und andere New7Wonders Logos und Produkt- und Service-Namen sind eingetragene Warenzeichen der New Open World Corporation, welche hier durch GPL vertreten wird. Ohne die vorherige Genehmigung von GPL erklärst du dich einverstanden die New7Wonders Warenzeichen in keiner Weise darzustellen oder anderweitig zu verwenden. Durch deine Nutzung der und durch die Teilnahme an der Website bestätigst du dein Einverständnis, dass du New7Wonders Inhalte nicht kopierst, vervielfältigst, neu veröffentlichst, herunterlädst, postest, überträgst, übermittelst, der Öffentlichkeit zugänglich machst oder anderweitig nutzt, in irgendeiner Weise in irgendeinem Medium oder einer Website außer für deinen persönlichen, nicht kommerziellen Gebrauch. Es ist verboten die Website und andere New7Wonders Inhalte zu adaptieren zu ändern und abgeleitete Werke auf deren Basis zu schaffen, außer für deinen privaten, ausschließlich nicht gewerblichen Gebrauch. Jede andere Nutzung der Website oder von New7Wonders Inhalten bedarf der vorherigen schriftlichen Erlaubnis von GPL.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Datenschutz");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL nimmt den Schutz deiner Daten sehr ernst. Da wir bestimmte Angaben über die Nutzer der Website und der Dienste von WHO/Europa sammeln, möchten wir sicherstellen, dass Sie sich über die Bedingungen für die Erhebung und Verwendung dieser Informationen völlig im Klaren sind. Wenn du GPÖ autorisiert hast, dich für Informationsupdates und andere Mitteilungen zu kontaktieren, kann es sein, dass deine Kontaktinformationen auf den GPL Datenbanken gespeichert werden. GPL kann bestimmte Web-Standardmethoden, wie zum Beispiel elektronische Bilder nutzen, um allgemeine Benutzerverkehrsmuster zu verfolgen. GPL kann außerdem automatisch Informationen von unseren Servern und deinem Browser erhalten und aufzeichnen, was die deine IP-Adresse, die Zeit und Informationen über die angefragte Seite beinhaltet. Wie es im Web Standard ist, kann GPL auch aus anderen Quellen Informationen über dich erhalten und damit die Informationen ergänzen, die wir von dir erhalten haben. GPL benutzt die gesammelten Informationen für vier allgemeine Zwecke: 1. Zur Anpassung der Werbung und der Inhalte, die du siehst. 2. Zur Verbesserung der Nutzererfahrung in unserem Seiten-Netzwerk. Wir benutzen Tracking-Informationen um festzustellen, wie gut jede Seite funktioniert, basierend auf aggregierten demographischen Nutzerdaten und Nutzerverkehrsmustern zu diesen Seiten. Das hilft uns dabei unseren Service für dich zu verbessern.; 3. Um in der Zukunft deine Anfragen für bestimmte Produkte und Services erfüllen zu können, wie das Versenden von elektronischen Newslettern und die Möglichkeit Nutzer an Umfragen, Wettbewerben, Foren und Auktionen teilnehmen zu lassen; 4. Um dir Informationen über interessante Themen zuzuschicken, für deren Empfang du dein Einverständnis erklärt hast.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Nationales Recht");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Das weltweite Wesen des Internets anerkennend, stimmst du zu alle relevanten nationalen und lokalen Gesetze, Vorschriften und Übereinkünfte in Bezug auf den Internet-Gebrauch und akzeptable Inhalte einzuhalten. Insbesondere willigst du ein, allen geltenden Gesetzen über die Übertragung technischer Daten aus dem Land, in dem du wohnst, einzuhalten.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Haftpflicht");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Du verpflichtest dich GPL und ihren Tochtergesellschaften, Niederlassungen, Gesellschafter, Manager, Vertreter, Werbepartner und andere Partner und Angestellte von allen Forderungen oder Ansprüchen schad- und klaglos zu halten, eingeschlossen angemessene Anwaltskosten, die von Dritten aufgrund von oder in Zusammenhang mit Inhalten, die Sie eingeben, veröffentlichen, übermitteln oder durch unseren Service zugänglich machen, deiner Nutzung unseres Service, deiner Verbindung zu unserem Service, deinem Verstoß gegen die Allgemeinen Geschäftsbedingungen oder die Verletzung irgendeines Rechts eines anderen.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Kein Wiederverkauf");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Es ist verboten irgendeinen Teil und die Nutzung des und den Zugang zu dem Service für gewerbliche Zwecke zu vervielfältigen, zu duplizieren, zu kopieren, zu verkaufen, wiederzuverkaufen oder auszunutzen.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Änderungen an der Website");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL behält sich das Recht vor jeder Zeit und von Zeit zu Zeit die Website (oder jeden Teil davon) mit oder ohne Benachrichtigung, temporär oder permanent zu verändern oder einzustellen. Du bist einverstanden, dass GPL dir oder Dritten gegenüber nicht haftbar für irgendeine Änderung, Suspension oder Einstellung der Website ist.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Links");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Diese Website oder Dritte stellen möglicherweise Links zu anderen Seiten im World Wide Web oder Webressourcen zur Verfügung. Da GPL keine Kontrolle über diese Seiten und Ressourcen besitzt, erkennst du an und bist damit Einverstanden, dass GPL nicht verantwortlich für die Verfügbarkeit solcher externen Seiten und Ressourcen ist und in keiner Weise für die Inhalte, Werbung, Produkte oder andere Materialien auf oder von solchen Seiten oder Ressourcen steht und verantwortlich oder haftbar ist. Weiterhin erkennst du an und bist damit einverstanden, dass GPL nicht für Schänden oder Verluste verantwortlich oder haftbar ist, weder direkt noch indirekt, die verursacht oder angeblich verursacht wurden durch oder im Zusammenhang mit der Nutzung der oder im Vertrauen auf solche Inhalte, Waren und Leistungen, die auf oder durch solche Seiten oder Ressourcen verfügbar sind.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Dateneigentum");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Alle Informationen, wie die Teilnahme bei der WondersWall, die durch GPL angezeigt oder veröffentlicht werden können, sind urheberrechtlich geschützt. Spezifische verpflichtende Regeln schützen die Nutzung dieser Informationen. Nachrichtenmedien: Anerkannte Nachrichtenmedien dürfen diese Informationen nur im Nachrichtenkontext verwenden, vorausgesetzt der folgende Text wird durchgehend sichtbar gezeigt: Data © www.wonderswall.com. Anderweitige  Verwendung: Jede anderweitige Verwendung durch Organisationen, Unternehmen, Websites bedürfen der Lizenzierung und Genehmigung von GPL. Kontaktiere uns für weitere Informationen.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Haftungsausschluss des technischen Dienstes");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Der Websiteinhalt, eingeschlossen Informationen, Namen, Bilder, Fotos und Logos mit Bezug zu New7Wonders und die WondersWall oder deren Dienste werden im \"IST-Zustand\" und \"je nach Verfügbarkeit\" bereitgestellt, ohne Gewährleistungen oder Garantien irgendeiner Art (weder benannt oder stillschweigend), soweit es der gesetzliche Rahmen erlaubt, eingeschlossen Nicht-Verletzung von Rechten, Vereinbarkeit, Sicherheit und Genauigkeit. GPL gibt keine Garantie darauf, dass die im Websiteinhalt enthaltenen Funktionen ohne Verzögerung oder Unterbrechung arbeiten oder, dass Foto- oder andere Dateiübertragungen genau und vollständig sind, dass Fehler behoben werden, oder dass die Website oder der Server, der sie zur Verfügung stellt frei von Viren, Fehlern oder irgendwelchen anderen schadhaften Internet-basierten Materialien ist. Unter keinen Umständen ist oder wird GPL verantwortlich oder haftbar gegenüber dir oder Dritten für jegliche Verluste (unabhängig davon, ob diese Verluste vorausgesehen wurden, oder vorhersehbar, bekannt oder anders bedingt waren), einschließlich, aber nicht limitiert auf: Datenverlust; Verlust von Einkünften; Geschäftsverlust; Verlust von Möglichkeiten; jegliche indirekte, exemplarische, spezielle oder zufällige Schäden jeder Art, die sich aus der Nutzung der Website ergeben.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Gewährleistungserklärung");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Du erklärst dich ausdrücklich mit Folgendem einverstanden: Die Nutzung der Website geschieht auf dein eigenes Risiko. Die Bereitstellung dieser Website erfolgt auf Basis des \"IST-Zustands\" und \"nach Verfügbarkeit\". GPL schließt ausdrücklich jegliche Garantie aus, ob ausdrücklich oder stillschweigend, einschließend, aber nicht limitiert auf die implizierte Gewährleistung der Marktfähigkeit, der Eignung für bestimmte Zwecke und Nicht-Verletzung von Rechten. GPL gibt keine Garantie darauf, dass (i) die Website deinen Anforderungen entspricht, (ii) die Website dauerhaft und schnell erreichbar, sicher oder fehlerfrei ist, (iii) die Ergebnisse, die durch die Nutzung der Website erhalten werden können, korrekt oder verlässlich sind, (iv) die Qualität jeglicher Produkte, Leistungen, Informationen oder anderen Materialien, die von dir auf/über die Website erhalten oder gekauft wurden, deinen Erwartungen entsprechen, (v) Fehler in der Software behoben werden. Alle heruntergeladenen, oder auf andere Weise durch die Nutzung der Website erhaltenen Materialien, beziehst du, soweit rechtlich zulässig, nach eigenem Ermessen und auf eigenes Risiko und du allein bist für jegliche Schäden an deinem Computersystem oder Datenverlust verantwortlich, der sich aus dem Herunterladen von solchen Materialien ergibt.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Haftungsbeschränkung");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Du bist ausdrücklich damit einverstanden, dass GPL nicht haftbar ist für jegliche direkte, indirekte, zufällige, spezielle, resultierende oder exemplarische Schäden, einschließlich aber nicht beschränkt auf, Schäden aufgrund von entgangenem Gewinnen, Firmenwert, entgangener Nutzung, entgangenen Daten oder sonstigen immateriellen Verlusten (auch wenn GPL auf die Möglichkeit solcher Schäden hingewiesen wurde), die sich ergeben aus: (i) der Nutzung oder Unmöglichkeit der Nutzung der Website; (ii) den Beschaffungskosten von Ersatzgütern bzw. -leistungen, die aus jeglichen Gütern, Daten, Informationen oder Leistungen resultieren, die über die Website erworben oder erhalten wurden oder durch erhaltene Mitteilungen oder Transaktionen, die über oder durch die Website durchgeführt werden; (iii)den nicht autorisierten Zugang zu deinen oder die Veränderung deiner Übermittlungen oder Daten; (iv) Ausserungen oder Verhalten von Dritten auf der Website; oder (v) jegliche Angelegenheiten mit Bezug zur Website.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Nutzungsalter");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Wir sind der Überzeugung, dass wonderswall.com allen Menschen und allen Altersgruppen offen steht. Trotzdem sind wir uns bewusst, dass es in manchen Ländern und Kulturen unterschiedliche rechtliche oder andere Rahmenbedingungen im Bezug auf das Nutzungsalter geben kann.  Wir nehmen keine bewusste Abfrage und Sammlung von personenbezogener Daten von Personen unter dem zulässigen (in deren Heimatland) Alter vor, wo dies gesetzlich Verboten ist oder erlauben es dieser Person wissentlich, sich zu registrieren.  Falls das Gesetz deines Landes, dir die Registrierung verbietet, ist es dir Verboten zu versuchen dich auf der Website zu registrieren oder uns irgendwelche Informationen über dich an uns zu schicken, einschließlich aber nicht limitiert auf, deinen Namen, Adresse, Telefonnummer oder E-Mail Adresse. Falls das Gesetz deines Landes es von dir erfordert eine Erlaubnis einzuholen, hole dir bitte die Erlaubnis deiner Eltern oder deines Vormunds bevor du personenbezogene Informationen auf der Website zur Verfügung stellst. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Änderungen der Nutzungsbedingungen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL behält sich das Recht vor diese Allgemeinen Geschäftsbedingungen jederzeit durch die Online-Veröffentlichung von Änderungen, zu ändern. Bitte überprüfe diese Bedingungen regelmäßig, um sicher zu stellen, dass du dir allen Änderungen bewusst bist, die GPL vorgenommen hat. Deine fortgeführte Nutzung der Website, nachdem Änderungen veröffentlicht wurden, bedeutet die Bestätigung, dass du einverstanden bist, dich an diese aktualisierten und/oder geänderten Bedingungen zu halten.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Geltendes Recht");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Diese Geschäftsbedingungen unterliegen dem Gesetz des Kantons Zürich/Schweiz und sind nach diesem auszulegen.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});