/* jshint ignore:start */

define('wow-app/config/environment', ['ember'], function(Ember) {
  var prefix = 'wow-app';
/* jshint ignore:start */

try {
  var metaName = prefix + '/config/environment';
  var rawConfig = Ember['default'].$('meta[name="' + metaName + '"]').attr('content');
  var config = JSON.parse(unescape(rawConfig));

  return { 'default': config };
}
catch(err) {
  throw new Error('Could not read config from meta tag with name "' + metaName + '".');
}

/* jshint ignore:end */

});

if (runningTests) {
  require("wow-app/tests/test-helper");
} else {
  require("wow-app/app")["default"].create({"fingerprintPrepent":null,"defaultLocale":"en","v":1.48,"localStorageCacheVersion":1.5,"LOG_RESOLVER":false,"LOG_ACTIVE_GENERATION":false,"LOG_TRANSITIONS":false,"LOG_TRANSITIONS_INTERNAL":false,"LOG_VIEW_LOOKUPS":false,"name":"wow-app","version":"0.0.0.7a4d9303"});
}

/* jshint ignore:end */
