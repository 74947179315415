define('wow-app/controllers/upload', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'tilezoom', 'error-modal', 'social-photo-list-modal'],

		tilezoom: Ember['default'].computed.alias("controllers.tilezoom"),
		photoTotal: 1,

		facebookLoginStatus: 'unknown',
		facebookProzessing: false,
		instagramProzessing: false,

		limitReached: (function () {

			var limit = this.get('photoTotal') - this.get('photoCount');

			if (limit <= 0) {

				return true;
			} else {

				return false;
			}
		}).property('tilezoom.myphotos.length'),

		photoLimit: (function () {

			var limit = this.get('photoTotal') - this.get('photoCount');

			if (limit <= 0) {

				return 0;
			} else {

				return limit;
			}
		}).property('photoCount'),

		photoCount: (function () {
			//return this.get('tilezoom').get('myphotos.length');
		}).property('tilezoom.myphotos.length'),

		init: function init() {

			var me = this;

			UTIL.socialConnect.facebook.getLoginStatus(function (response) {
				if (response && response.status) {
					me.set('facebookLoginStatus', response.status);
				}
			});
		},

		raiseError: function raiseError(r, m) {
			this.get('controllers.error-modal').raise({
				statusText: 'Sorry',
				statusCode: 404,
				reason: r,
				message: m
			});
		},

		getErrorMsg: function getErrorMsg(k) {

			var errorMessages = {
				permissionError: 'We coudn\'t get permissions to access your photos.', // todo translate
				loginError: 'Login to Facebook failed.', // todo translate
				noPhotosError: 'No photos found' // todo translate
			};

			return errorMessages[k];
		},

		getInstgramData: function getInstgramData() {

			var me = this;
			var socialListController = this.get('controllers.social-photo-list-modal');
			var url = socialListController.getUrl('instagram');

			$.ajax({
				method: 'GET',
				url: url,
				dataType: 'json',
				success: function success(response) {

					if (response && response.meta && response.meta.code !== 200) {
						location.href = '/social/oauth/instagram';
					} else if (response && response.data && response.data.length > 0) {
						socialListController.set('activeUrl', url);
						me.loadSocialPhotoListModal(response, 'instagram');
					} else {
						me.raiseError(null, me.getErrorMsg('noPhotosError'));
					}
					me.set('instagramProzessing', false);
				},
				error: function error(data) {
					location.href = '/social/oauth/instagram';
					me.set('instagramProzessing', false);
				}

			});
		},

		loadSocialPhotoListModal: function loadSocialPhotoListModal(data, type) {
			var modal = this.get('controllers.social-photo-list-modal');
			modal.show({
				data: data,
				type: type
			});
		},

		actions: {

			getInstagramPictures: function getInstagramPictures() {

				this.set('instagramProzessing', true);
				var data = this.getInstgramData();
			},

			getFacebookPictures: function getFacebookPictures() {

				var me = this;

				var socialListController = this.get('controllers.social-photo-list-modal');
				var url = socialListController.getUrl('facebook');
				var facebookLoginStatus = this.get('facebookLoginStatus');
				me.set('facebookProzessing', true);

				//UTIL.socialConnect.facebook.getLoginStatus(function(response) {
				//	if(response && response.status) {
				//		me.set('facebookLoginStatus',response.status);
				//	}
				//
				//});

				var getPermissions = function getPermissions() {
					UTIL.socialConnect.facebook.getPermissions(true, function (response, permissions) {
						if (typeof console === 'object') {
							console.log('getPermissions', response, permissions);
						}
						if ($.inArray('user_photos', permissions) === -1) {

							// we would need a Facebook Business Manager-Konto to ask for user_photos permissions

							// if(response && response.data && response.data.length > 0) {
							//   var askForPermission = true;
							//   for(var i=0;i<response.data.length;i++) {
							//     var p = response.data[i];
							//     if(p.permission === 'user_photos') {
							//       askForPermission = false;
							//     }
							//   }
							// }
							//
							// if(askForPermission) {
							//   UTIL.socialConnect.facebook.requestPermissions('user_photos',function(response) {
							//     if(typeof console === 'object') { console.log('requestPermissions',response); }
							//   });
							// } else {
							//   me.raiseError(null,me.getErrorMsg('permissionError'));
							//   me.set('facebookProzessing',false);
							// }

							me.raiseError(null, me.getErrorMsg('permissionError'));
							me.set('facebookProzessing', false);
						} else {

							UTIL.socialConnect.facebook.getUserData(false, url, function (response) {
								if (response && response.data && response.data.length > 0) {
									if (typeof console === 'object') {
										console.log('Facebook DATA', response);
									}
									me.loadSocialPhotoListModal(response, 'facebook');
								} else {
									me.raiseError(null, me.getErrorMsg('noPhotosError'));
								}
								me.set('facebookProzessing', false);
							});
						}
					});
				};

				if (facebookLoginStatus === 'not_authorized' || facebookLoginStatus === 'unknown') {
					UTIL.socialConnect.facebook.requestPermissions('user_photos', function (response) {
						if (response && response.status && response.status === 'unknown') {
							me.raiseError(null, me.getErrorMsg('loginError'));
							me.set('facebookProzessing', false);
						} else if (response && response.status && response.status === 'connected') {
							getPermissions();
						} else {
							me.raiseError(null, me.getErrorMsg('permissionError'));
							me.set('facebookProzessing', false);
						}
					});
				} else if (facebookLoginStatus === 'connected') {
					getPermissions();
				}
			},

			goToMyPhotos: function goToMyPhotos() {

				this.transitionToRoute('myphotos');
			},

			error: function error(xhr) {

				var message = xhr.message && xhr.message.length > 0 ? xhr.message : 'Could not detect faces';

				this.get('controllers.ErrorModal').show({

					statusText: 'Sorry',
					statusCode: xhr.statusText,
					message: message
				});
			},

			photoUploaded: function photoUploaded(data) {

				var me = this,
				    applicationController = this.get('controllers.application');

				if (typeof data == 'object' && data.picture) {

					var metadata = data.picture.metadata;
					var photo = me.store.createRecord('picture', data.picture);
					var user = applicationController.get('user');
					if (!user) {
						Cookies.set('pic_anon_owner_token', metadata.anonOwnerToken, { expires: 7, path: '/' });
					}
					UTIL.tracker.track('event', { category: 'Photo', action: 'upload', label: 'Photo Upload', value: 1 });
					this.transitionToRoute('photo-crop', data.picture.id);
				} else {
					if (typeof console === 'object') {
						console.log('Error.photoUploaded with ', data);
					}
					me.get('controllers.error-modal').raise({
						statusText: 'Sorry',
						statusCode: 404,
						reason: data,
						message: me.t('upload.upload_save_error')
					});
				}
			},

			/**
	   * Save a photo from a social service (or url?)
	   *
	   * @param type
	   * @param id
	   */
			savePhoto: function savePhoto(type, selectedPhoto) {
				//if(typeof console === 'object') { console.log('savePhoto',type,selectedPhoto); }

				var me = this,
				    data = {
					source: type,
					foreign_pic_id: selectedPhoto.id
				};

				var mosaicView = UTIL._lookupView('mosaic');
				mosaicView.set('hasLazyLoader', true);

				var applicationController = this.get('controllers.application');
				var mosaicId = applicationController.getMosaicId();
				var wonder = applicationController.getWonder(mosaicId);

				data['mosaicId'] = mosaicId;
				data['wonderId'] = wonder;

				$.ajax({
					data: data,
					method: 'POST',
					url: '/api/v1/picture/upload',
					dataType: 'json',
					success: function success(data) {
						me.send('photoUploaded', data);
					},
					error: function error(data) {
						if (typeof console === 'object') {
							console.log('Error.photoUploaded with ', data);
						}
						mosaicView.set('hasLazyLoader', false);
						me.get('controllers.error-modal').raise({
							statusText: 'Sorry',
							statusCode: 404,
							reason: data,
							message: me.t('upload.upload_save_error')
						});
					}

				});
			}
		}
	});

});