define('wow-app/templates/privacy-policy/de', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Letzte Aktualisierung: November 2015");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("NewOpenWorld Corporation (\"uns\", \"wir\", oder \"unser\") betreibt ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","//www.wonderswall.com");
        var el4 = dom.createTextNode("www.wonderswall.com");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (die \"Seite\").");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Diese Seite informiert dich über unsere Richtlinien bezüglich der Sammlung, Nutzung und Weitergabe von persönlichen Daten, die wir von den Besuchern unserer Seite erhalten. Wir benutzen deine persönlichen Daten ausschließlich zur Betreibung und zur Verbesserung der Seite. Durch die Nutzung der Seite stimmst du der Sammlung und Nutzung von Daten in Übereinstimmung mit diesen Richtlinien zu.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Sammlung und Nutzung von Daten");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Wenn du unsere Seite besuchst und benutzt, kann es vorkommen, dass wir dich um bestimmte persönliche Daten bitten, die dazu benutzt werden können dich zu kontaktieren oder zu identifizieren. Persönliche Daten können deinen Namen beinhalten, sind aber nicht auf deinen Namen limitiert (\"Pesönliche Daten\").");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Logdaten");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Wie viele Seitenbetreiber sammeln wir Informationen, die dein Browser immer dann sendet, wenn du unsere Seite besuchst (\"Logdaten\"). Diese Logdaten können Informationen wie die Internet Protocol (\"IP\") Adresse deines Computers, Browsertyp, Browserversion, die von dir besuchten Unterseiten unserer Seite, Uhrzeit und Datum deines Besuchs, Aufenhaltszeit auf diesen Seiten und andere statistische Daten beinhalten. Zusätzlich kann es vorkommen, dass wir Leistungen von Drittanbietern verwenden, um Logdaten zu sammeln, zu kontrollieren und zu analysieren.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Mitteilungen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Es kann vorkommen, dass wir deine persönlichen Daten verwenden, um dich mit Newslettern, Marketing- oder Promotionsmaterialien und anderen Informationen von Interesse zu kontaktieren. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Cookies");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Cookies sind Dateien mit einer kleinen Menge an Daten, die einen anonymen Identifikator enthalten können. Cookies werden von einer Website an deinen Browser gesendet und auf der Festplatte deines Computers oder Telefons gespeichert. Wie viele Seiten verwenden wir \"Cookies\", um Daten zu sammeln. Falls du keine Cookies akzeptierst, kannst du ggf. manche Teile unserer Seite nicht benutzen.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Sicherheit");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Die Sicherheit deiner persönlichen Daten ist sehr wichtig für uns. Beachte aber, dass keine Internetübertragungsmethode und keine elektronische Speichermethode zu 100% sicher ist. Obwohl wir geschäftlich angemessene Maßnahmen zum Schutz deiner persönlichen Daten anwenden, können wir keine absolute Sicherheit garantieren.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Änderungen dieser Datenschutzrichtlinien");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Diese Datenschutzrichtlinien sind wirksam mit der letzten oben genannten Aktualisierung und bleiben wirksam, mit Ausnahme aller zukünftiger Änderungen der Richtlinien, die sofort wirksam werden, sobald sie auf dieser Seite veröffentlicht werden. Wir behalten uns das Recht vor unsere Datenschutzrichtlinien jeder Zeit zu aktualisieren oder zu ändern, deshalb solltest du diese Datenschutzrichtlinien in regelmäßigen Abständen prüfen. Durch die fortführende Nutzung unserer Seite nach der Veröffentlichung einer Modifikation an den Datenschutzrichtlinien auf dieser Seite,  erklärst du dein Einverständnis mit den Modifikationen und verpflichtest dich, dich an die modifizierten Datenschutzrichtlinien zu halten. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Kontaktiere uns");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Falls du Fragen zu diesen Datenschutzrichtlinien hast, nimm bitte ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","mailto:support@wonderswall.com");
        var el4 = dom.createTextNode("Kontakt");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" zu uns auf.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});