define('wow-app/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'wow-app/config/environment', 'wow-app/helpers/assets'], function (exports, Ember, Resolver, loadInitializers, config, assetsHelper) {

	'use strict';

	Ember['default'].Handlebars.registerBoundHelper('assets', assetsHelper['default']);
	Ember['default'].deprecate = function () {};
	Ember['default'].warn = function () {};

	var App;

	Ember['default'].MODEL_FACTORY_INJECTIONS = true;

	App = Ember['default'].Application.extend({
		modulePrefix: config['default'].modulePrefix,
		Resolver: Resolver['default'],
		srvParams: srvParams

	});

	loadInitializers['default'](App, config['default'].modulePrefix);

	fastdom.onError = function (error) {
		if (typeof console === 'object') {
			console.log('FASTDOM error!', error);
		}
	};

	exports['default'] = App;

});