define('wow-app/controllers/social-photo-list-modal', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'upload', 'error-modal'],

		closeModal: false,
		type: null,
		selectedPhoto: null,
		hasNext: false,
		hasPrevious: false,
		activeUrl: null,
		nextUrl: null,
		previousUrl: null,
		pagingObj: [],
		pagingIdx: 0,

		show: function show(p) {
			this.set('closeModal', false);
			this.set('type', p.type);
			this.set('selectedPhoto', null);
			var applicationController = this.get('controllers.application');
			applicationController.send('showModal', 'social-photo-list-modal', p.data);
		},

		getUrl: function getUrl(type) {

			type = type || this.get('type');
			var url;

			switch (type) {
				case 'facebook':
					url = '/photos?fields=id,picture&limit=25&type=uploaded';
					break;
				case 'instagram':
					url = '/social/pics/instagram';
					break;
			}

			return url;
		},

		normalizeData: function normalizeData() {

			var model = this.get('model');
			var type = this.get('type');
			var data = ['id', 'picture']; // data array
			var paging = ['previous', 'next']; // pagination

			switch (type) {
				case 'instagram':
					var nData = [];
					paging = {};

					var midx = 0;
					for (var i = 0; i < model.data.length; i++) {
						var m = model.data[i];
						if (m.type !== 'image') {
							continue;
						}
						nData[midx] = {
							id: m.id,
							picture: m.images.low_resolution.url
						};
						midx++;
					}

					if (model.pagination && model.pagination.next_url) {
						paging['next'] = model.pagination.next_url;
					}
					if (model.pagination && model.pagination.previous_url) {
						paging['previous'] = model.pagination.previous_url;
					}

					return {
						data: nData,
						paging: paging
					};
			}

			return model;
		},

		actions: {

			useImage: function useImage() {

				var type = this.get('type');
				var selectedPhoto = this.get('selectedPhoto');

				if (!selectedPhoto) {
					// deprecated -- we shouldn't get here because
					// upload button is inactive on !selectedPhoto
					this.get('controllers.error-modal').raise({
						statusText: 'Hint',
						statusCode: 404,
						reason: null,
						message: 'Please select a photo'
					});
				} else {

					// just for the "Show" - add picture to dropezone to show it
					var dz = Dropzone.forElement('form#photo-upload');

					if (dz) {
						// Create the mock file:
						var mockFile = { name: "Filename", size: 12345 };

						// Call the default addedfile event handler
						dz.emit("addedfile", mockFile);
						// And optionally show the thumbnail of the file:
						dz.emit("thumbnail", mockFile, selectedPhoto.picture);
						//dz.options.maxFiles = dz.options.maxFiles - 1;
						// Make sure that there is no progress bar, etc...
						dz.emit("complete", mockFile);
					}

					var uploadController = this.get('controllers.upload');
					uploadController.send('savePhoto', type, selectedPhoto);
				}
			}

		}

	});

});