define('wow-app/routes/privacy-policy', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    model: function model() {
      UTIL.storage.storeSession('showModal', 'privacy-policy-modal');
      this.transitionTo('start');
    }

  });

});