define('wow-app/views/base-view', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].View.extend({

		navigationHeight: 60,
		buttonHeight: 60,
		defaultCSSTransitionDuration: 300,
		defaultCSSTransitionEasing: 'cubic-bezier(0,1,.5,1)', //'cubic-bezier(0,0.9,0.3,1)',

		getNavigationHeight: function getNavigationHeight(settings, c) {
			return this.navigationHeight;
		},

		getButtonHeight: function getButtonHeight() {
			return this.buttonHeight;
		},

		/**
	  * Activate a navigation item
	  *
	  * @param c string|array
	  */
		activeNavigation: function activeNavigation(c) {

			var $element = this.$(),
			    navElements = $('.app-navigation a.active', $element),
			    controller = this.get('controller'),
			    visibleContent = controller.get('model.applicationstate.visibleContent');

			c = c || visibleContent;

			navElements.removeClass('active');
			var activeNav = function activeNav(d) {
				$('.nav-' + d, $element).addClass('active');
			};
			if (typeof c === 'object') {
				for (var i = 0; i < c.length; i++) {
					activeNav(c[i]);
				}
			} else {
				activeNav(c);
			}
		},

		/**
	  * Just rearrange the Layout the complete layout with the active settings.
	  * Called on orientationchange and window resize.
	  *
	  */
		updateUX: function updateUX() {

			// refresh route in order to re-render it
			this.beforeLinkTo(this.visibleContent);
			// we can optimize this, if reArrangeLayout doesn´t call
			// resetIsRendered, cause we should have already the view
			this.reArrangeLayout();

			// call navbar view onResponse
			//var navBar = UTIL._lookupView('navbar');
		},

		/**
	  * Show Content - eather through transition or show/hide
	  * Keep in mind: BOTH actions call this function AND call all callbacks
	  * Donno... we should maybe change that...
	  *
	  */
		showContent: function showContent(c) {

			var hasBottomContent = this.settings && this.settings.templates && this.settings.templates.bottom;
			var hasTopContent = this.settings && this.settings.templates && this.settings.templates.top;
			var hasBottomContentOverlay = this.settings && this.settings.templates && this.settings.templates.bottomOverlay;

			if (hasBottomContent) {
				this._showBottomContent(this.settings.templates.bottom, c);
			}
			if (hasTopContent) {
				this._showTopContent(this.settings.templates.top, c);
			}

			if (hasBottomContentOverlay) {
				this._showBottomContentOverlay(this.settings.templates.bottomOverlay, c);
			}
		},

		hideContent: function hideContent(c) {

			var hasBottomContent = this.settings && this.settings.templates && this.settings.templates.bottom;
			var hasTopContent = this.settings && this.settings.templates && this.settings.templates.top;

			if (hasBottomContent) {
				this._hideBottomContent(this.settings.templates.bottom, c);
			}
			if (hasTopContent) {
				this._hideTopContent(this.settings.templates.top, c);
			}
		},

		_hideTopContent: function _hideTopContent(s, c) {

			var me = this,
			    controller = me.get('controller'),
			    $element = me.$();

			var topcontent = $('.app-topcontent-wrapper', $element),
			    height = 0;

			fastdom.write(function () {
				UTIL.transit(topcontent, { x: 0, y: height }, {
					duration: me.defaultCSSTransitionDuration,
					easing: me.defaultCSSTransitionEasing
				});
			});
		},

		_hideBottomContentOverlayContent: function _hideBottomContentOverlayContent(s, c) {

			var me = this,
			    controller = me.get('controller'),
			    $element = me.$();

			var contentWr = $('.app-bottom-overlay-wrapper', $element),
			    height = this.getNavigationHeight(s, c);

			fastdom.write(function () {
				contentWr.css({
					bottom: 0,
					height: height
				});

				UTIL.transit(contentWr, { x: 0, y: height }, {
					duration: me.defaultCSSTransitionDuration,
					easing: me.defaultCSSTransitionEasing
				});
			});
		},

		_hideBottomContent: function _hideBottomContent(s, c) {

			var me = this,
			    controller = me.get('controller'),
			    $element = me.$();

			if (c === 'toggle' && s.view && typeof s.view.onHideBottomContent === 'function') {
				if (s.view.onHideBottomContent(s, c, me)) {
					return true;
				}
			}

			var contentWr = $('.app-content-wrapper', $element),
			    tilezoom = $('.app-tilezoom-contain', $element),
			    height = 0,
			    navigationHeight = me.getNavigationHeight(),
			    orientation = UTIL.getOrientation(),
			    wHeight = $(window).height(),
			    wWidth = $(window).width();

			// hide content completly (leave space for application button)
			if (c && c === 'complete+application-button') {
				height = navigationHeight;
			}
			// hide content completly (hides navigation)
			else if (c && c.indexOf('complete') >= 0) {
					height = navigationHeight;
					navigationHeight = 0;
				}

			fastdom.write(function () {

				UTIL.transit(contentWr, { x: 0, y: height }, {
					duration: me.defaultCSSTransitionDuration,
					easing: me.defaultCSSTransitionEasing,
					callback: function callback() {

						fastdom.write(function () {
							tilezoom.css({
								height: orientation === 'portrait' ? wHeight - navigationHeight : wHeight,
								width: orientation === 'portrait' ? wWidth : wWidth - navigationHeight,
								bottom: orientation === 'portrait' ? navigationHeight : 0
							});
							fastdom.write(function () {
								if (c && c.indexOf('notilezoomrefresh') === -1) {
									var sleep = me._resizeTilezoom();

									//fastdom.defer(sleep,function () {
									//
									//});
								}
							});
						});
					}
				});
			});
		},

		reArrangeLayout: function reArrangeLayout() {

			var me = this,
			    controller = me.get('controller');

			// set expanded to true for now. cause we don´ really work with it
			controller.set('model.applicationstate.expanded', true);

			this.visibleContent = controller.get('model.applicationstate.visibleContent');
			this.isExpanded = controller.get('model.applicationstate.expanded');
			this.settings = this._lookUpSettings(this.visibleContent);

			if (!this.settings) {
				this.settings = { templates: {} };
			}

			var activeNavigationItem = this.visibleContent;
			if (this.settings.activeNavigationItem) {
				activeNavigationItem = this.settings.activeNavigationItem;
			}

			this.activeNavigation(activeNavigationItem);

			var addTilezoom = true;
			if (addTilezoom) {
				this.settings.templates.tilezoom = {
					name: 'tilezoom',
					outlet: 'tilezoom',
					parent: 'mosaic'
				};
			}

			// this is strange, cause this setting are the routing settings
			// in my point of view, they should not be cached or in instance of on aobject inside the route
			// but anyway... lets reset these settings and recheck in case we need it,
			// that if we change the settings on runtime, they MUST resetted
			this.resetIsRendered();

			this.waitForIsRendered(this.visibleContent, this.settings);
		},

		resetIsRendered: function resetIsRendered() {
			var t;

			for (t in this.settings.templates) {
				if (!this.settings.templates.hasOwnProperty(t)) {
					continue;
				}
				this.settings.templates[t].isRendered = false;
			}
		},

		maxWaitingIndex: 500,
		waitingIndex: 0,
		/**
	  * look through defined views and check if they are rendered
	  * In Order to make this working
	  * the route needs baseRouteViewSettings AND a view with isRendered=false must exit
	  * otherwise we will not wait until the maximum WaitTime till we execute
	  *
	  * @param content
	  * @param settings
	  */
		waitForIsRendered: function waitForIsRendered(content, settings) {

			var view,
			    t,
			    isRendered = true;

			//if(typeof console === 'object') { console.log('Ember.view',Ember.View.views); }
			//if(typeof console === 'object') { console.log('BaseView.waitForIsRendered with settings',this.settings); }

			for (t in this.settings.templates) {
				if (!this.settings.templates.hasOwnProperty(t)) {
					continue;
				}
				if (this.settings.templates[t].isRendered === true) {
					continue;
				}

				this.settings.templates[t].isRendered = false;

				view = this._lookupView(this.settings.templates[t].name);
				//if(typeof console === 'object') { console.log('FOUND VIEW? in ',this.settings.templates[t].name,view); }

				if (view) {
					this.settings.templates[t].isRendered = typeof view.get('isRendered') === 'undefined' ? true : view.get('isRendered');
					this.settings.templates[t].view = view;
					//if(typeof console === 'object') { console.log('VIEW '+this.settings.templates[t].name+' ISRENDERED?',this.settings.templates[t].isRendered,view.get('isRendered'),typeof view.get('isRendered')); }
				}
				//if(isRendered === false) break;
			}

			for (t in this.settings.templates) {
				if (!this.settings.templates.hasOwnProperty(t)) {
					continue;
				}
				//if(typeof console === 'object') { console.log('RENDER CHECK '+this.settings.templates[t].name+'',this.settings.templates[t].isRendered,(this.settings.templates[t].isRendered !== true)); }
				if (this.settings.templates[t].isRendered !== true) {
					isRendered = false;
				}
			}

			//if(typeof console === 'object') { console.log('isRendered',isRendered,(!isRendered)); }
			this.waitingIndex++;
			if (this.waitingIndex === this.maxWaitingIndex) {
				isRendered = true;

				for (t in this.settings.templates) {
					if (!this.settings.templates.hasOwnProperty(t)) {
						continue;
					}
					if (this.settings.templates[t].isRendered !== true) {
						if (!this.settings.templates[t].view) {
							if (typeof console === 'object') {
								console.log('BaseView.waitForIsRendered WARNING NO VIEW FOUND FOR ' + this.settings.templates[t].name + ' YOU NEED TO DEFINE --isRendered-- in your view OR in your baseRouteViewSettings of your Template in your Route', view);
							}
						}
					}
				}
			}
			//if(typeof console === 'object') { console.log('isRendered2',isRendered,(!isRendered)); }

			if (!isRendered) {
				//Ember.run.next(this, function() {
				//	this.waitForIsRendered(content,this.settings);
				//});
				Ember['default'].run.later(this, function () {
					this.waitForIsRendered(content, this.settings);
				}, 25);
			} else {
				this.waitingIndex = 0;
				// Todo: recheck if this is enough to take care that the view is loaded!
				Ember['default'].run.later(this, function () {
					this.showContent();
				}, 25);
			}
		},

		/**
	  * Help Function Returns a View (move to globals?)
	  * @param n
	  * @returns {*}
	  * @private
	  */
		_lookupView: function _lookupView(n) {

			var k;

			//if(typeof console === 'object') { console.log('_lookupView.views',Ember.View.views); }

			for (k in Ember['default'].View.views) {
				if (!Ember['default'].View.views.hasOwnProperty(k)) {
					continue;
				}
				if (Ember['default'].View.views[k].renderedName === n) {
					return Ember['default'].View.views[k];
				}
			}

			return false;
		},

		_showTopContent: function _showTopContent(settings, c) {

			var me = this,
			    controller = me.get('controller'),
			    $element = me.$();

			var orientation = UTIL.getOrientation();

			var topcontent = $('.app-topcontent-wrapper', $element),
			    tilezoom = $('.app-tilezoom-contain', $element),
			    size = this._getTopContentSize(settings, c, orientation);

			//if(typeof console === 'object') { console.log('BaseView._showContent.contentHeight',settings.height); }
			// requestAnimationFrame here!
			//http://creativejs.com/resources/requestanimationframe/

			fastdom.write(function () {

				var outerHeight;
				if (orientation === 'portrait') {
					outerHeight = size.height;
				} else {
					outerHeight = size.height;
				}

				var outerTop;
				if (orientation === 'portrait') {
					outerTop = -size.height;
				} else {
					outerTop = 0;
				}

				topcontent.css({
					top: outerTop,
					height: outerHeight,
					width: orientation === 'portrait' ? '100%' : size.width,
					left: orientation === 'portrait' ? 0 : -size.width
				});

				fastdom.write(function () {
					if (settings.scrollable) {
						var scrollWr = $('.scrollWrapper', topcontent);
						scrollWr.css({
							height: size.height
						});
					}

					fastdom.write(function () {

						UTIL.transit(topcontent, {
							x: orientation === 'portrait' ? 0 : size.width,
							y: orientation === 'portrait' ? size.height : 0
						}, {
							duration: me.defaultCSSTransitionDuration,
							easing: me.defaultCSSTransitionEasing,
							callback: function callback() {

								//if(typeof console === 'object') { console.log('BaseView._showTopContent.callback.view',me.settings.templates.top.view); }
								if (c === 'toggle' && me.settings.templates.top.view && typeof me.settings.templates.top.view.afterToggleShowCSSTransition === 'function') {
									me.settings.templates.top.view.afterToggleShowCSSTransition();
								} else if (me.settings.templates.top.view && typeof me.settings.templates.top.view.afterShowCSSTransition === 'function') {
									me.settings.templates.top.view.afterShowCSSTransition();
								}
							}
						});
					});
				});
			});
		},

		_getTopContentSize: function _getTopContentSize(settings, c, orientation) {

			var me = this,
			    wheight = $(window).height(),
			    wwidth = $(window).width();

			switch (orientation) {
				case 'portrait':
					settings.height = wheight;
					if (this.settings.templates.bottom && this.settings.templates.bottom.height > 0) {
						settings.height = settings.height - this.settings.templates.bottom.height;
					}

					settings.height = settings.height - me.getNavigationHeight();
					settings.width = wwidth;
					break;
				default:

					settings.width = wwidth;
					if (this.settings.templates.bottom && this.settings.templates.bottom.width > 0) {
						settings.width = settings.width - this.settings.templates.bottom.width;
					} else {
						settings.width = settings.width - me.getNavigationHeight();
					}

					settings.height = wheight;
			}

			return {
				height: settings.height,
				width: settings.width
			};
		},

		/**
	  * Show the Bottom Content
	  * Todo: keep track of start and end points of last and next view: if they are equal, call the callback directly without transition
	  * Todo: do not refresh the tilezoom if start and end point of upcoming and last transition are the same
	  * Todo: use a loading element while the transition is running
	  * 
	  * This Function shuold only handle content at bottom of the page (portrait)
	  * and at the right in landscape mode, if you need content at the bottom in landscape
	  * use the bottom overlay
	  *
	  *
	  * @param settings
	  * @param c
	  * @private
	  */
		_showBottomContent: function _showBottomContent(settings, c) {

			var orientation = UTIL.getOrientation();

			if (orientation === 'landscape') {

				switch (this.visibleContent) {
					case 'tilezoommap':
						this._showBottomContentLandscapeBottom(settings, c);
						break;
					case 'choose-wonder':
					case 'change-wonder':
					case 'change-position':
						this._showBottomContentLandscapeBottomOverlay(settings, c);
						break;
					default:
						this._showBottomContentNormalized(settings, c);
				}
			} else {
				this._showBottomContentNormalized(settings, c);
			}

			//// donno, sometimes overlay is visible and lays over the bottom content
			//var hasBottomContentOverlay = (settings && settings.templates && settings.templates.bottomOverlay);
			//if(typeof console === 'object') { console.log('hasBottomContentOverlay ',hasBottomContentOverlay); }
			//if(!hasBottomContentOverlay) {
			//	this._hideBottomContentOverlayContent();
			//}
		},

		/**
	  * Exception form _showBottomContentNormalized
	  * only in landscape show content as bottom overlay
	  *
	  * @param settings
	  * @param c
	  * @private
	  */
		_showBottomContentLandscapeBottomOverlay: function _showBottomContentLandscapeBottomOverlay(settings, c) {

			var me = this,
			    controller = me.get('controller'),
			    $element = me.$();

			//var orientation = UTIL.getOrientation();

			var contentWr = $('.app-content-wrapper', $element),
			    contentEl = $('.app-content-contain', $element),
			    topcontent = $('.app-topcontent-wrapper', $element),
			    tilezoom = $('.app-tilezoom-contain', $element),
			    size = this._getBottomContentSize(settings, c, 'landscape-bottom-overlay');

			var menuRight = true,
			    isOverlay = true;
			var wHeight = $(window).height(),
			    wWidth = $(window).width(),
			    navHeight = me.getNavigationHeight(settings, c);

			var aniIn = function aniIn() {
				fastdom.write(function () {

					// height of the content with navigation inside, will be 100% on right orientation
					var outerHeight = size.height;
					var outerWidth = settings.width;
					if (outerWidth > wWidth - navHeight) {
						outerWidth = wWidth - navHeight - 10;
					}
					var framePadding = (wWidth - navHeight - outerWidth) / 2;

					// mosaic height
					var tilezoomHeight;
					if (isOverlay) {
						tilezoomHeight = wHeight;
					} else {
						tilezoomHeight = wHeight - outerHeight;
					}

					// mosaic width
					var tilezoomWidth = wWidth - navHeight;

					contentWr.css({
						top: 'auto',
						left: framePadding,
						bottom: -outerHeight,
						right: menuRight ? framePadding + navHeight : 0,
						width: outerWidth,
						height: outerHeight
					});

					fastdom.write(function () {

						contentEl.css({
							height: outerHeight
						});

						if (settings.scrollable) {
							me._setScrollArea(settings, c, outerHeight);
						}

						fastdom.write(function () {
							var y = outerHeight + navHeight;
							if (isOverlay && wHeight < outerHeight * 2) {
								y = outerHeight + navHeight / 4;
							}

							UTIL.transit(contentWr, {
								x: 0,
								y: -y
							}, {
								animate: 'animate-transform',
								duration: me.defaultCSSTransitionDuration,
								easing: me.defaultCSSTransitionEasing,
								callback: function callback() {

									me._afterBottomContentPositioning({
										width: tilezoomWidth,
										height: tilezoomHeight,
										bottom: 0
									}, settings, c);
								}
							});
						});
					});
				});
			};

			if (this.get('bottomContentPosition') !== 'bottom-left-overlay') {
				fastdom.write(function () {
					UTIL.transform(contentWr, { x: 0, y: 0 });
					me.set('bottomContentPosition', 'bottom-left-overlay');
					fastdom.defer(1, function () {
						aniIn();
					});
				});
			} else {
				this.set('bottomContentPosition', 'bottom-left-overlay');
				aniIn();
			}
		},

		/**
	  * Exception form _showBottomContentNormalized
	  * only in landscape show content at bottom position
	  *
	  * @param settings
	  * @param c
	  * @private
	  */
		_showBottomContentLandscapeBottom: function _showBottomContentLandscapeBottom(settings, c) {

			var me = this,
			    controller = me.get('controller'),
			    $element = me.$();

			//var orientation = UTIL.getOrientation();

			var contentWr = $('.app-content-wrapper', $element),
			    contentEl = $('.app-content-contain', $element),
			    topcontent = $('.app-topcontent-wrapper', $element),
			    tilezoom = $('.app-tilezoom-contain', $element),
			    size = this._getBottomContentSize(settings, c, 'landscape-bottom');

			var menuRight = true,
			    isOverlay = false;
			var wHeight = $(window).height(),
			    wWidth = $(window).width(),
			    navHeight = me.getNavigationHeight(settings, c);

			var aniIn = function aniIn() {
				fastdom.write(function () {

					// height of the content with navigation inside, will be 100% on right orientation
					var outerHeight = size.height;

					// mosaic height
					var tilezoomHeight;
					if (isOverlay) {
						tilezoomHeight = wHeight;
					} else {
						tilezoomHeight = wHeight - outerHeight;
					}

					// mosaic width
					var tilezoomWidth = wWidth - navHeight;

					contentWr.css({
						top: 'auto',
						left: 0,
						bottom: -outerHeight,
						right: menuRight ? navHeight : 0,
						width: 'auto',
						height: outerHeight
					});

					fastdom.write(function () {

						contentEl.css({
							height: outerHeight
						});

						if (settings.scrollable) {
							me._setScrollArea(settings, c, outerHeight);
						}

						fastdom.write(function () {

							UTIL.transit(contentWr, {
								x: 0,
								y: -outerHeight
							}, {
								animate: 'animate-transform',
								duration: me.defaultCSSTransitionDuration,
								easing: me.defaultCSSTransitionEasing,
								callback: function callback() {

									me._afterBottomContentPositioning({
										width: tilezoomWidth,
										height: tilezoomHeight,
										bottom: isOverlay ? 0 : outerHeight
									}, settings, c);
								}
							});
						});
					});
				});
			};

			if (this.get('bottomContentPosition') !== 'bottom-left') {
				fastdom.write(function () {
					UTIL.transform(contentWr, { x: 0, y: 0 });
					me.set('bottomContentPosition', 'bottom-left');
					fastdom.defer(1, function () {
						aniIn();
					});
				});
			} else {
				this.set('bottomContentPosition', 'bottom-left');
				aniIn();
			}
		},

		/**
	  * Standard Bottom Content View,
	  * Portrait from bottom to top
	  * Landscape from right to left
	  *
	  * @param settings
	  * @param c
	  * @private
	  */
		_showBottomContentNormalized: function _showBottomContentNormalized(settings, c) {

			var me = this,
			    controller = me.get('controller'),
			    $element = me.$();

			var orientation = UTIL.getOrientation();

			var contentWr = $('.app-content-wrapper', $element),
			    contentEl = $('.app-content-contain', $element),
			    topcontent = $('.app-topcontent-wrapper', $element),
			    tilezoom = $('.app-tilezoom-contain', $element),
			    size = this._getBottomContentSize(settings, c, orientation);

			//if(typeof console === 'object') { console.log('_showBottomContent._getBottomContentSize.size',size,settings); }
			//if(typeof console === 'object') { console.log('BaseView._showContent.settings',emberView,settings,settings.height); }

			var aniIn = function aniIn() {
				fastdom.write(function () {

					var wHeight = $(window).height(),
					    wWidth = $(window).width(),
					    navHeight = me.getNavigationHeight(settings, c);

					// height of the content with navigation inside, will be 100% on right orientation
					var outerHeight; // = (orientation === 'portrait') ? size.height + navHeight : size.height;
					if (orientation === 'portrait') {
						outerHeight = size.height + navHeight;
					} else {
						outerHeight = size.height;
					}
					// height of the content, without navigation, usually the given height, but on the right orientation
					// it will be window height - navigation size
					var innerHeight = orientation === 'portrait' ? size.height : size.height - navHeight;

					// mosaic height
					var tilezoomHeight;
					if (orientation === 'portrait') {
						tilezoomHeight = wHeight - outerHeight;
					} else {
						tilezoomHeight = wHeight;
					}

					// mosaic width
					var tilezoomWidth;
					if (orientation === 'portrait') {
						tilezoomWidth = wWidth;
					} else {
						tilezoomWidth = wWidth - size.width;
					}

					var outerRight;
					if (orientation === 'portrait') {
						outerRight = 0;
					} else {
						outerRight = -size.width;
					}

					var outerWidth;
					if (orientation === 'portrait') {
						outerWidth = size.width;
					} else {
						outerWidth = size.width;
					}

					var outerTop;
					if (orientation === 'portrait') {
						outerTop = 'auto'; //wHeight - navHeight;
					} else {
							outerTop = 0;
						}

					contentWr.css({
						top: outerTop,
						left: orientation === 'portrait' ? 0 : 'auto',
						bottom: orientation === 'portrait' ? -innerHeight : 0,
						right: outerRight,
						width: outerWidth,
						height: outerHeight
					});
					//return;
					fastdom.write(function () {

						contentEl.css({
							height: innerHeight
						});

						if (settings.scrollable) {
							me._setScrollArea(settings, c, innerHeight);
						}

						fastdom.write(function () {

							UTIL.transit(contentWr, {
								x: orientation === 'portrait' ? 0 : -size.width,
								y: orientation === 'portrait' ? -innerHeight : 0
							}, {
								animate: 'animate-transform',
								duration: me.defaultCSSTransitionDuration,
								easing: me.defaultCSSTransitionEasing,
								callback: function callback() {

									me._afterBottomContentPositioning({
										width: tilezoomWidth,
										height: tilezoomHeight,
										bottom: orientation === 'portrait' ? size.height : 0
									}, settings, c);
								}
							});
						});
					});
				});
			};

			if (orientation === 'portrait') {
				this.set('bottomContentPosition', 'bottom-left');
				aniIn();
			} else {
				if (this.get('bottomContentPosition') !== 'top-right') {
					fastdom.write(function () {
						UTIL.transform(contentWr, { x: 0, y: 0 });
						me.set('bottomContentPosition', 'top-right');
						fastdom.defer(1, function () {
							aniIn();
						});
					});
				} else {
					this.set('bottomContentPosition', 'top-right');
					aniIn();
				}
			}
		},

		_afterBottomContentPositioning: function _afterBottomContentPositioning(tilezoomCSS, settings, c) {

			var me = this,
			    $element = me.$(),
			    tilezoom = $('.app-tilezoom-contain', $element);

			fastdom.write(function () {
				tilezoom.css(tilezoomCSS);
				fastdom.write(function () {
					var sleep = me._resizeTilezoom(settings, c);

					fastdom.defer(sleep, function () {
						if (c === 'toggle' && settings.view && typeof settings.view.afterToggleShowCSSTransition === 'function') {
							settings.view.afterToggleShowCSSTransition();
						} else if (settings.view && typeof settings.view.afterShowCSSTransition === 'function') {
							settings.view.afterShowCSSTransition();
						}

						me.set('hasLazyLoader', false);
					});
				});
			});
		},

		/**
	  * Get the size of bottom content
	  *
	  * @param settings
	  * @param c
	  * @returns {*}
	  * @private
	  */
		_getBottomContentSize: function _getBottomContentSize(settings, c, orientation) {

			var me = this,
			    wheight = $(window).height(),
			    wwidth = $(window).width();

			var saveAndResetValues = false; // deprecated!
			if (saveAndResetValues) {
				// save size
				if (typeof settings.defaultWidth === 'undefined') {
					settings.defaultWidth = settings.width;
				}
				if (typeof settings.defaultHeight === 'undefined') {
					settings.defaultHeight = settings.height;
				}

				// reset values
				settings.width = settings.defaultWidth;
				settings.height = settings.defaultHeight;
			}

			// if(typeof console === 'object') { console.log('orientation',orientation,settings); }

			switch (orientation) {
				case 'landscape-bottom-overlay':
					// if height will be greater than half the device, shorten it
					if (settings.height > wheight / 3 * 4) {
						settings.height = wheight / 3 * 4;
					}
					break;
				case 'landscape-bottom':
				case 'portrait':
					// if height will be greater than half the device, shorten it

					var maxHeight = wheight / 2;
					var navHeight = this.getNavigationHeight(settings, c);

					// test if we can use 2/3 of window height,
					// if we have no top screen...
					if (!this.settings.templates.top) {
						maxHeight = wheight / 3 * 2;
						// minimum size of top level should be 100
						if (wheight - maxHeight - navHeight < 100) {
							maxHeight = wheight - navHeight - 100;
						}
						if (maxHeight < 200) {
							maxHeight = 200;
						}
					}

					if (settings.height === 'fullscreen') {
						settings.height = wheight - navHeight;
					} else if (settings.height > maxHeight) {
						settings.height = maxHeight;
					}
					settings.width = wwidth;
					break;
				default:
					if (!settings.width) {
						settings.width = settings.height;
					}

					if (settings.width > wwidth / 2) {
						settings.width = wwidth / 2;
					}
					settings.height = wheight;
			}

			return {
				height: settings.height,
				width: settings.width
			};
		},

		/**
	  * Position at BottomContent may be bottom or right
	  * every orientation may have its own position
	  * 
	  * @param settings
	  * @param c
	  * @param orientation
	  * @private
	  *
	 _getBottomContentPosition: function(settings,c,orientation) {
	 	
	 	var position = 'bottom',
	 		hasPosition = settings.position;
	 	
	 	switch(orientation) {
	 		case'portrait':
	 			position = 'bottom';
	 			break;
	 		case 'landscape':
	 		default:
	 			position = 'right';
	 	}
	 		if(hasPosition && hasPosition[orientation]) {
	 		position = hasPosition[orientation];
	 	}
	 		this.set('bottomContentPosition',position);
	 		return position;
	 },*/

		/**
	  * Show the Bottom Content Overlay (Overlay on the Bottom)
	  *
	  * @param settings
	  * @param c
	  * @private
	  */
		_showBottomContentOverlay: function _showBottomContentOverlay(settings, c) {

			var me = this,
			    controller = me.get('controller'),
			    $element = me.$();

			var contentWr = $('.app-bottom-overlay-wrapper', $element),
			    topcontent = $('.app-topcontent-wrapper', $element),
			    tilezoom = $('.app-tilezoom-contain', $element),
			    height = settings.height;

			fastdom.write(function () {
				if (me.visibleContent !== 'joy-screen') {
					contentWr.css({
						bottom: -height,
						height: height
					});
				}

				if (me.visibleContent === 'joy-screen') {
					var tilezoomHeight = $(window).height();
					var tilezoomWidth = $(window).width();

					me._afterBottomContentPositioning({
						height: tilezoomHeight,
						width: tilezoomWidth,
						bottom: 0
					}, settings, c);
				} else {

					fastdom.write(function () {

						UTIL.transit(contentWr, { x: 0, y: -height }, {
							duration: me.defaultCSSTransitionDuration,
							easing: me.defaultCSSTransitionEasing,
							callback: function callback() {

								var tilezoomHeight = $(window).height() - me.getNavigationHeight(settings, c);

								me._afterBottomContentPositioning({
									height: tilezoomHeight,
									bottom: 0
								}, settings, c);
							}
						});
					});
				}
			});
		},

		/**
	  * Set the height of the scrollable area(s?)
	  * -buttonarea: absolute positioned button area at the bottom
	  *
	  *
	  * @param settings
	  * @param c
	  * @param height
	  * @private
	  */
		_setScrollArea: function _setScrollArea(settings, c, height) {
			var me = this,
			    $element = me.$();

			//if(typeof console === 'object') { console.log('_setScrollArea',$element); }
			if (!$element) {
				return false;
			}
			var scrollWr = $element.find('.scrollWrapper');

			//if(typeof console === 'object') { console.log('scrollWr,settings.scrollable,height',scrollWr,settings.scrollable,height); }

			switch (settings.scrollable) {
				case '-buttonarea':
					scrollWr.css({
						height: height - this.getButtonHeight()
					});

					break;
				default:
					scrollWr.css({
						height: height
					});
			}
		},

		_lookUpSettings: function _lookUpSettings(content) {
			var controller = this.get('controller'),
			    settings;

			var contentRoute = this.get('container').lookup('route:' + content);
			var contentController = this.get('container').lookup('controller:' + content);
			//var contentView = this.get('container').lookup('view:'+content);

			//if(typeof console === 'object') { console.log('_lookUpSettings contentRoute,contentController of '+content+'',contentRoute,contentController); }

			if (contentRoute && typeof contentRoute.getBaseRouteViewSettings === 'function') {
				settings = contentRoute.getBaseRouteViewSettings();
			} else if (contentRoute && typeof contentRoute.get('baseRouteViewSettings') !== 'undefined') {
				settings = contentRoute.get('baseRouteViewSettings');
			} else if (contentController && typeof contentController.get('baseRouteViewSettings') !== 'undefined') {
				settings = contentController.get('baseRouteViewSettings');
			}

			//if(typeof console === 'object') { console.log('_lookUpSettings',settings); }

			return settings;
		},

		_resizeTilezoom: function _resizeTilezoom(settings, c) {

			var Tilezoomontroller = this.get('container').lookup('controller:tilezoom');
			var sleep = 1;

			// just update boundaries, don´t updaate Layout, cause view will do it by itself
			if (settings && settings.resizeTilezoom && settings.resizeTilezoom === 'soft' && c !== 'toggle') {
				Tilezoomontroller.send('setSize');
			} else {
				setTimeout(function () {
					Tilezoomontroller.send('resize');
				}, 100);
				sleep = 100;
			}

			return sleep;
		},

		hideStartSplash: function hideStartSplash() {

			var startSplash = $('.start-loading');

			if (startSplash && startSplash.get(0)) {
				UTIL.hideStartSplash = function () {
					//return false;
					fastdom.write(function () {
						UTIL.transit(startSplash, { x: $(window).width() * -1, y: 0 }, {
							duration: 500,
							easing: 'cubic-bezier(.41,.05,.81,.63)',
							callback: function callback() {
								startSplash.remove();
							}
						});
					});
				};
			}
		}

	});

});