define('wow-app/routes/upload', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		isSecure: false,

		docTitle: 'Upload',

		baseRouteViewSettings: {
			visibleContent: 'upload',
			activeNavigationItem: ['choose-wonder', 'my-wonderlock'],
			templates: {
				bottom: {
					//isRendered: true,
					name: 'upload',
					outlet: 'bottom',
					height: 225,
					width: 400,
					parent: 'mosaic',
					scrollable: true
				},
				top: {
					//isRendered: true,
					name: 'upload-photo',
					outlet: 'top',
					parent: 'mosaic'
				}
			},
			showContent: true
		},

		model: function model(params, transition) {

			var me = this,
			    controller = me.controllerFor('application'),
			    mosaicId = controller.getMosaicId();

			//// Todo, this is just a fake image to work with... Do we need a list of images?
			//return me.store.find('my-photo','55781985c46988791d8b4567').then(function(myphoto) {
			//	if(typeof console === 'object') { console.log('FOUND MYPHOTO IN DB?',myphoto); }
			//	return myphoto;
			//});

			// find all photos
			return me.store.findQuery('picture', { mosaicId: mosaicId }).then(function (myphotos) {
				return myphotos;
			}, function (reason) {
				return null;
			});
		},

		afterModel: function afterModel() {
			this.updateBaseView();
		},

		/**
	  * load model into controller
	  *
	  * @param controller
	  * @param model
	  */
		setupController: function setupController(controller, model) {
			//if(typeof console === 'object') { console.log('UploadRoute.setupController controller, model',controller, model); }
			controller.set('model', model);
			//me.set('photoCount',0);
		},

		renderTemplate: function renderTemplate(controller, model) {

			this.render({
				into: 'mosaic',
				outlet: 'bottom'
			});

			this.render('upload-photo', {
				into: 'mosaic',
				outlet: 'top',
				model: model
			});
		}

	});

});