define('wow-app/transforms/array', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

	'use strict';

	exports['default'] = DS['default'].Transform.extend({
		//deserialize: function(serialized) {
		//  return serialized;
		//},
		//
		//serialize: function(deserialized) {
		//  return deserialized;
		//}

		deserialize: function deserialize(value) {
			if (Ember['default'].isArray(value)) {
				return Ember['default'].A(value);
			} else {
				return Ember['default'].A();
			}
		},
		serialize: function serialize(value) {
			if (Ember['default'].isArray(value)) {
				return Ember['default'].A(value);
			} else {
				return Ember['default'].A();
			}
		}
	});

});