define('wow-app/controllers/share', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'mosaic', 'error-modal', 'share-instagram-modal', 'share-link-modal', 'share-mail-modal'],

		facebookProzessing: false,
		twitterProzessing: false,
		emailSubject: null,

		facebooksharingUrl: null,
		twittersharingUrl: null,
		linksharingUrl: null,

		user: null,

		init: function init() {
			this.loadSharingURl('facebook', false);
			this.loadSharingURl('twitter', true);
			this.loadSharingURl('link', 'object');
		},

		/**
	  *
	  * @param type
	  * @param photo
	  * @param callback
	  * @returns {string}
	   *
	   * DEPRECATED!
	   *
	  */
		getSharingUrl: function getSharingUrl(type, photo, callback, shorten) {

			if (this.get('sharingUrl')) {
				var data = this.get('sharingUrl');
				var sharingUrl = data.url;
				if (shorten) {
					sharingUrl = data.short_url;
				}
				return this.send(callback, photo, sharingUrl);
			}

			var me = this;
			//application = this.get('controllers.application'),
			//mosaic = this.get('controllers.mosaic').get('model');

			//var user = application.get('user');
			//var sharingId = photo.get('metadata.profileId');
			//var sharingUrl = this.get('globals').get('srvParams.BaseUrl')+'/sharing/'+type+'/'+mosaic.get('name')+'/' + sharingId;

			this.loadSharingURl(type, shorten);

			// $.ajax({
			// 	method:         'GET',
			// 	url:            '/social/sharing-url/'+type,
			// 	dataType:       'json',
			// 	success: function(data) {
			// 		if(data && data.short_url) {
			// 			me.set('sharingUrl',data);
			// 			var sharingUrl = data.url;
			// 			if(shorten) {
			// 				sharingUrl = data.short_url;
			// 			}
			// 			me.send(callback,photo,sharingUrl);
			// 		}
			// 	},
			// 	error: function(data) {
			// 		if(typeof console === 'object') { console.log('Error.getSharingUrl with ',data); }
			// 	}
			// });
		},

		loadSharingURl: function loadSharingURl(type, shorten) {
			var me = this;

			$.ajax({
				method: 'GET',
				url: '/social/sharing-url/' + type,
				dataType: 'json',
				success: function success(data) {
					if (data && data.short_url) {
						var sharingUrl = data.url;
						if (shorten && shorten === 'object') {
							sharingUrl = data;
						} else if (shorten) {
							sharingUrl = data.short_url;
						}
						// me.send(callback,photo,sharingUrl);
						me.set(type + 'sharingUrl', sharingUrl);
						return sharingUrl;
					}
				},
				error: function error(data) {
					if (typeof console === 'object') {
						console.log('Error.getSharingUrl with ', data);
					}
				}
			});
		},

		actions: {

			shareMail: function shareMail(photo, sharingUrl) {

				if (!sharingUrl) {
					sharingUrl = this.get('linksharingUrl');
					// return this.getSharingUrl('link',photo,'shareMail',false);
				}

				// sharingUrl = this.get('sharingUrl');
				var subject = this.get('emailSubject') || this.t('share.fb_caption');
				// if(typeof console === 'object') { console.log('sharingUrl',sharingUrl); }

				window.location = 'mailto:?subject=' + subject + '&body=' + sharingUrl.short_url;

				//var modal = this.get('controllers.share-mail-modal');
				//modal.show(sharingUrl);
			},

			shareInstagram: function shareInstagram(photo, sharingUrl) {
				var user = this.get('user');
				user.suggestion = this.t('share.fb_description');

				var modal = this.get('controllers.share-instagram-modal');
				modal.show(user);
			},

			shareLink: function shareLink(photo, sharingUrl) {

				if (!sharingUrl) {
					sharingUrl = this.get('linksharingUrl');
				}

				var modal = this.get('controllers.share-link-modal');
				modal.show(sharingUrl);
			},

			shareTwitter: function shareTwitter(photo, sharingUrl) {

				this.set('twitterProzessing', true);

				if (!sharingUrl) {
					sharingUrl = this.get('twittersharingUrl');
				}
				// if(typeof console === 'object') { console.log('shareFacebook',photo, sharingUrl); }

				var url = encodeURIComponent(sharingUrl),
				    title = encodeURIComponent(this.t('share.fb_description'));

				window.open('https://twitter.com/intent/tweet?text=' + title + '&url=' + url, 'Twitter', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2 + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
				UTIL.tracker.track('social', { network: 'Twitter', action: 'tweet', target: url });
				this.set('twitterProzessing', false);
			},

			shareFacebook: function shareFacebook(photo, sharingUrl) {

				this.set('facebookProzessing', true);

				// if(typeof console === 'object') { console.log('shareFacebook',photo, sharingUrl); }

				if (!sharingUrl) {
					sharingUrl = this.get('facebooksharingUrl');
				}

				var mosaic = this.get('controllers.mosaic').get('model'),
				    me = this;

				FB.ui({
					app_id: this.get('globals').get('srvParams.FBid'),
					name: this.t('global.title'),
					caption: this.t('share.fb_caption'),
					description: this.t('share.fb_description'),
					link: sharingUrl,
					method: 'feed'
				}, function (response) {
					// if(typeof console === 'object') { console.log('Facebook.response',response); }
					if (response && (response.post_id || !response.error_code)) {
						me.get('controllers.error-modal').raise({
							statusText: me.t('upload.import_fb'),
							statusCode: 200,
							reason: null,
							message: me.t('share.fb_published')
						});
						UTIL.tracker.track('social', { network: 'Facebook', action: 'share', target: sharingUrl });
						me.set('facebookProzessing', false);
					} else {
						console.log('Post was not published.', response);
						me.set('facebookProzessing', false);
					}
				});
			}
		}

	});

});