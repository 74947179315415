define('wow-app/controllers/top', ['exports', 'wow-app/controllers/base-object-controller'], function (exports, BaseController) {

	'use strict';

	//import Ember from 'ember';
	exports['default'] = BaseController['default'].extend({

		needs: ['mosaic', 'tilezoom'],

		/**
	  * Sort (a copy of) Wonders by rank
	  */
		sortedWonders: (function () {

			var wondersArr = this.get('model').get('wonders');
			var wonders = $.extend(true, [], wondersArr);

			function SortByName(a, b) {
				var ar = a.rank;
				var br = b.rank;
				return ar < br ? -1 : ar > br ? 1 : 0;
			}

			wonders.sort(SortByName);

			return wonders;
		}).property('controllers.mosaic.model')

	});

});