define('wow-app/controllers/signin-email-modal', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'error-modal'],

		loginFailed: false,
		loginOk: false,
		isProcessing: false,
		closeModal: false,

		email: null,
		password: null,

		show: function show(p) {

			var me = this;

			var loadModal = function loadModal(user) {
				me.get('controllers.application').send('showModal', 'signin-email-modal', user);
			};

			var user = this.get('controllers.application').get('user');

			if (user) {
				me.setProperties({
					loginOk: true,
					loginFailed: false,
					isProcessing: false,
					closeModal: false
				});

				loadModal(user);
			} else {
				me.setProperties({
					loginOk: false,
					loginFailed: false,
					isProcessing: false,
					password: null,
					closeModal: false
				});

				loadModal();
			}
		},

		signIn: function signIn() {

			var me = this;
			var applicationController = this.get('controllers.application');

			this.setProperties({
				loginFailed: false,
				isProcessing: true
			});

			$.post("/login_check", {
				_username: this.get("email") || $('#register-email').val(),
				_password: this.get("password") || $('#register-password').val(),
				_csrf_token: '',
				_submit: 'Login'
			}).then(function (a, b, c) {

				if (typeof a === 'object' && typeof a.user === 'object') {
					Cookies.remove('pic_anon_owner_token', { path: '/' });
					var user = applicationController.setUser(a.user);
					me.set('model', user);

					me.store.findQuery('picture', { mosaicId: applicationController.getMosaicId() }).then(function (pictures) {
						applicationController.setPicture(pictures.get('firstObject'));
						applicationController.set('pictures', pictures);

						me.setProperties({
							loginOk: true,
							loginFailed: false,
							isProcessing: false,
							closeModal: true
						});

						me.send('linkToModal', 'settings-modal');

						var userHasPicture = applicationController.get('userHasPicture');
						var visibleContent = applicationController.get('model.visibleContent');

						if (userHasPicture && visibleContent !== 'user-stay') {
							me.transitionToRoute('my-wonderlock');
						}

						return pictures;
					});
				}
			}, (function (a, b, c) {
				me.setProperties({
					loginOk: false,
					loginFailed: true,
					isProcessing: false
				});
			}).bind(this));
		},

		logOut: function logOut() {
			var me = this,
			    applicationController = this.get('controllers.application');

			this.setProperties({
				isProcessing: true
			});

			$.get("/logout", {}).then(function () {
				me.setProperties({
					loginOk: false,
					loginFailed: false,
					isProcessing: false,
					password: null
				});
				applicationController.deleteUser();
			}, (function () {
				me.setProperties({
					loginOk: false,
					loginFailed: false,
					isProcessing: false
				});
			}).bind(this));
		},

		actions: {

			logOut: function logOut() {
				this.logOut();
			},

			signIn: function signIn() {
				this.signIn();
			}
		}
	});

});