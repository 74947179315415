define('wow-app/controllers/settings-modal', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'tilezoom', 'signup-email-modal', 'signin-email-modal'],

		releaseNumber: (function () {
			return this.get('globals').get('srvParams.version') || '1.0';
		}).property(),

		loginOk: false,
		isProcessing: false,
		globalstate: null,
		pictures: null,
		initialLang: false,

		currentLang: {
			id: 'en'
		},

		watchLang: (function () {
			var initialLang = this.get('initialLang');
			this.set('isProcessing', true);
			if (initialLang) {
				this.get('controllers.application').send('changeLanguage', this.get('currentLang.id'));
			}
		}).observes('currentLang.id'),

		mosaicTileArray: [{ tile: "old", id: 'old' }, { tile: "new", id: 'new' }],

		currentMosaicTile: {
			id: 'old'
		},

		isLoggedIn: (function () {
			return this.get('model.user.loggedIn');
		}).property('model.user.loggedIn'),

		init: function init() {
			this.set('currentLang.id', this.get('controllers.application').get('model.lang'));
			this.set('initialLang', true);
		},

		show: function show(p) {

			var me = this,
			    applicationController = this.get('controllers.application');

			var loadLoginModal = function loadLoginModal(user) {
				applicationController.send('showModal', 'settings-modal', user);
			};

			var globalstate = applicationController.get('model');
			var user = applicationController.get('user');

			me.set('globalstate', globalstate);
			var mosaicId = applicationController.getMosaicId();

			if (user) {
				me.set('loginOk', true);
				loadLoginModal(user);
			} else {
				loadLoginModal();
			}
		},

		logOut: function logOut() {
			var me = this,
			    applicationController = this.get('controllers.application'),
			    tilezoomController = this.get('controllers.tilezoom');

			this.setProperties({
				isProcessing: true
			});

			var l_url = '/api/v1/auth/logout';
			$.get(l_url, {}).then(function () {
				me.setProperties({
					loginOk: false,
					isProcessing: false
				});
				Cookies.remove('pic_anon_owner_token', { path: '/' });
				applicationController.deleteUser();
				tilezoomController.removeUserPicture();
				me.transitionToRoute('tilezoommap');
			}, (function () {
				me.setProperties({
					loginOk: true,
					isProcessing: false
				});
			}).bind(this));
		},

		actions: {

			logOut: function logOut() {
				this.logOut();
			}

		}

	});

});