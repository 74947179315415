define('wow-app/controllers/share-link-modal', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'error-modal'],

		urlFailed: false,
		closeModal: false,

		url: null,
		short_url: null,

		show: function show(p) {

			var me = this;
			var loadModal = function loadModal() {
				me.get('controllers.application').send('showModal', 'share-link-modal', p);
			};

			me.setProperties({
				urlFailed: false,
				url: p.url,
				short_url: p.short_url,
				closeModal: false
			});

			loadModal();
		}

	});

});