define('wow-app/views/choose-wonder', ['exports', 'wow-app/views/base-routing-view'], function (exports, BaseRoutingView) {

	'use strict';

	//import Ember from 'ember';
	exports['default'] = BaseRoutingView['default'].extend({

		classNames: ['hideOnLoad', 'view-choose-wonder'],

		/**
	  * get visible wonder inside mosaic
	  * @returns {*}
	  */
		getWonder: function getWonder() {
			var controller = this.get('controller');
			return controller.get('controllers.tilezoom').getActiveWonder();
		},

		/**
	  * move to specific wonder id
	  * @param wonderId
	  * @returns {*}
	  */
		gotoWonder: function gotoWonder(wonderId) {
			var controller = this.get('controller');
			return controller.get('controllers.tilezoom').gotoWonder(wonderId, false);
		},

		/**
	  * Save WonderId into app and local storage
	  * @param wonderId
	  * @returns {*}
	  */
		saveWonderId: function saveWonderId(wonderId) {
			var controller = this.get('controller');
			return controller.saveWonderId(wonderId);
		},

		/**
	  * Callback Function from base-view before hiding transition
	  * In portrait mode: hide Steps, Title and Text and set height to the rest of the content
	  */
		onHideBottomContent: function onHideBottomContent(s, c, v) {

			var r = false;

			var me = this,
			    $element = me.$();

			var controller = v.get('controller');

			var orientation = UTIL.getOrientation();

			if (orientation === 'portrait') {
				var height = 190;
				var scrollWrapper = $element.find('.scrollWrapper');
				if (scrollWrapper && scrollWrapper.get(0)) {
					scrollWrapper.css('height', 'auto');
				}

				var ns = $.extend(true, {}, s, { height: height });
				v._showBottomContentNormalized(ns, c);
				return true;
			}

			return r;
		},

		/**
	  * Callback Function from base-view after show transition
	  */
		afterShowCSSTransition: function afterShowCSSTransition() {

			var me = this,
			    $element = me.$(),
			    minWidth = 165,
			    charWidth = 10,
			    controller = this.get('controller');

			if (!$element) {
				return false;
			}

			controller.get('controllers.tilezoom').send('resize');

			var swiperContainer = $element.find('.swiper-container');
			var slides = $element.find('.swiper-slide');
			slides.each(function () {
				var slide = $(this),
				    w = slide.innerWidth() < minWidth ? minWidth : slide.innerWidth();

				// lets try to fix the width, if it is to small for the single char width
				var chars = slide.find('span.country').html().length;
				if (w < charWidth * chars) {
					w = charWidth * chars;
				}

				slide.css({
					width: w + 'px',
					visibility: 'visible'
				});

				slide.find('span.wonder').css({
					display: 'block',
					width: w + 'px'
				});
			});

			//var lastActiveIndex = 0;

			var settings = {
				/**
	    * get visible wonder(id) and slide to to active swipe
	    * @param swiper
	    */
				onInit: function onInit(swiper) {
					var wonder = me.getWonder(),
					    i,
					    attr,
					    moveTo = 0;

					for (i = 0; i < swiper.slides.length; i++) {
						attr = $(swiper.slides[i]).attr('data-wonder');
						if (attr === wonder.id) {
							moveTo = i;
						}
					}
					//lastActiveIndex = moveTo;
					swiper.slideTo(moveTo);
				},
				//onSlideChangeStart:function(swiper) {
				//	lastActiveIndex = swiper.previousIndex;
				//},
				/**
	    * move to active swipes wonder(id) inside masaic
	    * @param swiper
	    */
				onSlideChangeEnd: function onSlideChangeEnd(swiper) {
					var activeSlide = swiper.slides[swiper.activeIndex];
					var wonderId = $(activeSlide).attr('data-wonder');
					me.gotoWonder(wonderId);
					me.saveWonderId(wonderId);
				},
				// enable clicking on slide... see also jquery click event below
				//onClick:function(swiper,event) {
				//	if(typeof console === 'object') { console.log('SWIPER CLICK',swiper,event,swiper.activeIndex,swiper.clickedIndex,swiper.previousIndex,lastActiveIndex); }
				//	var el;
				//	if(event.srcElement) el = event.srcElement;
				//	else if(event.target) el = event.target;
				//	else {
				//		return false;
				//	}
				//
				//	var $el = $(el);
				//	if(typeof console === 'object') { console.log('$el',$el,$el.parent('div')); }
				//
				//	if($el.hasClass('swiper-slide-active') || $el.parent('div').hasClass('swiper-slide-active')) {
				//		if(typeof console === 'object') { console.log('Yeah! Found active swiper',true); }
				//	}
				//},
				// Optional parameters
				direction: 'horizontal',
				loop: false,
				speed: 300,
				mousewheelControl: true,
				slidesPerView: 'auto',
				centeredSlides: true,
				paginationClickable: true,
				slideToClickedSlide: true,
				//pagination: '.wonder-swiper-pagination',
				spaceBetween: 30
			};

			if (!UTIL.isTouchSupported()) {
				settings.prevButton = '.wonder-swiper-button-prev';
				settings.nextButton = '.wonder-swiper-button-next';
			} else {
				swiperContainer.find('.wonder-swiper-button-prev').css('display', 'none');
				swiperContainer.find('.wonder-swiper-button-next').css('display', 'none');
			}

			this.swiper = new Swiper(swiperContainer.get(0), settings);

			//$('.swiper-slide').on('click',function() {
			//	for(var i=0;i<me.swiper.slides.length;i++) {
			//		if($(me.swiper.slides[i]).hasClass('swiper-active-slide')) {
			//			var moveTo = i;
			//		}
			//	}
			//	if(typeof console === 'object') { console.log('click moveTo',moveTo); }
			//	lastActiveIndex = moveTo;
			//});
		},

		destroySwiper: function destroySwiper() {

			var me = this,
			    $element = me.$();

			if (!$element) {
				return false;
			}

			try {
				if (this.swiper && typeof this.swiper.destroy === 'function') {
					this.swiper.destroy();
					this.swiper = null;
				}
			} catch (e) {
				if (typeof console === 'object') {
					console.log('Swiper Error', e);
				}
			}
		},

		/**
	  * Called when the element of the view is going to be destroyed.
	  * Override this function to do any teardown that requires an element, like removing event listeners.
	  */
		willDestroyElement: function willDestroyElement() {
			this.destroySwiper();
		},

		/**
	  * Callback Function from base-view after show transition
	  */
		afterToggleShowCSSTransition: function afterToggleShowCSSTransition() {
			//if(typeof console === 'object') { console.log('PhotoCropView.afterToggleCSSTransition',true); }
		},

		actions: {

			/**
	   * Change Wonder - Controller and Route is change-wonder
	   * but we use the change-wonder view
	   */
			changeWonder: function changeWonder() {
				var controller = this.get('controller');
				var activeSlide = this.swiper.slides[this.swiper.activeIndex];
				var wonderId = $(activeSlide).attr('data-wonder');
				this.get('parentView').set('hasLazyLoader', true);
				controller.send('changeWonder', wonderId);
			},

			/**
	   * Change to another wall (mosaic)
	   * Controller and Route is change-wonder
	   * but we use the change-wonder view
	   */
			changeWall: function changeWall() {
				var controller = this.get('controller');
				var activeSlide = this.swiper.slides[this.swiper.activeIndex];
				var wonderId = $(activeSlide).attr('data-wonder');
				this.get('parentView').set('hasLazyLoader', true);
				controller.send('changeWall', wonderId);
			},

			/**
	   * Choose Wonder and goto to upload
	   */
			chooseWonder: function chooseWonder() {
				var activeSlide = this.swiper.slides[this.swiper.activeIndex];
				var wonderId = $(activeSlide).attr('data-wonder');
				var controller = this.get('controller');
				controller.saveWonderId(wonderId);
				var applicationController = controller.get('controllers.application');
				applicationController.setUploadStep('s1');
				this.get('parentView').send('linkTo', 'upload');
			}

		}

	});

});