define('wow-app/locales/id', ['exports', 'wow-app/locales/en'], function (exports, EN) {

    'use strict';

    var ID = {
        "about": {
            "sub_title": "Tinggalkan jejak Anda!<br />Bergabunglah dengan WondersWall!<br />Kami tunggu Anda di sana!",
            "text": "<p>Ratusan juta suara telah memilih New7Wonders,, dan kini ANDA bisa menjadi bagian dari WondersWall dengan foto karya Anda sendiri dipajang di sana dengan salah satu objek New7Wonders.</p><p>Pilihlah Wonder Anda. Unggahlah foto Anda. Berbagilah dengan setiap orang. <br />Ya, Anda kini menjadi bagian dari <a href=\"https://twitter.com/hashtag/wonderswall\" target=\"_blank\">#wonderswall</a>!!!</p>"
        },
        "buttons": {
            "cancel": "Batalkan",
            "change_photo": "Ubah foto",
            "change_wall": "Ubah Wall",
            "change_wonder": "Ubah Wonder",
            "choose_wonder": "Pilih Wonder",
            "close": "Tutup",
            "crop_and_save": "Pangkas foto dan simpan",
            "crop_photo": "Pangkas foto",
            "join": "Bergabung",
            "log_out": "Logout",
            "ok": "OK",
            "register": "Mendaftar",
            "save": "Simpan",
            "select": "Pilih",
            "share": "Berbagi",
            "sign_in": "Login",
            "sign_up": "Mendaftar",
            "upload": "Mengunggah foto",
            "copy": "Salin",
            "yes": "Ya!",
            "edit": "Edit",
            "forget_password": "Tidak ingat kata sandi?"
        },
        "congratulation": {
            "sub_title": "ANDA kini menjadi bagian dari<br>WondersWall",
            "title": "Selamat"
        },
        "global": {
            "error_title": "Maaf",
            "next": "Berikutnya",
            "previous": "Sebelumnya",
            "title": "WondersWall"
        },
        "joyscreen": {
            "join": "Bergabung dengan<span class=\"break-portrait break-landscape\"></span>WondersWall",
            "title": "Anda ingin tetap bergabung dengan<span class=\"break-portrait break-landscape\"></span>WondersWall",
            "welcome": "Selamat datang di<span class=\"break-portrait break-landscape\"></span>WondersWall"
        },
        "mosaic": {
            "manmade": {
                "title": "7 Wonders of the World yang Baru"
            },
            "nature": {
                "title": "7 Wonders of the Nature yang Baru"
            }
        },
        "my_photo": {
            "my_wonder": "Wonder Saya",
            "no_login": "Anda tidak login! Anda harus mendaftar untuk dapat tetap bergabung dengan WondersWall.",
            "no_photo": "Anda belum mengunggah foto sama sekali...",
            "status": "Status",
            "sub_title": "Ubah foto atau ubah Wonder",
            "title": "Foto saya",
            "uploaded": "Diunggah",
            "motto": "Say something",
            "motto_placeholder": "Tell us about your photo",
            "location": "Location",
            "city": "City",
            "country": "Country",
            "ping": "WOW!",
            "my_wows": "My WOWs",
            "wowed_by": "WOWed by",
            "no_wows": "No WOWs yet."
        },
        "register": {
            "email": "Email",
            "logged_in_as": "Login sebagai",
            "password": "Sandi",
            "sign_in_email": "Login lewat email",
            "sign_up_email": "Mendaftar lewat email"
        },
        "seo": {
            "meta_description": "26\nRatusan juta suara telah memilih New7Wonders, dan kini ANDA bisa menjadi bagian dari WondersWall.\n"
        },
        "settings": {
            "change_language": "Bahasa",
            "email": "Email",
            "facebook": "Facebook",
            "login": "Login",
            "new_to_wall": "Bergabung dengan WondersWall?",
            "on_the_wall": "Sudah bergabung WondersWall?",
            "signin": "Mendaftar",
            "title": "Pengaturan",
            "twitter": "Twitter",
            "upload_photo": "Mengunggah foto"
        },
        "share": {
            "fb_caption": "Kini saya menjadi bagian dari<br>WondersWall",
            "fb_description": "Bergabunglah dengan saya! Unggahlah foto Anda ke www.wonderswall.com dan Anda pun bisa menjadi wonder! #wonderswall",
            "fb_name": "WondersWall",
            "fb_published": "Posting Anda telah dipublikasikan.",
            "sub_title": "Berbagilah dengan teman-teman,<br>bergabunglah bersama WondersWall",
            "title": "Berbagi",
            "whatsapp": "Whatsapp",
            "link": "Tautan",
            "email_from": "Dari",
            "email_to": "Ke",
            "email_subject": "Subjek",
            "email_text": "Teks Anda",
            "email_to_placeholder": "Pisahkan beberapa alamat email dengan tanda baca koma",
            "email_successfully_send": "Email berhasil dikirim"
        },
        "stay": {
            "accept": "Terima Syarat & Ketentuan",
            "title": "Tetap bergabung dengan <span class=\"break-portrait\"></span>WondersWall",
            "privacy_policy": "Kebijakan Privasi"
        },
        "terms": {
            "accept": "Silakan terima Syarat & Ketentuan",
            "title": "Syarat & Ketentuan"
        },
        "top": {
            "title": " Paling Banyak Diunggah"
        },
        "upload": {
            "image_error": "Tidak dapat mengunggah foto, silakan kembali dan mencoba mengunggah lagi",
            "import_fb": "Facebook",
            "import_insta": "Instagram",
            "import_my_photos": "Foto karya Anda",
            "import_tw": "Twitter",
            "import_webcam": "Webcam",
            "sub_title": "atau impor dari berikut ini",
            "take_photo": "Ambil foto",
            "title": "Ambil foto",
            "upload_error": "Terjadi kesalahan saat mengunggah gambar Anda",
            "upload_save_error": "Tidak dapat menyimpan foto, silakan kembali dan mencoba mengunggah lagi",
            "webcam_allow_error": "Klik \"allow\" (izinkan) untuk mengambil foto",
            "webcam_error": "Webcam Anda tidak bekerja dengan browser Anda, silakan perbarui perangkat lunaknya"
        },
        "upload_choose": {
            "change_title": "Ganti Wonder Anda",
            "sub_title": "dan unggahlah foto karya Anda ke sana",
            "title": "Pilih Wonder Anda"
        },
        "upload_steps": {
            "1": "Langkah 1",
            "2": "Langkah 2",
            "3": "Langkah 3"
        },
        "wall": {
            "upload_photo": "Unggahlah foto karya Anda ke WondersWall"
        },
        "wonders": {
            "amazon": {
                "location": "Amerika Selatan",
                "title": "Amazon"
            },
            "christ_redeemer": {
                "location": "Brasil",
                "title": "Christ Redeemer"
            },
            "colosseum": {
                "location": "Italia",
                "title": "Colosseum"
            },
            "great_wall_of_china": {
                "location": "Tiongkok",
                "title": "Great Wall"
            },
            "ha_long_bay": {
                "location": "Vietnam",
                "title": "Ha Long Bay"
            },
            "iguazu_falls": {
                "location": "Argentina/Brazil",
                "title": "Iguazu Falls"
            },
            "jeju_island": {
                "location": "Korea Selatan",
                "title": "Jeju Island"
            },
            "komodo": {
                "location": "Indonesia",
                "title": "Komodo"
            },
            "machu_pichu": {
                "location": "Peru",
                "title": "Machu Picchu"
            },
            "petra": {
                "location": "Jordania",
                "title": "Petra"
            },
            "pp_underground_river": {
                "location": "Filipina",
                "title": "PP Underground River"
            },
            "pyramid_at_chichen_itza": {
                "location": "Meksiko",
                "title": "Chichén Itzá"
            },
            "table_mountain": {
                "location": "Afrika Selatan",
                "title": "Table Mountain"
            },
            "taj_mahal": {
                "location": "India",
                "title": "Taj Mahal"
            }
        },
        "oauth_errors": {
            "login_failed": "Log in via social network failed or was cancelled."
        }
    };

    ID = $.extend(true, {}, EN['default'], ID);

    exports['default'] = ID;

});