define('wow-app/routes/photo-crop', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		isSecure: false,

		docTitle: 'Crop Photo',

		baseRouteViewSettings: {
			visibleContent: 'photo-crop',
			activeNavigationItem: ['choose-wonder', 'my-wonderlock'],
			templates: {
				bottom: {
					name: 'photo-crop',
					outlet: 'bottom',
					height: 242,
					width: 400,
					parent: 'mosaic',
					scrollable: true
				},
				top: {
					name: 'photo-crop-lock',
					outlet: 'top',
					parent: 'mosaic'
				}
			},
			showContent: true
		},

		/**
	  * load photo by id
	  *
	  * @param params
	  * @param transition
	  * @returns {*}
	  */
		model: function model(params, transition) {

			var me = this;

			return me.store.find('picture', params.photo_id).then(function (photo) {
				return photo;
			}, function (reason) {

				var mosaic_id = me.getMosaicId(params, transition);

				if (mosaic_id) {
					me.transitionTo('choose-wonder', mosaic_id);
				} else {
					me.transitionTo('start');
				}

				me.controllerFor('error-modal').raise({
					statusText: 'Sorry',
					statusCode: 404,
					reason: reason,
					message: 'The photo <strong>"' + params.photo_id + '"</strong> could not be found'
				});

				return null;
			});
		},

		afterModel: function afterModel() {
			this.updateBaseView();
		},

		renderTemplate: function renderTemplate(controller, model) {

			this.render({
				into: 'mosaic',
				outlet: 'bottom'
			});

			this.render('photo-crop-lock', {
				into: 'mosaic',
				outlet: 'top',
				model: model
			});
		}

	});

});