define('wow-app/objects/contentstate-dep', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Object.extend({

    contentVisible: false,

    collapsed: localStorage.isContentCollapsed,

    collapsedChanged: (function () {
      if (typeof console === 'object') {
        console.log('Contentstate.collapsedChanged', this.get('collapsed'));
      }
      if (this.get('collapsed')) {

        localStorage.isContentCollapsed = true;
      } else {

        localStorage.removeItem('isContentCollapsed');
      }
    }).observes('collapsed')

  }).create();

  //export default Contentstate;

  /*
  export default Ember.Object.extend({

      contentVisible: false,

      collapsed: localStorage.isContentCollapsed,

      collapsedChanged: function () {
  		if(typeof console === 'object') { console.log('Contentstate.collapsedChanged',this.get('collapsed')); }
          if ( this.get('collapsed') ) {

              localStorage.isContentCollapsed = true;
          }
          else {

              localStorage.removeItem( 'isContentCollapsed' );
          }

      }.observes('collapsed')

  }).create();
  */
  //export default Sidebarstate;

});