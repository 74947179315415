define('wow-app/templates/accept-terms/pt', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Ao entrar na versão para dispositivos móveis do site wonderswall.com, no aplicativo ou no site (também algumas vezes aqui referido coletivamente como wonderswall.com ou o site)\n        e/ou ao usar seus recursos, você concorda em obedecer aos seguintes termos e condições em relação ao site, aos serviços e a todos os assuntos relacionados com estes.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Direitos autorais");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Direitos Autorais 2014-2021, Global Platform Limited, (GPL), Dubai, Emirados Árabes Unidos.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Sob a licença da NewOpenWorld Corporation.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Contato: contact ( arroba ) wonderswall ponto com");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Todos os direitos reservados.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Agente de propriedade intelectual para avisos e outras comunicações.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("PRINCIPAIS TERMOS RELACIONADOS COM A PARTICIPAÇÃO NO WONDERSWALL.COM");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Upload de fotos");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Algumas partes do site permitem que você nos envie suas fotos. Nosso objetivo é examinar brevemente a qualidade do conteúdo e a adequação do material\n        para termos a certeza de que este site continua a ser algo de que todos possam usufruir, e que não seja responsabilizado no caso de surgir qualquer problema de conteúdo.\n        Você garante que qualquer material fornecido por você não infringe os direitos autorais ou outros direitos de qualquer outra pessoa ou organização.\n        Não nos envie materiais pertencentes a outra pessoa da qual você não possui a autorização necessária.\n        A GPL renuncia expressamente a responsabilidade por qualquer material de propriedade de terceiros; a GPL não pode ser responsabilizada por você ou qualquer\n        terceiro relativamente a qualquer perda (se estas perdas estavam previstas, eram previsíveis, conhecidas ou de outro modo), incluindo mas não se limitando a:\n        perda de dados; perda de receitas; perda de negócios; perda de oportunidade; quaisquer danos indiretos, consequentes, especiais ou exemplares decorrentes\n        do uso do site como resultado de você deliberadamente ou inconscientemente fornecer conteúdo de terceiros para a GPL.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Propriedade do conteúdo carregado");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Ao carregar fotos para o site, você concorda, através do envio de sua contribuição, em conceder à GPL o direito e a licença perpétua, livre de royalties, não exclusiva\n        e sub-licenciável para usar, divulgar, modificar, adaptar, publicar, traduzir, criar trabalhos derivados, distribuir, executar, reproduzir,\n        colocar à disposição do público, e exercer todos os direitos autorais e direitos de publicidade para a sua contribuição em todo o mundo e / ou para incorporar a sua\n        contribuição em outras obras em qualquer mídia atualmente conhecida ou posteriormente desenvolvida pelo prazo integral de quaisquer direitos que possam existir em sua contribuição.\n        Caso não deseje conceder à GPL os direitos estabelecidos acima, não carregue suas fotos.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Fotos no site wonderswall.com");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("A GPL se reserva o direito de aceitar ou recusar qualquer foto. A posição de qualquer foto pode ser alterada a qualquer momento para garantir que a imagem global\n        gerada das maravilhas fique ideal, ou por qualquer outro motivo determinado pela GPL. A fim de gerar uma imagem global ideal das maravilhas,\n        a GPL pode, ocasionalmente e a seu critério, complementar fotos carregadas com outras fotos já obtidas.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Conduta moral e ética");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("O site não poderá ser usado para qualquer uma das seguintes finalidades: (i) Divulgar qualquer material ilegal, ofensivo, difamatório, abusivo, ameaçador,\n        material prejudicial, vulgar, obsceno, censurável ou que viole qualquer lei; (ii) Transmitir materiais que encorajam condutas\n        que constituem uma infração penal, que resultam em responsabilidade civil ou violem quaisquer leis, regulamentos ou códigos de prática na sua ou\n        em qualquer outra jurisdição; (iii) Interferir com o uso ou fruição do site de qualquer outra pessoa, ou fazer, transmitir ou armazenar cópias eletrônicas de\n        materiais protegidos por direitos autorais sem a permissão do proprietário.Você será responsável por nossas perdas e custos resultantes de sua violação\n        deste requisito. Reservamo-nos o direito de remover qualquer material que entendermos e com a nossa absoluta discrição.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("OUTROS TERMOS E CONDIÇÕES GERAIS");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Respeito dos direitos autorais e outros direitos de propriedade intelectual e convenções");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("A GPL respeita a propriedade intelectual de terceiros e solicitamos aos usuários que façam o mesmo. A GPL pode, em circunstâncias adequadas e a seu critério, desativar e / ou encerrar as contas de usuários que possam estar infringindo os direitos de propriedade intelectual da GPL e / ou de outros. Se achar que seu trabalho foi copiado de uma forma que constitua uma violação de direitos autorais ou que seus direitos de propriedade intelectual foram violados, entre em contato conosco.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Marcas registradas da New7Wonders");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("A New7Wonders e outros logotipos, produtos e nomes de serviços da New7Wonders são marcas registradas da New Open World Corporation, aqui representada pela GPL. Sem a permissão prévia da GPL, você concorda em não exibir ou usar de qualquer maneira as marcas registradas da New7Wonders. Seu uso do e participação no site confirma que você concorda em não copiar, reproduzir, republicar, baixar, postar, transmitir, tornar acessível ao público ou usar de qualquer maneira, em qualquer mídia, em qualquer site, ou qualquer conteúdo da New7Wonders de qualquer outra forma que não seja para seu uso pessoal e de forma não comercial. Você concorda em não adaptar, alterar ou criar uma obra derivada a partir do site ou outro conteúdo da New7Wonders, exceto para seu próprio uso pessoal e estritamente não comercial. Qualquer outra utilização do site ou do conteúdo da New7Wonders requer a prévia autorização por escrito da GPL.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Privacidade");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("A GPL leva a sua privacidade a sério. Devido ao fato de coletarmos necessariamente determinados tipos de informações sobre nossos usuários, desejamos ajudar você a compreender os termos e condições relativos à coleta e ao uso dessas informações. Se você autorizou a GPL a entrar em contato com você para fins de atualização de informações ou outros tipos de comunicação, suas informações poderão ser colocadas nos bancos de dados da GPL. A GPL poderá usar certos dispositivos padrão da Web, como imagens eletrônicas, para rastrear padrões de tráfego gerais do usuário. A GPL também poderá receber e registrar automaticamente informações de nossos servidores e de nosso navegador, incluindo o endereço IP, a hora e as informações sobre a página que você solicitou. Como é normal na Web, a GPL também poderá receber informações sobre você provenientes de outras fontes e adicioná-las às informações que você nos forneceu. A GPL usa as informações coletadas para quatro finalidades gerais: Para personalizar a publicidade e o conteúdo que você vê; 2. Para melhorar a experiência do usuário em nossa rede de sites. Usamos informações rastreadas para determinar o bom desempenho de cada página com base em agregação de demografia de usuários e padrões de tráfego para essas páginas. Isso nos ajuda a continuar a desenvolver um serviço melhor para você; 3. Para atender a seus pedidos por determinados produtos e serviços no futuro, como enviar boletins informativos eletrônicos e permitir que usuários participem de enquetes, concursos, fóruns e leilões; 4. Para enviar a você informações que aceitou receber sobre tópicos que serão de seu interesse.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Leis nacionais");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Reconhecendo a natureza global da Internet, você concorda em cumprir com todas as leis, regulamentos e convenções, nacionais e locais, relativas a conduta online e conteúdo aceitável. Especificamente, você concorda em cumprir com todas as leis aplicáveis relativas à transmissão de dados técnicos exportados do país no qual você reside.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Indenização");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Você concorda em indenizar e isentar a GPL, assim como suas subsidiárias, afiliadas, diretores, agentes, co-proprietários de marca ou outros parceiros e funcionários, de qualquer reclamação ou demanda, incluindo honorários advocatícios razoáveis, feita por quaisquer terceiros devido a, ou resultantes de, conteúdo que você enviar, publicar, transmitir ou disponibilizar, através do serviço, seu uso do serviço, sua ligação com o serviço, sua violação dos Termos e Condições ou sua violação de quaisquer direitos de terceiros.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Sem revenda de serviço");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Você concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar para quaisquer fins comerciais, qualquer parte do serviço, uso do serviço ou acesso ao serviço.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Modificação do site");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode(" A GPL se reserva o direito de, a qualquer momento e de tempos em tempos, modificar ou descontinuar, de forma temporária ou permanente, o site (ou qualquer parte dele) com ou sem aviso prévio. Você concorda que a GPL não deve ser responsabilizada por você ou qualquer terceiro por qualquer modificação, suspensão ou interrupção do site.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Links");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("O site pode fornecer, ou terceiros podem fornecer, links para outros sites ou recursos da World Wide Web. Dado que a GPL não tem controle sobre tais sites e recursos, você reconhece e concorda que a GPL não é responsável pela disponibilidade de tais sites ou recursos externos, assim como não endossa e não é responsável por qualquer conteúdo, publicidade, produtos ou outros materiais presentes ou disponíveis em tais sites ou recursos. Você também reconhece e concorda que a GPL não será responsável, direta ou indiretamente, por qualquer dano ou perda causado ou alegadamente causado por ou em conexão com o uso de ou confiança em tais conteúdos, produtos ou serviços disponíveis no ou através de tais sites ou recursos.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Propriedade dos dados");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("A GPL é proprietária dos direitos autorais de todos os dados, como a participação no WondersWall, que possam ser exibidos ou publicados pela GPL. Regras vinculativas específicas abrangem o uso destes dados. Meios de comunicação: Os meios de comunicação de notícias reconhecidos podem reproduzir estes dados apenas em um contexto de notícias, contanto que o texto a seguir seja sempre apresentado de forma visível: Dados © www.wonderswall.com. Outras utilizações: Qualquer outro uso por organizações, empresas e sites está sujeito a licença e aprovação da GPL. Para mais informações, entre em contato conosco.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Isenção de responsabilidade sobre serviço técnico");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Conteúdo do site, incluindo as informações, nomes, imagens, fotos e logotipos relacionados à New7Wonders e à WondersWall ou aos seus serviços, é fornecido \"como está\" e \"mediante disponibilidade\" sem qualquer representação ou qualquer tipo de garantia feita (seja ela expressa ou implícita por lei) até o limite permitido por lei, incluindo não infração, compatibilidade, segurança e precisão. A GPL não garante que as funções contidas no conteúdo do site não sofrerão atraso ou interrupção ou que a foto ou outras transferências serão precisas ou completas, que os defeitos serão corrigidos ou que o site ou o servidor que as torna disponível estejam livres de vírus ou bugs ou quaisquer outros materiais prejudiciais baseados na Internet. Sob nenhuma circunstância a GPL é ou será responsabilizada por você ou qualquer terceiro por qualquer perda (sejam tais perdas previstas, previsíveis, conhecidas ou não), incluindo, mas não limitados a: perda de dados; perda de receitas; perda de negócios; perda de oportunidade; quaisquer danos indiretos, consequentes, especiais ou exemplares resultantes do uso do site.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Isenção de responsabilidade de garantias");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Você entende e concorda expressamente que: o seu uso do site é por sua conta e risco. O site é fornecido \"como está\" e mediante \"disponibilidade\". A GPL se isenta de todas as garantias de qualquer tipo, expressas ou implícitas, incluindo, mas não limitadas, às garantias implícitas de comercialização, adequação a uma finalidade específica e não violação. A GPL não faz nenhuma garantia de que (i) o site atenderá às suas necessidades, (ii) o site será ininterrupto, pontual, seguro ou livre de erros, (iii) os resultados que podem ser obtidos com o uso do site serão precisos ou confiáveis, (iv) a qualidade de quaisquer produtos, serviços, informações ou outros materiais comprados ou obtidos por você através do site atenderão às suas expectativas, e (v) quaisquer erros no software serão corrigidos. Qualquer material descarregado ou obtido por meio do uso do site, onde permitido, é feito por sua própria conta e risco, sendo que você será o único responsável por qualquer dano ao seu sistema de computador ou perda de dados que resultem do download de tal material.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Limitação de responsabilidade");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Você entende e concorda expressamente que a GPL não se responsabilizará por quaisquer danos diretos, indiretos, incidentais, especiais, consequenciais ou exemplares, incluindo, mas não limitado a, danos por perda de lucros, boa vontade, uso, dados ou outras perdas intangíveis (mesmo se a GPL tiver sido avisada da possibilidade de tais danos), resultantes de: (i) o uso ou a incapacidade de usar o site; (ii) o custo de aquisição de bens e serviços de substituição resultantes de quaisquer bens, dados, informações ou serviços adquiridos ou obtidos, mensagens recebidas ou transações efetuadas através ou a partir do site; (iii) acesso não autorizado ou alteração de suas transmissões ou dados; (iv) declarações ou conduta de terceiros no site; ou (v) qualquer outro assunto relacionado com o site.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Idade dos usuários");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Acreditamos que o site wonderswall.com está aberto a todas as pessoas de qualquer idade. No entanto, entendemos que em alguns países e culturas, pode haver algumas diferenças legais ou outros enquadramentos em relação à idade do usuário. Nós não recolhemos ou solicitamos informações pessoais de alguém com menos da idade permitida (em seu próprio país) onde seja proibido por lei ou conscientemente permitimos que tal pessoa se cadastre. Se a lei do seu país proíbe você de se registrar, não tente se registrar no site ou enviar qualquer informação sobre si mesmo para nós, incluindo, mas não limitado a, o seu nome, endereço, número de telefone ou endereço de e-mail. Se as leis do seu país exigem que você obtenha permissão, solicite a permissão dos seus pais / tutores antes de fornecer informações pessoais ao site.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Alteração dos termos");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("A GPL se reserva o direito de alterar estes termos e condições a qualquer momento, publicando as alterações online. Revise estes termos regularmente para garantir que você está ciente de todas as alterações feitas pela GPL. Seu uso continuado do site, após a publicação das alterações, significa a confirmação de que você concorda estar legalmente vinculado a estes termos tal como foram atualizados e / ou alterados.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Lei aplicável");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Estes Termos e Condições são regidos e interpretados de acordo com as leis do Cantão de Zurique, na Suíça.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});