define('wow-app/transforms/integer', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Transform.extend({
		serialize: function serialize(value) {
			return parseInt(value);
		},
		deserialize: function deserialize(value) {
			return parseInt(value);
		}
	});

});