define('wow-app/helpers/compare-helper', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(function (v1, op, v2, options) {

		//if(typeof console == 'object') console.log('COMPARE HELPER',v1, op, v2, options);
		//
		//var v1m,v2m;
		//
		//var v1m = Ember.Handlebars.get(this, v1, options);
		//var v2m = Ember.Handlebars.get(this, v2, options);
		//
		//
		//if(!v1m){
		//	v1m=v1
		//}
		//
		//if(!v2m){
		//	v2m=v2
		//}
		//
		//var c = {
		//	"eq": function( v1m, v2m ) {
		//		return v1m == v2m;
		//	},
		//	"neq": function( v1m, v2m ) {
		//		return v1m != v2m;
		//	}
		//}
		//
		//if( Object.prototype.hasOwnProperty.call( c, op ) ) {
		//	return c[ op ].call( this, v1m, v2m ) ? options.fn( this ) : options.inverse( this );
		//}
		//
		//return options.inverse( this );
	});

});