define('wow-app/templates/privacy-policy/id', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Terakhir diperbarui: November 2015");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("NewOpenWorld Corporation (\"kami\" atau \"kita\") menjalankan ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","//www.wonderswall.com");
        var el4 = dom.createTextNode("www.wonderswall.com");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (\"Situs\").");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Halaman ini memberi tahu Anda tentang kebijakan kami mengenai pengumpulan, penggunaan, dan pengungkapan Informasi Pribadi yang telah kami terima dari pengguna Situs kami. Kami menggunakan Informasi Pribadi hanya untuk menyediakan dan meningkatkan Situs. Dengan menggunakan Situs kami, berarti Anda dianggap setuju dengan pengumpulan dan penggunaan informasi sesuai dengan kebijakan ini.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Pengumpulan Informasi dan Penggunaanya");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Di saat menggunakan Situs kami, kami mungkin akan meminta kepada Anda untuk menyediakan informasi identitas pribadi tertentu yang dapat digunakan untuk menghubungi atau mengidentifikasi diri Anda. informasi identitas pribadi tersebut antara lain, namun tidak terbatas pada, nama Anda (\"Informasi Pribadi\")");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Data Log");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Seperti halnya operator pengelola situs lainnya, kami mengumpulkan informasi yang kan dikirim oleh browser Anda ketika Anda mengunjungi Situs kami (\"Data Log\"). Data Logini mungkin meliputi informasi seperti alamat IP (Internet Protocol)  komputer, jenis browser, versi browser, halaman di Situs kami yang Anda buka, wakt dan tanggal kunjungan Anda ke Situs kami, lamanya waktu yang Anda habiskan membuka laman Situs kami, dan statistik lainnya. Selain itu, kami mungkin menggunakan jasa pihak ketiga untuk mengumpulkan, memantau, dan menganalisis Data Log tersebut.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Komunikasi");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Kami mungkin akan menggunakan Informasi Pribadi tentang diri Anda untuk menghubungi Anda melalui buletin berkala, bahan pemasaran atau informasi lainnya yang mungkin menarik minat Anda.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Cookie");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Cookie adalah file dengan data kecil di dalamnya, yang mungkin meliputi identifier unik anonim. Cookie dikirim ke browser Anda dari situs web dan akan disimpan di hard drive di komputer atau di telepon Anda. Seperti halnya banyak situs lainnya, kami menggunakan \"cookie\" untuk mengumpulkan informasi. Jika Anda tidak menerima cookie, Anda mungkin tidak akan dapat menggunakan sebagian fitur dari Situs.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Keamanan");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Keamanan dari Informasi Pribadi tentang diri Anda sangat penting bagi kami, namun perlu diingat bahwa tidak akan ada metode transmisi apa pun lewat Internet, maupun melalui penyimpanan elektronik apa pun, yang 100% aman. Sekalipun kami sudah berusaha keras untuk menggunakan cara-cara yang diterima secara komersial untuk melindungi Informasi Pribadi tentang diri Anda, kami tidak bisa menjamin keamanan mutlak.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Perubahan pada Kebijakan Privasi Kami Ini");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Kebijakan Privasi ini yang dianggap berlaku adalah yang terakhir diperbarui, dan akan terus berlaku hingga ada perubahan apa pun pada ketentuan-ketentuannya di masa mendatang, yang akan diberlakukan segera setelah diposting ke halaman Situs ini. Kami memegang hak untuk memperbarui atau mengubah Kebijakan Privasi kami kapan pun, dan Anda harus memeriksa isi Kebijakan Privasi ini secara berkala. Jika Anda masih menggunakan Layanan ini setelah kami memposting perubahan apa pun pada Kebijakan Privasi di halaman ini, maka ini berarti Anda dianggap mengakui adanya perubahan tersebut dan setuju untuk mematuhinya dan Anda akan terikat pada Kebijakan Privasi yang telah diubah tersebut. Jika kami melakukan perubahan isi Kebijakan Privasi, kami akan memberitahu Anda baik dengan menggunakan Informasi Pribadi tentang diri Anda atau dengan menempatkan pemberitahuan yang terlihat mencolok di dalam Situs.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Hubungi Kami");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Jika Anda ada pertanyaan mengenai Kebijakan Privasi ini, silakan ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","mailto:support@wonderswall.com");
        var el4 = dom.createTextNode("hubungi kami");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});