define('wow-app/views/upload', ['exports', 'ember', 'wow-app/views/base-routing-view'], function (exports, Ember, BaseRoutingView) {

	'use strict';

	exports['default'] = BaseRoutingView['default'].extend({

		classNames: 'view-upload',
		isRendered: false,

		didInsertElement: function didInsertElement() {

			var me = this,
			    controller = me.get('controller');

			// show webcam link
			if (!UTIL.user.isDevice) {
				var webCamSupport = UTIL.testWebcamSupport();
				if (webCamSupport) {
					var wcTrigger = $('.wc-trigger');
					wcTrigger.show();
				}
			}

			me.set('isRendered', true);

			var loadService = UTIL.storage.getSession('loadService');
			if (loadService) {
				UTIL.storage.removeSession('loadService');
				switch (loadService) {
					case 'instagram':
						controller.send('getInstagramPictures');
						break;
				}
			}
		},

		actions: {

			triggerDropzone: function triggerDropzone() {
				var dropzone = $('.uploadForm');
			}

		}

	});

});