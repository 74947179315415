define('wow-app/routes/share', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		docTitle: 'Share',

		baseRouteViewSettings: {
			visibleContent: 'share',
			activeNavigationItem: 'my-wonderlock',
			templates: {
				bottom: {
					//isRendered: true,
					name: 'share',
					outlet: 'bottom',
					height: 230,
					width: 400,
					parent: 'mosaic',
					scrollable: true
				},
				top: {
					//isRendered: true,
					name: 'photo-lock',
					outlet: 'top',
					parent: 'mosaic'
				}
			},
			showContent: true
		},

		/**
	  * load current picture
	  *
	  * @param params
	  * @param transition
	  * @returns {*}
	  */
		model: function model(params, transition) {

			//if(typeof console === 'object') { console.log('StayOnWallRoute model',params, transition); }

			var me = this;

			return me.store.find('picture', params.photo_id).then(function (photo) {
				return photo;
			}, function () {
				transition.abort();
				var mosiacId = me.getMosaicId();
				if (mosiacId) {
					me.transitionTo('choose-wonder', mosiacId);
				} else {
					me.transitionTo('start');
				}
				return null;
			});
		},

		afterModel: function afterModel() {
			this.updateBaseView();
			var me = this;
			var shareController = me.controllerFor('share');
			$.get("/api/v1/auth/checkuser").then(function (a) {
				if (a) {
					shareController.set('user', a.user);
				}
			});
		},

		renderTemplate: function renderTemplate(controller, model) {

			this.render({
				into: 'mosaic',
				outlet: 'bottom'
			});

			//if(typeof console === 'object') { console.log('renderTemplate.model',model); }

			this.render('photo-lock', {
				into: 'mosaic',
				outlet: 'top',
				model: model
			});
		}

	});

});