define('wow-app/views/about-arena', ['exports', 'ember', 'wow-app/views/base-routing-view'], function (exports, Ember, BaseRoutingView) {

	'use strict';

	exports['default'] = BaseRoutingView['default'].extend({

		classNames: ['view-about-arena'],
		isRendered: false,

		/**
	  * Callback Function from base-view after show transition
	  */
		afterShowCSSTransition: function afterShowCSSTransition() {

			var me = this,
			    $element = this.$(),
			    arena = $('.arena', $element);

			if (UTIL.user.osPC) {
				$('#bgArena').on('click tap touchstart', function () {
					UTIL.videoPlayer.youtube.play('yt_shortinfovideo');
					$('#yt_shortinfovideotablink').tab('show');
				});
			}

			return true;

			var controller = this.get('controller');
			controller.set('isLoading', true);

			if (typeof window.load3dWall !== 'function') {
				var detectorLoaded = function detectorLoaded() {

					if (me.webglDetector()) {
						if (me.threeLoaded()) {
							threeLoaded();
						} else {
							me.$L(['/wow-app/3dwall/three.min.js'], threeLoaded);
						}
					} else {
						if (typeof console === 'object') {
							console.log('No Webgl support', Detector);
						}
					}
				};

				var threeLoaded = function threeLoaded() {
					if (me.TrackballControlsLoaded()) {
						TrackballControlsLoaded();
					} else {
						me.$L(['/wow-app/3dwall/TrackballControls.min.js'], TrackballControlsLoaded);
					}
				};

				var TrackballControlsLoaded = function TrackballControlsLoaded() {
					if (me.wallLoaded()) {
						wallLoaded();
					} else {
						me.$L(['/wow-app/3dwall/index_main.js'], wallLoaded);
					}
				};

				var wallLoaded = function wallLoaded() {
					if ($('#bgArena')) {
						$('#bgArena').remove();
					}

					if (typeof load3dWall === 'function') {
						window.load3dWall();
						//var load3dWall = new load3dWall();
					}
				};

				var scripts = ['/wow-app/3dwall/Detector.min.js'];

				if (this.webglDetectorLoaded()) {
					detectorLoaded();
				} else {
					this.$L(scripts, detectorLoaded);
				}
			} else {
				this.willClearRender();
				$('#bgArena').remove();
				window.load3dWall();
			}
		},

		wallLoaded: function wallLoaded() {
			if (typeof load3dWall === 'function') {
				return true;
			}

			return false;
		},

		TrackballControlsLoaded: function TrackballControlsLoaded() {
			if (typeof THREE === 'object' && typeof THREE.TrackballControls === 'function') {
				return true;
			}

			return false;
		},

		threeLoaded: function threeLoaded() {
			if (typeof THREE === 'object') {
				return true;
			}

			return false;
		},

		webglDetectorLoaded: function webglDetectorLoaded() {
			if (typeof Detector === 'object') {
				return true;
			}

			return false;
		},

		webglDetector: function webglDetector() {
			if (typeof Detector === 'object' && Detector.canvas && Detector.webgl) {
				return true;
			}

			return false;
		},

		$L: function $L(c, d) {
			for (var b = c.length, e = b, f = function f() {
				if (!(this.readyState && this.readyState !== "complete" && this.readyState !== "loaded")) {
					this.onload = this.onreadystatechange = null;
					--e || d();
				}
			}, g = document.getElementsByTagName("head")[0], i = function i(h) {
				var a = document.createElement("script");
				a.async = false;
				a.src = h;
				//a.id = h;
				a.onload = a.onreadystatechange = f;
				g.appendChild(a);
			}; b;) i(c[--b]);
		},

		/**
	  * on unload of the view remove widget and widgets events
	  */
		willClearRender: function willClearRender() {
			//tearDown3DWall();
			$("#bgArena").unbind("click");
		}

	});

});