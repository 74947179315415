define('wow-app/controllers/privacy-policy-modal', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'error-modal'],

		termsTemplate: 'privacy-policy/en',

		lang: (function () {
			this.setLang();
		}).property('controllers.application.model.lang'),

		setLang: function setLang() {

			var applicationController = this.get('controllers.application');
			var lang = applicationController.get('model.lang');

			if (typeof lang !== 'undefined') {
				this.set('termsTemplate', 'privacy-policy/' + lang);
			} else {
				this.set('termsTemplate', 'privacy-policy/en');
			}
		},

		show: function show(p) {

			var me = this;
			var loadModal = function loadModal() {
				me.get('controllers.application').send('showModal', 'privacy-policy-modal');
			};
			loadModal();
		}

	});

});