define('wow-app/models/mosaic', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({

		contentType: DS['default'].attr(),
		length: DS['default'].attr(),
		md5: DS['default'].attr(),
		name: DS['default'].attr(),

		mosaicId: DS['default'].attr(), // deprecated, this is the id now!
		tileZoomId: DS['default'].attr(), // this is the tile id, was before the id
		currentTarget: DS['default'].attr(), // this is the tile id, was before the id
		active: DS['default'].attr('boolean'),
		renderSnapshot: DS['default'].attr(),

		zoomLvlMin: DS['default'].attr('integer'),
		zoomLvlMax: DS['default'].attr('integer'),

		tilezoomWidth: DS['default'].attr('integer'),
		tilezoomHeight: DS['default'].attr('integer'),
		tilezoomBase: DS['default'].attr('number'),
		tilezoomBlowFactor: DS['default'].attr('number'),

		sourceWidth: DS['default'].attr('integer'),
		sourceHeight: DS['default'].attr('integer'),

		finalWidth: DS['default'].attr('integer'),
		finalHeight: DS['default'].attr('integer'),

		tileWidth: DS['default'].attr('integer'),
		tileHeight: DS['default'].attr('integer'),

		tileNumX: DS['default'].attr('integer'),
		tileNumY: DS['default'].attr('integer'),
		tileNumTotal: DS['default'].attr('integer'),

		wonders: DS['default'].attr('array')
		//wonders: [
		//	{
		//		id:'petra',
		//		rank:5,
		//		title: 'Petra',
		//		location: 'Jordan',
		//		img:'',
		//		start_x: 0/28000,
		//		end_x: 3980/28000
		//	},
		//	{
		//		id:'machu_pichu',
		//		rank:1,
		//		title: 'Machu Pichu',
		//		location: 'Peru',
		//		img:'',
		//		start_x: 3980/28000,
		//		end_x: 8080/28000
		//	},
		//	{
		//		id:'great_wall_of_china',
		//		rank:3,
		//		title: 'Great Wall of China',
		//		location: 'China',
		//		img:'',
		//		start_x: 8080/28000,
		//		end_x: 11980/28000
		//	},
		//	{
		//		id:'taj_mahal',
		//		rank:2,
		//		title: 'Taj Mahal',
		//		location: 'India',
		//		img:'',
		//		start_x: 11980/28000,
		//		end_x: 16240/28000
		//	},
		//	{
		//		id:'colosseum',
		//		rank:4,
		//		title: 'Colosseum',
		//		location: 'Italy',
		//		img:'',
		//		start_x: 16240/28000,
		//		end_x: 20680/28000
		//	},
		//	{
		//		id:'pyramid_at_chichén_itzá',
		//		rank:6,
		//		title: 'Pyramid at Chichén Itzá',
		//		location: 'Mexico',
		//		img:'',
		//		start_x: 20680/28000,
		//		end_x: 25500/28000
		//	},
		//	{
		//		id:'christ_redeemer',
		//		rank:7,
		//		title: 'Christ Redeemer',
		//		location: 'Brasil',
		//		img:'',
		//		start_x: 25500/28000,
		//		end_x: 28000/28000
		//	}
		//]
	});
	/*
	<div class="swiper-wrapper">
		<!-- Slides -->
	<div class="swiper-slide"><span class="wonder">Petra</span> <span class="country">Jordan</span></div>
	<div class="swiper-slide"><span class="wonder">Great&nbsp;Wall&nbsp;of&nbsp;China</span> <span class="country">China</span></div>
	<div class="swiper-slide"><span class="wonder">Taj&nbsp;Mahal</span> <span class="country">India</span></div>
	<div class="swiper-slide"><span class="wonder">Colosseum</span> <span class="country">Italy</span></div>
	<div class="swiper-slide"><span class="wonder">Pyramid&nbsp;at&nbsp;Chichén&nbsp;Itzá</span> <span class="country">Mexico</span></div>
	<div class="swiper-slide"><span class="wonder">Machu&nbsp;Pichu</span> <span class="country">Peru</span></div>
	<div class="swiper-slide"><span class="wonder">Christ&nbsp;Redeemer</span> <span class="country">Brazil</span></div>
	</div>
		*/

});