define('wow-app/controllers/navbar', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'mosaic', 'tilezoom', 'settings-modal'],

		showUploadTeaser: (function () {

			var applicationController = this.get('controllers.application');
			return applicationController.get('showUploadTeaser');
		}).property('controllers.application.showUploadTeaser'),

		actions: {
			/**
	   * send toggleContent action to mosaic controller
	   */
			toggleContent: function toggleContent() {
				this.get('controllers.mosaic').send('toggleContent');
			}
		}

	});

});