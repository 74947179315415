define('wow-app/locales/es', ['exports', 'wow-app/locales/en'], function (exports, EN) {

    'use strict';

    var ES = {
        "about": {
            "sub_title": "¡Deja tu marca!<br /> ¡Únete al Muro de las Maravillas!",
            "text": "<p>Cientos de millones de votos han elegido las nuevas 7 maravillas, ahora TÚ puedes ser parte del Muro de las Maravillas con tu propia foto en alguna de las nuevas 7 maravillas.</p><p>1.) Elije tu maravilla.<br />2.) Sube tu foto.<br />3.) Compártela con todos.<br />Sí, ¡¡¡ahora tu eres parte del <a href=\"https://twitter.com/hashtag/wonderswall\" target=\"_blank\">#murodelasmaravillas</a>!!!</p>"
        },
        "buttons": {
            "cancel": "Cancelar",
            "change_photo": "Cambiar de foto",
            "change_wall": "Cambiar de muro",
            "change_wonder": "Cambiar de maravilla",
            "choose_wonder": "Elegir maravilla",
            "close": "Cerrar",
            "crop_and_save": "Recortar foto y guardar",
            "crop_photo": "Recortar foto",
            "join": "Participar",
            "log_out": "Salir",
            "ok": "Aceptar",
            "register": "Registrarse",
            "save": "Guardar",
            "select": "Seleccionar",
            "share": "Compartir",
            "sign_in": "Iniciar sesión",
            "sign_up": "Registrarse",
            "upload": "Subir foto",
            "copy": "Copiar",
            "yes": "¡Sí!",
            "edit": "Edit",
            "forget_password": "¿Se te olvidó tu contraseña?"
        },
        "congratulation": {
            "sub_title": "Ahora eres parte del Muro de las Maravillas",
            "title": "Enhorabuena"
        },
        "global": {
            "error_title": "Perdón",
            "next": "Siguiente",
            "previous": "Atrás",
            "title": "El Muro de las Maravillas"
        },
        "joyscreen": {
            "join": "Únete al <span class=\"break-portrait break-landscape\"></span>Muro de las Maravillas",
            "title": "¿Quieres quedarte en el <span class=\"break-portrait break-landscape\"></span>Muro de las Maravillas?",
            "welcome": "Bienvenido al <span class=\"break-portrait break-landscape\"></span>Muro de las Maravillas"
        },
        "mosaic": {
            "manmade": {
                "title": "Las nuevas 7 maravillas del mundo"
            },
            "nature": {
                "title": "Las nuevas 7 maravillas de la naturaleza"
            }
        },
        "my_photo": {
            "my_wonder": "Mi maravilla",
            "no_login": "¡No has iniciado sesión! Tienes que registrarte para entrar en el Muro de las Maravillas.",
            "no_photo": "Aún no has subido ninguna foto...",
            "status": "Estado",
            "sub_title": "Cambia tu foto o cambia de maravilla",
            "title": "Mi foto",
            "uploaded": "Cargada",
            "motto": "Say something",
            "motto_placeholder": "Tell us about your photo",
            "location": "Location",
            "city": "City",
            "country": "Country",
            "ping": "WOW!",
            "my_wows": "My WOWs",
            "wowed_by": "WOWed by",
            "no_wows": "No WOWs yet."
        },
        "register": {
            "email": "Correo electrónico",
            "logged_in_as": "Sesión iniciada como",
            "password": "Contraseña",
            "sign_in_email": "Iniciar sesión con correo electrónico",
            "sign_up_email": "Registrarse con correo electrónico"
        },
        "seo": {
            "meta_description": "Cientos de millones de votos han elegido las nuevas 7 maravillas, ahora TÚ puedes ser parte del Muro de las Maravillas"
        },
        "settings": {
            "change_language": "Idioma",
            "email": "Correo electrónico",
            "facebook": "Facebook",
            "login": "Iniciar sesión",
            "new_to_wall": "¿Deseas ser parte del Muro de las Maravillas?",
            "on_the_wall": "¿Ya eres parte del Muro de las Maravillas?",
            "signin": "Registrarse",
            "title": "Configuración",
            "twitter": "Twitter",
            "upload_photo": "Subir una foto"
        },
        "share": {
            "fb_caption": "Ahora soy parte del Muro de las Maravillas",
            "fb_description": "¡Únete! Sube tu foto a www.wonderswall.com y ¡tu también podrás ser una nueva maravilla! #murodelasmaravillas",
            "fb_name": "El Muro de las Nuevas 7 Maravillas",
            "fb_published": "Se ha publicado tu comentario.",
            "sub_title": "¡Compártelo con tus amigos,<br>participad juntos en el Muro de las Maravillas!",
            "title": "Compartir",
            "whatsapp": "Whatsapp",
            "link": "Enlace",
            "email_from": "De",
            "email_to": "Para",
            "email_subject": "Asunto",
            "email_text": "Tu texto",
            "email_to_placeholder": "Separa las direcciones con una coma",
            "email_successfully_send": "Mensaje enviado correctamente"
        },
        "stay": {
            "accept": "Aceptar términos y condiciones",
            "title": "Quédate en el Muro de las Maravillas",
            "privacy_policy": "Política de privacidad"
        },
        "terms": {
            "accept": "Acepta nuestros términos y condiciones",
            "title": "Términos y condiciones"
        },
        "top": {
            "title": "Fotos más cargadas"
        },
        "upload": {
            "image_error": "No se puede cargar la foto, vuelve atrás y súbela de nuevo",
            "import_fb": "Facebook",
            "import_insta": "Instagram",
            "import_my_photos": "Tus fotos",
            "import_tw": "Twitter",
            "import_webcam": "Cámara web",
            "sub_title": "o impórtala desde aquí",
            "take_photo": "Sube una Foto",
            "title": "Sube una Foto",
            "upload_error": "Se ha producido un error al subir tu imagen",
            "upload_save_error": "No se puede guardar la foto, vuelve atrás y súbela de nuevo",
            "webcam_allow_error": "Haz clic en ''Permitir'' para sacar una foto",
            "webcam_error": "Tu cámara web no funciona con este navegador, actualiza tu software"
        },
        "upload_choose": {
            "change_title": "Cambia tu maravilla",
            "sub_title": "y sube su foto en ella",
            "title": "Elije tu Maravilla"
        },
        "upload_steps": {
            "1": "Paso 1",
            "2": "Paso 2",
            "3": "Paso 3"
        },
        "wall": {
            "upload_photo": "Sube tu foto al Muro de las Maravillas"
        },
        "wonders": {
            "amazon": {
                "location": "Sudamérica",
                "title": "Amazonas"
            },
            "christ_redeemer": {
                "location": "Brasil",
                "title": "Cristo Redentor"
            },
            "colosseum": {
                "location": "Italia",
                "title": "Coliseo"
            },
            "great_wall_of_china": {
                "location": "China",
                "title": "Gran Muralla"
            },
            "ha_long_bay": {
                "location": "Vietnam",
                "title": "Bahía de Ha Long"
            },
            "iguazu_falls": {
                "location": "Argentina/Brasil",
                "title": "Cataratas del Iguazú"
            },
            "jeju_island": {
                "location": "Corea del Sur",
                "title": "Isla de Jeju"
            },
            "komodo": {
                "location": "Indonesia",
                "title": "Komodo"
            },
            "machu_pichu": {
                "location": "Perú",
                "title": "Machu Picchu"
            },
            "petra": {
                "location": "Jordania",
                "title": "Petra"
            },
            "pp_underground_river": {
                "location": "Filipinas",
                "title": "Río Subterráneo de Puerto Princesa"
            },
            "pyramid_at_chichen_itza": {
                "location": "Mexico",
                "title": "Chichén Itzá"
            },
            "table_mountain": {
                "location": "Sudáfrica",
                "title": "Montaña de la Mesa"
            },
            "taj_mahal": {
                "location": "India",
                "title": "Taj Mahal"
            }
        },
        "oauth_errors": {
            "login_failed": "Log in via social network failed or was cancelled."
        }
    };

    ES = $.extend(true, {}, EN['default'], ES);

    exports['default'] = ES;

});