define('wow-app/controllers/change-position', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'mosaic', 'tilezoom', 'error-modal'],

		do_changePosition: function do_changePosition(position, settings) {

			var me = this,
			    mosaicId = null,
			    photo = this.get('model'),
			    applicationController = this.get('controllers.application');

			var user = this.get('controllers.application').get('user');
			if (!user) {
				return false;
			}

			var m = {
				'wishPosX': position.x,
				'wishPosY': position.y
			};

			applicationController.updatePictureMetadata(photo, m);

			var onSuccess = function onSuccess(p) {

				// if(typeof console === 'object') { console.log('SUCCESS GET NEW METADATA',p,p.get('metadata.pos')); }

				if (!mosaicId) {
					mosaicId = applicationController.getMosaicId();
				}

				var m = {
					pos: p.get('metadata.pos'),
					wonderId: p.get('metadata.wonderId'),
					wishPosX: null,
					wishPosY: null
				};
				//
				applicationController.updatePictureMetadata(photo, m);
				applicationController.setWonder(mosaicId, p.get('metadata.wonderId'));
				applicationController.setPicture(photo);
				me.transitionToRoute('my-wonderlock');
			};

			var onFail = function onFail(p) {
				me.get('controllers.error-modal').raise({
					statusText: 'Sorry',
					statusCode: 404,
					reason: p,
					message: me.t('upload.upload_save_error')
				});
			};

			photo.save().then(onSuccess, onFail);
		},

		actions: {

			changePosition: function changePosition(position, settings) {
				this.do_changePosition(position, settings);
			}

		}

	});

});