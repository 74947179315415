define('wow-app/controllers/application', ['exports', 'wow-app/controllers/base-object-controller'], function (exports, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['error-modal'],

		languages: [],

		/**
	  * binded template attribute to expand/collpase content area
	  */
		isExpanded: (function () {
			// not in use
			return this.get('model.collapsed');
		}).property('model.expanded'),

		mosaicList: null,

		setMosaicId: function setMosaicId(mosaicId) {
			this.set('model.mosaic', mosaicId);
		},

		getMosaicId: function getMosaicId(transition) {

			var mosaicId = this.get('globals').get('srvParams.nearestWonderWallId');

			if (!mosaicId) {
				mosaicId = this.get('globals').get('srvParams.defaultMosaicId'); //'53aff19ffda67df5282e7e92';
			}

			if (typeof transition === 'object' && typeof transition.params === 'object' && typeof transition.params.mosaic === 'object') {
				mosaicId = transition.params.mosaic.mosaic_Id;
			} else if (this.get('model.mosaic')) {
				mosaicId = this.get('model.mosaic');
			}

			return mosaicId;
		},

		getPhotoId: function getPhotoId(transition) {

			var photoId = null;

			if (typeof transition === 'object' && typeof transition.params === 'object') {
				for (var i in transition.params) {
					var transObj = transition.params[i];
					if (typeof transObj === 'object' && transObj.photo_id) {
						photoId = transObj.photo_id;
					}
				}
			}

			return photoId;
		},

		loginChecked: false,

		/**
	  * Registered user model
	  */
		user: null,

		deleteUser: function deleteUser() {
			this.setPicture(null, 'deleteAll');
			this.setUser(null);
		},

		/**
	  * Active Picture of the registred user(!)
	  * Use only this picture and assign it on
	  * session-login (start of app), registration and sign-up
	  * All others should use this picture so we don´t need
	  * to call this all the time...
	  */
		picture: null,
		pictures: null, // deprecated - we have only one picture

		/**
	  * Set Picture - load it into the store or delete it
	  * @param picture
	  * @param action
	  * @returns {*}
	  */
		setPicture: function setPicture(picture, action) {

			if (picture) {
				if (typeof picture.set !== 'function') {
					picture = this.store.createRecord('picture', picture);
				}
			} else if (action === 'delete') {

				this.store.find('picture', picture.get('id')).then(function (record) {
					record.content.forEach(function (rec) {
						Ember.run.once(this, function () {
							rec.deleteRecord();
							rec.save();
						});
					}, this);
				});
			} else if (action === 'deleteAll') {
				this.store.unloadAll('picture');
				this.set('pictures', null);
			}

			this.set('picture', picture);
			return picture;
		},

		/**
	  * Update picture models metadata
	  * @param photo
	  * @param m
	  */
		updatePictureMetadata: function updatePictureMetadata(photo, m) {

			if (photo) {
				var metadata = photo.get('metadata');
				//if(typeof console === 'object') { console.log('ORG metadata',metadata); }
				if (typeof metadata !== 'object') {
					photo.set('metadata', {
						d: 1
					});
				}
				for (var i in m) {
					//if(typeof console === 'object') { console.log('SET METADATA '+i,m[i]); }
					if (typeof m[i] !== 'undefined') {
						photo.set('metadata.' + i, m[i]);
					}
				}
			}
		},

		/**
	  * Check if given pictureId is an picture of the running user
	  *
	  * @param pictureId
	  * @returns {boolean}
	  */
		isUserPictureById: function isUserPictureById(pictureId) {
			var userPicture = this.get('picture');
			var length = 0;

			if (userPicture && pictureId) {
				length = userPicture.get('id') === pictureId;
			}

			return length ? true : false;
		},

		/**
	  * Check if given picture is an picture of the running user
	  *
	  * @param picture
	  * @returns {boolean}
	  */
		isUserPicture: function isUserPicture(picture) {
			var userPicture = this.get('picture');
			var length = 0;

			if (userPicture && picture) {
				length = userPicture.get('id') === picture.get('id');
			}

			return length ? true : false;
		},

		/**
	  * Set user - use only this function to set and delete a user model
	  * @param user
	  * @returns {*}
	  */
		setUser: function setUser(user) {

			if (user) {
				if (typeof user.set === 'function') {
					user.set('loggedIn', true);
				} else {
					user['loggedIn'] = true;
					user = this.store.createRecord('user', user);
				}
			} else {
				this.store.unloadAll('user');
			}

			this.set('user', user);
			return user;
		},

		/**
	  * Watch user and pictures and set userHasPicture, if user has a picture
	  */
		//userHasPictures: function() {
		//	var user = this.get('user');
		//	var pictures = this.get('pictures');
		//	var userpics,
		//		length = 0;
		//
		//	if(user && pictures /*&& typeof pictures.filterBy === 'function'*/) { // maybe a promise...
		//		userpics = pictures.filterBy('metadata.profileId', user.get('token'));//.get('length');
		//		if(userpics){
		//			var activePic = this.get('picture');
		//			if(!activePic) {
		//				this.set('picture',userpics.get('firstObject'));
		//			}
		//			length = userpics.get('length');
		//		}
		//	}
		//
		//	return (length) ? true : false;
		//}.property('user.loggedIn','pictures.firstObject'),

		/**
	  * Watch defined application controller picture against user id
	  *
	  */
		userHasPicture: (function () {
			var user = this.get('user');
			var picture = this.get('picture');
			var length = 0;

			if (user && picture) {
				// maybe a promise...
				length = picture.get('metadata.profileId') === user.get('token');
			}
			return length ? true : false;
		}).property('user.loggedIn', 'picture.id'),

		/**
	  * Upload Steps
	  * Remember which upload steps are done and jump to the last active step
	  */

		enableStepRouting: false,
		uploadSteps: {
			s1: { route: 'choose-wonder' },
			s2: { route: 'upload' },
			s3: { route: 'user-stay' }
		},

		/**
	  * While user is not registered, we route to the last active upload step on click of upload
	  *
	  * @param s
	  */
		setUploadStep: function setUploadStep(s) {
			var step = this.get('uploadSteps.' + s);
			//if(typeof console === 'object') { console.log('UPLOAD STEP DONE',step); }
			this.set('uploadSteps.' + s + '.done', true);
			//if(typeof console === 'object') { console.log('uploadSteps',this.get('uploadSteps')); }
		},

		getActiveUploadStep: function getActiveUploadStep() {

			if (!this.enableStepRouting) {
				return false;
			}

			var route = null;
			var subRoute = null;

			if (this.get('user') && this.get('user').get('loggedIn')) {
				route = 'my-wonderlock';
			} else {
				var steps = this.get('uploadSteps');
				var lastStepDone = null;

				for (var i = 1; i <= 3; i++) {
					if (!lastStepDone && steps['s' + i].done === true) {
						lastStepDone = i;
					}
				}

				if (lastStepDone && steps['s' + (lastStepDone + 1)] && steps['s' + (lastStepDone + 1)].route) {
					route = steps['s' + (lastStepDone + 1)].route;
				}
			}

			if (route && subRoute) {
				this.transitionTo(route, subRoute);
			} else if (route) {
				this.transitionTo(route);
			}

			return route;
		},

		resetUploadStep: function resetUploadStep(s) {},

		// getUser: function() {
		//   return this.get('user');
		// }.property('user'),

		/**
	  * Check if user is logged in
	  */
		userLoggedIn: (function () {
			var user = this.get('user');
			return user ? true : false;
		}).property('user.loggedIn'),

		/**
	  * Helper property which defines, if the upload teaser is visible
	  * (if user has no picture and tilezoommap is visible)
	  */
		showUploadTeaser: (function () {

			//var orientation = UTIL.getOrientation();

			var ut = false;
			if (this.get('model.visibleContent') === 'tilezoommap' && this.get('userHasPicture') === false) {
				ut = true;
			}
			//else if(this.get('model.visibleContent') === 'choose-wonder' && this.get('userHasPicture') === false) {
			//	ut = true;
			//}
			return ut;
		}).property('model.visibleContent', 'userHasPicture'),

		/**
	  * property to set true/false, if the ship of the splash screen has already been done
	  * if this is true, we can transitionTo the splash screen
	  * otherwise the afterModel of the splash route
	  * will transition us to the mosaic, if we have seen it already
	  *
	  */
		skipSplashRedirectDone: false,

		/**
	  * initialize global UTIL (helper) function
	  * globals: this.get('globals').get('name')
	  */
		init: function init() {
			UTIL.init();
		},

		/**
	  * WATCH "globalstate" changes and store them in the localStorage
	 	 */

		/**
	  * Applicationstate "expanded" changes: set localStorage Object to track the state
	  */
		expandedChanged: (function () {
			if (this.get('model.expanded')) {
				localStorage.isContentExpanded = true;
			} else {
				localStorage.removeItem('isContentExpanded');
			}
		}).observes('model.expanded'),

		/**
	  * Applicationstate "visibleContent" changes: set localStorage Object to track the state
	  */
		visibleContentChanged: (function () {
			if (this.get('model.visibleContent')) {
				localStorage.visibleContent = this.get('model.visibleContent');
			} else {
				localStorage.removeItem('visibleContent');
			}
		}).observes('model.visibleContent'),

		/**
	  * Language changed
	  */
		langChanged: (function () {
			if (this.get('model.lang')) {
				Cookies.set('lang', this.get('model.lang'), { expires: 365, path: '/' });
			}
		}).observes('model.lang'),

		/**
	  * Mosaic changed
	  */
		mosaicChanged: (function () {
			if (this.get('model.mosaic')) {
				localStorage.mosaic = this.get('model.mosaic');
				Cookies.set('mosaicTile', this.get('model.mosaic'), { expires: 7, path: '/' });
			} else {
				localStorage.removeItem('mosaic');
			}
		}).observes('model.mosaic'),

		setWonder: function setWonder(mosaicId, wonderId) {

			mosaicId = mosaicId || this.getMosaicId();
			var wonders = this.get('model.wonder');
			if (typeof wonders != 'object') {
				wonders = {};
			}

			wonders[mosaicId] = wonderId;
			this.set('model.wonder', wonders);
			UTIL.storage.storeLocal('wonder', JSON.stringify(wonders));
		},

		getWonder: function getWonder(mosaicId) {
			mosaicId = mosaicId || this.getMosaicId();
			var wonders = this.get('model.wonder');
			if (typeof wonders != 'object') {
				wonders = {};
			}

			if (wonders[mosaicId]) {
				return wonders[mosaicId];
			}

			return null;
		},

		/**
	  * Wonder changed
	  */
		wonderChanged: (function () {
			if (this.get('model.wonder')) {
				localStorage.wonder = JSON.stringify(this.get('model.wonder'));
			} else {
				localStorage.removeItem('wonder');
			}
		}).observes('model.wonder@each'),

		/**
	  * Development only... not neede anymore
	  */
		//mosaicTilesChanged: function() {
		//
		//	if ( this.get('model.mosaicTiles') ) {
		//		localStorage.mosaicTiles = this.get('model.mosaicTiles');
		//		Cookies.set('mosaicTile', this.get('model.mosaicTiles'),{ expires: 7, path: '/' });
		//	}
		//	else {
		//		localStorage.removeItem( 'mosaicTiles' );
		//	}
		//
		//}.observes('model.mosaicTiles'),

		actions: {

			contentCollapse: function contentCollapse() {
				// not in use
				this.set('model.expanded', true);
			},

			/**
	   * Change Language
	   * @link https://github.com/dockyard/ember-cli-i18n|Ember-Cli Language Module
	   * @param lang
	   */
			changeLanguage: function changeLanguage(lang) {
				// lang=en
				this.set('model.lang', lang);
				window.location.href = location.href;
				//var set = Ember.set;
				//var application = this.container.lookup('application:main');
				//set(application, 'locale', lang);
			},

			/**
	   * Vibrate the device
	   * This is probably working nowhere...
	   */
			vibrate: function vibrate() {
				navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;

				if (navigator.vibrate) {
					console.log('we can vibrate');
					navigator.vibrate(500);
					navigator.vibrate([500, 1000, 500]);
				} else {
					console.log('no vibration for you :-(');
				}
			}
		}

	});

});