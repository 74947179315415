define('wow-app/objects/user-object-dep', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    var User = Ember['default'].Object.create({

        loggedIn: false,

        firstname: 'Jon',
        lastname: 'Doe',
        id: 'X',

        getFullName: function getFullName() {

            return this.get('firstname') + ' ' + this.get('lastname');
        }
    });

    exports['default'] = User;

});