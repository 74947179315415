define('wow-app/routes/catchall', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({

    model: function model() {
      this.transitionTo('start');
    }

  });

});