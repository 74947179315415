define('wow-app/routes/choose-wonder', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		isSecure: false,
		docTitle: 'Choose Wonder',

		baseRouteViewSettings: {
			visibleContent: 'choose-wonder',
			//activeNavigationItem: 'upload',
			templates: {
				bottom: {
					name: 'choose-wonder',
					outlet: 'bottom',
					height: 290,
					parent: 'mosaic',
					resizeTilezoom: 'soft',
					scrollable: '-buttonarea'
				}
			},
			showContent: true
		},

		beforeModel: function beforeModel() {
			var applicationController = this.controllerFor('application');
			var r = applicationController.getActiveUploadStep();
		},

		/**
	  * load mosaic by id
	  *
	  * @param params
	  * @param transition
	  * @returns {*}
	  */
		model: function model(params, transition) {

			var me = this;

			return me.store.find('mosaic', transition.params.mosaic.mosaic_Id).then(function (mosaic) {
				return mosaic;
			}, function () {
				me.transitionTo('start');
				return null;
			});
		},

		/**
	  * Refers to BaseView._lookupsettings
	  * Usally _lookupsettings returns just the baseRouteViewSettings but
	  * if getBaseRouteViewSettings is defined you may use this function
	  * to set baseRouteViewSettings dynamically
	  *
	  * @returns {baseRouteViewSettings|{visibleContent, templates, showContent}|{visibleContent, activeNavigationItem, templates, showContent}}
	  */
		getBaseRouteViewSettings: function getBaseRouteViewSettings() {
			var me = this,
			    orientation = UTIL.getOrientation(),
			    settings = $.extend(true, {}, me.baseRouteViewSettings);

			switch (orientation) {
				case 'landscape':
					settings.templates.bottom.height = 275;
					settings.templates.bottom.width = 900;
					settings.templates.bottom.isOverlay = true;
					break;
			}

			return settings;
		},

		afterModel: function afterModel() {
			this.updateBaseView();
		},

		renderTemplate: function renderTemplate(controller, model) {

			this.render({
				into: 'mosaic',
				outlet: 'bottom'
			});
		}

	});

});