define('wow-app/views/about', ['exports', 'ember', 'wow-app/views/base-routing-view'], function (exports, Ember, BaseRoutingView) {

	'use strict';

	exports['default'] = BaseRoutingView['default'].extend({

		classNames: ['hideOnLoad', 'view-about'],

		afterShowCSSTransition: function afterShowCSSTransition() {
			if (UTIL.videoPlayer.youtubeApiLoaded) {
				UTIL.addAllVideoPlayer();
			} else {
				UTIL.videoPlayer.loadAPI('youtube');
			}

			this.stopAllVideosOnTabClick();
			this.startVideoOnTabClick();
		},

		startVideoOnTabClick: function startVideoOnTabClick() {
			if (UTIL.user.osPC) {
				$('.view-about a[data-toggle="tab"]').on('click', function (e) {

					var video = $(e.target).attr('data-video');

					if (UTIL.videoPlayer.players[video] && typeof UTIL.videoPlayer.players[video].getPlayerState === 'function') {
						if (UTIL.videoPlayer.players[video].getPlayerState() !== 1) {
							UTIL.stopAllVideoPlayer();
							UTIL.videoPlayer.youtube.play(video);
						}
					}
				});
			}
		},

		stopAllVideosOnTabClick: function stopAllVideosOnTabClick() {
			$('.view-about a[data-toggle="tab"]').on('hide.bs.tab', function (e) {
				UTIL.stopAllVideoPlayer();
			});
		},

		willClearRender: function willClearRender() {
			$("#bgArena").unbind("click");
			$('.view-about a[data-toggle="tab"]').unbind("click");
			UTIL.videoPlayer.players['yt_shortinfovideo'] = null;
			UTIL.videoPlayer.players['yt_mediuminfovideo'] = null;
			UTIL.videoPlayer.players['yt_extendedinfovideo'] = null;
		}

	});

});