define('wow-app/views/social-photo-list-modal', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].View.extend({

		classNames: 'view-social-photo-list',

		didInsertElement: function didInsertElement() {
			// set title and icon?
			this.setTitle();
			this.buildList();
		},

		setTitle: function setTitle() {
			var me = this,
			    controller = me.get('controller'),
			    $element = me.$();

			var type = controller.get('type');

			if (type) {
				var title = $element.find('.modal-title');
				title.get(0).innerHTML = '<i class="icon-' + type + '" />' + UTIL.tracker.capitalize(type);
			}
		},

		buildList: function buildList() {

			var me = this,
			    controller = me.get('controller'),
			    $element = me.$(),
			    n;

			controller.set('selectedPhoto', null);
			var pagingIdx = controller.get('pagingIdx');
			var pagingObj = controller.get('pagingObj.' + pagingIdx);

			if (pagingObj) {
				n = pagingObj;
			} else {
				n = controller.normalizeData();
				controller.set('pagingObj.' + pagingIdx, n);
			}

			var model = n.data;
			var paging = n.paging;

			var photoListContainer = $element.find('.social-photo-list-modal');
			photoListContainer.empty();

			for (var i = 0; i < model.length; i++) {
				var m = model[i];

				var baseId = m.id; //m.get('id')

				var con = $('<a>', {
					'class': 'social-pic loading-animation',
					href: '#',
					id: 'con' + baseId,
					'data-id': baseId
				}).appendTo(photoListContainer);

				var spin = $('<i>', {
					'class': 'icon-spin4 animate-spin',
					id: 'spin' + baseId
				}).appendTo(con);
			}

			var loadImage = function loadImage(m) {
				//var m = model[i];
				var baseId = m.id; //m.get('id')
				var img = new Image();

				img.onload = function () {
					$('#spin' + baseId).remove();
					var con = $('#con' + baseId);
					con.css({
						backgroundImage: 'url(' + this.src + ')'
					});
					con.on('click', function (e) {
						e.preventDefault();
						photoListContainer.find('a').removeClass('active');
						$(this).addClass('active');
						controller.set('selectedPhoto', m);
					});
				};

				img.onerror = function () {
					$('#con' + baseId).remove();
				};

				img.src = m.picture;
			};

			for (var ix = 0; ix < model.length; ix++) {
				loadImage(model[ix]);
			}

			this.updatePaging(paging);
		},

		updatePaging: function updatePaging(paging) {

			var controller = this.get('controller');
			var pagingIdx = controller.get('pagingIdx');
			var lastPagingObj = controller.get('pagingObj.' + (pagingIdx - 1));
			var nextPagingObj = controller.get('pagingObj.' + (pagingIdx + 1));

			if (paging && paging.next || nextPagingObj) {
				controller.set('hasNext', true);
				controller.set('nextUrl', paging.next);
			} else {
				controller.set('hasNext', false);
				controller.set('nextUrl', null);
			}

			if (paging && paging.previous || lastPagingObj) {
				controller.set('hasPrevious', true);
				controller.set('previousUrl', paging.previous);
			} else {
				controller.set('hasPrevious', false);
				controller.set('previousUrl', null);
			}
		},

		loadDataP: function loadDataP(url) {
			var me = this;
			var controller = this.get('controller');
			var uploadController = this.get('controllers.upload');

			$.ajax({
				method: "GET",
				url: url,
				dataType: "jsonp",
				jsonp: "callback",
				jsonpCallback: "jsonpcallback",
				success: function success(response) {

					if (response && response.meta && response.meta.code !== 200) {
						uploadController.raiseError(null, uploadController.getErrorMsg('noPhotosError'));
					} else if (response && response.data && response.data.length > 0) {
						controller.set('activeUrl', url);
						controller.set('model', response);
						me.buildList();
					} else {
						uploadController.raiseError(null, uploadController.getErrorMsg('noPhotosError'));
					}
				},
				error: function error(jqXHR, textStatus, errorThrown) {
					if (typeof console === 'object') {
						console.log('Error.getInstgramData with ', jqXHR, textStatus, errorThrown);
					}
				}
			});
		},

		willDestroyElement: function willDestroyElement() {

			// Reset all:
			var controller = this.get('controller');
			controller.set('type', null);
			controller.set('selectedPhoto', null);
			controller.set('hasNext', null);
			controller.set('hasPrevious', null);
			controller.set('nextUrl', null);
			controller.set('previousUrl', null);
			controller.set('activeUrl', null);
			controller.set('pagingObj', []);
			controller.set('pagingIdx', 0);
		},

		actions: {

			next: function next() {

				var controller = this.get('controller');

				if (!controller.get('hasNext')) {
					return false;
				} else {
					var pagingIdx = controller.get('pagingIdx');
					pagingIdx = pagingIdx + 1;
					controller.set('pagingIdx', pagingIdx);
					var pagingObj = controller.get('pagingObj.' + pagingIdx);
					if (pagingObj) {
						controller.set('model', pagingObj);
						this.buildList();
					} else {
						var nextUrl = controller.get('nextUrl');
						controller.set('activeUrl', nextUrl);
						this.loadDataP(nextUrl);
					}
				}
			},

			previous: function previous() {

				var controller = this.get('controller');

				if (!controller.get('hasPrevious')) {
					return false;
				} else {

					var pagingIdx = controller.get('pagingIdx');
					pagingIdx = pagingIdx - 1;
					controller.set('pagingIdx', pagingIdx);
					var pagingObj = controller.get('pagingObj.' + pagingIdx);
					if (pagingObj) {
						controller.set('model', pagingObj);
						this.buildList();
					} else {
						var previousUrl = controller.get('previousUrl');
						controller.set('activeUrl', previousUrl);
						this.loadDataP(previousUrl);
					}
				}
			}
		}
	});

});