define('wow-app/views/splash', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].View.extend({

		classNames: ['splash-screen'],

		/**
	  * component wonderlock options
	  */
		wonderlock: {
			//isOpen: false,
			size: 'big',
			hasActions: false,
			uploadPhoto: false,
			disableActions: true,
			isPreview: true
		},

		/**
	  * Init carousel
	  */
		didInsertElement: function didInsertElement() {
			var me = this,
			    $element = me.$();

			var settings = {
				pagination: '.swiper-pagination',
				// Navigation arrows
				paginationClickable: true
			};

			if (!UTIL.isTouchSupported()) {
				settings.prevButton = '.swiper-button-prev';
				settings.nextButton = '.swiper-button-next';
			} else {
				$('.swiper-button-prev').css('display', 'none');
				$('.swiper-button-next').css('display', 'none');
			}

			this.swiper = new Swiper('.splash-screen .swiper-container', settings);
		},

		destroySwiper: function destroySwiper() {

			var me = this,
			    $element = me.$();

			if (!$element) {
				return false;
			}

			if (this.swiper) {
				this.swiper.destroy();
			}
		},

		/**
	  * Called when the element of the view is going to be destroyed.
	  * Override this function to do any teardown that requires an element, like removing event listeners.
	  */
		willDestroyElement: function willDestroyElement() {
			this.destroySwiper();
		}

	});

});