define('wow-app/controllers/my-wows', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'tilezoom', 'error-modal'],

		userId: (function () {
			var userId = null;
			var applicationController = this.get('controllers.application');
			var user = applicationController.get('user');
			if (user) {
				userId = user.get('id');
			}
			return userId;
		}).property('controllers.application.user.id'),

		MyWOWs: (function () {
			return this.get('model.length');
		}).property(),

		actions: {
			getPhoto: function getPhoto(m) {
				var tilezoomController = this.get('controllers.tilezoom');

				var e = {
					custom: true,
					coordinates: {
						x: m.get('targetPic.x'),
						y: m.get('targetPic.y')
					},
					target: m.get('targetPic.target')
				};

				tilezoomController.getTilephoto(e);
			}
		}

	});

});