define('wow-app/controllers/choose-wonder', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'mosaic', 'tilezoom'],

		//langChanged: function () {
		//	var applicationController = this.get('controllers.application');
		//	if(typeof console === 'object') { console.log('ChooseWonderController.langChanged', applicationController.get('model.lang')); }
		//	return applicationController.get('model.lang');
		//}.property('controllers.application.model.lang'),

		/**
	  * get visible wonder inside mosaic
	  * @returns {*}
	  */
		getWonder: function getWonder() {
			return this.get('controllers.tilezoom').getActiveWonder();
		},

		/**
	  * move to specific wonder id
	  * @param wonderId
	  * @returns {*}
	  */
		gotoWonder: function gotoWonder(wonderId) {
			return this.get('controllers.tilezoom').gotoWonder(wonderId, false);
		},

		saveWonderId: function saveWonderId(wonderId) {
			var applicationController = this.get('controllers.application');
			applicationController.setWonder(false, wonderId);
		}

	});

});