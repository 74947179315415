define('wow-app/locales/ar', ['exports', 'wow-app/locales/en'], function (exports, EN) {

    'use strict';

    var AR = {
        "about": {
            "sub_title": "اترك بصمتك!<br />انضم إلى عجائب الدنيا السبع!<br />كن هناك!",
            "text": "اختار <p> مئات الملايين من الأشخاص تطبيق عجائب الدنيا السبع, ويمكنك الآن أن تصبح جزءًا منه من خلال تحميل صورتك عليه ضمن إحدى العجائب .</p><p> بادر باختيار أعجوبتك, وحمل صورتك, وشاركها مع الجميع, <br />حقا أنت الآن عضو في <a href=\"https://twitter.com/hashtag/wonderswall\" target=\"_blank\">#wonderswall</a>!!!</p>>"
        },
        "buttons": {
            "cancel": "إلغاء",
            "change_photo": "غيّر صورة",
            "change_wall": "غيّر حائط",
            "change_wonder": "غيّر أعجوبة",
            "choose_wonder": "اختر أعجوبة",
            "close": "إغلاق",
            "crop_and_save": "قص صورة واحفظها",
            "crop_photo": "قص صورة",
            "join": "الدخول",
            "log_out": "تسجيل الخروج",
            "ok": "موافق",
            "register": "تسجيل",
            "save": "حفظ",
            "select": "تحديد",
            "share": "مشاركة",
            "sign_in": "تسجيل الدخول",
            "sign_up": "الاشتراك",
            "upload": "حمّل صورة",
            "copy": "نسخة",
            "yes": "نعم!",
            "edit": "Edit",
            "forget_password": "نسيت كلمة المرور؟"
        },
        "congratulation": {
            "sub_title": "أنت الآن عضو في <br>عجائب الدنيا السبع",
            "title": "تهانينا"
        },
        "global": {
            "error_title": "سري",
            "next": "التالي",
            "previous": "السابق",
            "title": "عجائب الدنيا السبع"
        },
        "joyscreen": {
            "join": "مرحبا بك في <span class=\"break-portrait break-landscape\"></span>عجائب الدنيا السبع",
            "title": "\nهل ترغب في البقاء متصلا على <span class=\"break-portrait break-landscape\"></span> عجائب الدنيا السبع؟",
            "welcome": "مرحبا بك في<span class=\"break-portrait break-landscape\"></span> عجائب الدنيا السبع"
        },
        "mosaic": {
            "manmade": {
                "title": "عجائب الدنيا السبع الجديدة"
            },
            "nature": {
                "title": "عجائب الدنيا السبع الجديدة الطبيعية"
            }
        },
        "my_photo": {
            "my_wonder": "أعجوبتي",
            "no_login": "لم تسجل دخولك بعد! سجل دخولك لتبق على اتصال بحائط العجائب.",
            "no_photo": "تعذر تحميل أي صورة حتى الآن...",
            "status": "الحالة",
            "sub_title": "غيّر صورة أو أعجوبة",
            "title": "صورتي",
            "uploaded": "تم التحميل",
            "motto": "Say something",
            "motto_placeholder": "Tell us about your photo",
            "location": "Location",
            "city": "City",
            "country": "Country",
            "ping": "WOW!",
            "my_wows": "My WOWs",
            "wowed_by": "WOWed by",
            "no_wows": "No WOWs yet."
        },
        "register": {
            "email": "البريد الإلكتروني",
            "logged_in_as": "تسجيل الدخول باسم",
            "password": "كلمة المرور",
            "sign_in_email": "تسجيل الدخول عن طريق البريد الإلكتروني",
            "sign_up_email": "الاشتراك عن طريق البريد الإلكتروني"
        },
        "seo": {
            "meta_description": "اختار مئات الملايين من الأشخاص New7Wonders, ويمكنك الآن أن تصبح جزء منه."
        },
        "settings": {
            "change_language": "اللغة",
            "email": "البريد الإلكتروني",
            "facebook": "فيسبوك",
            "login": "تسجيل الدخول",
            "new_to_wall": "هل سبق لك الانضمام إلى عجائب الدنيا السبع؟",
            "on_the_wall": "هل لديك حساب بالفعل على عجائب الدنيا السبع؟",
            "signin": "الاشتراك",
            "title": "الإعدادات",
            "twitter": "تويتر",
            "upload_photo": "حمّل صورة"
        },
        "share": {
            "fb_caption": "أنا الآن عضو في عجائب الدنيا السبع.",
            "fb_description": "انضموا إليّ! حمل صورتك على موقع www.wonderswall.com وستصبح أنت الأعجوبة! #wonderswall",
            "fb_name": "عجائب الدنيا السبع",
            "fb_published": "تم عرض منشورك.",
            "sub_title": "شارك صورتك مع أصدقائك,<br>واصحبهم إلى عجائب الدنيا السبع",
            "title": "مشاركة",
            "whatsapp": "واتس آب",
            "link": "رابط",
            "email_from": "من",
            "email_to": "إلى",
            "email_subject": "الموضوع",
            "email_text": "نصك",
            "email_to_placeholder": "افصل العناوين المختلفة بفاصلة",
            "email_successfully_send": "تم إرسال البريد الإلكتروني بنجاح"
        },
        "stay": {
            "accept": "الموافقة على الشروط والأحكام",
            "title": "ابق على اتصال <span class=\"break-portrait\"></span>بحائط العجائب",
            "privacy_policy": "سياسة الخصوصية"
        },
        "terms": {
            "accept": "برجاء الموافقة على الشروط والأحكام",
            "title": "الشروط والأحكام"
        },
        "top": {
            "title": "تم تحميل معظمها"
        },
        "upload": {
            "image_error": "تعذر تحميل الصورة, برجاء العودة وتحميلها مجددا",
            "import_fb": "فيسبوك",
            "import_insta": "إنستغرام",
            "import_my_photos": "صورك",
            "import_tw": "تويتر",
            "import_webcam": "كاميرا ويب",
            "sub_title": "أو اختر من أسفل",
            "take_photo": "التقط صورة",
            "title": "التقط صورة",
            "upload_error": "حدوث خطأ أثناء تحميل صورتك",
            "upload_save_error": "تعذر حفظ الصورة, برجاء العودة وتحميلها مجددا",
            "webcam_allow_error": "انقر فوق \"سماح\" لالتقاط صورة",
            "webcam_error": "كاميرا ويب الخاصة بك غير متوافقة مع متصفحك, لذا يرجى تحديث برنامجك"
        },
        "upload_choose": {
            "change_title": "غيّر أعجوبتك",
            "sub_title": "وحمل صورتك عليها",
            "title": "اختر أعجوبتك"
        },
        "upload_steps": {
            "1": "الخطوة 1",
            "2": "الخطوة 2",
            "3": "الخطوة 3"
        },
        "wall": {
            "upload_photo": "حمل صورتك على عجائب الدنيا السبع"
        },
        "wonders": {
            "amazon": {
                "location": "أمريكا الجنوبية",
                "title": "الأمازون"
            },
            "christ_redeemer": {
                "location": "البرازيل",
                "title": "المسيح الفادي"
            },
            "colosseum": {
                "location": "إيطاليا",
                "title": "الكولوسيوم"
            },
            "great_wall_of_china": {
                "location": "الصين",
                "title": "السور العظيم"
            },
            "ha_long_bay": {
                "location": "فيتنام",
                "title": "خليج هالونج"
            },
            "iguazu_falls": {
                "location": "الأرجنتين/البرازيل",
                "title": "شلالات إجوازو"
            },
            "jeju_island": {
                "location": "كوريا الجنوبية",
                "title": "جزيرة جيجو"
            },
            "komodo": {
                "location": "اندونيسيا",
                "title": "جزيرة كومودو"
            },
            "machu_pichu": {
                "location": "بيرو",
                "title": "ماتشو بيتشو"
            },
            "petra": {
                "location": "الأردن",
                "title": "بترا"
            },
            "pp_underground_river": {
                "location": "الفلبين",
                "title": "نهر بورتو برنسيسا الجوفي"
            },
            "pyramid_at_chichen_itza": {
                "location": "المكسيك",
                "title": "تشيتشن إيتزا"
            },
            "table_mountain": {
                "location": "جنوب أفريقيا",
                "title": "جبل تيبل"
            },
            "taj_mahal": {
                "location": "الهند",
                "title": "تاج محل"
            }
        },
        "oauth_errors": {
            "login_failed": "Log in via social network failed or was cancelled."
        }
    };

    AR = $.extend(true, {}, EN['default'], AR);

    exports['default'] = AR;

});