define('wow-app/templates/privacy-policy/fr', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Dernière mise à jour : novembre 2015");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("NewOpenWorld Corporation (« nous » ou « notre ») exploite ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","//www.wonderswall.com");
        var el4 = dom.createTextNode("www.wonderswall.com");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (le « Site »).");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Sur cette page, nous vous informons de nos politiques relatives à la collecte, l'utilisation et la divulgation des informations personnelles fournies par les utilisateurs via le Site. Vos informations personnelles sont utilisées exclusivement aux fins de gestion et d'amélioration du Site. En utilisant le Site, vous acceptez la collecte et l'utilisation d'informations aux fins indiquées dans notre politique de confidentialité.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Collecte et utilisation des informations");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Lors de votre visite sur notre Site, nous pourrons vous demander de nous fournir certains renseignements personnels d'identification pouvant être utilisées pour vous contacter ou pour vous identifier. Les renseignements personnels d'identification recueillis peuvent inclure les éléments suivants, mais ne s'y limitent pas : votre nom (« informations personnelles »).");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Journal de données");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Comme de nombreux exploitants de sites, nous collectons des informations que votre navigateur envoie chaque fois que vous visitez notre Site (« journal de données »). Ce journal peut contenir des informations telles que votre adresse IP (« protocole Internet »), le type de navigateur, la version du navigateur, les pages que vous visitez sur notre Site, la date et l'heure de votre visite, le temps passé sur les pages consultées et d'autres statistiques. En outre, nous pouvons utiliser des services tiers pour collecter, surveiller et analyser ce journal de données.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Communications");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Nous pourrons utiliser vos informations personnelles pour vous envoyer des bulletins d'information, des documents publicitaires ou promotionnels et d'autres informations susceptibles de vous intéresser.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Cookies");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Un cookie est un fichier contenant une petite quantité de données, pouvant inclure un identifiant unique anonyme. Les cookies sont transmis à votre navigateur à partis d'un site Internet, puis stockés sur le disque dur de votre ordinateur ou téléphone. À l'instar de nombreux autres sites Internet, nous collectons des informations. Veuillez noter qu’en refusant les cookies, l’utilisation du Site s’en trouve limitée.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Sécurité");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("La sécurité de vos informations personnelles est une de nos préoccupations. Toutefois, aucune méthode de transmission sur Internet, ou méthode de stockage électronique, n'est complètement sûre. Nous nous efforçons d'utiliser des moyens commercialement acceptables pour protéger vos informations personnelles, mais nous ne pouvons pas garantir la sécurité absolue.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Modifications de la politique de confidentialité");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("La présente politique de confidentialité entre en vigueur à compter de la dernière mise à jour indiquée ci-dessus. Elle demeure en vigueur, sauf à l'égard des modifications à venir dans ses dispositions, qui prendront effet dès leur publication sur la présente page. Nous nous réservons le droit de mettre à jour et de modifier cette politique de confidentialité à tout moment. C'est pourquoi nous vous invitons à la consulter régulièrement. En poursuivant l'utilisation du Site après que des modifications aient été effectuées à la politique de confidentialité présente sur cette page, vous acceptez d’être lié par la politique de confidentialité nouvellement modifiée. Vous serez informé de toute modification importante apportée à la présente politique de confidentialité, soit par une communication envoyée en utilisant vos informations personnelles soit par notification placée en évidence sur le site.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Nous contacter");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Pour toute question au sujet de notre politique de confidentialité, n'hésitez pas à ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","mailto:support@wonderswall.com");
        var el4 = dom.createTextNode("nous contacter");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(".");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});