define('wow-app/templates/accept-terms/id', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Dengan mengunjungi situs web mobile, aplikasi, situs web (terkadang secara bersama-sama disebut sebagai wonderswall.com atau dikenal sebagai ‘situs web’ saja)\n\n        dan atau menggunakan fitur-fiturnya berarti Anda dianggap telah setuju untuk mematuhi syarat dan ketentuan berikut ini dalam kaitannya dengan situs web, layanannya, semua hal yang berkaitan dengannya.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Hak Cipta");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Hak Cipta 2014-2021, Global Platform Limited, (GPL), Dubai, United Emirat Arab.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Di bawah lisensi dari  NewOpenWorld Corporation.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Kontak: hubungi ( at ) wonderswall dot com");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Semua hak dilindungi undang-undang.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Agen Kekayaan Intelektual untuk Pemberitahuan dan bentuk komunikasi lainnya.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("SYARAT UTAMA BERKAITAN DENGAN PARTISIPASI ANDA DI WONDERSWALL.COM");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Mengunggah foto");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Bagian dari situs web akan mengizinkan Anda untuk mengirimkan foto karya Anda. Kam bermaksud menyaring secara ringkas mengenai kualitas konten dan kesesuaian bahan\n        untuk memastikan bahwa situs ini akan tetap layak bagi siapa pun yang ingin menikmatinya, namun kami tetap tidak bisa bertanggung jawab sekiranya kelak muncul masalah berkaitan dengan konten.\n        Anda harus menjamin bahwa bahan yang Anda unggah tidak melanggar hak cipta atau hak lainnya milik orang pihak ketiga atau organisasi mana pun.\n        Jangan sampai Anda mengirim bahan milik orang lain mana pun yang tidak Anda miliki izinnya untuk menggunakannya.\n        GPL dengan ini menyanggah untuk bertanggung jawab atas bahan-bahan yang dimiliki oleh pihak ketiga mana pun; GPL juga tidak dapat bertangung jawab kepada Anda atau pihak ketiga mana pun\n        untuk segala bentuk kerugian (baik kerugian yang telah atau dapat diramalkan, atau yang telah diketahui sebelumnya atau apa pun) termasuk namun tidak terbatas pada:\n        kehilangan data; kehilangan pendapatan; kehilangan bisnis; kehilangan kesempatan; kerugian tidak langsung, konsekuensial, khusus, atau tipikal yang muncul\n        dari penggunaan situs web sebagai akibat dari kesengajaan Anda atau ketidaktahuan Anda dalam menyediakan konten milik pihak ketiga kepada GPL.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Kepemilikan konten yang diunggah");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Ketika Anda mengunggah foto ke situs web, berarti Anda dianggap setuju, dengan pengiriman kontribusi Anda tersebut, dan berarti Anda harus menyerahkan kepada GPL, yaitu hak atau lisensi yang dapat disublisensikan yang bersifat abadi, bebas royalti, non-eksklusif,\n        untuk menggunakan, mereproduksi, memodifikasi, mengadaptasi, menerbitkan, menerjemahkan, menciptakan karya turunan dari, mendistribusikan, menjalankan, memutar,\n        menyediakan untuk umum, dan melaksanakan semua hak cipta dan hak untuk mempublikasikan kontribusi Anda di seluruh dunia dan / atau untuk mewnggabungkan kontribusi Anda\n        dengan karya lainnya di media yang sudah dikenal sekarang atau yang akan dikembangkan di kemudian hari untuk jangka penuh untuk hak mana pun yang mungkin ada dalam kontribusi Anda.\n        Jika Anda tidak ingin menyerahkan hak sebagaimana dijelaskan di atas kepada GPL, maka janganlah mengunggah foto karya Anda.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Foto yang dipajang di wonderswall.com");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL memegang hak untuk menerima atau menolak foto apa pun. Posisi foto mana pun mungkin akan diubah kapan saja untuk memastikan tampilan gambar yang optimal\n        dari hasil penataan oleh wonders, atau untuk alasan lain yang ditentukan oleh GPL. Untuk menghasilkan tampilan gambar yang optimal,\n        GPL mungkin perlu, atas diskresinya sendiri, menambahkan foto lain yang sudah diperolehnya.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Perilaku etis dan bermoral");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Anda tidak boleh menggunakan situs web untuk tujuan berikut ini: (i) Menyebarkan materi yang dianggap melanggar hukum, melecehkan, berisi fitnah, kasar, mengancam,\n        merbahayakan, vulgar, cabul, atau tidak pantas atau melanggar hukum; (ii) Mengirimkan materi yang mendukung perilaku\n        yang dianggap tindak kriminal, melanggar hukum perdata atau pidana, peraturan atau kode etik yang berlaku dalam\n        yurisdiksi hukum Anda yurisdiksi di tempat lain; (iii) Mengganggu penggunaan situs web oleh orang lain atau kenyamanan orang lain dalam menggunakan situs web; atau membuat, menyebarluaskan atau menyimpan salinan elektronik\n        file yang dilindungi hak cipta tanpa seizin pemiliknya. Anda akan bertanggung jawab atas kehilangan dan kerugian yang diakibatkan dari\n        pelanggaran yang Anda lakukan. Kami memegang hak untuk menghapus materi apa pun yang kami pandang layak untuk dilakukan dan memenuhi diskresi mutlak kami sendiri.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("SYARAT DAN KETENTUAN UMUM LAINNYA");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Hargai hak cipta dan hak kekayaan intelektual orang lain serta konvensi yang berlaku");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL menghargai kekayaan intelektual orang lain, dan kita mengharapkan pengguna situs kami juga melakukan hal yang sama. GPL dapat, dalam situasi dan diskresi yang sesuai, menonaktifkan dan/atau menghapus akun milik pengguna yang melanggar hak kekayaan intelektual milik GPL maupun orang lain. Jika Anda yakin bahwa hasil karya Anda telah disalin sehingga melanggar hak cipta, atau hak kekayaan intelektual Anda telah dilanggar, silakan menghubungi kami.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Merek dagang New7Wonders");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("New7Wonders, dan logo New7Wonders yang lain, serta produk dan nama layanan New7Wonders merupakan merek dagang terdaftar dari New Open World Corporation, dalam hal ini diwakili oleh GPL. Tanpa seizin sebelumnya dari GPL, Anda setuju untuk tidak menampilkan atau menggunakan, merek dagang New7Wonders dengan cara apa pun. Penggunaan dan partisipasi Anda di situs web kami menegaskan bahwa Anda telah setuju untuk tidak menyalin, mereproduksi, menerbitkan ulang, mengunduh, memposting, menyiarkan, mengirim, atau membuat tersedia bagi publik, atau menggunakan dengan cara apa pun di media lain apa pun di situs web lain mana pun konten New7Wonders lainnya dengan cara apa pun dan kecuali untuk keperluan non-komersial yang bersifat pribadi. Anda setuju untuk tidak meniru, mengubah atau membuat karya turunan dari situs web atau konten dari New7Wonders lainnya kecuali untuk tujuan non-komersial yang bersifat pribadi. Penggunaan situs web lainnya apa pun atau konten dari New7Wonders membutuhkan izin tertulis sebelumnya dari GPL.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Privasi");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL menganggap serius privasi Anda. Karena kami perlu mengumpulkan beberapa informasi tentang diri pengguna, maka kami ingin membantu Anda memahami syarat dan ketentuan seputar pengumpulan dan penggunaan informasi tersebut. Jika Anda telah mengizinkan GPL untuk menghubungi Anda untuk kepentingan pembaruan informasi dan komunikasi lainnya, data tentang diri Anda mungkin akan ditempatkan di database GPL. GPL dapat menggunakan beberapa perangkat standar web, seperti misalnya gambar elektronik, untuk melacak pola lalu lintas pengguna umum. GPL juga dapat menerima dan merekam informasi otomatis dari server dan browser yang Anda gunakan, termasuk alamat IP, waktu, dan informasi tentang halaman yang Anda minta. Seperti halnya situs web pada umumnya, GPL juga dapat menerima informasi tentang diri Anda dari sumber lain dan menambahkannya ke dalam informasi yang telah Anda berikan kepada kami. GPL menggunakan informasi yang diperoleh untuk empat tujuan umum: 1. Untuk mengkustomisasi iklan dan konten yang Anda baca; 2. Untuk meningkatkan pengalaman pengguna di jaringan situs kami. Kami menggunakan informasi pelacakan untuk menentukan seberapa baik performa dari setiap halaman berdasarkan demografi sekumpulan pengguna dan pola lalu lintas yang mengarah ke halaman tersebut. Ini akan membantu kami untuk terus membangun pelayanan yang lebih baik untuk Anda; 3. Untuk memenuhi permintaan Anda terhadap beberapa produk dan jasa tertentu di masa mendatang, seperti misalnya pengiriman suratkabar elektronik dan perizinan kepada pengguna untuk ikut serta dalam polling, lomba, papan pesan, dan lelang; 4. Untuk mengirimi Anda informasi yang telah Anda sepakati untuk diterima mengenai topik-topik yang menurut kami menarik bagi Anda.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Hukum negara");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Mengenali kebiasaan umum di Internet, Anda setuju untuk mematuhi semua hukum, peraturan dan konvensi setempat dan negara yang terkait dengan perilaku dan konten online yang dapat diterima. Khususnya, Anda setuju untuk mematuhi semua hukum yang berlaku terkait dengan pengiriman data teknis yang dikirim dari negara tempat tinggal Anda.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Ganti rugi");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Anda bersedia mengganti rugi dan menjaga agar GPL, dan anak perusahaan, afiliasi, pejabat, agen, mitra-merek atau rekan lainnya, dan karyawannya, tetap dijauhkan dari segala klaim atau tuntutan, termasuk biaya pengacara, yang dibuat oleh pihak ketiga yang muncul akibat tulisan yang Anda serahkan, posting, kirim atau sediakan melalui layanan, penggunaan Anda di layanan, koneksi Anda ke layanan, pelanggaran Anda atas Syarat dan Ketentuan atau pelanggaran yang Anda lakukan terhadap hak orang lain.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Tidak ada penjualan-ulang jasa");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Anda setuju untuk tidak mereproduksi, menduplikasi, menyalin, menjual, menjual-kembali atau mengeksploitasi untuk tujuan komersial apa pun, bagian mana pun dari layanan, penggunaan layanan, atau akses ke layanan.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Modifikasi terhadap situs web");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL kapan pun dan dari waktu ke waktu berhak untuk mengubah atau menghentikan, baik semnatara maupun selamanya, situs web (atau bagian mana pun darinya) dengan atau tanpa pemberitahuan. Anda setuju bahwa GPL tidak boleh menyerahkan tanggung jawab kepada Anda atau pihak ketiga mana pun untuk segala bentuk modifikasi, penangguhan atau pemberhentian situs web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Tautan");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Situs web mungkin menyediakan, atau pihak ketiga mungkin menyediakan, tautan ke situs World Wide Web atau sumberdaya yang lain. Karena GPL tidak punya kendali atas situs dan sumberdaya tersebut, maka Anda akan dianggap mengetahui dan setuju bahwa GPL tidak bertanggung jawab atas ketersediaan situs atau sumberdaya eksternal tersebut, serta tidak mendukung dan tidak bertanggung jawab atas segala konten, iklan, produk, atau materi lainnya yang tersedia pada situs atau sumberdaya tersebut. Anda selanjutnya mengetahui dan setuju bahwa GPL tidak akan bertanggung jawab, baik langsung maupun tidak langsung, atas segala kerusakan atau kehilangan yang disebabkan oleh atau yang berkaitan dengan penggunaan atau kebergantungan pada konten, barang atau layanan yang tersedia di dalam situs atau sumberdaya mana pun.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Kepemilikan data");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Data apa pun, seperti misalnya, tentang partisipasi di WondersWall, yang mungkin ditampilkan atau diterbitkan olah GPL adalah hak milik di bawah hak cipta GPL. Peraturan khusus tentang pengikatan menjelaskan penggunaan data ini. Media berita: Gerai media berita boleh mereproduksi data ini hanya dalam konteks berita saja, dengan syarat teks berikut ini harus selalu ditunjukkan secara tertulis: Data © www.wonderswall.com. Penggunaan lain: Penggunaan lain apa pun oleh organisasi, perusahaan, situs web akan tunduk pada lisensi dan persetujuan dari GPL. Hubungi kami untuk informasi selengkapnya.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Sanggahan layanan teknis");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Konten situs web, termasuk informasi, nama, foto, gambar dan logo yang terkait New7Wonders dan WondersWall atau layanannya, tersedia “sebagaimana adanya” dan “sebagaimana tersedia” tanpa harus menyatakan garansi apa pun (baik yang tersirat maupun tersurat oleh hukum) sejauh diizinkan oleh hukum, termasuk menyangkut tidak adanya pelanggaran terhadap hak cipta, kesesuaian, keamanan dan akurasi konten yang dimaksud. GPL tidak menjamin bahwa fungsi yang terdapat dalam konten situs web akan tanpa penundaan atau tanpa gangguan atau bahwa foto atau transfer lainnya akan akurat atau lengkap, bahwa kalau ada kecacatan akan dikoreksi, atau bahwa situs web atau server akan terbebas dari virus atau bug atau materi berbasis internet pengganggu lainnya apa pun. Dalam keadaan apa pun GPL tidak akan bertanggung jawab atau menyerahkan tanggung jawab kepada Anda atau pihak ketiga untuk segala bentuk kerugian (baik kerugian yang telah atau dapat diramalkan sebelumnya, atau yang telah diketahui sebelumnya atau apa pun) termasuk namun tidak terbatas pada: kehilangan data; kehilangan pendapatan; kehilangan bisnis; kehilangan kesempatan; kerugian tidak langsung, konsekuensial, khusus, atau tipikal yang muncul dari penggunaan situs web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Sanggahan garansi");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Anda secara tertulis memahami dan setuju bahwa: penggunaan situs web merupakan risiko Anda sendiri. situs web tersedia “sebagaimana adanya” dan “sebagaimana tersedia”. GPL secara tertulis menyanggah semua bentuk garansi apa pun, baik tersirat maupun tersurat, termasuk, namun tidak terbatas pada, garansi tersurat mengenai kesesuaian untuk diperjualbelikan, kesesuaian untuk tujuan tertentu atau pelanggaran terhadap hak cipta. GPL tidak memberikan jaminan bahwa (i) situs web akan sesuai dengan harapan Anda, (ii) pengelolaan situs web tidak akan terganggu, tepat waktu, aman, atau terbebas dari kesalahan, (iii) hasil yang diperoleh dari penggunaan situs web akan akurat atau dapat diandalkan, (iv) kualitas dari produk, layanan, informasi, atau materi lainnya yang Anda beli atau Anda peroleh melalui situs web akan sesuai harapan, dan (v) kesalahan apa pun yang terdapat pada perangkat lunak akan diperbaiki. Dokumen yang diunduh atau diperoleh dari penggunaan situs web, sepanjang diizinkan, dilakukan atas diskresi dan risiko Anda sendiri, dan Anda merupakan satu-satunya pihak yang bertanggung jawab atas kerusakan sistem komputer milik Anda atau kehilangan data Anda yang diakibatkan dari pengunduhan dokumen apa pun tersebut.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Pembatasan tanggung jawab");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Anda secara tertulis memahami dan setuju bahwa GPL tidak akan bertanggung jawab atas segala kerusakan baik yang bersifat langsung, tidak langsung, insidental, khusus, konsekuensial atau tipikal, termasuk namun tidak terbatas pada, kerusakan atas kehilangan keuntungan, niat baik, penggunaan, data atau kerugian berwujud lainnya (bahkan ketika GPL telah disarankan akan adanya kemungkinan kerusakan tersebut), sebagai akibat dari: (i) penggunaan atau ketidakmampuan untuk menggunakan situs web; (ii) biaya pembelian barang dan jasa yang muncul dari pembelian atau pemerolehan barang, data, informasi atau layanan, pesan yang diterima atau transaksi yang dimasukkan ke atau dari situs web; (iii) akses tak sah ke atau perubahan pada transmisi atau data Anda; (iv) pernyataan atau perilaku dari pihak ketiga mana pun di situs web; atau (v) masalah lainnya apa pun terkait dengan situs web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Usia pengguna");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Kami percaya bahwa wonderswall.com terbuka untuk umum dan dari segala usia. Namun, kami mengerti bahwa di beberapa negara dan budaya mungkin terdapat beberapa perbedaan hukum atau kerangka kerja lain terkait umur pengguna. Kami tidak dengan sadar mengumpulkan informasi pribadi dari seseorang yang dianggap di bawah umur (di negara mereka sendiri) di mana kondisi tersebut dilarang oleh hukum atau dengan sadar mengizinkan orang tersebut untuk mendaftar. Jika hukum di negara Anda melarang untuk Anda untuk mendaftar, maka Anda tidak boleh mendaftar ke situs web dan Anda juga tidak boleh mengirimkan informasi tentang diri Anda ke kami, termasuk, namun tidak terbatas pada, nama, alamat, nomor telepon, atau alamat email. Jika hukum negara mengharuskan Anda mendapatkan izin, maka silakan mencari izin dari orangtua/wali sebelum menyerahkan informasi pribadi tentang diri Anda ke situs web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Perubahan ketentuan");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL memegang hak mengubah syarat dan ketentuan ini kapan saja dengan mem-posting perubahan tersebut secara online. Silakan pelajari syarat ini secara berkala untuk memastikan bahwa Anda menyadari adanya perubahan yang dibuat oleh GPL. Penggunaan situs web secara kontinu, setelah dilakukan perubahan, menandakan bahwa Anda dianggap setuju untuk mematuhi syarat yang sudah diperbarui dan/atau diubah");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Undang-undang yang mengatur");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Syarat dan Ketentuan ini diatur dan ditafsirkan sesuai dengan Undang-Undang Canton of Zurich/Swiss.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});