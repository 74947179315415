define('wow-app/controllers/accept-terms-modal', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'error-modal'],

		termsTemplate: 'accept-terms/ar',

		lang: (function () {
			this.setLang();
		}).property('controllers.application.model.lang'),

		setLang: function setLang() {

			var applicationController = this.get('controllers.application');
			var lang = applicationController.get('model.lang');

			if (typeof lang !== 'undefined') {
				this.set('termsTemplate', 'accept-terms/' + lang);
			} else {
				this.set('termsTemplate', 'accept-terms/en');
			}
		},

		show: function show(p) {

			var me = this;
			var loadModal = function loadModal() {
				me.get('controllers.application').send('showModal', 'accept-terms-modal');
			};
			loadModal();
		}

	});

});