define('wow-app/routes/tilezoommap', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		docTitle: 'Wall',

		baseRouteViewSettings: {
			visibleContent: 'tilezoommap',
			templates: {
				bottom: {
					name: 'tilezoommap',
					outlet: 'bottom',
					height: 139,
					resizeTilezoom: 'soft',
					parent: 'mosaic'
				}
			},
			showContent: true
		},

		/**
	  * Refers to BaseView._lookupsettings
	  * Usally _lookupsettings returns just the baseRouteViewSettings but
	  * if getBaseRouteViewSettings is defined you may use this function
	  * to set baseRouteViewSettings dynamically
	  *
	  * @returns {baseRouteViewSettings|{visibleContent, templates, showContent}|{visibleContent, activeNavigationItem, templates, showContent}}
	  */
		getBaseRouteViewSettings: function getBaseRouteViewSettings() {
			var me = this,
			    tilezoomController = me.controllerFor('tilezoom'),
			    settings = $.extend(true, {}, me.baseRouteViewSettings);

			var overviewImageSize = tilezoomController.getOverviewImageSize();
			settings.templates.bottom.height = overviewImageSize.c;

			return settings;
		},

		beforeModel: function beforeModel() {
			this.updateBaseView();
		},

		renderTemplate: function renderTemplate() {

			this.render({
				into: 'mosaic',
				outlet: 'bottom'
			});
		}
	});

});