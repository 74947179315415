define('wow-app/templates/user-stay', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.0",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","textcontent");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","form-group");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3,"class","accept-terms");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4,"class","checkbox");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          var el6 = dom.createTextNode("\n	                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          dom.setAttribute(el5,"href","#accept-terms-modal");
          dom.setAttribute(el5,"style","text-decoration: underline;");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","import-list landscape-button-list");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5,"href","/account/facebook");
          var el6 = dom.createElement("i");
          dom.setAttribute(el6,"class","pull-right icon-right-open");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("i");
          dom.setAttribute(el6,"class","icon-left icon-facebook");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5,"href","/account/twitter");
          var el6 = dom.createElement("i");
          dom.setAttribute(el6,"class","pull-right icon-right-open");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("i");
          dom.setAttribute(el6,"class","icon-left icon-twitter-bird");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5,"class","list-action closeModal");
          dom.setAttribute(el5,"href","#sign-up");
          var el6 = dom.createElement("i");
          dom.setAttribute(el6,"class","pull-right icon-right-open");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("i");
          dom.setAttribute(el6,"class","icon-left icon-mail");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline, element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [5, 1, 1]);
          var element2 = dom.childAt(element1, [3]);
          var element3 = dom.childAt(element0, [7, 1]);
          var element4 = dom.childAt(element3, [1, 0]);
          var element5 = dom.childAt(element3, [3, 0]);
          var element6 = dom.childAt(element3, [5, 0]);
          var morph0 = dom.createMorphAt(element0,1,1);
          var morph1 = dom.createUnsafeMorphAt(dom.childAt(element0, [3]),0,0);
          var morph2 = dom.createMorphAt(dom.childAt(element1, [1]),1,1);
          var morph3 = dom.createMorphAt(element2,0,0);
          var morph4 = dom.createMorphAt(element4,2,2);
          var morph5 = dom.createMorphAt(element5,3,3);
          var morph6 = dom.createMorphAt(element6,2,2);
          inline(env, morph0, context, "upload-steps", [], {"active": "s3", "applicationController": get(env, context, "controllers.application")});
          inline(env, morph1, context, "t", ["stay.title"], {});
          inline(env, morph2, context, "input", [], {"type": "checkbox", "checked": get(env, context, "acceptedTerms"), "id": "accept-terms"});
          element(env, element2, context, "action", ["linkToModal", "accept-terms-modal"], {});
          inline(env, morph3, context, "t", ["stay.accept"], {});
          element(env, element4, context, "action", ["CheckTermsAndlinkToOAuth", "facebook", "user-stay"], {});
          inline(env, morph4, context, "t", ["settings.facebook"], {});
          element(env, element5, context, "action", ["CheckTermsAndlinkToOAuth", "twitter", "user-stay"], {});
          inline(env, morph5, context, "t", ["settings.twitter"], {});
          element(env, element6, context, "action", ["CheckTermsAndlinkToModal", "signup-email-modal"], {});
          inline(env, morph6, context, "t", ["settings.email"], {});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "scroll-content", [], {}, child0, null);
        return fragment;
      }
    };
  }()));

});