define('wow-app/views/change-position', ['exports', 'wow-app/views/base-routing-view'], function (exports, BaseRoutingView) {

	'use strict';

	//import Ember from 'ember';
	exports['default'] = BaseRoutingView['default'].extend({

		classNames: ['hideOnLoad', 'view-choose-wonder', 'view-change-position'],

		afterTileLoadedIdx: 0,

		setTilephotoClickMode: function setTilephotoClickMode(mode) {
			var controller = this.get('controller');
			controller.get('controllers.tilezoom').tilephotoClickMode = mode;
		},

		/**
	  * Callback Function from base-view before hiding transition
	  * In portrait mode: hide Steps, Title and Text and set height to the rest of the content
	  */
		onHideBottomContent: function onHideBottomContent(s, c, v) {

			var r = false;

			var me = this,
			    $element = me.$();

			var controller = v.get('controller');

			var orientation = UTIL.getOrientation();

			if (orientation === 'portrait') {
				var height = 190;
				var scrollWrapper = $element.find('.scrollWrapper');
				if (scrollWrapper && scrollWrapper.get(0)) {
					scrollWrapper.css('height', 'auto');
				}

				var ns = $.extend(true, {}, s, { height: height });
				v._showBottomContentNormalized(ns, c);
				return true;
			}

			return r;
		},

		/**
	  * Callback Function from base-view after show transition
	  */
		afterShowCSSTransition: function afterShowCSSTransition() {

			var me = this,
			    $element = me.$(),
			    controller = this.get('controller');

			if (!$element) {
				return false;
			}

			var tilezoomController = controller.get('controllers.tilezoom');
			var photo = tilezoomController.getUserPicture();

			tilezoomController.send('resize');
			this.setTilephotoClickMode('setPosition');

			// disable my photo placement
			tilezoomController.tilezoom._overwrite_afterTilesLoaded = function () {
				if (me.afterTileLoadedIdx === 0) {
					tilezoomController.tilezoom.cont.tilezoom('joyPlacement', photo);
					me.afterTileLoadedIdx++;
				}
			};
		},

		/**
	  * Called when the element of the view is going to be destroyed.
	  * Override this function to do any teardown that requires an element, like removing event listeners.
	  */
		willDestroyElement: function willDestroyElement() {
			var controller = this.get('controller'),
			    tilezoomController = controller.get('controllers.tilezoom');

			tilezoomController.tilezoom._overwrite_afterTilesLoaded = null;
			this.setTilephotoClickMode('viewPhoto');
		},

		/**
	  * Callback Function from base-view after show transition
	  */
		afterToggleShowCSSTransition: function afterToggleShowCSSTransition() {
			//if(typeof console === 'object') { console.log('PhotoCropView.afterToggleCSSTransition',true); }
		},

		actions: {

			/**
	   * change position of photo on mosaic
	   */
			changePosition: function changePosition() {
				var controller = this.get('controller'),
				    tilezoomController = controller.get('controllers.tilezoom'),
				    settings = tilezoomController.tilezoom.cont.data('tilezoom.settings');

				var lastPosition = settings.joy.lastClickCoodinates;

				// if(typeof console === 'object') { console.log('changePosition.settings',lastPosition,settings); }

				if (!lastPosition) {
					controller.get('controllers.error-modal').raise({
						statusText: 'Hint',
						statusCode: 301,
						message: 'Please select a new position' || controller.t('upload.upload_save_error')
					});
					return false;
				}

				this.get('parentView').set('hasLazyLoader', true);
				controller.send('changePosition', lastPosition, settings);
			}

		}

	});

});