define('wow-app/controllers/photo-crop', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'mosaic', 'tilezoom', 'error-modal'],

		getCropData: false,

		statusCls: (function () {
			return this.get('model').get('statusCls');
		}).property('model'),

		actions: {

			/**
	   * Save photo,
	   * call mosiac to hide all content views but keep the space for an application button (register) and show it
	   * zoom to the photo and call tilezoom with "joy" (callback)
	   *
	   *
	   * @param canvasData
	   * @param cropBoxData
	   * @param imageData
	   */
			saveCroppedPhoto: function saveCroppedPhoto(canvasData, cropBoxData, imageData) {

				var me = this,
				    photo = this.get('model');

				var applicationController = this.get('controllers.application');
				var tilezoomController = this.get('controllers.tilezoom');
				tilezoomController.removeUserPicture();

				var m = {
					cropX: (cropBoxData.left - canvasData.left) / imageData.width,
					cropY: (cropBoxData.top - canvasData.top) / imageData.height,
					cropWidth: cropBoxData.width / imageData.width,
					cropHeight: cropBoxData.height / imageData.height
				};

				if (m.cropX < 0) {
					m.cropX = 0;
				}
				if (m.cropY < 0) {
					m.cropY = 0;
				}
				if (m.cropWidth > 1) {
					m.cropWidth = 1;
				}
				if (m.cropHeight > 1) {
					m.cropHeight = 1;
				}

				var user = this.get('controllers.application').get('user');

				if (user) {
					m['profileId'] = user.get('token');
				}

				applicationController.updatePictureMetadata(photo, m);

				var mosaicView = UTIL._lookupView('mosaic');
				mosaicView.set('hasLazyLoader', true);

				var onSuccess = function onSuccess(p) {
					if (mosaicView) {
						UTIL.tracker.track('event', { category: 'Photo', action: 'cropped', label: 'Photo Crop', value: 1 });

						applicationController.setPicture(photo);
						if (user) {
							tilezoomController.removeAllTiles();
							me.transitionToRoute('my-wonderlock');
						} else {
							//mosaicView.hideAll('complete-notilezoomrefresh');// --> moved to mosaicView
							tilezoomController.removeAllTiles();
							me.transitionToRoute('joy-screen', photo.get('id'));
						}
					}
				};

				var onFail = function onFail(p) {

					var serverMessage = '';
					if (p && typeof p === 'object' && typeof p.responseJSON === 'object' && p.responseJSON.message) {
						serverMessage = ' Reason: ' + p.responseJSON.message;
					}

					mosaicView.set('hasLazyLoader', false);
					me.get('controllers.error-modal').raise({
						statusText: 'Sorry',
						statusCode: 404,
						reason: p,
						route: 'choose-wonder',
						message: me.t('upload.upload_save_error') + serverMessage
					});
				};

				photo.save().then(onSuccess, onFail);
			}
		}

	});

});