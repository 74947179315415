define('wow-app/templates/privacy-policy/vi', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Cập nhật lần cuối: 11.2015");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("NewOpenWorld Corporation (\"cho chúng ta\", \"chúng ta\", hoặc \"của chúng ta\") tổ chức thực hiện ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","//www.wonderswall.com");
        var el4 = dom.createTextNode("www.wonderswall.com");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (\"Trang mạng\").");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Trang web này cung cấp cho bạn thông tin về các qui tắc của chúng tôi trong việc thu thập, sử dụng và bảo mật thông tin cá nhân mà chúng tôi nhận được từ những người sử dụng trang web. Chúng tôi chỉ dùng thông tin cá nhân của bạn để hỗ trợ và phát triển trang web. Khi sử dụng trang này, bạn đã đồng ý với việc thu thập và sử dụng thông tin theo qui tắc.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Thu thập và sử dụng thông tin");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Trong quá trình sử dụng trang web của chúng tôi, có thể chúng tôi sẽ yêu cầu bạn cung cấp thông tin cá nhân để xác định danh tính hoặc liên hệ với bạn. Những thông tin xác định danh tính cá nhân có thể bao gồm tên bạn, nhưng không chỉ giới hạn ở đó (\"Thông tin cá nhân\").");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Dữ liệu đăng nhập");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Giống như cơ cấu hoạt động của nhiều trang web khác, chúng tôi thu thập thông tin gửi đến từ nguồn của bạn mỗi khi bạn vào trang của chúng tôi (\"Dữ liệu đăng nhập\"). Dữ liệu đăng nhập này có thể bao gồm các thông tin như địa chỉ mã nguồn internet của máy tính (\"IP\"), bộ trình duyệt, dạng trình duyệt, những trang bạn xem của chúng tôi, ngày giờ bạn tới xem, thời gian bạn sử dụng cho những trang đó và các số liệu thống kê khác. Thêm vào đó, có thể chúng tôi sử dụng người thứ ba để thu thập, giám sát và phân tích dữ liệu đăng nhập này.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Các cách giao dịch");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Có thể chúng tôi sẽ sử dụng thông tin cá nhân để gửi tin tức, quảng cáo, tài liệu cổ động hoặc những thông tin đáng quan tâm khác cho bạn.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Cookies");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Cookies là các tệp tin với lượng dữ liệu nhỏ có thể chứa phần tử nhận dạng vô danh. Cookies được gửi đến bộ trình duyệt của bạn từ một trang web và lưu lại trên máy tính hoặc ổ cứng điện thoại của bạn. Giống như nhiều trang web khác, chúng tôi sử dụng \"cookies\" để thu thập thông tin. Nếu bạn không chấp nhận cookies, có thể bạn sẽ không sử dụng được vài phần trong trang của chúng tôi.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("An ninh");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Việc bảo mật thông tin cá nhân của bạn rất quan trọng đối với chúng tôi, nhưng xin bạn hãy nhớ rằng không có bất cứ cách thức chuyển dữ liệu qua mạng hoặc phương pháp của bộ nhớ điện tử nào là chắc chắn 100%. Chúng tôi cố gắng sử dụng các biện pháp có thể để bảo mật thông tin cá nhân của bạn, nhưng chúng tôi không đảm bảo tuyệt đối điều đó. ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Thay chế độ bảo mật");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Chế độ bảo mật dữ liệu mới được cập nhật rất hiệu quả và sẽ tiếp tục được áp dụng ở đây, trừ phi có thay đổi trong tương lai, và chúng cũng sẽ được đăng lên trang này ngay sau đó. Chúng tôi có quyền cập nhật hoặc thay đổi chế độ bảo mật dữ liệu của mình bất cứ lúc nào, thỉnh thoảng bạn nên kiểm tra lại chế độ bảo mật này. Nếu sau khi chúng tôi đăng bất cứ thay đổi nào về việc bảo mật dữ liệu trên trang này mà bạn vẫn tiếp tục sử dụng dịch vụ, nghĩa là bạn chấp nhận những thay đổi đó cũng như đồng ý tuân theo chế độ bảo mật dữ liệu đã được thay đổi. Nếu có bất cứ thay đổi nào về việc bảo mật dữ liệu, chúng tôi sẽ thông báo cho bạn bằng cách sử dụng thông tin cá nhân hoặc đăng lên trang web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Liên hệ với chúng tôi");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Nếu bạn có bất cứ thắc mắc nào về chế độ bảo mật dữ liệu này, xin bạn hãy liên hệ với chúng tôi ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","mailto:support@wonderswall.com");
        var el4 = dom.createTextNode("contact us");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(".");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});