define('wow-app/controllers/mosaic', ['exports', 'wow-app/controllers/base-object-controller'], function (exports, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'tilezoom', 'navbar', 'settings-modal', 'signup-email-modal'],

		pictureIsOnActiveMosaic: (function () {
			var applicationController = this.get('controllers.application');
			var userPicture = applicationController.get('picture');
			var length = 0;

			if (userPicture) {
				length = userPicture.get('metadata.mosaicId') === this.get('model.id');
			}

			return length ? true : false;
		}).property('model.id', 'controllers.application.picture.metadata.mosaicId'),

		/**
	  * binded attribute to collapse content
	  */
		isContentExpanded: (function () {
			return this.get('model.applicationstate.expanded');
		}).property('model.applicationstate.expanded'),

		/**
	  * content visible
	  */
		isContentVisible: (function () {
			//var applicationController = this.get('controllers.application');
			//if(typeof console === 'object') { console.log('MosaicController.isContentVisible',this.get('model.applicationstate.visibleContent'),applicationController.get('model.visibleContent')); }
			return this.get('model.applicationstate.visibleContent');
		}).property('model.applicationstate.visibleContent'),

		actions: {

			/**
	   * toggleContent area DEPRECATED --> Mosaic View!
	   */
			toggleContent: function toggleContent() {

				var me = this;
				var willBeExpanded = me.get('model.applicationstate.expanded') ? false : true;

				if (willBeExpanded) {
					this.transitionToRoute('tilezoommap');
				} else {
					this.transitionToRoute('base-content-route');
				}
			}

		}

	});

	//import _BaseContentController from '/wow-app/controllers/-base-content';
	//import Sidebarstate from '/wow-app/objects/sidebarstate-object';
	//import User from '/wow-app/objects/user-object';
	//import MyPhoto from '/wow-app/models/my-photo';
	//
	//var MosaicController = _BaseContentController.extend({
	//
	//	needs: [ 'tilezoom', 'navbar' ],
	//
	//    logoSrc: srvParams.AssetsPath + '/bundles/n7wwallofwonders/images/logos/new7wonders-wow-logo.png',
	//
	////  Navigation Responsive Design
	//    navigationActive: false,
	//
	////  sidebar collpase
	//    isSidebarCollapsed: function () {
	//
	//        return Sidebarstate.get('collapsed');
	//
	//    }.property('Sidebarstate.collapsed'),
	//
	//
	//    switchMosaicListObserver: function() {
	//        this.set('switchMosaicList',this.get("controllers.navbar.switchMosaicList"));
	//        return this.get("controllers.navbar.switchMosaicList");
	//    }.observes("controllers.navbar.switchMosaicList"),
	//
	//    switchMosaicList: [],
	//
	////  content visible
	//    isContentVisible: function () {
	//
	//        return Sidebarstate.get('contentVisible');
	//
	//    }.property('Sidebarstate.contentVisible'),
	//
	//    actions: {
	//
	//        switchToMosaic: function ( mosaic ) {
	//
	//            Sidebarstate.set('contentVisible', false);
	//
	//            this.transitionToRoute('mosaic', mosaic.get('id'));
	//        },
	//
	//    	closeSideBarContent: function () {
	//
	//            Sidebarstate.set('contentVisible', false);
	//
	//            this.transitionToRoute('mosaic', this.get('model').get('id'));
	//    	},
	//
	//        goHome: function () {
	//
	//            this.get('controllers.tilezoom').send('goHome');
	//        },
	//
	//        zoomIn: function ( level ) {
	//
	//            this.get('controllers.tilezoom').send('zoomIn');
	//        },
	//
	//        zoomOut: function ( level ) {
	//
	//        	this.get('controllers.tilezoom').send('zoomOut');
	//        },
	//
	//        toggleNavigation: function () {
	//
	//        	var value = this.get('navigationActive') ? false : true;
	//
	//        	this.set('navigationActive', value);
	//        },
	//
	//        toggleSidebar: function ( e ) {
	//
	//            var me = this,
	//            	Sidebarstate = Sidebarstate;
	//
	//            Sidebarstate.set('collapsed', ( Sidebarstate.get('collapsed') ? false : true ) );
	//
	//            setTimeout(function () {
	//
	//                me.get('controllers.tilezoom').send('resize');
	//
	//            }, 100);
	//        },
	//
	//        logout: function() {
	//
	//            var me    = this,
	//                store = me.store;
	//
	//            User.set('loggedIn', false);
	//
	//            store.unloadAll( MyPhoto );
	//
	//            me.send('closeSideBarContent');
	//
	////			Logout message
	//            Bootstrap.GNM.push('Bye ' + User.get('firstname'), 'See you next time');
	//
	////			remove all photos after logout
	//            $.get('/api/v1/auth/logout').always(function() {
	//
	//            	console.log( 'logged out' );
	//            });
	//        }
	//    }
	//});
	//
	//export default MosaicController;

});