define('wow-app/views/base-routing-view', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].View.extend({

		isRendered: false,

		didInsertElement: function didInsertElement() {
			this.set('isRendered', true);
		},

		/**
	  * Called when the view is about to rerender, but before anything has been torn down.
	  * This is a good opportunity to tear down any manual observers you have installed based on the DOM state
	  */
		willClearRender: function willClearRender() {
			//if(typeof console === 'object') { console.log('BaseRoutingView.willClearRender',true); }
			//this.set('isRendered', false);

		},

		/**
	  * Called when the element of the view is going to be destroyed.
	  * Override this function to do any teardown that requires an element, like removing event listeners.
	  */
		willDestroyElement: function willDestroyElement() {
			//if(typeof console === 'object') { console.log('BaseRoutingView.willDestroyElement',true); }
			//this.set('isRendered', false);
		}
	});

});