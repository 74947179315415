define('wow-app/templates/accept-terms/en', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("By entering wonderswall.com mobile website, application, website (also sometimes referred to here collectively as wonderswall.com or as the website)\n    and or using its features you agree to adhere to the following terms and conditions in relation to the website, the services, and all related matters.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Copyright");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    Copyright 2014-2021, Global Platform Limited, (GPL), Dubai, United Arab Emirates.");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    Under license from NewOpenWorld Corporation.");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    Contact: contact ( at ) wonderswall dot com");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    All rights reserved.");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    Intellectual Property Agent for Notices and other communications.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("MAIN TERMS RELATING TO PARTICIPATING IN WONDERSWALL.COM");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Upload of photos");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Parts of the website allow you to send us your photos. We aim to screen briefly for content quality and appropriateness of material\n    to make sure that this remains a site that everyone can enjoy, but cannot be held responsible should a content issue arise.\n    You warrant any material you do provide as not infringing the copyright or other rights of any third party person or organisation.\n    Please do not send us materials owned by another person where you do not have the requisite permission.\n    GPL expressly disclaims liability for any such third party owned material; GPL cannot be responsible or liable to you or any\n    third party for any loss (whether such losses were foreseen, foreseeable, known or otherwise) including but not limited to:\n    loss of data; loss of revenue; loss of business; loss of opportunity; any indirect, consequential, special or exemplary damages arising\n    from the use of the website as a result of you wilfully or unknowingly providing third party content to GPL.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Ownership of uploaded content");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("When you upload photos to the website, you agree, by submitting your contribution, to grant GPL a perpetual, royalty-free, non-exclusive,\n    sub-licensable right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, play,\n    make available to the public, and exercise all copyright and publicity rights for your contribution worldwide and/or to incorporate your\n    contribution in other works in any media now known or later developed for the full term of any rights that may exist in your contribution.\n    If you do not want to grant to GPL the rights set out above, please do not upload your photos.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Photos on wonderswall.com");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("GPL reserves the right to accept or refuse any photo. The position of any photo may be changed at any time to ensure the optimum overall image\n    generated of the wonders, or for any other reason determined by GPL. In order to generated an optimum overall image of the wonders,\n    GPL may occasionally at its discretion supplement uploaded photos with other photos it has obtained.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Ethical and moral conduct");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("You may not use the website for any of the following purposes: (i) Disseminating any unlawful, harassing, libellous, abusive, threatening,\n    harmful, vulgar, obscene, or otherwise objectionable material or otherwise breaching any laws; (ii) Transmitting material that encourages conduct\n    that constitutes a criminal offence, results in civil liability or otherwise breaches any applicable laws, regulations or code of practice in your or\n    any other jurisdiction; (iii) Interfering with any other person’s use or enjoyment of the website; or making, transmitting or storing electronic copies of\n    materials protected by copyright without the permission of the owner. You will be responsible for our losses and costs resulting from your breach of\n    this requirement. We reserve the right to remove any material we see fit and at our absolute discretion.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h4");
        var el2 = dom.createTextNode("OTHER GENERAL TERMS AND CONDITIONS");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Respect of copyright and other intellectual property rights and conventions");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("GPL respects the intellectual property of others, and we ask our users to do the same. GPL may, in appropriate circumstances and at its discretion, disable and/or terminate the accounts of users who may be infringing the intellectual property rights of GPL and or others. If you believe that your work has been copied in a way that constitutes copyright infringement, or your intellectual property rights have been otherwise violated, please contact us.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("New7Wonders trademarks");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("New7Wonders, and other New7Wonders logos and product and service names are registered trademarks of New Open World Corporation, hereby represented by GPL. Without GPL’s prior permission, you agree not to display or use in any manner, the New7Wonders trademarks. Your use of and participation in the website confirms you agree that you may not copy, reproduce, republish, download, post, broadcast, transmit, make available to the public, or otherwise use in any way in any media in any website or other New7Wonders content in any way other than and except for your own personal, non-commercial use. You agree not to adapt, alter or create a derivative work from the website or other New7Wonders content except for your own personal, strictly non-commercial use. Any other use of the website or of New7Wonders content requires the prior written permission of GPL.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Privacy");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("GPL takes your privacy seriously. Because we necessarily gather certain types of information about our users we want to help you understand the terms and conditions surrounding the collection and use of that information. If you have authorized GPL to contact you for the purposes of information updates and other communication, your details may be placed on the GPL databases. GPL may use certain web standard devices, such as electronic images, to track general user traffic patterns. GPL may also automatically receive and record information from our servers and from your browser, including your IP address, the time, and information about the page you requested. As is normal on the web, GPL may also receive information about you from other sources and add it to the information you have provided to us. GPL uses the information we gather for four general purposes: 1. To customize the advertising and content you see; 2. To enhance the user experience in our network of sites. We use tracking information to determine how well each page performs overall based on aggregate user demographics and traffic patterns to those pages. This helps us continue to build a better service for you; 3. To in the future fulfil your requests for certain products and services, such as sending out electronic newsletters and enabling users to participate in polls, contests, message boards, and auctions; 4. To send you information you agreed to receive about topics we think will be of interest to you.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("National laws");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Recognizing the global nature of the Internet, you agree to comply with all relevant national and local laws, rules and conventions regarding online conduct and acceptable content. Specifically, you agree to comply with all applicable laws regarding the transmission of technical data exported from the country in which you reside.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Indemnity");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("You agree to indemnify and hold GPL, and its subsidiaries, affiliates, officers, agents, co-branders or other partners, and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of content you submit, post, transmit or make available through the service, your use of the service, your connection to the service, your violation of the Terms and Conditions or your violation of any rights of another.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("No resale of service");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("You agree not to reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes, any portion of the service, use of the service, or access to the service.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Modification to website");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("GPL reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the website (or any part thereof) with or without notice. You agree that GPL shall not be liable to you or to any third party for any modification, suspension or discontinuance of the website.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Links");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("The website may provide, or third parties may provide, links to other World Wide Web sites or resources. Because GPL has no control over such sites and resources, you acknowledge and agree that GPL is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products, or other materials on or available from such sites or resources. You further acknowledge and agree that GPL shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Data ownership");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Any data, such as participation in the WondersWall, that may be displayed or published by GPL is copyright owned. Specific binding rules cover the use of this data. News media: Recognised news media outlets may reproduce this data in a news context only, providing the following text is always shown in a visible manner: Data © www.wonderswall.com. Other uses: Any other uses by organisations, companies, websites are subject to license and approval by GPL. Contact us for further information.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Disclaimer of technical service");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("Website content, including information, names, images, pictures and logos relating to New7Wonders and the WondersWall or its services, is provided “as is” and on an “is available basis” without any representations or any kind of warranty made (whether express or implied by law) to the extent permitted by law, including non-infringement, compatibility, security and accuracy. GPL does not warrant that the functions contained in the website content will be without delay or interruption or that the photo or other transfers will be accurate or complete, that defects will be corrected, or that the website or the server that makes it available are free of viruses or bugs or any other damaging internet based materials. Under no circumstances is or will GPL be responsible or liable to you or any third party for any loss (whether such losses were foreseen, foreseeable, known or otherwise) including but not limited to: loss of data; loss of revenue; loss of business; loss of opportunity; any indirect, consequential, special or exemplary damages arising from the use of the website.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Disclaimer of warranties");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("You expressively understand and agree that: your use of the website is at your sole risk. The website is provided on an “as is” and “as available” basis. GPL expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and non- infringement. GPL makes no warranty that (i) the website will meet your requirements, (ii) the website will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the website will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by you through the website will meet your expectations, and (v) any errors in the software will be corrected. Any material downloaded or otherwise obtained through the use of the website, where permitted, is done at your own discretion and risk and that you will be solely responsible for any damage to your computer system or loss of data that results from the download of any such material.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Limitation of liability");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("You expressly understand and agree that GPL shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if GPL has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the website; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the website; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the website; or (v) any other matter relating to the website.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Users age");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("We believe that wonderswall.com is open to everyone and to all ages. However, we understand that in some countries and cultures there may be some different legal or other frameworks concerning the age of the user. We do not knowingly collect or solicit personal information from anyone under a permissible (in their own country) age where this is prohibited by law or knowingly allow such person to register. If the law of your country prohibits you from registering, you must not attempt to register with the website or send any information about yourself to us, including, but not limited to, your name, address, telephone number, or e-mail address. If the law of your country requires you to obtain permission, then please obtain your parent/guardian’s permission before providing personal information to the website.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Change of terms");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("GPL reserves the right to change these terms and conditions at any time by posting changes online. Please review these terms regularly to ensure that you are aware of any changes made by GPL. Your continued use of the website, after any changes are posted, signifies confirmation that you agree to be legally bound by these terms as updated and/or amended.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createTextNode("Governing law");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("These Terms and Conditions are governed by and construed in accordance with the laws of the Canton of Zurich/Switzerland.");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});