define('wow-app/templates/privacy-policy/es', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Última actualización: noviembre de 2015");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("NewOpenWorld Corporation («nosotros») gestiona ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","//www.wonderswall.com");
        var el4 = dom.createTextNode("www.wonderswall.com");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (el«sitio»).");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Esta página ofrece información sobre nuestra política en materia de recopilación, uso y divulgación de los datos personales que recibimos de los usuarios del sitio. Utilizamos su información personal solo para facilitar y mejorar el sitio. Al utilizar el sitio, usted acepta la recopilación y el uso de la información conforme a esta política.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Recopilación y uso de la información");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Mientras utiliza nuestro sitio, es posible que le pidamos que nos facilite cierta información personal que podemos usar para contactar con usted o identificarlo. La información personal puede incluir, entre otras cosas, su nombre («información personal»).");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Datos de registro");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Como muchos operadores de sitios, recopilamos la información que su navegador envía cada vez que usted visita nuestro sitio («datos de registro»). Estos datos de registro pueden incluir información como la dirección de protocolo de Internet («IP») de su ordenador, el tipo y la versión de navegador, las páginas que visita de nuestro sitio, la hora y la fecha de la visita, el tiempo que pasa en esas páginas y otras estadísticas. Además, podemos utilizar servicios de terceros para recopilar, controlar y analizar estos datos de registro.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Comunicaciones");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Podemos usar su información personal para contactar con usted y enviarle boletines informativos, material promocional o de marketing y otra información interesante.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Cookies");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Las cookies son archivos con una pequeña cantidad de datos, que puede incluir un identificador único anónimo. Las cookies se envían a su navegador desde un sitio web y se guardan en su ordenador o en el disco duro de su teléfono. Al igual que muchos sitios, utilizamos cookies para recopilar información. Si no acepta cookies, es posible que no pueda utilizar algunas partes de nuestro sitio.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Seguridad");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("La seguridad de su información personal es importante para nosotros, pero recuerde que no existe un método de transmisión por Internet o un método de almacenamiento electrónico que sea seguro al 100 %. Aunque nos esforzamos por utilizar medios comercialmente aceptables para proteger su información personal, no podemos garantizar la seguridad absoluta.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Modificaciones de esta política de privacidad");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Esta política de privacidad entra en vigor a partir de la última actualización mencionada anteriormente y seguirá en vigor salvo en lo que se refiere a cualquier cambio en sus disposiciones que se produzca en el futuro, que tendrán efecto inmediatamente después de publicarse en esta página. Nos reservamos el derecho a actualizar o modificar nuestra política de privacidad en cualquier momento, por lo que usted debería comprobar esta política de privacidad periódicamente. Si continúa utilizando el servicio después de que publiquemos cualquier modificación de la política de privacidad en esta página, esto implicará que usted conoce las modificaciones y da su consentimiento para cumplir la política de privacidad modificada y estar sujeto a ella. Si hacemos cualquier cambio material en esta política de privacidad, se lo notificaremos utilizando su información personal o publicando un aviso destacado en el sitio.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Contacto");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Si tiene cualquier duda con respecto a esta política de privacidad, contacte con nosotros mediante ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","mailto:support@wonderswall.com");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(".");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});