define('wow-app/routes/about', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		baseRouteViewSettings: {
			visibleContent: 'about',
			templates: {
				bottom: {
					name: 'about',
					outlet: 'bottom',
					height: 600,
					width: 400,
					position: {
						portrait: 'bottom',
						landscape: 'right'
					},
					parent: 'mosaic',
					scrollable: true
				},
				top: {
					name: 'about-arena',
					outlet: 'top',
					parent: 'mosaic'
				}
			},
			showContent: true
		},

		beforeModel: function beforeModel() {
			this.updateBaseView();
		},

		renderTemplate: function renderTemplate() {

			this.render({
				into: 'mosaic',
				outlet: 'bottom'
			});

			this.render('about-arena', {
				into: 'mosaic',
				outlet: 'top'
			});
		}

	});

});