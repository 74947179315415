define('wow-app/routes/splash', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({

		/**
	  * Load mosaic model
	  *
	  * @returns {*}
	  */
		model: function model() {
			return this.store.findAll('mosaic');
		},

		/**
	  * After model is loaded, transitionTo mosaic, if
	  * application property skipSplashRedirectDone is false
	  * and user has already seen the splash screen
	  *
	  * @param mosaics
	  * @param transition
	  */
		afterModel: function afterModel(mosaics, transition) {

			return;

			//var controller = this.controllerFor('application');
			//
			//// set "first" mmosaic id to the running / global mosaic id
			//this.store.find('applicationstate','globalstate').then(function(globalstate) {
			//	globalstate.set('mosaic',mosaics.get('firstObject').get('id'));
			//});
			//
			//
			//if(!controller.get('skipSplashRedirectDone')) {
			//
			//	var seenSplash = localStorage.seenSplash;
			//	//var seenSplash = this.store.find('applicationstate','globalstate').get('seenSplash');
			//	controller.set('skipSplashRedirectDone',true);
			//
			//
			//	if (seenSplash) {
			//		//this.transitionTo('mosaic', mosaics.get('firstObject').get('id'));
			//		this.transitionTo('tilezoommap', mosaics.get('firstObject').get('id'));
			//	} else {
			//		//localStorage.seenSplash = true;
			//	}
			//}
		}

	});

});