define('wow-app/views/share', ['exports', 'ember', 'wow-app/views/base-routing-view'], function (exports, Ember, BaseRoutingView) {

	'use strict';

	exports['default'] = BaseRoutingView['default'].extend({

		classNames: 'view-share',

		afterShowCSSTransition: function afterShowCSSTransition() {
			//UTIL.socialConnect.facebook.getLoginStatus();
			//this.initTwitterButton();

			var me = this,
			    controller = me.get('controller');
		},

		getFacebookStats: function getFacebookStats() {
			var facebookSharingUrl = controller.getSharingUrl('facebook', controller.get('model'));
			if (typeof console === 'object') {
				console.log('facebookSharingUrl', facebookSharingUrl);
			}

			var a = UTIL.socialConnect.facebook.getUrlStats(facebookSharingUrl);
			if (typeof console === 'object') {
				console.log('STATS?', a);
			}
			UTIL.socialConnect.facebook.getUrlStatsfql(facebookSharingUrl);
		},

		/**
	  * Facebook Buttons
	  * Insert into DOM
	  * Do not forget to set xfbml=true in fbAsyncInit (util.js)
	  * <div class="fb-like" data-href="http://cr.dev.wallofwonders.com/sharing/facebook/manmade/560a754972fa2ebc8200834d" data-layout="standard" data-action="like" data-show-faces="true" data-share="true"></div>
	  */
		reRenderFBML: function reRenderFBML() {
			FB.XFBML.parse();
		},

		initTwitterButton: function initTwitterButton() {
			twttr.widgets.load();
		}

	});

});