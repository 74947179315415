define('wow-app/routes/top', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		isSecure: false,

		docTitle: 'Top',

		baseRouteViewSettings: {
			visibleContent: 'top',
			//activeNavigationItem: 'upload',
			templates: {
				bottom: {
					//isRendered: true,
					name: 'top',
					outlet: 'bottom',
					height: 'fullscreen',
					width: 400,
					parent: 'mosaic',
					scrollable: true
				}
			},
			showContent: true
		},

		beforeModel: function beforeModel() {
			this.updateBaseView();
		},

		renderTemplate: function renderTemplate(controller, model) {

			this.render({
				into: 'mosaic',
				outlet: 'bottom'
			});
		}

	});

});