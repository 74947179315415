define('wow-app/templates/accept-terms/it', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Accedendo al sito web per dispositivi mobili wonderswall.com, all'applicazione, al sito web (talvolta citati collettivamente come wonderswall.com o sito web)\n        ed utilizzando le sue funzionalità, siete d'accordo sul fatto di aderire ai seguenti Termini e condizioni per quanto riguarda il sito web, i servizi e tutti gli argomenti correlati.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Copyright");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Copyright 2014-2021, Global Platform Limited, (GPL), Dubai, Emirati Arabi Uniti. - Tutti i diritti riservati.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Sotto licenza dalla NewOpenWorld Corporation.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Contatto: contatto ( a ) wonderswall punto com");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("All rights reserved. - Tutti i diritti riservati.");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("Agente della proprietà intellettuale per le notizie ed altre comunicazioni.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("TERMINI PRINCIPALI CORRISPONDENTI ALLA PARTECIPAZIONE AL SITO WONDERSWALL.COM");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Caricamento di foto");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Parti del sito web vi consentono di spedirci le vostre foto. Desideriamo passare in rassegna brevemente la qualità del contenuto e l'adeguatezza del materiale\n        per accertarci che questo rimanga un sito di cui tutti possano usufruire, senza poter essere ritenuti responsabili dei problemi derivanti da certi contenuti.\n        Voi garantite che ogni materiale da voi fornito non viola il copyright o gli altri diritti di una qualsiasi parte terza od organizzazione.\n        Non spediteci alcun materiale che sia proprietà di una terza persona e per cui non possedete la debita e richiesta autorizzazione.\n        GPL disconosce espressamente la responsabilità per un qualsiasi elemento detenuto da una parte terza; GPL non può essere ritenuta responsabile, né obbligata a rispondere a voi o a una qualsiasi\n        parte terza per qualsiasi perdita (anche se tale perdita sia prevista, prevedibile, nota o altro) compresi, senza che questo costituisca una limitazione:\n        perdite di dati; perdite di proventi; perdite commerciali; perdite di opportunità; qualsiasi danno indiretto, consequenziale, speciale o esemplare risultante\n        dall'utilizzo del sito web in quanto risultato della vostra volontà, o senza esserne a conoscenza, di fornire contenuti di parti terze a GPL.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Proprietà del contenuto caricato");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Quando caricate foto sul sito web, siete d'accordo, inoltrando la vostra contribuzione, per garantire a GPL un diritto e una licenza perpetui, liberi da royalty, non esclusivi,\n        attribuibili in sotto-licenza per l'utilizzo, la riproduzione, la modifica, l'adattamento, la pubblicazione, la traduzione, la creazione di lavori derivati, la distribuzione, l'esecuzione, l'utilizzo multimediale,\n        la disponibilità presso il pubblico e l'esercizio di tutti i diritti di proprietà intellettuale e pubblicitari per la vostra contribuzione in tutto il mondo, e/o di incorporare la vostra\n        contribuzione in altre opere, in qualsiasi supporto di media già ora noto o che sarà sviluppato in futuro, per l'intera estensione di qualsiasi diritto che possa esistere nella vostra contribuzione.\n        Se non voleste garantire a GPL i diritti suesposti, non dovete caricare le vostre foto.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Foto sul sito web wonderswall.com");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL si riserva il diritto di accettare o rifiutare qualsiasi foto. La posizione di qualsiasi foto può essere cambiata in qualsiasi momento, per assicurare una copertura ottimale delle immagini\n        generate delle meraviglie, oppure per qualsiasi altro motivo determinato da GPL. Al fine di poter creare una copertura generale ottimale delle immagini delle meraviglie,\n        GPL può, in certi casi e a sua discrezione, supplementare le foto caricate con altre foto che abbia ottenuto.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Comportamento etico e morale");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Non potete utilizzare il sito web per qualsiasi dei seguenti scopi: (i) Per distribuire qualsiasi materiale illegale, che rechi molestia, diffamatorio, abusivo, minaccioso,\n        dannoso, volgare, osceno, o in altro modo sgradevole o che violi qualsiasi legge; (ii) Per trasmettere materiale che incoraggi un comportamento\n        che costituisca una trasgressione criminale, che determini una responsabilità civile o che infranga in altro modo una qualsiasi legge, regolamentazione o codice professionale in vigore, nella vostra o in una\n\n        qualsiasi altra giurisdizione; (iii) Per interferire con l'utilizzo o lo svago del sito web da parte di qualsiasi altra persona; oppure per effettuare, trasmettere o memorizzare copie elettroniche dei\n        materiali protetti da copyright, senza il permesso del detentore dei diritti intellettuali. Sarete responsabili per le nostre perdite e costi risultanti dalla vostra violazione di\n        questo requisito. Ci riserviamo il diritto di rimuovere qualsiasi materiale che vedremo installato, a nostra assoluta discrezione.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createTextNode("ALTRI TERMINI E CONDIZIONI GENERALI");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Rispetto del copyright e di altri diritti e convenzioni riguardanti la proprietà intellettuale ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL rispetta la proprietà intellettuale di altri detentori e chiediamo ai nostri utenti di fare lo stesso. GPL può, in circostanze pertinenti e a sua discrezione, disabilitare e/o porre termine agli account di utenti che possano violare i diritti di proprietà intellettuale di GPL e di altri detentori. Se credete che le vostre opere siano state copiate in un modo che costituisca una violazione di coyright, o che i vostri diritti di proprietà intellettuale siano stati in qualche altro modo trasgrediti, mettetevi in contatto con noi.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Marchi commerciali depositati New7Wonders");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("New7Wonders, come pure gli altri logo New7Wonders ed i nomi dei prodotti e servizi, sono marchi commerciali depositati della New Open World Corporation, quindi rappresentati da GPL. In assenza di un previo consenso di GPL, siete d'accordo sul fatto di non visualizzare, né utilizzare in qualsiasi modo, i marchi commerciali depositati New7Wonders. Il vostro utilizzo e partecipazione al sito web conferma che siete d'accordo sul fatto di non poter copiare, riprodurre, ripubblicare, scaricare, inserire in post, diffondere per via multimediale, trasmettere, rendere disponibile al pubblico, od utilizzare in qualsiasi altro modo, su qualsiasi supporto, in qualsiasi sito web o altro mezzo, contenuti New7Wonders in qualsiasi modo, ad eccezione del vostro proprio utilizzo personale e non commerciale. Siete d'accordo sul fatto di non adattare, alterare o creare opere o lavori derivati dal sito web o altri contenuti New7Wonders, ad eccezione del vostro proprio utilizzo personale, strettamente non commerciale. Qualsiasi altro utilizzo del sito web o dei contenuti New7Wonders richiede il previo consenso scritto di GPL.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Riservatezza");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("A GPL sta seriamente a cuore la vostra riservatezza. Dato che dobbiamo necessariamente raccogliere certi tipi di informazioni riguardanti i nostri utenti, desideriamo aiutarvi a comprendere i termini e le condizioni che stanno alla base della raccolta e dell'utilizzo di tali informazioni. Nel caso che abbiate autorizzato GPL ad entrare in contatto con voi a scopo di aggiornare le informazioni e per le altre comunicazioni, i vostri dettagli possono essere memorizzati nei database GPL. GPL potrà servirsi di certi dispositivi standard web, quali immagini elettroniche, per mantenere una traccia dei percorsi di circolazione generali degli utenti. GPL può inoltre ricevere e registrare automaticamente informazioni provenienti dai nostri server e dal vostro browser, inclusi il vostro indirizzo IP, l'ora ed informazioni a proposito della pagina da voi richiesta. Come prassi normale del web, GPL può pure ricevere informazioni su di voi da altre sorgenti ed aggiungerle a quelle che ci avete già fornito. GPL utilizza le informazioni raccolte per quattro scopi generali: 1. Al fine di personalizzare la pubblicità e i contenuti visualizzati; 2. Per migliorare l'esperienza dell'utente nella nostra rete di siti. Ci serviamo di informazioni di tracciatura per determinare la qualità delle prestazioni totali di ciascuna pagina, in base ai dati demografici complessivi degli utenti e dei percorsi di circolazione verso le pagine in questione. Questo contribuirà ad attuare un miglior servizio per voi; 3. Per poter esaudire in futuro le vostre richieste per certi prodotti e servizi, quali la spedizione dei nostri bollettini informativi elettronici e per poter consentire ai nostri utenti di partecipare a sondaggi, concorsi, pannelli di messaggi e vendite all'asta; 4. Per potervi spedire informazioni a cui avete acconsentito di ricevere, a proposito di argomenti che pensiamo possano interessarvi.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Leggi nazionali");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Siete consapevoli della natura globale di Internet, per cui siete d'accordo di conformarvi a tutte le leggi rilevanti, le regolamentazioni e le convenzioni, nazionali e locali, riguardanti la condotta online e i contenuti ritenuti accettabili. In modo specifico, siete d'accordo sul fatto di conformarvi a tutte le leggi applicabili riguardanti la trasmissione di dati tecnici esportati dal paese in cui risiedete.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Indennità");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Siete d'accordo per indennizzare e mantenere GPL, e le sue controllate ed affiliate, i suoi funzionari, agenti, coproprietari di marchio o altri partner e dipendenti, indenni da ogni rivendicazione o domanda di indennizzo, inclusi ragionevoli onorari di avvocati, avanzate da una qualsiasi parte terza, risultanti o sollevate dai contenuti da voi inoltrati, inclusi in post, trasmessi o resi disponibili tramite il servizio, il vostro utilizzo del servizio, la vostra connessione al servizio, la vostra violazione dei Termini e condizioni o la vostra infrazione di qualsiasi diritto detenuto da altri.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Nessuna rivendita di servizio");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Siete d'accordo sul fatto di non riprodurre, duplicare, copiare, vendere, rivendere o sfruttare per qualsiasi scopo commerciale nessuna parte del servizio, nessun utilizzo del servizio, né alcun accesso al servizio.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Modifica al sito web");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL si riserva il diritto, in qualsiasi momento e di tanto in tanto, di modificare o interrompere, temporaneamente o permanentemente, il sito web (o una sua parte qualsiasi), con o senza preavviso. Siete d'accordo sul fatto che GPL non sarà responsabile, verso di voi o una qualsiasi parte terza, per qualsiasi modifica, sospensione o interruzione del sito web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Collegamenti");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Il sito web può fornire, o parti terze possono fornire, collegamenti verso altri siti web o risorse. Dal momento che GPL non dispone di alcun controllo su tali siti web e risorse, riconoscete e siete d'accordo sul fatto che GPL non è responsabile della disponibilità di tali siti web esterni o di tali risorse, e non si assume né riconosce di essere responsabile o passibile per qualsiasi contenuto, pubblicità, prodotti o altri materiali o della loro disponibilità sugli altri siti web e risorse in questione. Riconoscete inoltre e siete d'accordo sul fatto che GPL non sarà ritenuta responsabile né passibile, direttamente o indirettamente, di qualsiasi danno o perdita causati o presunti essere la causa, o in connessione con l'utilizzo o il fatto di far affidamento su uno qualsiasi di tali contenuti, merci o servizi disponibili presso o mediante uno qualsiasi di tali siti web o risorse.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Proprietà dei dati");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Qualsiasi dato, quale la partecipazione al Muro delle Meraviglie, che possa essere visualizzato o pubblicato da GPL, è di proprietà oggetto di copyright. Regole specifiche e vincolanti coprono l'utilizzo di questi dati. Media informativi: media informativi riconosciuti possono riprodurre i dati in questione soltanto in un contesto di notizie, a condizione che il testo seguente sia sempre riprodotto in maniera visibile: Dati © www.wonderswall.com. Altri utilizzi: qualsiasi altro utilizzo da parte di organizzazioni, aziende e siti web è soggetto a licenza ed approvazione da parte di GPL. Mettetevi in contatto con noi per ulteriori informazioni.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Disconoscimento di servizio tecnico");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("I contenuti del sito web, inclusi informazioni, nomi, immagini, foto e logo riguardanti New7Wonders ed il Muto delle Meraviglie o i loro servizi, vengono forniti \"come stanno\" e in base \"alla loro disponibilità\", senza alcuna rappresentazione, né alcun tipo di garanzia (che sia espressa o ritenuta implicita dalla legge), per l'estensione consentita dalla legge, ivi incluse la non violazione, compatibilità, sicurezza e precisione. GPL non garantisce che le funzionalità contenute nel sito web saranno disponibili senza ritardi o interruzioni, o che le foto o gli altri trasferimenti saranno precisi o completi, che eventuali difetti saranno corretti, o che il sito web o il server che rende disponibile il tutto siano esenti da virus o bug o qualsiasi altro elemento dannoso basato su internet. In nessuna circostanza GPL è o sarà ritenuta responsabile o passibile verso voi o un'altra parte terza per qualsiasi perdita (sia essa prevista, prevedibile, conosciuta o altro) compresi, senza che questo rappresenti una limitazione: perdita di dati; perdita di profitti; perdita d'affari; perdita di opportunità; qualsiasi danno indiretto, consequanziale, speciale o esemplare, derivante dall'utilizzo del sito web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Disconoscimento di garanzie");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Riconoscete espressamente e siete d'accordo sul fatto che il vostro utilizzo del sito web è unicamente a vostro rischio. Il sito web è fornito \"com'è\" e su una base di \"quand'è disponibile\". GPL respinge espressamente tutte le garanzie di qualsiasi tipo, che siano espresse o implicite, incluse, senza che questo rappresenti una limitazione, garanzie implicite di commerciabilità, adeguatezza ad uno scopo particolare e non violabilità. GPL non vi dà alcuna garanzia che (i) il sito web soddisfi i vostri requisiti, (ii) che non venga interrotto, che sia puntuale, sicuro, o esente da errori, (iii) che i risultati che possano essere ottenuti dall'utilizzo del sito web siano precisi o affidabili, (iv) che la qualità di qualsiasi prodotto, servizio, informazione, o che altri materiali acquistati o ottenuti tramite il sito web soddisfino le vostre aspettative, e (v) che eventuali errori del software siano corretti. Qualsiasi materiale scaricato, o ottenuto in altro modo, tramite l'utilizzo del sito web, laddove permesso, è recepito alla vostra propria discrezione e a vostro rischio e sarete gli unici responsabili in caso di danni arrecati al vostro sistema informatico o per perdite di dati risultanti dallo scaricamento di tali materiali.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Limitazione di responsabilità");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Siete espressamente consapevoli e d'accordo sul fatto che GPL non sarà responsabile per qualsiasi danno diretto, indiretto, incidentale, speciale, consequenziale o esemplare, inclusi, senza che questo rappresenti una limitazione, danni per perdite di profitto, avviamento, utilizzo, dati o altre perdite intangibili (anche nell'eventualità che GPL sia stata avvisata della possibilità dell'insorgere di tali danni), risultanti da: (i) l'utilizzo o la mancata possibilità dell'utilizzo del sito web; (ii) il costo dell'ottenimento di merci e di servizi di sostituzione risultanti da qualsiasi merce, dati, informazioni o servizi acquistati od ottenuti, o da messaggi ricevuti o da transazioni immesse tramite o dal sito web; (iii) accessi non autorizzati o alterazione delle vostre trasmissioni di dati; (iv) dichiarazioni o condotte di qualsiasi parte terza sul sito web; oppure (v) qualsiasi altro argomento correlato al sito web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Età degli utenti");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Crediamo che il sito web wonderswall.com sia aperto a tutti e a tutte le età. Comprendiamo comunque che, presso alcuni paesi e culture possano esistere alcune leggi o taluni inquadramenti a proposito dell'età degli utenti. Non raccogliamo né sollecitiamo informazioni da persone minori di un'età ammessa (nel loro paese), se questo fosse proibito dalla legge, né consentiamo l'iscrizione della persona in questione, qualora ne fossimo a conoscenza. Se la legge in vigore nel vostro paese vi vieta l'iscrizione, non dovete tentare di iscrivervi al sito web, né di inviarci alcuna informazione riguardante la vostra persona, inclusi elementi, senza che questo rappresenti una limitazione, quali il vostro nome, indirizzo, numero di telefono o indirizzo email. Nel caso che la legislazione in vigore nel vostro paese richieda che otteniate il permesso, dovete ottenerlo dai vostri genitori o dal vostro tutore legale, prima di inoltrare informazioni personali al sito web.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Modifica di termini");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("GPL si riserva il diritto di modificare questi Termini e condizioni in qualsiasi momento, mediante dei post online di avviso. Passate regolarmente in rassegna questi Termini, al fine di assicurarvi di essere consapevoli di tutte le modifiche apportate da GPL. Se continuate ad utilizzare il sito web una volta che tali post di avviso siano stati pubblicati, questo significa che siete d'accordo sul fatto di essere legalmente vincolati da questi Termini, in quanto aggiornati e/o modificati.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("Giurisdizione disciplinante");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Questi Termini e condizioni sono disciplinati e redatti in conformità alle leggi del Cantone di Zurigo, Svizzera.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});