define('wow-app/controllers/share-mail-modal', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'error-modal'],

		urlFailed: false,
		closeModal: false,
		isProcessing: false,

		to: null,
		subject: null,
		text: null,

		error: 'An error occured',

		show: function show(p) {

			var me = this;
			var loadModal = function loadModal() {
				me.get('controllers.application').send('showModal', 'share-mail-modal', p);
			};

			var user = this.get('controllers.application').get('user');

			me.setProperties({
				urlFailed: false,
				isProcessing: false,
				subject: this.get('subject') || this.t('share.fb_caption'),
				text: this.get('text') || p.short_url,
				closeModal: false
			});

			loadModal();
		},

		actions: {

			shareMail: function shareMail() {

				var me = this;

				this.setProperties({
					isProcessing: true
				});

				$.post("/social/sharing/email", {
					_to: $('#to-email').val(),
					_subject: $('#subject-email').val(),
					_text: $('#text-email').val(),
					_csrf_token: '',
					_submit: 'share-mail'
				}).then(function (a, b, c) {

					me.setProperties({
						isProcessing: false,
						closeModal: true
					});

					me.get('controllers.error-modal').raise({
						statusText: me.t('register.email'),
						statusCode: 200,
						reason: null,
						message: me.t('share.email_successfully_send')
					});
				}, (function (a, b, c) {
					me.setProperties({
						loginFailed: true,
						isProcessing: false
					});
				}).bind(this));
			}
		}

	});

});