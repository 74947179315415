define('wow-app/models/user', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		username: DS['default'].attr(),
		email: DS['default'].attr(),
		password: DS['default'].attr(),
		sharing_pic_src: DS['default'].attr(),
		loggedIn: DS['default'].attr('boolean'),

		token: (function () {
			return this.get('id');
		}).property('id'),

		isLoggedIn: (function () {
			return this.get('username') ? true : false;
		}).property('username')

	});

});