define('wow-app/views/user-stay', ['exports', 'ember', 'wow-app/views/base-routing-view'], function (exports, Ember, BaseRoutingView) {

  'use strict';

  exports['default'] = BaseRoutingView['default'].extend({

    classNames: 'view-user-stay'

  });

});