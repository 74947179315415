define('wow-app/tests/unit/helpers/mosaic-image-test', ['wow-app/helpers/mosaic-image', 'qunit'], function (mosaic_image, qunit) {

  'use strict';

  qunit.module('Unit | Helper | mosaic image');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = mosaic_image.mosaicImage(42);
    assert.ok(result);
  });

});