define('wow-app/routes/base-content-route', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({

		/**
	  * Settings for the base view
	  * may stay here or in the controller
	  */
		baseRouteViewSettings: {
			visibleContent: 'base-content-route', // the route
			activeNavigationItem: null, // will be the route, if not set
			templates: {
				bottom: {
					name: 'base-content-route',
					outlet: 'bottom',
					height: 0,
					//scrollable: true,
					parent: 'mosaic'
				}
				//,top: {
				//	name: '',
				//	outlet: 'top',
				//	parent: 'mosaic'
				//}
			},
			showContent: true
		},

		/**
	  * Refers to BaseView._lookupsettings
	  * Usally _lookupsettings returns just the baseRouteViewSettings but
	  * if getBaseRouteViewSettings is defined you may use this function
	  * to set baseRouteViewSettings dynamically
	  *
	  * @returns {baseRouteViewSettings|{visibleContent, templates, showContent}|{visibleContent, activeNavigationItem, templates, showContent}}
	  */
		getBaseRouteViewSettings: function getBaseRouteViewSettings() {
			var settings = $.extend(true, {}, this.baseRouteViewSettings);
			return settings;
		},

		/**
	  * The top and bottom content is always hidden (transform outer view)
	  * This function updates the base view to show the content with an animation
	  *
	  * Very Important: this function needs to be called on beforeModel
	  * or afterModel (if we have an model)
	  */
		updateBaseView: function updateBaseView() {
			var me = this;

			this.store.find('applicationstate', 'globalstate').then(function (globalstate) {
				globalstate.setProperties({ expanded: me.baseRouteViewSettings.showContent, visibleContent: me.baseRouteViewSettings.visibleContent });
			});
		},

		renderTemplate: function renderTemplate() {

			this.render({
				into: 'mosaic',
				outlet: 'bottom'
			});
		},

		getMosaicId: function getMosaicId(params, transition) {
			if (typeof transition === 'object' && typeof transition.params === 'object' && typeof transition.params.mosaic === 'object') {
				return transition.params.mosaic.mosaic_Id;
			} else {
				var controller = this.controllerFor('application');
				if (controller) {
					return controller.getMosaicId();
				}
			}

			return null;
		},

		//,events: {
		//	// before transition is done
		//	willTransition: function(transition) {
		//		if(typeof console === 'object') { console.log('BaseContentRoute.willTransition transition',transition); }
		//	}
		//},
		//
		actions: {

			// The didTransition action is fired after a transition has successfully been completed.
			// This occurs after the normal model hooks (beforeModel, model, afterModel, setupController) have resolved.
			didTransition: function didTransition() {
				//this._super(arguments);
				var baseDocTitle = 'WondersWall';
				var sep = ' | ';

				if (this.get('docTitle')) {
					document.title = baseDocTitle + sep + this.get('docTitle');
					UTIL.tracker.track('page', {});
				}

				return true; // Bubble the didTransition event
			}
			//
			//// The loading action is fired on the route when a route's model hook returns a promise that is not already resolved.
			//loading: function(transition, route) {
			//	if(typeof console === 'object') { console.log('BaseContentRoute.loading',transition, route); }
			//
			//	return true; // Bubble the loading event
			//},
			//
			//error: function(error, transition) {
			//	// Assuming we got here due to the error in `beforeModel`,
			//	// we can expect that error === "bad things!",
			//	// but a promise model rejecting would also
			//	// call this hook, as would any errors encountered
			//	// in `afterModel`.
			//
			//	// The `error` hook is also provided the failed
			//	// `transition`, which can be stored and later
			//	// `.retry()`d if desired.
			//	if(typeof console === 'object') { console.log('BaseContentRoute - WARNING transition error',error, transition); }
			//	this.transitionTo('splash');
			//}
		}
	});

});