define('wow-app/views/my-wonderlock', ['exports', 'ember', 'wow-app/views/base-routing-view'], function (exports, Ember, BaseRoutingView) {

	'use strict';

	exports['default'] = BaseRoutingView['default'].extend({

		classNames: ['hideOnLoad', 'view-my-wonderlock'],
		classNameBindings: ['selectCountry'],

		/**
	  * zoom to "my" photo
	  * But only if we are already on the right mosaic
	  */
		zoomToPhoto: function zoomToPhoto() {
			var me = this,
			    controller = me.get('controller'),
			    tilezoomController = controller.get('controllers.tilezoom'),
			    applicationController = controller.get('controllers.application'),
			    picture = applicationController.get('picture');

			if (controller.get('pictureIsOnActiveMosaic')) {
				tilezoomController.joyPlacement(applicationController.get('picture'), true);
			} else {
				tilezoomController.send('resize');
			}
		},

		country: (function () {
			var application = this.get('controllers.application');
			return application.get('picture.metadata.country');
		}).property('controllers.application.picture.metadata.country'),

		selectCountry: (function () {
			return this.setCountry();
		}).property('controller.country'),

		setCountry: function setCountry() {
			var me = this,
			    controller = me.get('controller');

			var c = controller.get('country');
			$('#picture-country').val(c);
			this.setCountryPlaceholderClass(c);
			return c;
		},

		setCountryPlaceholderClass: function setCountryPlaceholderClass(c) {
			if (c && c.length > 0) {
				$('#picture-country').addClass('hasValue');
			} else {
				$('#picture-country').removeClass('hasValue');
			}
		},

		getCountry: function getCountry() {

			var me = this,
			    controller = me.get('controller');

			var pc = $('#picture-country');
			var application = controller.get('controllers.application');

			pc.on('change', function () {
				var v = pc.val();
				application.set('picture.metadata.country', v);
				me.setCountryPlaceholderClass(v);
			});
		},

		/**
	  * Callback Function from base-view after show transition
	  */
		afterShowCSSTransition: function afterShowCSSTransition() {

			var me = this,
			    $element = me.$();

			if (!$element) {
				return false;
			}

			var orientation = UTIL.getOrientation(),
			    swiperWrapper = $element.find('.my-wonderlock-swiper .buttons-horizontal');

			if (this.swiper) {
				this.destroySwiper();
			}

			if (swiperWrapper && swiperWrapper.get(0)) {
				if (orientation === 'portrait') {

					swiperWrapper.addClass('swiper-wrapper');

					var loadSwiper = function loadSwiper() {
						me.swiper = new Swiper('.my-wonderlock-swiper', {
							slidesPerView: 'auto',
							mousewheelControl: true,
							spaceBetween: 0,
							freeMode: true
						});
					};

					setTimeout(function () {
						loadSwiper();
					}, 100);
				} else {
					swiperWrapper.removeClass('swiper-wrapper');
				}
			}

			// move to my picture
			this.zoomToPhoto();
			this.setCountry();
			this.getCountry();
		},

		afterToggleShowCSSTransition: function afterToggleShowCSSTransition() {
			this.zoomToPhoto();
		},

		destroySwiper: function destroySwiper() {

			var me = this,
			    $element = me.$();

			if (!$element) {
				return false;
			}

			try {
				if (this.swiper && typeof this.swiper.destroy === 'function') {
					this.swiper.destroy();
					this.swiper = null;
				}
			} catch (e) {
				if (typeof console === 'object') {
					console.log('Swiper Error', e);
				}
			}
		},

		/**
	  * Called when the element of the view is going to be destroyed.
	  * Override this function to do any teardown that requires an element, like removing event listeners.
	  */
		willDestroyElement: function willDestroyElement() {
			this.destroySwiper();
		},

		actions: {

			zoomtophoto: function zoomtophoto() {
				this.zoomToPhoto();
			},

			getLocation: function getLocation() {

				var me = this,
				    controller = me.get('controller');

				controller.set('locationProzessing', true);

				var callback = function callback() {
					var success = function success(pos) {
						var location = $.extend(true, {}, pos.coords);
						var latlng = { lat: location.latitude, lng: location.longitude };

						var fn = function fn(addr) {
							if (addr && typeof addr === 'object' && addr[0]) {
								//if(typeof console === 'object') { console.log('FOUND ADDR',addr); }
								var cpc = UTIL.googleMapsApi.getCityPOICountry(addr);
								controller.send('setLocation', cpc, latlng);
							}
							controller.set('locationProzessing', false);
						};

						UTIL.googleMapsApi.geocodeLatLng(latlng, fn);
					};

					var error = function error(e) {
						if (typeof console === 'object') {
							console.log('getLocation error', e);
						}
						controller.set('locationProzessing', false);
						controller.get('controllers.error-modal').raise({
							statusText: 'Sorry',
							statusCode: e.code || 404,
							reason: e,
							message: e.message || 'An error occured, please try again later.' //me.t('upload.upload_save_error')
						});
					};

					UTIL.googleMapsApi.getLocation(success, error);
				};

				var lang = controller.get('controllers.application').get('model.lang');
				UTIL.googleMapsApi.loadApi('AIzaSyAkgdcSdwEMWtEcMUvW00jg6QOc6QsrWfk', lang, callback);
			}

		}

	});

});