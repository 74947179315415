define('wow-app/views/congratulation', ['exports', 'ember', 'wow-app/views/base-routing-view'], function (exports, Ember, BaseRoutingView) {

	'use strict';

	exports['default'] = BaseRoutingView['default'].extend({

		classNames: 'view-congratulation',

		/**
	  * zoom to "my" photo
	  * But only if we are already on the right mosaic
	  */
		zoomToPhoto: function zoomToPhoto() {
			var me = this,
			    controller = me.get('controller'),
			    tilezoomController = controller.get('controllers.tilezoom'),
			    applicationController = controller.get('controllers.application'),
			    picture = applicationController.get('picture');

			if (controller.get('pictureIsOnActiveMosaic')) {
				tilezoomController.joyOut(applicationController.get('picture'));
			} else {
				tilezoomController.send('resize');
			}
		},
		/**
	  * Callback Function from base-view after show transition
	  */
		afterShowCSSTransition: function afterShowCSSTransition() {
			var showMyPicture = UTIL.storage.getSession('showMyPicture');
			UTIL.storage.removeSession('showMyPicture');
			if (showMyPicture) {
				this.zoomToPhoto();
			}
		}

	});

});