define('wow-app/controllers/about-arena', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

  'use strict';

  exports['default'] = BaseController['default'].extend({

    needs: ['application', 'error-modal'],

    isLoading: false

  });

});