define('wow-app/routes/my-wonderlock', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		isSecure: false,
		docTitle: 'My Photo',

		baseRouteViewSettings: {
			visibleContent: 'my-wonderlock',

			templates: {
				bottom: {
					name: 'my-wonderlock',
					outlet: 'bottom',
					height: 275,
					width: 444,
					parent: 'mosaic',
					scrollable: true, //'-buttonarea',
					resizeTilezoom: 'soft'
				}
			},
			showContent: true
		},

		getCountries: function getCountries(controller) {
			$.getJSON('/country-selection').then(function (data) {
				var result = [];
				var idx = 0;
				for (var i in data) {
					result[idx] = { id: i, label: data[i] };
					idx++;
				}
				controller.set('countries', result);
			});
		},

		afterModel: function afterModel() {
			this.updateBaseView();
		},

		setupController: function setupController(controller, model) {
			this.getCountries(controller);
		},

		renderTemplate: function renderTemplate(controller, model) {

			var applicationController = this.controllerFor('application');
			var picture = applicationController.get('picture');

			this.render({
				into: 'mosaic',
				outlet: 'bottom',
				model: picture
			});
		}

	});

});