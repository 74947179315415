define('wow-app/templates/privacy-policy/ar', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("آخر تحديث: نوفمبر 2015");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("NewOpenWorld Corporation (\"us\", \"we\", or \"our\") operates ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","//www.wonderswall.com");
        var el4 = dom.createTextNode("www.wonderswall.com");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (the \"Site\").");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("تطلعك هذه الصفحة على سياساتنا التي تدور حول جمع واستخدام ونشر البيانات الشخصية التي ترد إلينا من مستخدمي الموقع, نستخدم معلوماتك الشخصية فقط لتزويد الموقع وتحسينه, تعي أن استخدامك للموقع يعني موافقتك لجمع المعلومات واستخدامها وفقا لهذا السياسة.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("جمع المعلومات واستخدامها");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("قد نطلب أثناء استخدامك للموقع أن تزودنا بمعلومات تعريفية شخصية محددة والتي يمكن أن تُستخدم للاتصال بك أو تحديد هويتك, وقد تتضمن هذه المعلومات اسمك (\"معلومات شخصية\") ولا تقتصر على ذلك.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("بيانات الدخول");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("كحال العديد من مشغلي المواقع, نجمع المعلومات التي يرسلها متصفحك كلما زرت موقعنا (\"بيانات الدخول\"), وقد تتضمن هذه البيانات معلومات كعنوان بروتوكول الإنترنت (\"IP\") لحاسبك, نوع المتصفح وإصداره صفحات موقعنا التي تزورها ووقت الزيارة وتاريخها ومقدار الوقت الذي تمضيه على تلك الصفحات وغيرها من الإحصائيات, وبالإضافة إلى ما سبق, قد نستخدم خدمات طرف خارجي لجمع هذه البيانات ومراقبتها وتحليلها.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("التواصل");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("قد نستعين بمعلوماتك الشخصية لتزويدك برسائلنا الإخبارية والمواد الدعائية والتسويقية ومعلومات أخرى تهمك.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("ملفات تعريف الارتباط");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("هي ملفات تحتوي على مساحة صغيرة من البيانات التي قد تشمل مُعرِف فريد من نوعه دون ذكر الاسم, تُرسَل هذه الملفات إلى متصفحك من موقع إلكتروني وتُخزَن على القرص الصلب لحاسوبك أو هاتفك المحمول, كحال العديد من المواقع, نرسل \"ملفات الارتباط\" هذه لجمع المعلومات, قد لا تتمكن من استخدام بعض أجزاء من موقعنا إذا لم توافق على استلام هذه الملفات.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("الأمن");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("نضع أمن معلوماتك الشخصية نصب أعيننا, مع العلم أنه ليس ثمة طريقة إرسال عبر الإنترنت أو تخزين إلكتروني آمنة 100 %, بالرغم من سعينا الدائم لاستخدام وسائل مقبولة تجاريا لحماية معلوماتك الشخصية, فإننا لا نستطيع أن نضمن تأمينها بشكل مطلق.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("تغييرات على سياسة الخصوصية المذكورة");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("تظل سياسة الخصوصية فعّالة اعتبارا من آخر تحديث مبين أعلاه, وستبقى كذلك باستثناء وجود أية تغييرات في أحكامها أو طبيعتها والتي ستصبح فعّالة على الفور عقب نشرها على هذه الصفحة, حيث نحتفظ بحق تحديث سياسة الخصوصية الخاصة بنا أو تغيير أي جزء منها في أي وقت وعليك أن تتطلع على هذه السياسة دوريا, حيث يعني استمرارك في استخدام الخدمة بعد نشرنا لأية تعديلات لسياسة الخصوصية على هذه الصفحة أنك تقر بالتعديلات وتوافق على الالتزام بسياسة الخصوصية المُعدلَة وتلتزم بها, كما سوف نخطرك إما باستخدام معلوماتك الشخصية أو من خلال نشر ملاحظة بارزة على الموقع في حال أجرينا أية تغييرات جوهرية لسياسة الخصوصية هذه.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("تواصل معنا");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("إذا كان لديك أية أسئلة حول سياسة الخصوصية هذه, برجاء التكرم وزيارة ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3,"href","mailto:support@wonderswall.com");
        var el4 = dom.createTextNode("contact us");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        return fragment;
      }
    };
  }()));

});