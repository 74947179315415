define('wow-app/locales/it', ['exports', 'wow-app/locales/en'], function (exports, EN) {

    'use strict';

    var IT = {
        "about": {
            "sub_title": "\nLascia la tua traccia!<br />Unisciti al Muro delle Meraviglie!<br />Partecipa anche tu!",
            "text": "<p>Centinaia di milioni di voti hanno scelto le New7Wonders, le Nuove 7 Meraviglie, ed ora TU puoi far parte del Muro delle Meraviglie con una tua foto lassù, su uno dei New7Wonders.</p><p>Scegli la tua Meraviglia. Carica la tua foto. Codividila con tutti. <br />Sì, ora fai parte del <a href=\"https://twitter.com/hashtag/wonderswall\" target=\"_blank\">#wonderswall</a>!!!</p>"
        },
        "buttons": {
            "cancel": "Annulla",
            "change_photo": "Cambia la foto",
            "change_wall": "Cambia Muro",
            "change_wonder": "Cambia Meraviglia",
            "choose_wonder": "Scegli la Meraviglia",
            "close": "Chiudi",
            "crop_and_save": "Ritaglia la foto e salva",
            "crop_photo": "Ritaglia la foto",
            "join": "Registrati",
            "log_out": "Esci (Log-out)",
            "ok": "OK",
            "register": "Registra",
            "save": "Salva",
            "select": "Seleziona",
            "share": "Condividi",
            "sign_in": "Associatevi",
            "sign_up": "Registrati",
            "upload": "Carica foto",
            "copy": "Copia",
            "yes": "Sì!",
            "edit": "Modifica",
            "forget_password": "Ha dimenticato la password?"
        },
        "congratulation": {
            "sub_title": "Ora fai parte del<br>Muro delle Meraviglie",
            "title": "Congratulazioni"
        },
        "global": {
            "error_title": "Siamo spiacenti",
            "next": "Passa al successivo",
            "previous": "Ritorna al precedente",
            "title": "Muro delle Meraviglie"
        },
        "joyscreen": {
            "join": "Unisciti al<span class=\"break-portrait break-landscape\"></span>Muro delle Meraviglie",
            "title": "Vuoi rimanere sul<span class=\"break-portrait break-landscape\"></span>Muro delle Meraviglie?",
            "welcome": "Benvenuti sul<span class=\"break-portrait break-landscape\"></span>Muro delle Meraviglie"
        },
        "mosaic": {
            "manmade": {
                "title": "Le Nuove 7 Meraviglie del Mondo"
            },
            "nature": {
                "title": "Le nuove 7 Meraviglie della Natura"
            }
        },
        "my_photo": {
            "my_wonder": "La mia Meraviglia",
            "no_login": "Non hai effettuato il Log-in! Devi registrarti per poter restare sul Muro delle Meraviglie.",
            "no_photo": "Non hai ancora caricato alcuna foto...",
            "status": "Stato",
            "sub_title": "Cambio di foto o cambio di Meraviglia",
            "title": "La mia foto",
            "uploaded": "Caricato",
            "motto": "Scrivi un commento",
            "motto_placeholder": "Commenta la tua foto",
            "location": "Luogo",
            "city": "Città",
            "country": "Paese",
            "ping": "WOW!",
            "my_wows": "I miei WOW",
            "wowed_by": "WOW di altri",
            "no_wows": "Nessun WOW"
        },
        "register": {
            "email": "Email",
            "logged_in_as": "Login come",
            "password": "Password",
            "sign_in_email": "Registrazione via email",
            "sign_up_email": "Registrazione via email"
        },
        "seo": {
            "meta_description": "Centinaia di milioni di voti hanno scelto le New7Wonders ed ora TU puoi far parte del Muro delle Meraviglie."
        },
        "settings": {
            "change_language": "Lingua",
            "email": "Email",
            "facebook": "Facebook",
            "login": "Inizio sessione (Log-in)",
            "new_to_wall": "Vuoi far parte del Muro delle Meraviglie?",
            "on_the_wall": "Fai già parte del Muro delle Meraviglie?",
            "signin": "Registrati",
            "title": "Impostazioni",
            "twitter": "Twitter",
            "upload_photo": "Carica una foto"
        },
        "share": {
            "fb_caption": "Faccio ora parte del Muro delle Meraviglie.",
            "fb_description": "Partecipate! Caricate la vostra foto sul sito www.wonderswall.com e diventatene parte! #wonderswall",
            "fb_name": "Muro delle Meraviglie",
            "fb_published": "Il tuo post è stato pubblicato.",
            "sub_title": "Condividi con i tuoi amici,<br>ritrovatevi sul Muro delle Meraviglie",
            "title": "Condividi",
            "whatsapp": "WhatsApp",
            "link": "Link",
            "email_from": "Da",
            "email_to": "A",
            "email_subject": "Oggetto",
            "email_text": "Il tuo testo",
            "email_to_placeholder": "Separa più indirizzi di email con delle virgole",
            "email_successfully_send": "Email inviata con successo"
        },
        "stay": {
            "accept": "Accetta i Termini e le condizioni",
            "title": "Resta sul <span class=\"break-portrait\"></span>Muro delle Meraviglie",
            "privacy_policy": "Informativa sulla privacy"
        },
        "terms": {
            "accept": "Accettate i nostri Termini e condizioni",
            "title": "Termini e condizioni"
        },
        "top": {
            "title": "Elementi più caricati"
        },
        "upload": {
            "image_error": "Impossibile caricare la foto; per favore torna indietro e riprova",
            "import_fb": "Facebook",
            "import_insta": "Instagram",
            "import_my_photos": "Le tue foto",
            "import_tw": "Twitter",
            "import_webcam": "Webcam",
            "sub_title": "o scegli un'opzione tra quelle qui sotto",
            "take_photo": "Scatta una foto",
            "title": "Scatta una foto",
            "upload_error": "Si è verificato un errore nel caricamento della vostra immagine",
            "upload_save_error": "Impossibile salvare la foto, per favore torna indietro e riprova",
            "webcam_allow_error": "Fate clic su \"consenti\" per scattare la foto",
            "webcam_error": "La tua webcam non funziona con il tuo browser. Aggiorna il browser o usane un altro."
        },
        "upload_choose": {
            "change_title": "Cambia Meraviglia",
            "sub_title": "per caricarvi su la tua foto",
            "title": "Scegli la tua Meraviglia"
        },
        "upload_steps": {
            "1": "Passo 1",
            "2": "Passo 2",
            "3": "Passo 3"
        },
        "wall": {
            "upload_photo": "Carica la tua foto sul Muro delle Meraviglie"
        },
        "wonders": {
            "amazon": {
                "location": "America meridionale",
                "title": "Amazzonia"
            },
            "christ_redeemer": {
                "location": "Brasile",
                "title": "Cristo Redentore"
            },
            "colosseum": {
                "location": "Italia",
                "title": "Colosseo"
            },
            "great_wall_of_china": {
                "location": "Cina",
                "title": "La Grande muraglia"
            },
            "ha_long_bay": {
                "location": "Vietnam",
                "title": "La baia Ha Long"
            },
            "iguazu_falls": {
                "location": "Argentina/Brasile",
                "title": "Cascate dell'Iguassù"
            },
            "jeju_island": {
                "location": "Corea del Sud",
                "title": "Isola Jeju"
            },
            "komodo": {
                "location": "Indonesia",
                "title": "Komodo"
            },
            "machu_pichu": {
                "location": "Perú",
                "title": "Machu Picchu"
            },
            "petra": {
                "location": "Il Giordano",
                "title": "Petra"
            },
            "pp_underground_river": {
                "location": "Filippine",
                "title": "Fiume sotterraneo PP"
            },
            "pyramid_at_chichen_itza": {
                "location": "Messico",
                "title": "Chichén Itzá"
            },
            "table_mountain": {
                "location": "Sud Africa",
                "title": "Table Mountain - Parco nazionale"
            },
            "taj_mahal": {
                "location": "India",
                "title": "Taj Mahal"
            }
        },
        "oauth_errors": {
            "login_failed": "Il Log In da social network non ha funzionato."
        }
    };

    IT = $.extend(true, {}, EN['default'], IT);

    exports['default'] = IT;

});