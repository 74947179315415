define('wow-app/helpers/photo-url', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(function (photo, key) {

		if (!photo) {

			return '';
		}

		var url = this.get('globals').get('srvParams.AssetsPath') + '/picture/' + key + '/' + photo.get('id');
		return new Ember['default'].Handlebars.SafeString(url);
	});

});