define('wow-app/controllers/upload-photo', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'mosaic', 'tilezoom', 'upload', 'error-modal'],

		tilezoom: Ember['default'].computed.alias("controllers.tilezoom"),

		// Todo: UploadPhotoController photoLimit needs to be dynamic
		photoLimit: (function () {
			return 1;
		}).property(),

		photoCount: (function () {

			return this.get('tilezoom').get('photos.length');
		}).property('tilezoom.photos.length'),

		actions: {

			goToMyPhotos: function goToMyPhotos() {

				this.transitionToRoute('myphotos');
			},

			beforeUpload: function beforeUpload(file, xhr, formData) {

				var model = this.get('controllers.mosaic').get('model');

				formData.append('mosaicId', model.get('id'));
			},

			progress: function progress(file, _progress, bytesSent) {},

			success: function success(file, response, e) {

				if (response.success && response.data) {
					$(window).trigger('track_pupload_done');
					this.get('controllers.upload').send('photoUploaded', response.data);
				}
			},

			error: function error(file, xhr) {
				$(window).trigger('track_pupload_error');
				this.get('controllers.upload').send('error', xhr);
			}
		}

	});

	/*
	import Ember from 'ember';

	var UploadPhotouploadController = Ember.Controller.extend({

		needs: [ 'mosaic', 'tilezoom', 'upload' ],

		tilezoom: Ember.computed.alias("controllers.tilezoom"),

		photoCount: function() {

			return this.get('tilezoom').get('photos.length');
		}.property('tilezoom.photos.length'),

		actions: {

			goToMyPhotos: function () {

				this.transitionToRoute('myphotos');
			},

			beforeUpload: function ( file, xhr, formData ) {

				var model = this.get('controllers.mosaic').get('model');

				formData.append('mosaicId', model.get('mosaicId') );
			},

			progress: function ( file, progress, bytesSent ) {

			},

			success: function ( file, response, e ) {

				if ( response.success && response.data ) {
					$(window).trigger('track_pupload_done');
					this.get('controllers.upload').send('photoUploaded', response.data);
				}
			},

			error: function ( file, xhr ) {
				$(window).trigger('track_pupload_error');
				this.get('controllers.upload').send('error', xhr);
			}
		}
	});

	export default UploadPhotouploadController;
	*/

});