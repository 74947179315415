define('wow-app/views/wowed-by', ['exports', 'ember', 'wow-app/views/base-routing-view'], function (exports, Ember, BaseRoutingView) {

	'use strict';

	exports['default'] = BaseRoutingView['default'].extend({

		classNames: ['hideOnLoad', 'view-my-wows', 'view-wowed-by'],

		/**
	  * Callback Function from base-view after show transition
	  */
		afterShowCSSTransition: function afterShowCSSTransition() {

			var me = this,
			    $element = me.$();

			if (!$element) {
				return false;
			}
		}

	});

});