define('wow-app/locales/en', ['exports'], function (exports) {

    'use strict';

    var EN = {
        "about": {
            "sub_title": "Leave your mark!<br />Join the WondersWall!<br />Be there!",
            "text": "<p>Hundreds of millions of votes have chosen the New7Wonders, and now YOU can be part of the WondersWall with your own photo up there within one of the New7Wonders.</p><p>Choose your Wonder. Upload your photo. Share with everyone. <br />Yes you are now part of the <a href=\"https://twitter.com/hashtag/wonderswall\" target=\"_blank\">#wonderswall</a>!!!</p>"
        },
        "buttons": {
            "change_position": "Change Position",
            "back_to_admin": "Back to admin",
            "cancel": "Cancel",
            "change_photo": "Change photo",
            "change_wall": "Change Wall",
            "change_wonder": "Change Wonder",
            "choose_wonder": "Choose Wonder",
            "close": "Close",
            "crop_and_save": "Crop photo and save",
            "crop_photo": "Crop photo",
            "join": "Join",
            "log_out": "Log out",
            "ok": "OK",
            "register": "Register",
            "save": "Save",
            "select": "Select",
            "share": "Share",
            "sign_in": "Sign In",
            "sign_up": "Sign up",
            "upload": "Upload photo",
            "copy": "Copy",
            "yes": "Yes!",
            "edit": "Edit",
            "forget_password": "Forgot Password?"
        },
        "congratulation": {
            "sub_title": "You are now part of the<br>WondersWall",
            "title": "Congratulations"
        },
        "global": {
            "error_title": "Sorry",
            "next": "Next",
            "previous": "Previous",
            "title": "WondersWall"
        },
        "joyscreen": {
            "join": "Join the<span class=\"break-portrait break-landscape\"></span>WondersWall",
            "title": "Want to stay on the<span class=\"break-portrait break-landscape\"></span>WondersWall?",
            "welcome": "Welcome on the<span class=\"break-portrait break-landscape\"></span>WondersWall"
        },
        "mosaic": {
            "manmade": {
                "title": "New 7 Wonders of the World"
            },
            "nature": {
                "title": "New 7 Wonders of Nature"
            }
        },
        "my_photo": {
            "my_wonder": "My Wonder",
            "no_login": "You are not looged in! You need to register in order to stay on the WondersWall.",
            "no_photo": "You have no photo uploaded yet...",
            "status": "Status",
            "sub_title": "Change photo or change Wonder",
            "title": "My photo",
            "uploaded": "Uploaded",
            "motto": "Say something",
            "motto_placeholder": "Tell us about your photo",
            "location": "Location",
            "city": "City",
            "country": "Country",
            "ping": "WOW!",
            "my_wows": "My WOWs",
            "wowed_by": "WOWed by",
            "no_wows": "No WOWs yet."
        },
        "register": {
            "email": "Email",
            "logged_in_as": "Logged in as",
            "password": "Password",
            "sign_in_email": "Sign in by email",
            "sign_up_email": "Sign up by email"
        },
        "seo": {
            "meta_description": "Hundreds of millions of votes have chosen the New7Wonders, and now YOU can be part of the WondersWall."
        },
        "settings": {
            "change_language": "Language",
            "email": "E-Mail",
            "facebook": "Facebook",
            "instagram": "Instagram",
            "login": "Log In",
            "new_to_wall": "Join the WondersWall?",
            "on_the_wall": "Already on the WondersWall?",
            "signin": "Sign Up",
            "title": "Settings",
            "twitter": "Twitter",
            "upload_photo": "Upload a photo"
        },
        "share": {
            "fb_caption": "I am now part of the WondersWall.",
            "fb_description": "Join me! Upload your photo at www.wonderswall.com and you too can become the wonder! #wonderswall",
            "fb_name": "WondersWall",
            "fb_published": "Your Post was published.",
            "sub_title": "Share with your friends,<br>join together on the WondersWall",
            "title": "Share",
            "whatsapp": "Whatsapp",
            "link": "Link",
            "email_from": "From",
            "email_to": "To",
            "email_subject": "Subject",
            "email_text": "Your Text",
            "email_to_placeholder": "Separate several addresses with comma",
            "email_successfully_send": "Email successfully send",
            "instagramExplanation": "Please right click / long touch on the picture and download it to your photo library.",
            "instagramTextSuggesttion": "Text suggestion"
        },
        "stay": {
            "accept": "Accept Terms & Conditions",
            "title": "Stay on the <span class=\"break-portrait\"></span>WondersWall",
            "privacy_policy": "Privacy Policy"
        },
        "terms": {
            "accept": "Please accept our Terms & Conditions",
            "title": "Terms & Conditions"
        },
        "top": {
            "title": "Most Uploaded"
        },
        "upload": {
            "image_error": "Cannot load photo, please go back and upload again",
            "import_fb": "Facebook",
            "import_insta": "Instagram",
            "import_my_photos": "Your photos",
            "import_tw": "Twitter",
            "import_webcam": "Webcam",
            "sub_title": "or import from below",
            "take_photo": "Take photo",
            "title": "Take photo",
            "upload_error": "An error occured uploading your image",
            "upload_save_error": "Cannot save photo, please go back and upload again",
            "webcam_allow_error": "Click \"allow\" to take photo",
            "webcam_error": "Your webcam does not work with your browser, please update your software"
        },
        "upload_choose": {
            "change_title": "Change your Wonder",
            "sub_title": "and upload your photo on to it",
            "title": "Choose your Wonder"
        },
        "upload_steps": {
            "1": "Step 1",
            "2": "Step 2",
            "3": "Step 3"
        },
        "wall": {
            "upload_photo": "Upload your photo on the WondersWall"
        },
        "wonders": {
            "amazon": {
                "location": "South America",
                "title": "Amazon"
            },
            "christ_redeemer": {
                "location": "Brasil",
                "title": "Christ Redeemer"
            },
            "colosseum": {
                "location": "Italy",
                "title": "Colosseum"
            },
            "great_wall_of_china": {
                "location": "China",
                "title": "Great Wall"
            },
            "ha_long_bay": {
                "location": "Vietnam",
                "title": "Ha Long Bay"
            },
            "iguazu_falls": {
                "location": "Argentina/Brazil",
                "title": "Iguazu Falls"
            },
            "jeju_island": {
                "location": "South Korea",
                "title": "Jeju Island"
            },
            "komodo": {
                "location": "Indonesia",
                "title": "Komodo"
            },
            "machu_pichu": {
                "location": "Peru",
                "title": "Machu Picchu"
            },
            "petra": {
                "location": "Jordan",
                "title": "Petra"
            },
            "pp_underground_river": {
                "location": "Philippines",
                "title": "PP Underground River"
            },
            "pyramid_at_chichen_itza": {
                "location": "Mexico",
                "title": "Chichén Itzá"
            },
            "table_mountain": {
                "location": "South Africa",
                "title": "Table Mountain"
            },
            "taj_mahal": {
                "location": "India",
                "title": "Taj Mahal"
            }
        },
        "oauth_errors": {
            "login_failed": "Log in via social network failed or was cancelled."
        }
    };

    exports['default'] = EN;

});