define('wow-app/views/application', ['exports', 'ember'], function (exports, Ember) {

    'use strict';

    exports['default'] = Ember['default'].View.extend({

        classNames: ['app-wrapper'],
        classNameBindings: ['showUploadTeaser:upload-teaser'],

        showUploadTeaser: (function () {
            return this.get('controller').get('showUploadTeaser');
        }).property('controller.showUploadTeaser'),

        didInsertElement: function didInsertElement() {

            //$(function() {
            //    document.addEventListener("touchmove", function(e){
            //        e.preventDefault();
            //    }, false);
            //});

        }

    });

});