define('wow-app/views/signin-email-modal', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].View.extend({

		//_isProcessing: function () {
		//
		//	if(typeof console === 'object') { console.log('isProcessing',this.get('controller').get('isProcessing')); }
		//	this.setLoadingAnimationHeight();
		//
		//}.observes('controller.isProcessing'),
		//
		//
		//
		//setLoadingAnimationHeight: function() {
		//
		//
		//	var $element = this.$(this.element),
		//		height = $element.find('.modal-content').height();
		//
		//	if(typeof console === 'object') { console.log('setLoadingAnimationHeight',height,$element); }
		//
		//	if(height) {
		//		var loadingAnimation = $element.find('.loading-animation');
		//		if(loadingAnimation && loadingAnimation.get(0)) {
		//			loadingAnimation.css('height',height);
		//		}
		//	}
		//
		//	return height;
		//
		//},
		//
		//actions: {
		//
		//	signIn: function() {
		//
		//		var $element = this.$(this.element),
		//			controller = this.get('controller'),
		//			model = controller.get('model'),
		//			callback;
		//
		//		//this.setLoadingAnimationHeight();
		//
		//		//var modal = $element.find('.wow-modal');
		//		//modal.addClass('lazyLoader');
		//		//
		//		//
		//		//var callback = function() {
		//		//	modal.removeClass('lazyLoader');
		//		//};
		//
		//		// save user in controller...
		//		controller.send('signIn',callback);
		//
		//	}
		//}

	});

});