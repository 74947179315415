define('wow-app/routes/oauth-login', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({

		model: function model(params, transition) {

			var me = this,
			    controller = me.controllerFor('application'),
			    mosaicId = controller.getMosaicId();

			var oauthLoginType = UTIL.storage.getSession('oauth-login');
			var lastRoute = UTIL.storage.getSession('oauth-login-route');
			var external_connect_error = UTIL.storage.getSession('external_connect_error');

			if (external_connect_error && typeof external_connect_error !== 'undefined' && external_connect_error.indexOf('t-') === 0) {
				external_connect_error = this.t(str_replace('t-', '', external_connect_error));
			}

			UTIL.storage.removeSession('oauth-login');
			UTIL.storage.removeSession('oauth-login-route');
			UTIL.storage.removeSession('external_connect_error');

			var applicationController = this.controllerFor('application');
			var user = applicationController.get('user');

			// this is a login, we remove any unknown user pic,
			// because this user should already have it´s own user picture
			if (lastRoute === 'settings-modal') {
				Cookies.remove('pic_anon_owner_token', { path: '/' });
			}

			return this.store.findQuery('picture', { mosaicId: mosaicId }).then(function (pictures) {

				var picture;
				if (typeof pictures === 'object') {
					picture = pictures.get('firstObject');
				}
				//transition.abort();

				if (picture && !applicationController.get('userLoggedIn')) {

					me.controllerFor('error-modal').raise({
						statusText: 'Sorry',
						statusCode: 404,
						reason: external_connect_error,
						message: external_connect_error || 'Login failed'
					});

					me.transitionTo('user-stay', mosaicId, picture.get('id'));
				} else if (!picture && applicationController.get('userLoggedIn')) {

					me.transitionTo('choose-wonder', mosaicId);
				} else if (!picture && !applicationController.get('userLoggedIn')) {

					me.controllerFor('error-modal').raise({
						statusText: 'Sorry',
						statusCode: 404,
						reason: external_connect_error,
						message: external_connect_error || 'Login failed'
					});

					me.transitionTo('choose-wonder', mosaicId);
				} else {

					// Todo: combine this and signup-email-modal to one function
					var firstPicId = null,
					    firstPic;

					pictures.forEach(function (item) {
						// this is not the correct id of the user (it is checkuser)
						// backend should take care of the correct id
						// but we need to change something, to save it
						if (!firstPicId) {
							firstPic = item;
							firstPicId = item.get('id');
						}

						var hasProfileId = item.get('metadata.profileId');
						if (!hasProfileId || hasProfileId.length === 0) {
							item.set('metadata.profileId', user.get('token'));
							item.save().then(function () {
								// Cookie will be removed on the first picture...
								// this should be done, when all images are saved
								Cookies.remove('pic_anon_owner_token', { path: '/' });
							});
						}
					});

					applicationController.set('picture', pictures.get('firstObject'));
					applicationController.set('pictures', pictures);
					picture = applicationController.get('picture');

					switch (lastRoute) {
						case 'user-stay':
							UTIL.tracker.track('event', { category: 'User', action: 'signup', label: UTIL.tracker.capitalize(oauthLoginType) + ' Sign-Up', value: 1 });
							UTIL.storage.storeSession('showMyPicture', 1);
							me.transitionTo('congratulation', mosaicId, picture.get('id'));
							break;
						default:
							me.transitionTo('my-wonderlock', mosaicId);
					}
				}

				return pictures;
			}, function (reason) {

				transition.abort();

				me.controllerFor('error-modal').raise({
					statusText: 'Sorry',
					statusCode: 404,
					reason: reason,
					message: external_connect_error || 'Login failed'
				});

				me.transitionTo('choose-wonder', mosaicId);

				return null;
			});
		}

	});

});