define('wow-app/controllers/user-stay', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'signup-email-modal', 'signin-email-modal', 'error-modal', 'accept-terms-modal'],

		acceptedTerms: true,

		checkAcceptedTerms: function checkAcceptedTerms() {

			if (!this.get('acceptedTerms')) {
				this.get('controllers.error-modal').raise({
					statusText: this.t('terms.title'),
					statusCode: 401,
					message: this.t('terms.accept')
				});
				return false;
			}

			return true;
		},

		actions: {

			CheckTermsAndlinkToOAuth: function CheckTermsAndlinkToOAuth(type, route) {
				if (this.checkAcceptedTerms()) {
					this.get('controllers.application').send('oauthLogin', type, route);
				}
			},

			CheckTermsAndlinkToModal: function CheckTermsAndlinkToModal(route) {
				if (this.checkAcceptedTerms()) {
					var modal = this.get('controllers.' + route);
					modal.show({});
				}
			}

		}
	});

});