define('wow-app/components/settings-button', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({

		needs: ['settings-modal'],

		classNames: ['settings-button'],
		tagName: 'a',
		attributeBindings: ['href'],
		href: "/settings",

		click: function click(e) {

			var $element = this.$();
			e.preventDefault();

			// animate
			//$element.addClass('animated rotateIn');
			//$element.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
			//	$element.removeClass('rotateIn').removeClass('animated');
			//});

			var loginmodal = UTIL._parentView_getControllerFor(this, 'controllers.settings-modal');
			loginmodal.show({});
		}

	});

});