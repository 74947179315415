define('wow-app/views/joy-screen', ['exports', 'wow-app/views/base-routing-view'], function (exports, BaseRoutingView) {

	'use strict';

	//import Ember from 'ember';
	exports['default'] = BaseRoutingView['default'].extend({

		classNames: ['view-joy-screen'],
		welcomeScreen: null,

		/**
	  * Donno, sometimes I get no model here... :(
	  */
		getModel: function getModel() {

			var m,
			    controller = this.get('controller');

			if (controller.get('runningPicture')) {
				return controller.get('runningPicture');
			} else if (controller.get('model')) {
				return controller.get('model');
			}

			return m;
		},

		/**
	  * Wait until the image is proloaded before we tell the base-view that this view is readay
	  */
		didInsertElement: function didInsertElement() {

			var me = this,
			    model = this.getModel(),
			    controller = this.get('controller'),
			    tilezoomController = controller.get('controllers.tilezoom');

			tilezoomController.preloadUserPicture(model, function (img) {
				if (!img) {
					me.setError();
				}
				me.set('isRendered', true);
			});
		},

		setError: function setError() {
			var controller = this.get('controller');

			controller.get('controllers.error-modal').raise({
				statusText: 'Sorry',
				statusCode: 404,
				route: 'choose-wonder',
				reason: null,
				message: controller.t('upload.image_error')
			});
		},

		/**
	  * Callback Function from base-view after show transition
	  */
		afterShowCSSTransition: function afterShowCSSTransition() {

			var me = this,
			    controller = me.get('controller'),
			    model = this.getModel(),
			    tilezoomController = controller.get('controllers.tilezoom');

			tilezoomController.tilezoom.afterJoyIn = function () {
				me.setWelcomeMessage();
				me.showJoyContent();
			};

			tilezoomController.joyIn(model);

			// hide complete navigation on tilezoom
			tilezoomController.set('zoomNavigation', false);
		},

		/**
	  * Remove and reposition welcome message if it is on the / behinde the image
	  * It is directl moved to tilezoom zoom holder, so that we can swipe and zoom on it
	  */
		setWelcomeMessage: function setWelcomeMessage() {

			if (this.welcomeScreen) {
				//this.resetScreen();
			}

			var me = this,
			    controller = me.get('controller'),
			    model = this.getModel(),
			    tilezoomController = controller.get('controllers.tilezoom'),
			    tilezoomSettings = tilezoomController.tilezoom;
			var route = UTIL.storage.getLocal('visibleContent');
			//var userPicture = $('.user-picture');
			//var pic = this.getModel();

			var scale = tilezoomSettings.cont.tilezoom('getScale');

			var y0 = tilezoomSettings.transform.y;
			var tilePicDimension = {
				width: parseInt(Math.ceil(tilezoomSettings.tileWidth * scale)),
				height: parseInt(Math.ceil(tilezoomSettings.tileHeight * scale)),
				left: model.get('metadata').pos[0] * scale,
				top: model.get('metadata').pos[1] * scale
			};

			//var picRelPos = tilePicDimension.top - y0;
			var mainStage = $('.zoom-container');
			var yCenter = tilezoomSettings.containerHeight / 2;

			if (!this.welcomeScreen) {
				$('<div>', {
					'class': 'joy-screen-welcome'
				}).appendTo(mainStage);
			}

			this.welcomeScreen = $('.joy-screen-welcome');
			this.contentWr = $('.app-bottom-overlay-wrapper');
			var screenWr = this.contentWr.find('.view-joy-screen');
			var title = screenWr.find('.tilezoom-overlay');

			title.appendTo(this.welcomeScreen);
			var transformed = this.welcomeScreen.css('transform');
			if (UTIL.user.isDevice) {
				transformed = false;
			}

			if (tilePicDimension.top > yCenter) {
				if (!transformed || transformed === 'none') {
					this.welcomeScreen.css({
						top: -this.welcomeScreen.height() + 'px'
					});
				}

				UTIL.transit(this.welcomeScreen, {
					x: 0,
					y: this.welcomeScreen.height() + 80
				}, {});
			} else {
				if (!transformed || transformed === 'none') {
					this.welcomeScreen.css({
						top: tilezoomSettings.containerHeight + 'px'
					});
				}

				UTIL.transit(this.welcomeScreen, {
					x: 0,
					y: (80 + this.welcomeScreen.height()) * -1
				}, {});
			}
		},

		resetScreen: function resetScreen() {

			var contentWr = $('.app-bottom-overlay-wrapper');
			var screenWr = contentWr.find('.view-joy-screen');

			contentWr.css({
				bottom: 0
			});

			var title = this.welcomeScreen.find('.tilezoom-overlay');
			title.prependTo(screenWr);

			this.destroyWelcomeScreen();
		},

		showJoyContent: function showJoyContent() {

			var contentWr = $('.app-bottom-overlay-wrapper');
			var screenWr = contentWr.find('.view-joy-screen');
			var parentView = this.get('parentView');
			var contentHeight = screenWr.height();

			fastdom.write(function () {

				contentWr.css({
					bottom: -contentHeight,
					height: contentHeight
				});

				fastdom.defer(1, function () {

					UTIL.transit(contentWr, { x: 0, y: -contentHeight }, {
						duration: parentView ? parentView.defaultCSSTransitionDuration : 300,
						easing: parentView ? parentView.defaultCSSTransitionEasing : 'cubic-bezier(0,1,.5,1)',
						callback: function callback() {}
					});
				});
			});
		},

		destroyWelcomeScreen: function destroyWelcomeScreen() {
			if (this.welcomeScreen) {
				this.welcomeScreen.remove();
				delete this.welcomeScreen;
			}
		},

		/**
	  * Called when the element of the view is going to be destroyed.
	  * Override this function to do any teardown that requires an element, like removing event listeners.
	  */
		willDestroyElement: function willDestroyElement() {
			var controller = this.get('controller'),
			    tilezoomController = controller.get('controllers.tilezoom');

			// show tilezoom navigation
			tilezoomController.set('zoomNavigation', true);
			this.destroyWelcomeScreen();
		}

	});

});