define('wow-app/routes/congratulation', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		docTitle: 'Congratulations',

		baseRouteViewSettings: {
			visibleContent: 'congratulation',
			activeNavigationItem: 'my-wonderlock',
			templates: {
				bottom: {
					//isRendered: true,
					name: 'congratulation',
					outlet: 'bottom',
					height: 210,
					width: 400,
					parent: 'mosaic',
					scrollable: true
				}
			},
			showContent: true
		},

		//beforeModel: function(transition) {
		//	var applicationController = this.controllerFor('application');
		//	applicationController.set('pictures',this.store.findQuery('picture', { mosaicId: transition.params.mosaic.mosaic_Id }));
		//},

		/**
	  * load current picture
	  *
	  * @param params
	  * @param transition
	  * @returns {*}
	  */
		model: function model(params, transition) {

			var me = this;

			// check for user
			var applicationController = this.controllerFor('application');
			var user = applicationController.get('user');
			var mosaic_id = this.getMosaicId(params, transition);

			if (!mosaic_id) {
				transition.abort();
				me.transitionTo('start');
				return null;
			}

			if (user) {
				return me.store.find('picture', params.photo_id).then(function (photo) {
					return photo;
				}, function () {
					me.transitionTo('choose-wonder', mosaic_id);
					return null;
				});
			} else {
				me.transitionTo('choose-wonder', mosaic_id);
				return null;
			}
		},

		afterModel: function afterModel() {
			this.updateBaseView();
		},

		renderTemplate: function renderTemplate(controller, model) {

			this.render({
				into: 'mosaic',
				outlet: 'bottom'
			});
		}

	});

});