define('wow-app/views/top', ['exports', 'ember', 'wow-app/views/base-routing-view'], function (exports, Ember, BaseRoutingView) {

	'use strict';

	exports['default'] = BaseRoutingView['default'].extend({

		classNames: ['hideOnLoad', 'view-top'],

		/**
	  *
	  */
		didInsertElement: function didInsertElement() {

			//this.onResponse();
			this.set('isRendered', true);
		},

		/**
	  * Align all list items to fit perfectly the full height
	  */
		onResponse: function onResponse() {

			var me = this,
			    controller = this.get('controller'),
			    $element = me.$(),
			    $textcontent = $element.find('.textcontent');

			// get height of ul and li
			var list = $element.find('.import-list ul');
			var listItems = $element.find('.import-list li');

			// Get maximal height from scrollContent
			var scrollWrapper = $element.find('.scrollWrapper');
			var testHeight = scrollWrapper.height() - $textcontent.outerHeight();

			if (testHeight > list.height()) {
				var rtl = $('html').hasClass('rtl');
				var newHeight = testHeight / listItems.length;

				// set image width and height
				var images = $element.find('.import-list li .image');
				images.css({
					height: newHeight + 'px',
					width: newHeight + 'px'
				});

				// set title-wrapper right position
				var titleWrappers = $element.find('.import-list li .title-wrapper');
				if (rtl) {
					titleWrappers.css({
						left: newHeight + 10 + 'px'
					});
				} else {
					titleWrappers.css({
						right: newHeight + 10 + 'px'
					});
				}

				// set height of list items (li)
				listItems.css({
					height: newHeight + 'px'
				});
			}
		},

		/**
	  * Callback Function from base-view after show transition
	  */
		afterShowCSSTransition: function afterShowCSSTransition() {
			this.onResponse();
		},

		actions: {

			gotoWonder: function gotoWonder(wonderId) {
				var parentView = this.get('parentView');
				UTIL.storage.storeSession('gotoWonder', wonderId);
				parentView.send('linkTo', 'tilezoommap');
			}

		}

	});

});