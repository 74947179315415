define('wow-app/views/photo-lock', ['exports', 'ember', 'wow-app/views/base-routing-view'], function (exports, Ember, BaseRoutingView) {

	'use strict';

	exports['default'] = BaseRoutingView['default'].extend({

		classNames: 'view-photo-crop-lock',

		/**
	  * component wonderlock options
	  */
		wonderlock: {
			hasActions: false,
			uploadPhoto: false,
			disableActions: false,
			isPreview: false
		}

	});

});