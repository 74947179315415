define('wow-app/components/bs-modal', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({

		classNames: ['wow-modal'],

		closeText: 'Close',
		okText: 'Ok',
		closeButton: true,

		// Donno, if we need this... Just a test
		title: (function (title) {
			//if(typeof console === 'object') { console.log('titletitletitle',title,this.get('title')); }
			return title || 'No Title';
		}).property('title'),

		/**
	  * set property closeModal in your controller to true to close the modal
	  * template needs {{bs-modal controllerCloseModal=closeModal }}
	  */
		onControllerClose: (function () {
			if (this.get('controllerCloseModal') === true) {
				this.$('.modal').modal('hide');
			}
		}).observes('controllerCloseModal'),

		actions: {
			ok: function ok() {
				this.$('.modal').modal('hide');
				this.sendAction('ok');
			}
		},

		show: (function () {
			this.$('.modal').modal().on('hidden.bs.modal', (function () {
				this.sendAction('close');
			}).bind(this));
		}).on('didInsertElement'),

		focusElement: function focusElement() {},
		selectElement: function selectElement() {},
		loadClipboard: function loadClipboard(instance) {

			var me = this;

			var setButtonInstanceClass = function setButtonInstanceClass() {
				var buttons = $('.' + instance);
				buttons.removeClass('btn-error').removeClass('btn-success').addClass('btn-wow');
			};

			var clipboard = new Clipboard('.' + instance, {
				target: function target(trigger) {
					var $trigger = $(trigger);
					var copyElementId = $trigger.attr('data-copy');
					var copyElement = $('#' + copyElementId);
					return copyElement.get(0);
				}
			});

			clipboard.on('success', function (e) {
				setButtonInstanceClass();
				var $trigger = $(e.trigger);
				$trigger.removeClass('btn-wow').addClass('btn-success');
				e.clearSelection();
				return false;
			});

			clipboard.on('error', function (e) {
				setButtonInstanceClass();
				var $trigger = $(e.trigger);
				$trigger.removeClass('btn-wow').addClass('btn-error');
				return false;
			});
		},

		didInsertElement: function didInsertElement() {

			// set title
			var title = this.get('title'),
			    $element = $(this.element);

			if (title.indexOf('t-') === 0) {
				title = this.t(str_replace('t-', '', title));
			}

			if (title.indexOf('p-') === 0) {
				var f = str_replace('p-', '', title);

				switch (f) {
					case 'today':
						title = $.format.date(new Date(), "MMM, dd yyyy");
						break;
				}
			}

			if (this.get('icon')) {
				title = '<i class="' + this.get('icon') + '"></i>' + title;
			}

			if (title) {
				this.set('title', title);
			}

			// close
			$element.find('.closeModal').click(function () {
				$element.find('.modal').modal('hide');
			});

			var clipboardInstance = this.get('clipboardInstance');
			if (clipboardInstance) {
				this.loadClipboard(clipboardInstance);
			}
		},

		willDestroyElement: function willDestroyElement() {
			var clipboardInstance = this.get('clipboardInstance');
			if (clipboardInstance) {
				var clipboard = new Clipboard('.' + clipboardInstance);
				clipboard.destroy();
			}
		}

	});

});