define('wow-app/views/tilezoommap', ['exports', 'ember', 'wow-app/views/base-routing-view'], function (exports, Ember, BaseRoutingView) {

	'use strict';

	exports['default'] = BaseRoutingView['default'].extend({

		classNames: ['ui-tilezoommap-view'],

		tilezoommapLoaded: null,
		tilezoommap: null,

		getTilezoomMap: function getTilezoomMap() {

			var $element = this.$(),
			    map = $('.ui-tilezoommap', $element),
			    tilezoommap;

			if (map && map.get(0) && typeof map.get(0).tilezoommap === 'function') {
				tilezoommap = $('.ui-tilezoommap', $element).tilezoommap;
			}

			return tilezoommap;
		},

		/**
	  * Callback Function from base-view after show transition
	  */
		afterShowCSSTransition: function afterShowCSSTransition() {

			var me = this;

			if (this.tilezoommap) {

				fastdom.write(function () {
					me.willClearRender();
					fastdom.write(function () {
						me.loadTitlezoomMap();
					});
				});
			} else {
				this.loadTitlezoomMap();
			}
		},

		loadTitlezoomMap: function loadTitlezoomMap() {

			var me = this,
			    controller = me.get('controller'),
			    $element = me.$(),
			    tilezoomController = controller.get('controllers.tilezoom'),
			    tilezoomSettings = tilezoomController.get('tilezoom'),
			    $tilezoom = tilezoomSettings.cont;

			var $thumb = false;
			var overviewImageSize = tilezoomController.getOverviewImageSize();
			var wowmosaicFrontendUrlBase = me.get('globals').get('srvParams.wowmosaicFrontendUrlBase');

			this.tilezoommap = $('.ui-tilezoommap', $element).tilezoommap({
				tilezoom: $tilezoom,
				thumb: wowmosaicFrontendUrlBase + overviewImageSize.n,
				thumbSize: overviewImageSize,

				load: function load($img) {
					$thumb = $img;
					var wonderId = UTIL.storage.getSession('gotoWonder');
					UTIL.storage.removeSession('gotoWonder');
					if (wonderId && wonderId.length > 0) {
						tilezoomController.gotoWonder(wonderId);
					} else {
						tilezoomController.send('resize');
					}
				}
			});
		},

		/**
	  * on unload of the view remove widget and widgets events
	  */
		willClearRender: function willClearRender() {
			var $element = this.$(),
			    map = $('.ui-tilezoommap', $element);

			if (this.tilezoommap) {
				this.tilezoommap.tilezoommap('destroy');
				delete this.tilezoommap;
			}
		}

	});

});