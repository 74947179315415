define('wow-app/controllers/congratulation', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'mosaic', 'tilezoom'],

		pictureIsOnActiveMosaic: (function () {
			return this.get('controllers.mosaic').get('pictureIsOnActiveMosaic');
		}).property('controllers.mosaic.pictureIsOnActiveMosaic')

	});

});