define('wow-app/components/loading-animation', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({

		classNames: ['loading-animation'],

		didInsertElement: function didInsertElement() {

			var $element = this.$(),
			    parentHeightSelector = this.get('parentHeightSelector'),
			    height;

			if (parentHeightSelector) {
				height = $element.closest(parentHeightSelector).height();
				if (height) {
					$element.css('height', height);
				}
			}
		}

	});

});