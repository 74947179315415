define('wow-app/initializers/global', ['exports'], function (exports) {

	'use strict';

	exports.initialize = initialize;

	var globals = Ember.Object.extend({
		name: 'WondersWall App',
		srvParams: srvParams,
		app_route: '/wow-app',

		// In TilezoomView: in order to delay content loading
		// isRendered of Tilezoom is delayed by this time in mms
		inititalTilezoomRenderedDelay: 1000,

		test: function test() {
			if (typeof console === 'object') {
				console.log('TEST!', true);
			}
		}
	});

	function initialize(container, application) {
		container.typeInjection('component', 'store', 'store:main');
		application.register('global:variables', globals, { singleton: true });
		application.inject('controller', 'globals', 'global:variables');
		application.inject('route', 'globals', 'global:variables');
		application.inject('view', 'globals', 'global:variables');
		application.inject('helper', 'globals', 'global:variables');
		application.inject('component', 'globals', 'global:variables');
		application.inject('model', 'globals', 'global:variables');
	}

	exports['default'] = {
		name: 'global',
		initialize: initialize
	};

});