define('wow-app/adapters/user', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

	'use strict';

	var inflector = Ember['default'].Inflector.inflector;

	inflector.irregular('user', 'auth');
	Ember['default'].Inflector.inflector.rules.uncountable['user'] = true;

	exports['default'] = DS['default'].RESTAdapter.extend({
		namespace: '/api/v1',

		deleteRecord: function deleteRecord() {
			return true;
		},
		destroyRecord: function destroyRecord() {
			return true;
		},

		createRecord: function createRecord(store, type, snapshot) {

			var id = snapshot.get('id');
			var email = snapshot.get('email') === 'undefined' ? '' : snapshot.get('email') || '';
			var password = snapshot.get('password') === 'undefined' ? '' : snapshot.get('password') || '';

			var data_str = 'fos_user_registration_form[email]=' + email;
			data_str += '&fos_user_registration_form[username]=' + email;
			data_str += '&fos_user_registration_form[plainPassword][first]=' + password;
			data_str += '&fos_user_registration_form[plainPassword][second]=' + password;

			var url = '/register/';

			return new Ember['default'].RSVP.Promise(function (resolve, reject) {
				jQuery.ajax({
					type: 'POST',
					method: "POST",
					url: url,
					dataType: 'json',
					data: data_str
				}).then(function (data) {
					if (typeof data === 'object' && typeof data.user === 'object') {
						data.user.loggedIn = true;
					}
					Ember['default'].run(null, resolve, data);
				}, function (jqXHR) {
					jqXHR.then = null; // tame jQuery's ill mannered promises
					Ember['default'].run(null, reject, jqXHR);
				});
			});
		}

	});

});