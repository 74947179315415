define('wow-app/routes/start', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({

		beforeModel: function beforeModel(transition) {
			transition.abort();
			var controller = this.controllerFor('application');
			var mosaicId = controller.getMosaicId();
			this.transitionTo('mosaic', mosaicId);
		}

	});

});