define('wow-app/adapters/mosaic', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

  'use strict';

  var inflector = Ember['default'].Inflector.inflector;

  inflector.irregular('mosaic', 'mosaic/list');
  //inflector.uncountable('advice');

  exports['default'] = DS['default'].RESTAdapter.extend({
    namespace: '/api/v1'
  });

});