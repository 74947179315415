define('wow-app/components/upload-steps', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({

		classNames: 'view-upload-steps',

		didInsertElement: function didInsertElement() {

			var me = this,
			    $element = $(this.element);

			var activeStep = this.get('active');
			var applicationController = this.get('applicationController');

			if (!applicationController.enableStepRouting) {
				return false;
			}

			if (activeStep === 's2') {
				var step1 = $element.find('.step1');
				step1.on('click', function () {
					var step1Obj = applicationController.get('uploadSteps.s1');
					applicationController.transitionTo(step1Obj.route);
				});
			}
		}

	});

});