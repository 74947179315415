define('wow-app/routes/joy-screen', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		docTitle: 'Joy Screen',

		baseRouteViewSettings: {
			visibleContent: 'joy-screen',
			activeNavigationItem: 'choose-wonder',
			hideAll: 'complete-notilezoomrefresh',
			templates: {
				bottomOverlay: {
					name: 'joy-screen',
					outlet: 'bottom-overlay',
					height: 330,
					parent: 'mosaic',
					resizeTilezoom: 'soft',
					scrollable: false
				}
			},
			showContent: true
		},

		/**
	  * the requested picture might not be a picture of the running user
	  */
		//isUserPicture: true,

		/**
	  * load photo by id
	  *
	  * @param params
	  * @param transition
	  * @returns {*}
	  */
		model: function model(params, transition) {

			var me = this;

			return me.store.find('picture', params.photo_id).then(function (photo) {
				//var applicationController = me.controllerFor('application')
				//me.controller.set('isUserPicture', applicationController.isUserPicture(photo));
				return photo;
			}, function () {
				var mosaic_id = me.getMosaicId(params, transition);

				if (mosaic_id) {
					me.transitionTo('choose-wonder', mosaic_id);
				} else {
					me.transitionTo('start');
				}
				return null;
			});
		},

		/**
	  *
	  *
	  * @param controller
	  * @param model
	  */
		setupController: function setupController(controller, model) {
			//if(typeof console === 'object') { console.log('setupController.model',model); }
			var applicationController = this.controllerFor('application');
			controller.set('model', model);
			controller.set('runningPicture', model);
			controller.set('isUserPicture', applicationController.isUserPicture(model));
			//if(typeof console === 'object') { console.log('controllero',controller.get('isUserPicture')); }
		},

		afterModel: function afterModel() {
			this.updateBaseView();
		},

		renderTemplate: function renderTemplate(controller, model) {

			this.render({
				into: 'mosaic',
				outlet: 'bottom-overlay'
			});
		}
	});

});