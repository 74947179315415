define('wow-app/helpers/translation-helper', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Handlebars.makeBoundHelper(function (context, key, textId, type, nbr) {
		//if(typeof console === 'object') { console.log('TRANSLATIONS?',context.t('wonders.'+textId+'.'+type)); }
		var text = context.t(key + '.' + textId + '.' + type);
		if (nbr) {
			return str_replace(' ', '&nbsp;', text);
		} else {
			return text;
		}
	});

});