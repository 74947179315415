define('wow-app/locales/ko', ['exports', 'wow-app/locales/en'], function (exports, EN) {

    'use strict';

    var KO = {
        "about": {
            "sub_title": "귀하의 흔적을 남기십시오!<br />불가사의의 벽에 가입하십시오!<br />그곳에 가십시오!",
            "text": "수억 명이 투표하여 새로운 7대 불가사의를 선택했습니다. 이제 귀하는 새로운 7대 불가사의 중 한 곳에 귀하 자신의 사진을 올려 불가사의의 벽 일부가 될 수 있습니다.귀하의 불가사의를 선택하십시오.귀하의 사진을 업로드하십시오.모든 사람과 공유하십시오.예, 귀하는 이제 불가사의의 벽<a href=https://twitter.com/hashtag/wonderswall\" target=\"_blank\">#일부입니다</a>!!!</p>"
        },
        "buttons": {
            "cancel": "취소",
            "change_photo": "사진 변경",
            "change_wall": "벽 변경",
            "change_wonder": "불가사의 변경",
            "choose_wonder": "불가사의 선택",
            "close": "닫기",
            "crop_and_save": "사진 자르기 및 저장",
            "crop_photo": "사진 자르기",
            "join": "가입",
            "log_out": "로그아웃",
            "ok": "확인",
            "register": "등록",
            "save": "저장",
            "select": "선택",
            "share": "공유",
            "sign_in": "로그인",
            "sign_up": "회원 가입",
            "upload": "사진 업로드",
            "copy": "사본",
            "yes": "예.",
            "edit": "Edit",
            "forget_password": "비밀번호를 잊으 셨나요?"
        },
        "congratulation": {
            "sub_title": "귀하는 이제 <br>불가사의의 벽 일부입니다.",
            "title": "축하합니다."
        },
        "global": {
            "error_title": "죄송합니다.",
            "next": "다음",
            "previous": "이전",
            "title": "불가사의의 벽"
        },
        "joyscreen": {
            "join": "불가사의의 벽에<span class=\"break-portrait break-landscape\"></span> 가입하십시오.",
            "title": "불가사의의 벽에<span class=\"break-portrait break-landscape\"></span> 머물고 싶으십니까?",
            "welcome": "불가사의의 벽에<span class=\"break-portrait break-landscape\"></span> 가입하신 걸 환영합니다."
        },
        "mosaic": {
            "manmade": {
                "title": "세계의 새로운 7대 불가사의"
            },
            "nature": {
                "title": "자연의 새로운 7대 불가사의"
            }
        },
        "my_photo": {
            "my_wonder": "나의 불가사의",
            "no_login": "로그인하지 않으셨습니다!불가사의의 벽에 머무시려면 등록하셔야 합니다.",
            "no_photo": "아직 사진을 업로드하지 않으셨습니다...",
            "status": "상태",
            "sub_title": "사진 변경 또는 불가사의 변경",
            "title": "내 사진",
            "uploaded": "업로드됨",
            "motto": "Say something",
            "motto_placeholder": "Tell us about your photo",
            "location": "Location",
            "city": "City",
            "country": "Country",
            "ping": "WOW!",
            "my_wows": "My WOWs",
            "wowed_by": "WOWed by",
            "no_wows": "No WOWs yet."
        },
        "register": {
            "email": "이메일",
            "logged_in_as": "로그인됨",
            "password": "비밀번호",
            "sign_in_email": "이메일로 로그인",
            "sign_up_email": "이메일로 가입"
        },
        "seo": {
            "meta_description": "수억 명이 투표하여 새로운 7대 불가사의를 선택했습니다. 이제 귀하는 불가사의의 벽 일부가 될 수 있습니다."
        },
        "settings": {
            "change_language": "언어",
            "email": "이메일",
            "facebook": "페이스북",
            "login": "로그인",
            "new_to_wall": "불가사의의 벽에 가입하시겠습니까?",
            "on_the_wall": "이미 불가사의의 벽에 가입하셨습니까?",
            "signin": "회원 가입",
            "title": "설정",
            "twitter": "트위터",
            "upload_photo": "사진 업로드"
        },
        "share": {
            "fb_caption": "나는 이제 <br>불가사의의 벽 일부입니다.",
            "fb_description": "나와 함께 해요! www.wonderswall.com에 귀하의 사진을 업로드하면 귀하도 불가사의가 될 수 있습니다!#wonderswall",
            "fb_name": "불가사의의 벽",
            "fb_published": "귀하의 게시물이 게재되었습니다.",
            "sub_title": "친구들과 공유하십시오.<br>불가사의의 벽에 함께 가입하십시오.",
            "title": "공유",
            "whatsapp": "왓츠앱",
            "link": "링크",
            "email_from": "발신",
            "email_to": "수신",
            "email_subject": "제목",
            "email_text": "텍스트",
            "email_to_placeholder": "여러 주소는 콤마로 구분하십시오.",
            "email_successfully_send": "성공적으로 이메일 발송"
        },
        "stay": {
            "accept": "이용 약관에 동의",
            "title": "불가사의의 벽에<span class=\"break-portrait\"></span> 계속 머뭄",
            "privacy_policy": "개인정보취급방침  "
        },
        "terms": {
            "accept": "이용 약관에 동의하십시오.",
            "title": "이용 약관"
        },
        "top": {
            "title": "최다 업로드"
        },
        "upload": {
            "image_error": "사진을 업로드할 수 없습니다. 되돌아가서 다시 업로드하십시오.",
            "import_fb": "페이스북",
            "import_insta": "인스타그램",
            "import_my_photos": "귀하의 사진",
            "import_tw": "트위터",
            "import_webcam": "웹캠",
            "sub_title": "또는 아래에서 가져옴",
            "take_photo": "사진 촬영",
            "title": "사진 촬영",
            "upload_error": "이미지를 업로드하는 데 오류가 발생했습니다.",
            "upload_save_error": "사진을 저장할 수 없습니다. 되돌아가서 다시 업로드하십시오.",
            "webcam_allow_error": "“하락”을 클릭해 사진을 촬영하십시오.",
            "webcam_error": "웹캠이 브라우저에서 작동하지 않습니다. 소프트웨어를 업데이트하십시오."
        },
        "upload_choose": {
            "change_title": "귀하의 불가사의를 변경하십시오.",
            "sub_title": "그리고 그것에 귀하의 사진을 업로드하십시오.",
            "title": "귀하의 불가사의를 선택하십시오."
        },
        "upload_steps": {
            "1": "1단계",
            "2": "2단계",
            "3": "3단계"
        },
        "wall": {
            "upload_photo": "불가사의의 벽에 귀하의 사진을 업로드하십시오."
        },
        "wonders": {
            "amazon": {
                "location": "남아메리카",
                "title": "아마존"
            },
            "christ_redeemer": {
                "location": "브라질",
                "title": "브라질 예수상"
            },
            "colosseum": {
                "location": "이탈리아",
                "title": "콜로세움"
            },
            "great_wall_of_china": {
                "location": "중국",
                "title": "만리장성"
            },
            "ha_long_bay": {
                "location": "베트남",
                "title": "할롱 베이"
            },
            "iguazu_falls": {
                "location": "아르헨티나/브라질",
                "title": "이구아수 폭포"
            },
            "jeju_island": {
                "location": "한국",
                "title": "제주도"
            },
            "komodo": {
                "location": "인도네시아",
                "title": "코모도"
            },
            "machu_pichu": {
                "location": "페루",
                "title": "마추픽추"
            },
            "petra": {
                "location": "요르단",
                "title": "페트라"
            },
            "pp_underground_river": {
                "location": "필리핀",
                "title": "PP 지하 강"
            },
            "pyramid_at_chichen_itza": {
                "location": "멕시코",
                "title": "치첸이트사"
            },
            "table_mountain": {
                "location": "남아프리카공화국",
                "title": "테이블 마운틴"
            },
            "taj_mahal": {
                "location": "인도",
                "title": "타지마할"
            }
        },
        "oauth_errors": {
            "login_failed": "Log in via social network failed or was cancelled."
        }
    };

    KO = $.extend(true, {}, EN['default'], KO);

    exports['default'] = KO;

});