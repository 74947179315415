define('wow-app/routes/wowed-by', ['exports', 'ember', 'wow-app/routes/base-content-route'], function (exports, Ember, BaseRoute) {

	'use strict';

	exports['default'] = BaseRoute['default'].extend({

		isSecure: false,
		docTitle: 'WOWed By',

		baseRouteViewSettings: {
			visibleContent: 'wowed-by',
			activeNavigationItem: 'my-wonderlock',
			templates: {
				bottom: {
					name: 'wowed-by',
					outlet: 'bottom',
					height: 275,
					width: 444,
					parent: 'my-wonderlock',
					scrollable: true
				}
			},
			showContent: true
		},

		model: function model(params, transition) {

			var me = this;
			var applicationController = this.controllerFor('application');
			var user = applicationController.get('user');

			if (typeof console === 'object') {
				console.log('user', user);
			}

			return this.store.findQuery('interaction', { recipient: user.get('id') }).then(function (records) {

				return records;
			}, function (reason) {

				reason = reason || {};
				if (typeof reason.responseJSON !== 'object') {
					reason.responseJSON = {};
				}

				transition.abort();

				me.transitionTo('tilezoommap');

				me.controllerFor('error-modal').raise({
					statusText: reason.responseJSON.statusText || 'Sorry',
					statusCode: reason.status || 404,
					reason: reason,
					message: reason.responseJSON.message || 'Please log in to see your interactions'
				});

				return null;
			});
		},

		afterModel: function afterModel() {
			this.updateBaseView();
		},

		renderTemplate: function renderTemplate(controller, model) {

			this.render({
				into: 'mosaic',
				outlet: 'bottom',
				model: model
			});
		}

	});

});