define('wow-app/locales/pt', ['exports', 'wow-app/locales/en'], function (exports, EN) {

    'use strict';

    var PT = {
        "about": {
            "sub_title": "Deixe sua marca!<br />Junte-se ao Mural das Maravilhas!<br />Esteja lá!",
            "text": "<p>Centenas de milhões de votos escolheram as 7 Novas Maravilhas, e agora VOCÊ pode fazer parte do Mural das Maravilhas com sua própria foto nas 7 Novas Maravilhas.</p><p>Escolha a sua Maravilha. Carregue sua foto. Compartilhe com todos. <br />Sim, agora você faz parte do <a href=\"https://twitter.com/hashtag/wonderswall\" target=\"_blank\">#wonderswall</a>!!!</p>"
        },
        "buttons": {
            "cancel": "Cancelar",
            "change_photo": "Alterar foto",
            "change_wall": "Alterar Muro",
            "change_wonder": "Alterar Maravilha",
            "choose_wonder": "Escolher Maravilha",
            "close": "Fechar",
            "crop_and_save": "Cortar foto e salvar",
            "crop_photo": "Cortar foto",
            "join": "Participar",
            "log_out": "Sair",
            "ok": "OK",
            "register": "Registrar",
            "save": "Salvar",
            "select": "Selecionar",
            "share": "Compartilhar",
            "sign_in": "Entrar",
            "sign_up": "Cadastrar-se",
            "upload": "Carregar foto",
            "copy": "Copiar",
            "yes": "Sim!",
            "edit": "Edit",
            "forget_password": "Esqueceu a senha?"
        },
        "congratulation": {
            "sub_title": "Agora você faz parte do<br>Mural das Maravilhas",
            "title": "Parabéns"
        },
        "global": {
            "error_title": "Desculpe",
            "next": "Próximo",
            "previous": "Anterior",
            "title": "Mural das Maravilhas"
        },
        "joyscreen": {
            "join": "Participe do<span class=\"break-portrait break-landscape\"></span>Mural das Maravilhas",
            "title": "Deseja permanecer no<span class=\"break-portrait break-landscape\"></span>Mural das Maravilhas?",
            "welcome": "Bem-vindo ao<span class=\"break-portrait break-landscape\"></span>Mural das Maravilhas"
        },
        "mosaic": {
            "manmade": {
                "title": "7 Novas Maravilhas do Mundo"
            },
            "nature": {
                "title": "7 Novas Maravilhas da Natureza"
            }
        },
        "my_photo": {
            "my_wonder": "Minha Maravilha",
            "no_login": "Você não está logado! Você precisa se registrar para permanecer no Mural das Maravilhas.",
            "no_photo": "Você ainda não carregou nenhuma foto...",
            "status": "Status",
            "sub_title": "Altere a foto ou a Maravilha",
            "title": "Minha foto",
            "uploaded": "Carregada",
            "motto": "Say something",
            "motto_placeholder": "Tell us about your photo",
            "location": "Location",
            "city": "City",
            "country": "Country",
            "ping": "WOW!",
            "my_wows": "My WOWs",
            "wowed_by": "WOWed by",
            "no_wows": "No WOWs yet."
        },
        "register": {
            "email": "Email",
            "logged_in_as": "Logado como",
            "password": "Senha",
            "sign_in_email": "Entrar com email",
            "sign_up_email": "Sair com email"
        },
        "seo": {
            "meta_description": "Centenas de milhões de votos escolheram as / Novas Maravilhas, e agora VOCÊ pode fazer parte do Mural das Maravilhas."
        },
        "settings": {
            "change_language": "Idioma",
            "email": "E-Mail",
            "facebook": "Facebook",
            "login": "Entrar",
            "new_to_wall": "Deseja participar do Mural das Maravilhas?",
            "on_the_wall": "Já está no Mural das Maravilhas?",
            "signin": "Cadastrar-se",
            "title": "Ajustes",
            "twitter": "Twitter",
            "upload_photo": "Carregar uma foto"
        },
        "share": {
            "fb_caption": "Agora eu faço parte do Mural das Maravilhas.",
            "fb_description": "Participe! Carregue sua foto em www.wonderswall.com e você também pode se tornar parte da maravilha! #muraldasmaravilhas",
            "fb_name": "Mural das Maravilhas",
            "fb_published": "Sua Publicação foi postada.",
            "sub_title": "Compartilhe com seus amigos,<br>participem juntos do Mural das Maravilhas",
            "title": "Compartilhar",
            "whatsapp": "Whatsapp",
            "link": "Link",
            "email_from": "De",
            "email_to": "Para",
            "email_subject": "Assunto",
            "email_text": "Seu texto",
            "email_to_placeholder": "Separar vários endereços com vírgula",
            "email_successfully_send": "Email enviado com êxito"
        },
        "stay": {
            "accept": "Aceitar Termos e Condições",
            "title": "Permaneça no <span class=\"break-portrait\"></span>Mural das Maravilhas",
            "privacy_policy": "Política de privacidade"
        },
        "terms": {
            "accept": "Aceite nossos Termos e Condições",
            "title": "Termos e Condições"
        },
        "top": {
            "title": "Mais carregada"
        },
        "upload": {
            "image_error": "Não foi possível carregar a foto, retorne e carregue-a novamente",
            "import_fb": "Facebook",
            "import_insta": "Instagram",
            "import_my_photos": "Suas fotos",
            "import_tw": "Twitter",
            "import_webcam": "Webcam",
            "sub_title": "ou importe uma das fotos abaixo",
            "take_photo": "Tirar foto",
            "title": "Tirar foto",
            "upload_error": "Ocorreu um erro durante o carregamento da imagem",
            "upload_save_error": "Não foi possível salvar a foto, retorne e carregue-a novamente",
            "webcam_allow_error": "Clique em \"permitir\" para tirar a foto",
            "webcam_error": "Sua webcam não funciona com este navegador, atualize seu software"
        },
        "upload_choose": {
            "change_title": "Mude de Maravilha",
            "sub_title": "e carregue sua foto nela",
            "title": "Escolha sua Maravilha"
        },
        "upload_steps": {
            "1": "Passo 1",
            "2": "Passo 2",
            "3": "Passo 3"
        },
        "wall": {
            "upload_photo": "Carregue sua foto no Mural das Maravilhas"
        },
        "wonders": {
            "amazon": {
                "location": "América do Sul",
                "title": "Amazônia"
            },
            "christ_redeemer": {
                "location": "Brasil",
                "title": "Cristo Redentor"
            },
            "colosseum": {
                "location": "Itália",
                "title": "Coliseu"
            },
            "great_wall_of_china": {
                "location": "China",
                "title": "Grande Muralha da China"
            },
            "ha_long_bay": {
                "location": "Vietnã",
                "title": "Baía de Ha Long"
            },
            "iguazu_falls": {
                "location": "Argentina / Brasil",
                "title": "Cataratas do Iguaçu"
            },
            "jeju_island": {
                "location": "Coreia do Sul",
                "title": "Ilha Jeju"
            },
            "komodo": {
                "location": "Indonésia",
                "title": "Comodo"
            },
            "machu_pichu": {
                "location": "Peru",
                "title": "Machu Picchu"
            },
            "petra": {
                "location": "Jordânia",
                "title": "Petra"
            },
            "pp_underground_river": {
                "location": "Filipinas",
                "title": "Parque Nacional do rio subterrâneo de Puerto Princesa"
            },
            "pyramid_at_chichen_itza": {
                "location": "México",
                "title": "Chichén Itzá"
            },
            "table_mountain": {
                "location": "África do Sul",
                "title": "Montanha da Mesa"
            },
            "taj_mahal": {
                "location": "Índia",
                "title": "Taj Mahal"
            }
        },
        "oauth_errors": {
            "login_failed": "Log in via social network failed or was cancelled."
        }
    };

    PT = $.extend(true, {}, EN['default'], PT);

    exports['default'] = PT;

});