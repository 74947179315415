define('wow-app/adapters/interaction', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

	'use strict';

	exports['default'] = DS['default'].RESTAdapter.extend({
		namespace: '/api/v1',

		createRecord: function createRecord(store, type, snapshot) {

			var json = snapshot.record.toJSON({ includeId: true });
			var id = snapshot.get('targetPic');
			json = { targetPic: id };
			var url = this.namespace + '/interaction';

			return new Ember['default'].RSVP.Promise(function (resolve, reject) {
				jQuery.ajax({
					type: 'POST',
					url: url,
					contentType: "application/json; charset=utf-8",
					dataType: 'json',
					data: JSON.stringify(json)
				}).then(function (data) {
					Ember['default'].run(null, resolve, data);
				}, function (jqXHR) {
					jqXHR.then = null; // tame jQuery's ill mannered promises
					Ember['default'].run(null, reject, jqXHR);
				});
			});
		}

	});

});