define('wow-app/models/applicationstate', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	exports['default'] = DS['default'].Model.extend({
		expanded: DS['default'].attr('boolean'),
		seenSplash: DS['default'].attr('boolean'),
		visibleContent: DS['default'].attr('object'),
		lang: DS['default'].attr('string'),
		mosaic: DS['default'].attr('string'),
		wonder: DS['default'].attr('object')
	});

});