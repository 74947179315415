define('wow-app/views/mosaic-list', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].View.extend({

		classNames: ['tilezoom-mosaic-choose'],

		classNameBindings: ['mosaicName'],

		mosaicName: (function () {
			var me = this,
			    controller = me.get('controller');
			return controller.get('controllers.mosaic.model.name');
		}).property('controllers.mosaic.model.name'),

		didInsertElement: function didInsertElement() {
			var me = this,
			    $element = this.$(),
			    controller = me.get('controller');

			$element.css({
				x: $(window).width() + $element.outerWidth() + 100 + 'px',
				y: '0px',
				display: 'block'
			});
		}

	});

});