define('wow-app/controllers/splash', ['exports', 'ember', 'wow-app/controllers/base-object-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'settings-modal'],
		sortProperties: ['id'],
		sortAscending: true

	});

});