define('wow-app/services/server-variables', ['exports', 'ember', 'ember-cli-server-variables/services/server-variables', 'wow-app/config/environment'], function (exports, Ember, ServerVariablesService, ENV) {

  'use strict';

  exports['default'] = ServerVariablesService['default'].extend({
    env: Ember['default'].computed(function () {
      return ENV['default'];
    })
  });

});