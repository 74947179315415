define('wow-app/tests/unit/helpers/assets-test', ['wow-app/helpers/assets', 'qunit'], function (assets, qunit) {

  'use strict';

  qunit.module('Unit | Helper | assets');

  // Replace this with your real tests.
  qunit.test('it works', function (assert) {
    var result = assets.assets(42);
    assert.ok(result);
  });

});