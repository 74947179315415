define('wow-app/controllers/tilezoom', ['exports', 'ember', 'wow-app/controllers/base-controller'], function (exports, Ember, BaseController) {

	'use strict';

	exports['default'] = BaseController['default'].extend({

		needs: ['application', 'mosaic', 'error-modal', 'my-wows'],

		// hide/show zoomNavigation
		zoomNavigation: true,

		// set screensaver mode
		screensaver: 'screensaver',

		// tilephoto click mode: show photo (getTilephoto or setMyPhotoPosition)
		tilephotoClickMode: 'viewPhoto', // viewPhoto || setPosition

		//	allows select pictures on the mosaic
		mosaicphotoSelectable: false,

		currentZoomLevel: 0,
		maxZoomlevel: 15,

		tilezoom: null,
		overviewmap: null,

		//mosaicList: [ ],
		myphotos: [],
		photos: [],
		tilephotos: [],
		whenResize: null, // special resize order, when resizing though base view runs only once!

		//  Properties
		getMosaic: function getMosaic() {
			return this.get('mosaic');
		},

		mosaicName: (function () {
			return this.get('mosaic').get('name');
		}).property('mosaic.name'),

		//	this value is set to "true" when the tilezoom initialized
		isLoading: false,

		// Tests if the device has fullscreen support
		fullscreenEnabled: false, //window.screenfull.enabled, // screenful library

		zoomLevelCls: (function () {
			return 'level-' + this.get('currentZoomLevel');
		}).property('currentZoomLevel'),

		isMaxZoomlevel: false,

		loginChanged: (function () {
			console.log('USER login changed');
		}).observes('model.user.loggedIn'),

		isLoggedIn: (function () {
			return this.get('controllers.application').get('userLoggedIn');
			//return this.get('model.user.loggedIn');//User.get('loggedIn');
		}).property('model.user.loggedIn'),

		photoList: (function () {
			return this.get('photos');
		}).property('photos.@each.posX'),

		//  calculate tileposition after zoom
		refreshPhotoTiles: function refreshPhotoTiles() {

			var me = this,
			    tilezoom = me.tilezoom,
			    $holder = tilezoom.holder;

			var holderWidth = $holder.width(),
			    holderHeight = $holder.height();

			var widthRatio = holderWidth / tilezoom.width,
			    heightRatio = holderHeight / tilezoom.height;

			var overlap = 0.5,
			    tileWidth = me.getMosaic().get('tileWidth') - overlap,
			    tileHeight = me.getMosaic().get('tileHeight') - overlap;

			//		refreshFunction
			var refreshFunc = function refreshFunc(photo, index) {

				var x = parseInt(parseInt(photo.get('x') / tileWidth) * tileWidth * widthRatio),
				    y = parseInt(parseInt(photo.get('y') / tileHeight) * tileHeight * heightRatio);

				var tileSize = me.getMosaic().get('tileWidth');
				switch (tilezoom.level) {

					case 15:
						{
							tileSize = tileWidth;break;
						}
					case 14:
						{
							tileSize = 16;break;
						}
					default:
						{
							tileSize = 10;
						}
				}

				if (y + tileSize > holderHeight) {
					y = holderHeight - tileSize;
				}
				if (x + tileSize > holderWidth) {
					x = holderWidth - tileSize;
				}

				photo.set('posX', x);
				photo.set('posY', y);
			};

			me.get('myphotos').forEach(refreshFunc);
			me.get('tilephotos').forEach(refreshFunc);
		},

		/**
	  * Zoom to photo
	  * If the photo is not on the active mosaic: load mosaic
	  * and set whenResize to zoomToPhoto see resize action below
	  *
	  * @param photo
	  * @param openModal
	  * @param callback
	  * @param level
	  * @returns {boolean}
	  */
		zoomToPhoto: function zoomToPhoto(photo, openModal, callback, level) {

			var me = this,
			    tilezoom = me.tilezoom,
			    $holder = tilezoom.holder,
			    mosaic = this.getMosaic();

			var photoMosaicId = photo.get('metadata.mosaicId');
			if (mosaic.get('id') !== photoMosaicId) {
				this.set('whenResize', {
					action: 'zoomToPhoto',
					args: [photo, openModal, callback, level]
				});
				this.send('linkToMosaic', photoMosaicId);
				return false;
			}

			var maxLevel = tilezoom.numLevels - 1;
			level = level || tilezoom.maxLevel;

			// Todo get scale factor from tilezoom
			var scale = Math.pow(tilezoom.tilezoomBase, maxLevel - level) * tilezoom.tilezoomBlowFactor;

			var dimension = {
				width: parseInt(Math.ceil(tilezoom.width * scale)),
				height: parseInt(Math.ceil(tilezoom.height * scale))
			};

			var widthRatio = dimension.width / tilezoom.width,
			    heightRatio = dimension.height / tilezoom.height;

			if (!photo.get('metadata')) {
				return false;
			}

			var coords = {
				x: parseInt((photo.get('metadata').pos[0] + tilezoom.tileWidth / 2) * widthRatio),
				y: parseInt((photo.get('metadata').pos[1] + tilezoom.tileHeight / 2) * heightRatio)
			};

			me.zoom(level, coords, function () {

				if (callback) {
					callback();
				}
			});
		},

		zoom: function zoom(level, coords, callback) {
			this.tilezoom.cont.tilezoom('moveTo', level, coords, callback);
		},

		openDetail: function openDetail(photo) {
			var tilezoom = this.tilezoom;
		},

		removeAllTiles: function removeAllTiles() {
			this.tilezoom.cont.tilezoom('removeAllTiles');
		},

		removeUserPicture: function removeUserPicture() {
			this.tilezoom.cont.tilezoom('removeUserPicture');
		},

		getTilephoto: function getTilephoto(e) {
			this.tilezoom.cont.tilezoom('getTilephoto', e);
		},

		zoomToAndSetPhoto: function zoomToAndSetPhoto(photo) {

			var me = this,
			    tilezoom = me.tilezoom;

			//tilezoom.interaction = false;
			tilezoom.afterTilesLoaded = function () {
				me.tilezoom.cont.tilezoom('setUserPicture', photo);
			};

			me.zoomToPhoto(photo, false, null, null);
		},

		joyPlacement: function joyPlacement(photo) {
			var me = this,
			    tilezoom = me.tilezoom;

			tilezoom.interaction = false;
			tilezoom.preloadTiles = true;
			tilezoom.afterTilesLoaded = function () {
				me.tilezoom.cont.tilezoom('joyPlacement', photo);
			};

			me.zoomToPhoto(photo, false, null, tilezoom.level);
		},

		/**
	  * Joy out (in my photo) animation
	  * @param photo
	  */
		joyOut: function joyOut(photo, level) {

			var me = this,
			    tilezoom = me.tilezoom;

			tilezoom.interaction = false;
			tilezoom.afterTilesLoaded = function () {
				me.tilezoom.cont.tilezoom('joyOut', photo);
			};

			if (level === true) {
				level = tilezoom.level;
			}

			me.zoomToPhoto(photo, false, null, level);
		},

		/**
	  * Do joy screen animation
	  *
	  * @param photo
	  */
		joyIn: function joyIn(photo) {

			var me = this,
			    tilezoom = me.tilezoom;

			tilezoom.interaction = false;
			tilezoom.preloadTiles = true;
			tilezoom.afterTilesLoaded = function () {
				me.tilezoom.cont.tilezoom('joyIn', photo);
			};

			var levelSize = this.tilezoom.cont.tilezoom('getBestLevel', false, true, false);
			me.zoomToPhoto(photo, false, null, levelSize.level);
		},

		/**
	  * User picture property
	  */
		userPicture: (function () {
			return this.get('controllers.application').get('picture');
		}).property('controllers.application.picture'),

		/**
	  * get user picture from application
	  * @returns {*}
	  */
		getUserPicture: function getUserPicture() {
			return this.get('controllers.application').get('picture');
		},

		/**
	  * User picture source property
	  */
		userPictureSrc: (function () {
			var userPicture = this.getUserPicture();
			if (userPicture) {
				return userPicture.get('croppedSrc');
			}

			return '';
		}).property('controllers.application.picture.croppedSrc'),

		/**
	  * Preload the user picture
	  * @param photo
	  * @param callback
	  * @returns {*|number}
	  */
		preloadUserPicture: function preloadUserPicture(photo, callback) {
			//return this.get('controllers.application').get('picture');
			var me = this;

			if (!this.tilezoom) {
				return setTimeout(function () {
					me.preloadUserPicture(photo, callback);
				}, 500);
			}
			this.tilezoom.cont.tilezoom('preloadUserPicture', photo, callback);
		},

		/**
	  * aks application if user has a picture
	  */
		userHasPicture: (function () {
			var userHasPicture = this.get('controllers.application').get('userHasPicture');
			if (!userHasPicture) {
				return false;
			} else {
				return true;
			}
		}).property('controllers.application.userHasPicture'),

		/**
	  * Get Overview image size, container size and name
	  * Possible Sizes: ['1000x0', '1400x0', '2000x0', '0x100', '0x300', '0x400']
	  *
	  * @returns {*}
	  */
		getOverviewImageSize: function getOverviewImageSize() {

			var mosaic = this.getMosaic(),
			    orientation = UTIL.getOrientation();

			var basePath = '/overview/' + mosaic.get('currentTarget') + '/' + mosaic.get('renderSnapshot');

			var hBase = mosaic.get('finalHeight') / mosaic.get('finalWidth');

			var accepted_specs = [{
				w: 1000,
				h: hBase * 1000,
				n: basePath + '/1000x0'
			}, {
				w: 1400,
				h: hBase * 1400,
				n: basePath + '/1400x0'
			}, {
				w: 2000,
				h: hBase * 2000,
				n: basePath + '/2000x0'
			}];

			var ww = $(window).width(),
			    choosenSpec = accepted_specs[0];

			for (var i = 0; i < accepted_specs.length; i++) {
				if (ww >= choosenSpec.w) {
					choosenSpec = accepted_specs[i];
				}
			}

			choosenSpec.c = choosenSpec.h;

			// increase container height in portrait orientation
			// if userHasPicture to show upload teaser
			var userHasPicture = this.get('userHasPicture');
			if (orientation === 'portrait' && !userHasPicture) {
				choosenSpec.c = choosenSpec.c + 10; // upload teaser
			} else if (orientation === 'landscape') {
					choosenSpec.c = choosenSpec.c + 2; // padding top (white line)
				}

			return choosenSpec;
		},

		/**
	  * get active/visible wonder
	  * @returns {*}
	  */
		getActiveWonder: function getActiveWonder() {

			var settings = this.tilezoom.cont.data('tilezoom.settings'),
			    mosaic = this.get('mosaic'),
			    wonders = mosaic.get('wonders'),
			    mosaic_left = settings.transform.x * -1,
			    i,
			    foundWonder;

			// use center... cause settings.transform.x is the left position...
			mosaic_left = mosaic_left + settings.containerWidth / 2;
			var x_pos = mosaic_left / settings.holderWidth;
			for (i = 0; i < wonders.length; i++) {
				if (x_pos >= wonders[i].start_x && x_pos <= wonders[i].end_x) {
					foundWonder = wonders[i];
				}
			}

			return foundWonder;
		},

		getNextWonder: function getNextWonder(wonderId) {

			var settings = this.tilezoom.cont.data('tilezoom.settings'),
			    mosaic = this.get('mosaic'),
			    wonders = mosaic.get('wonders'),
			    i,
			    foundWonder = wonders[0],
			    foundWonderIdx;

			for (i = 0; i < wonders.length; i++) {
				if (wonders[i].id == wonderId) {
					foundWonderIdx = i;
				}
			}

			if (wonders[foundWonderIdx + 1]) {
				foundWonder = wonders[foundWonderIdx + 1];
			}

			return foundWonder;
		},

		/**
	  * Move to wonder with id wonderId
	  * @param wonderId
	  * @param fit
	  * @param fallback
	  * @returns {boolean}
	  */
		gotoWonder: function gotoWonder(wonderId, fit, fallback) {

			var settings = this.tilezoom.cont.data('tilezoom.settings'),
			    mosaic = this.get('mosaic'),
			    wonders = mosaic.get('wonders'),
			    foundWonder,
			    i;

			for (i = 0; i < wonders.length; i++) {
				if (wonderId == wonders[i].id) {
					foundWonder = wonders[i];
				}
			}

			if (!foundWonder) {
				if (fallback) {
					var max = wonders.length - 1;
					var min = 0;
					var x = Math.floor(Math.random() * (max - min + 1)) + min;
					foundWonder = wonders[x].id;
				} else {
					return false;
				}
			}

			var levelWidth = settings.holderWidth;
			var levelHeight = settings.holderHeight;
			var level = settings.level;
			var levelSize = this.tilezoom.cont.tilezoom('getBestLevel', false, true, fit);

			if (levelSize) {
				levelWidth = levelSize.width;
				levelHeight = levelSize.height;
				level = levelSize.level;
			}
			// set position and center
			var start_x = foundWonder.start_x + (foundWonder.end_x - foundWonder.start_x) / 2;
			var x_pos = start_x * levelWidth;
			var y_pos = levelHeight / 2;

			this.zoom(level, {
				x: x_pos,
				y: y_pos
			}, function () {});
		},

		//  Actions
		actions: {

			initTilezoom: function initTilezoom(tilezoom) {

				var me = this;
				me.set('tilezoom', tilezoom);
				me.set('firstResize', false);
			},

			mosaicTileClick: function mosaicTileClick(event, tilePosX, tilePosY) {

				var me = this;
				var obj = {

					tilelistId: me.get('mosaic').get('id'),
					x: parseInt(event.mouseX + event.tileWidth / 2),
					y: parseInt(event.mouseY + event.tileHeight / 2)
				};

				me.store.find(TilePhoto, obj).then(function (photos) {

					var tilePhoto = photos.content[0];

					me.get('controllers.PhotoOverlay').openDialog(tilePhoto);
				}, function (reason) {

					console.log('--------------------------');
					console.log('SOOOOOOOORRRRRRRRYYYYYYYYY');
					console.log(reason);
				});
			},

			photoTileClick: function photoTileClick(photo) {

				var me = this,
				    photoOverlayController = me.get('controllers.PhotoOverlay'),
				    tilezoom = me.get('tilezoom');

				if (tilezoom.level < me.get('maxZoomlevel') - 1) {

					me.zoomToPhoto(photo, false, function () {

						setTimeout(function () {

							photoOverlayController.openDialog(photo);
						}, 300);
					});
				} else {

					photoOverlayController.openDialog(photo);
				}
			},

			photoClick: function photoClick(photo, joy) {
				if (joy === 'joyPlacement') {
					this.joyPlacement(photo);
				} else if (typeof joy !== 'undefined') {
					this.joyOut(photo);
				} else {
					this.zoomToPhoto(photo);
				}
			},

			upload: function upload() {

				this.transitionToRoute('upload.photoupload');
			},

			dragStart: function dragStart($container, $widget) {

				$widget.css({

					right: 'auto'
				});
			},

			dragStop: function dragStop($container, $widget) {

				var containerWidth = $container.innerWidth(),
				    widgetWidth = $widget.outerWidth(),
				    widgetLeft = parseInt($widget.css('left'));

				if (widgetLeft > containerWidth / 2) {

					var right = containerWidth - widgetLeft - widgetWidth;

					$widget.css({

						left: 'auto',
						right: right + 'px'
					});
				}
			},

			tilezoomModalClick: function tilezoomModalClick() {

				this.transitionToRoute('home');
			},

			goHome: function goHome() {

				var tilezoom = this.tilezoom;

				tilezoom.cont.tilezoom('zoom', tilezoom.minLevel, {});
			},

			zoomIn: function zoomIn(level) {

				var tilezoom = this.tilezoom;

				level = level ? level : tilezoom.level + 1;
				//if(typeof console == 'object') console.log('TileZoomController.zoomIn',level);

				tilezoom.cont.tilezoom('zoom', level, {});
			},

			zoomOut: function zoomOut(level) {

				var tilezoom = this.tilezoom;

				level = level ? level : tilezoom.level - 1;

				tilezoom.cont.tilezoom('zoom', level, {});
			},

			fullscreen: function fullscreen() {

				if (screenfull.enabled) {

					screenfull.toggle($('body').get(0));
				}
			},

			beforeZoom: function beforeZoom(oldLevel, newLevel) {

				if (oldLevel != newLevel) {
					// Todo: check photooverlay: what is it? Do we need it?
					//var photoOverlayController = this.get('controllers.PhotoOverlay');
					//photoOverlayController.send('closeDialog');
				}
			},

			afterZoom: function afterZoom(coords, level) {

				var me = this,
				    tilezoom = me.tilezoom;

				//          e.set('_isMaxZoomlevel', ( level == me.get('maxZoomlevel') ) );
				me.set('isMaxZoomlevel', true);

				if (me.get('currentZoomLevel') != level) {

					me.refreshPhotoTiles();
				}

				me.set('currentZoomLevel', level);
			},

			resize: function resize($tilezoom) {

				var me = this,
				    height = $('.app-tilezoom-contain').height();

				$tilezoom = $tilezoom ? $tilezoom : me.get('tilezoom').cont;
				$tilezoom.height(height);

				$tilezoom.tilezoom('updateBoundaries');

				// whenResize maybe carefully used,
				// when a function is called on the "wrong" mosaic
				// and we need to reload it and cameback to it again
				var whenResize = this.get('whenResize');
				if (whenResize && whenResize.action) {
					this[whenResize.action].apply(this, whenResize.args);
					this.set('whenResize', null);
				} else if (!me.firstResize) {
					// refresh layout
					me.firstResize = true;
					$tilezoom.tilezoom('center');
				} else {
					$tilezoom.tilezoom('updateLayout');
				}
			},

			setSize: function setSize($tilezoom) {

				var me = this,
				    height = $('.app-tilezoom-contain').height(),
				    width = $('.app-tilezoom-contain').width();

				// whenResize maybe carefully used,
				// when a function is called on the "wrong" mosaic
				// and we need to reload it and cameback it again
				var whenResize = this.get('whenResize');
				if (whenResize && whenResize.action) {
					this.set('whenResize', null);
				}

				$tilezoom = $tilezoom ? $tilezoom : me.get('tilezoom').cont;
				$tilezoom.height(height);
				$tilezoom.tilezoom('updateBoundaries');
			}

		}
	});

});