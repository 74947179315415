define('wow-app/adapters/picture', ['exports', 'ember-data', 'ember'], function (exports, DS, Ember) {

	'use strict';

	var inflector = Ember['default'].Inflector.inflector;
	Ember['default'].Inflector.inflector.rules.uncountable['picture'] = true;
	inflector.irregular('picture', 'picture/list');
	//inflector.uncountable('advice');

	exports['default'] = DS['default'].RESTAdapter.extend({
		namespace: '/api/v1',

		//headers: {
		//	'API_KEY': 'secret key',
		//	'ANOTHER_HEADER': 'Some header value'
		//},

		deleteRecord: function deleteRecord() {
			return true;
		},

		findQuery: function findQuery(store, type, id, snapshot) {
			var url = this.namespace + '/picture/list'; //this.buildURL(type.modelName, id, snapshot, 'findRecord');
			return new Ember['default'].RSVP.Promise(function (resolve, reject) {
				jQuery.ajax({
					type: 'GET',
					url: url,
					dataType: 'json',
					data: id
				}).then(function (data) {
					Ember['default'].run(null, resolve, data);
				}, function (jqXHR) {
					jqXHR.then = null; // tame jQuery's ill mannered promises
					Ember['default'].run(null, reject, jqXHR);
				});
			});
		},

		// Default is already a put on picture/pic-id with {picture:()} object
		createRecord: function createRecord(store, type, snapshot) {

			var json = snapshot.record.toJSON({ includeId: true });
			json = { picture: json };
			var id = snapshot.get('id');

			var url = this.namespace + '/picture/' + id;

			return new Ember['default'].RSVP.Promise(function (resolve, reject) {
				jQuery.ajax({
					type: 'PUT',
					url: url,
					contentType: "application/json; charset=utf-8",
					dataType: 'json',
					data: JSON.stringify(json)
				}).then(function (data) {
					Ember['default'].run(null, resolve, data);
				}, function (jqXHR) {
					jqXHR.then = null; // tame jQuery's ill mannered promises
					Ember['default'].run(null, reject, jqXHR);
				});
			});
		}

		//// Default is already a put on picture/pic-id with {picture:()} object
		//updateRecord: function(store, type, snapshot) {
		//	//if(typeof console === 'object') { console.log('PictureAdpater.updateRecord store, type, record',store, type, snapshot); }
		//
		//	//var data = this.serialize(snapshot, { includeId: true });
		//	var json = snapshot.record.toJSON({ includeId: true });
		//    json = {picture:json};
		//	var id = snapshot.get('id');
		//
		//	//var url = this.namespace+'/'+['picture', id].join('/');
		//	var url = this.namespace+'/picture/'+id;
		//
		//	if(typeof console === 'object') { console.log('PictureAdpater.updateRecord',json); }
		//
		//	return new Ember.RSVP.Promise(function(resolve, reject) {
		//		jQuery.ajax({
		//			type: 'PUT',
		//			url: url,
		//			contentType: "application/json; charset=utf-8",
		//			dataType: 'json',
		//			data: JSON.stringify(json)
		//		}).then(function(data) {
		//			Ember.run(null, resolve, data);
		//		}, function(jqXHR) {
		//			jqXHR.then = null; // tame jQuery's ill mannered promises
		//			Ember.run(null, reject, jqXHR);
		//		});
		//	});
		//}
	});

});